import styled from "styled-components";

export const ResultEventsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 30rem;

  margin: 30rem 0;

  @media screen and (min-width: 481px) {
    gap: 30px;
    margin: 30px 0;
  }
`;
