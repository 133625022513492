import styled from "styled-components";

export const DropdownDtpAnyLocationCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 20px 0px;
  gap: 10px;
`;

export const DtpAnyLocationCellIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const DtpAnyLocationCellTitle = styled.div`
  color: var(--icon-4d-black, #4d4d4d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
