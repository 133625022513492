import { styled } from "styled-components";

export const EventCellMobileCardAddressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20rem;
  width: 374rem;
  z-index: 2;

  .icon {
    margin-left: 20rem;
    width: 20rem;
    height: 20rem;
    flex-shrink: 0;
  }

  .title {
    margin-right: 16rem;
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
