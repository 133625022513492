import React, { memo, useContext } from "react";
import {
  WhoWeAreLeftContent,
  WhoWeAreLeftLeftBottom,
  WhoWeAreLeftMainTitle,
  WhoWeAreLeftSubTitle,
  WhoWeAreLeftWrapper,
  WhoWeArePoster,
  WhoWeArePosterWrapper,
  WhoWeAreWrapper,
} from "./style";

import KTWindowSizeContext from "../../../../common/context/windowSizeContext";

const KTWhoWeAre = memo(() => {
  const windowWidth = useContext(KTWindowSizeContext)?.windowSize?.width;
  if (windowWidth >= 1024) {
    return (
      <WhoWeAreWrapper>
        <WhoWeAreLeftWrapper>
          <WhoWeAreLeftMainTitle>WHO WE ARE</WhoWeAreLeftMainTitle>
          <WhoWeAreLeftLeftBottom>
            <WhoWeAreLeftSubTitle>
              The Evolution from Kustom to Unparalleled Live Experiences at
              KustomTickets
            </WhoWeAreLeftSubTitle>
            <WhoWeAreLeftContent>
              At the core of America's music evolution, a fusion of the thrill
              of hot rods and the soulful beats of the mid-1960s birthed the
              iconic Kustom brand. Emerging from this legacy, KustomTickets
              embodies a seamless harmony of sound, style, and unforgettable
              experiences.
            </WhoWeAreLeftContent>
          </WhoWeAreLeftLeftBottom>
        </WhoWeAreLeftWrapper>
        <WhoWeArePosterWrapper>
          <WhoWeArePoster
            src={require("@/assets/img/about-us/who-we-are-poster.png")}
          />
        </WhoWeArePosterWrapper>
      </WhoWeAreWrapper>
    );
  } else {
    return (
      <WhoWeAreWrapper>
        <WhoWeAreLeftWrapper>
          <WhoWeAreLeftMainTitle>WHO WE ARE</WhoWeAreLeftMainTitle>
          <WhoWeArePosterWrapper>
            <WhoWeArePoster
              src={require("@/assets/img/about-us/who-we-are-poster.png")}
            />
          </WhoWeArePosterWrapper>
          <WhoWeAreLeftLeftBottom>
            <WhoWeAreLeftSubTitle>
              The Evolution from Kustom to Unparalleled Live Experiences at
              KustomTickets
            </WhoWeAreLeftSubTitle>
            <WhoWeAreLeftContent>
              At the core of America's music evolution, a fusion of the thrill
              of hot rods and the soulful beats of the mid-1960s birthed the
              iconic Kustom brand. Emerging from this legacy, KustomTickets
              embodies a seamless harmony of sound, style, and unforgettable
              experiences.
            </WhoWeAreLeftContent>
          </WhoWeAreLeftLeftBottom>
        </WhoWeAreLeftWrapper>
      </WhoWeAreWrapper>
    );
  }
});

export default KTWhoWeAre;
