import styled from "styled-components";

export const CategoryTitleWrapper = styled.div`
  margin: 0rem 20rem;
  padding: 20rem 0rem;

  color: #19192a;
  font-family: Inter;
  font-size: 24rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  border-bottom: 1rem solid #f2f2f2;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    margin: 0rem 30px;
    padding: 20px 0rem;

    color: #19192a;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    border-bottom: 1px solid #f2f2f2;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    margin: 0rem 50px;
    padding: 30px 0rem;

    color: #19192a;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    border-bottom: 1px solid #f2f2f2;
  }

  @media screen and (min-width: 1920px) {
    margin: 0rem 100px;
    padding: 40px 0rem;

    color: #19192a;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    border-bottom: 1px solid #f2f2f2;
  }
`;
