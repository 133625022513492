import React, { memo } from "react";
import { MainMenuDropdownTitle, MainMenuDropdownWrapper } from "./style";
import { Dropdown } from "antd";
import { withRouter } from "react-router-dom";

const KTMainMenuDropdown = memo((props) => {
  const { children } = props;
  const items = [
    {
      label: <MainMenuDropdownTitle>Musics</MainMenuDropdownTitle>,
      key: 0,
      onClick: () => {
        props.history.push("/music");
      },
    },
    {
      type: "divider",
      style: { margin: "0px 20px" },
    },
    {
      label: <MainMenuDropdownTitle>Sports</MainMenuDropdownTitle>,
      key: 1,
      onClick: () => {
        props.history.push("/sports");
      },
    },
    {
      type: "divider",
      style: { margin: "0px 20px" },
    },
    {
      label: <MainMenuDropdownTitle>Shows</MainMenuDropdownTitle>,
      key: 2,
      onClick: () => {
        props.history.push("/shows");
      },
    },
  ];

  function dropdownRender(menu) {
    return (
      <MainMenuDropdownWrapper>
        {React.cloneElement(menu, {
          style: { boxShadow: "none" },
        })}
      </MainMenuDropdownWrapper>
    );
  }

  return (
    <Dropdown
      arrow
      autoAdjustOverflow={false}
      destroyPopupOnHide={false}
      placement={"bottomRight"}
      trigger={["click"]}
      menu={{ items }}
      dropdownRender={dropdownRender}
    >
      {children}
    </Dropdown>
  );
});

export default withRouter(KTMainMenuDropdown);
