import React, { memo } from "react";
import {
  SearchDropdownVenueCellName,
  SearchDropdownVenueCellPortrait,
  SearchDropdownVenueCellWrapper,
} from "./style";

const KTSearchDropdownVenueCell = memo(({ venue, onClick }) => {
  return (
    <SearchDropdownVenueCellWrapper
      onClick={() => {
        onClick(venue);
      }}
    >
      <SearchDropdownVenueCellPortrait
        src={require("@/assets/img/icons/icon-venue-default@3x.png")}
      />
      <SearchDropdownVenueCellName>{venue?.name}</SearchDropdownVenueCellName>
    </SearchDropdownVenueCellWrapper>
  );
});

export default KTSearchDropdownVenueCell;
