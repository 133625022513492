export const headerFilterItems = [
  {
    title: "ALL\nEVENTS",
    mark: 0,
    to: "/home/all",
    maxWidth: 74,
  },

  // {
  //   title: "EDITOR’S\nCHOICE",
  //   mark: 1,
  //   to: "/home/editors",
  //   maxWidth: 74,
  // },

  // {
  //   title: "TOP\nSELLING",
  //   mark: 2,
  //   to: "/home/top",
  //   maxWidth: 84,
  // },

  {
    title: "TRENDING\nEVENTS",
    mark: 4,
    to: "/home/trending",
    maxWidth: 84,
  },

  // {
  //   title: "BASED ON\nYOUR LIKES",
  //   mark: 3,
  //   to: "/home/like",
  //   maxWidth: 100,
  // },
];

export const performerHeaderItems = [
  {
    title: "Events",
    mark: 0,
    to: "events",
  },
  {
    title: "Parking",
    mark: 0,
    to: "parking",
  },
  {
    title: "About",
    mark: 0,
    to: "about",
  },
];

/**
 * 0: Any Dates
 * 1:This Weekend
 * 2:Next Weekend
 * 3:Next 7 Days
 * 4:Next 30 Days
 * 5:Next 60 Days
 * 6:Custom
 */
export const homeFilterDateIntervals = [
  {
    title: "Any Dates",
    value: "0",
  },
  {
    title: "This Weekend",
    value: "1",
  },
  {
    title: "Next Weekend",
    value: "2",
  },
  {
    title: "Next 7 Days",
    value: "3",
  },
  {
    title: "Next 30 Days",
    value: "4",
  },
  {
    title: "Next 60 Days",
    value: "5",
  },
  {
    title: "Custom",
    value: "6",
  },
];

export const allEventsFilterDesktopCategorys = [
  {
    title: "All Categories",
    keyWord: "",
  },
  {
    title: "Concert",
    keyWord: "Concert",
  },
  {
    title: "Festival",
    keyWord: "Festival",
  },
  {
    title: "Football",
    keyWord: "Football",
  },
  {
    title: "Baseball",
    keyWord: "Baseball",
  },
  {
    title: "Hockey",
    keyWord: "Hockey",
  },
  {
    title: "Basketball",
    keyWord: "Basketball",
  },
  {
    title: "Soccer",
    keyWord: "Soccer",
  },
  {
    title: "Tennis",
    keyWord: "Tennis",
  },
  {
    title: "Golf",
    keyWord: "Golf",
  },
  {
    title: "Wrestling",
    keyWord: "Wrestling",
  },
  {
    title: "Boxing",
    keyWord: "Boxing",
  },
  {
    title: "Mixed Martial Arts",
    keyWord: "Mixed Martial Arts",
  },
  {
    title: "Auto Racing",
    keyWord: "Auto Racing",
  },
  {
    title: "Motorcycle",
    keyWord: "Motorcycle",
  },
  {
    title: "Horse Racing",
    keyWord: "Horse Racing",
  },
  {
    title: "Other racing",
    keyWord: "Other racing",
  },
  {
    title: "Lacrosse",
    keyWord: "Lacrosse",
  },
  {
    title: "Rodeo",
    keyWord: "Rodeo",
  },
  {
    title: "Volleyball",
    keyWord: "Volleyball",
  },
  {
    title: "Skating",
    keyWord: "Skating",
  },
  {
    title: "Rugby",
    keyWord: "Rugby",
  },
  {
    title: "Softball",
    keyWord: "Softball",
  },
  {
    title: "Gymnastics",
    keyWord: "Gymnastics",
  },
  {
    title: "Other Sports",
    keyWord: "Other Sports",
  },
  {
    title: "Broadway",
    keyWord: "Broadway",
  },
  {
    title: "Ballet",
    keyWord: "Ballet",
  },
  {
    title: "Musical/Play",
    keyWord: "Musical/Play",
  },
  {
    title: "Opera",
    keyWord: "Opera",
  },
  {
    title: "Dance",
    keyWord: "Dance",
  },
  {
    title: "Children/Family",
    keyWord: "Children/Family",
  },
  {
    title: "Las Vegas Shows",
    keyWord: "Las Vegas Shows",
  },
  {
    title: "Cirque Du Soleil",
    keyWord: "Cirque Du Soleil",
  },
  {
    title: "West End",
    keyWord: "West End",
  },
  {
    title: "Circus",
    keyWord: "Circus",
  },
  {
    title: "Fairs/Festival",
    keyWord: "Fairs/Festival",
  },
  {
    title: "Magic Shows",
    keyWord: "Magic Shows",
  },
  {
    title: "Religious",
    keyWord: "Religious",
  },
  {
    title: "Lecture",
    keyWord: "Lecture",
  },
  {
    title: "Film",
    keyWord: "Film",
  },
  {
    title: "Museum/Exhibit",
    keyWord: "Museum/Exhibit",
  },
  {
    title: "Taped Program (TV/Radio)",
    keyWord: "Taped Program (TV/Radio)",
  },
  {
    title: "Adult",
    keyWord: "Adult",
  },
  {
    title: "Other Shows",
    keyWord: "Other Shows",
  },
];
