import React, { memo, useEffect, useState } from "react";
import { SportsWrapper } from "./style";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTMobileEventList from "@/components/mobile-components/mobile-event-list";
import KTMainCategoryHeader from "../../components/main-category-header";
import KTCategoriesSilderBar from "../../components/categories-silder-bar";
import KTSpace from "@/components/space";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import KTEventsListView from "@/components/event/events-list-view";
import {
  getChangeSportsCategoriesAction,
  getChangeSportsLayoutModeAction,
  getSportsEventsAction,
} from "./store/actionCreators";
import { sportsCategories } from "./local-data";
import KTMLyoutModeBar from "../../components/layout-mode/m-layout-mode-bar";
import KTSportsMobFilterBar from "./c-components/sports-mob-filter-bar";
import axios from "axios";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import KTMusicsCategory from "../musics/c-components/musics-category";
import KTSportsCategory from "./c-components/sports-category";
import { Affix } from "antd";
import KTSportsDtpFiterBar from "./c-components/sports-dtp-filter-bar";
import KTEventLoadMore from "../../components/event/event-load-more";


const KTSports = memo(({ windowSize }) => {
  const dispatch = useDispatch();
  const [lastCancneSource, setLastCancneSource] = useState();
  var selectedCategories=[];
  const {
    sortMode,
    layoutMode,
    showLoadMore = false,
    events = [],
    pagenum = 1,
    city = {},
    categories =[],
    intervalType = "0",
    startdt = undefined,
    enddt = undefined,
  } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
      sortMode: state.getIn(["sports", "sortMode"]),
      layoutMode: state.getIn(["sports", "layoutMode"]),
      showLoadMore: state.getIn(["sports", "showLoadMore"]),
      events: state.getIn(["sports", "events"]),
      pagenum: state.getIn(["sports", "pagenum"]),
      categories: state.getIn(["sports", "categories"]),
      intervalType: state.getIn(["sports", "intervalType"]),
      startdt: state.getIn(["sports", "startdt"]),
      enddt: state.getIn(["sports", "enddt"]),
    }),
    shallowEqual
  );
  console.log("*************************sports selected:");
  console.log(categories);

  useEffect(() => {
    getSportsEvents(1);
  }, [dispatch, city, sortMode, categories, intervalType, startdt, enddt]);

  const onclickLoadMoreButton = () => {
    getSportsEvents(pagenum + 1);
  };

  function getSportsEvents(pageNumber) {
    if (!!lastCancneSource) {
      lastCancneSource.cancel();
    }

    const cancelSource = axios.CancelToken.source();
    setLastCancneSource(cancelSource);

    dispatch(
      getSportsEventsAction(
        pageNumber,
        city,
        sortMode,
        cancelSource,
        categories,
        intervalType,
        startdt,
        enddt
      )
    );
  }

  function onChangeSelectdCategories(categories) {
    console.log("onChangeSelectdCategories", categories);
    dispatch(getChangeSportsCategoriesAction(categories));
  }

  const onclickChangeLayoutMode = (mode) => {
    dispatch(getChangeSportsLayoutModeAction(mode));
  };

  const windowWidth = windowSize.width;

  const headerImageSrc =
    windowWidth > 480
      ? require("@/assets/img/sports/dtp-header-sports.jpg")
      : require("@/assets/img/sports/mob-header-sports.jpg");

  const headerTitle = windowWidth > 1024 ? "Sports Tickets" : null;

  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <SportsWrapper>
        <KTMainCategoryHeader
          backgroundSrc={headerImageSrc}
          title={headerTitle}
        />
        {windowWidth > 480 ? (
          <KTMusicsCategory
            categories={sportsCategories.filter(
              (ele) => ele.keyWord.length > 0
            )}
            selectedCategories={categories}
            onChangeSelectdCategories={onChangeSelectdCategories}
          />
        ) : (
          <KTCategoriesSilderBar
            title="Categories"
            categories={sportsCategories}
            selectedCategories={categories}
            onChangeSelectdCategories={onChangeSelectdCategories}
          />
        )}
        {console.log("sports selected:")}

        {windowWidth > 1024
          ? [
              <KTSpace height="40px" />,
              <Affix offsetTop={0}>
                <KTSportsDtpFiterBar />
              </Affix>,
            ]
          : [
              <KTSpace height="14rem" />,
              <Affix offsetTop={0}>
                <KTSportsMobFilterBar />
              </Affix>,
              <KTMLyoutModeBar
                layoutMode={layoutMode}
                didChangeLayoutMode={(newLayoutMode) => {
                  onclickChangeLayoutMode(newLayoutMode);
                }}
              />,
            ]}

        {windowWidth > 480 ? (
          <KTEventsListView
            key={layoutMode}
            events={events}
            layoutMode={layoutMode}
          />
        ) : (
          <KTMobileEventList events={events} layoutMode={layoutMode} />
        )}

        {showLoadMore ? (
          <KTEventLoadMore onLoadMoreClick={onclickLoadMoreButton} />
        ) : null}
      </SportsWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTSports);
