import styled from "styled-components";
import { KT_THEME } from "../../common/constants";

export const MobileHeaderBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 70rem;
  justify-content: space-between;
  align-items: center;

  padding: 0 20rem;

  background-color: ${(props) =>
    props.theme === KT_THEME.DEFAULT ? "rgba(0, 0, 0, 0.2)" : "#fff"};
  border-bottom: ${(props) =>
    props.theme === KT_THEME.DEFAULT ? "" : "1rem solid #F2F2F2"};
`;

export const HeaderLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`;

export const HeaderLeftLogo = styled.img`
  width: 63rem;
  height: 34rem;
  flex-shrink: 0;
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 20rem;
`;

export const HeaderBarActionItem = styled.img`
  width: 30rem;
  height: 30rem;
  flex-shrink: 0;
`;
