import { styled } from "styled-components";

export const HomeFilterDropdownItem = styled.div`
  color: #8c8c8c;
  text-align: left;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 200px;
  margin: 12px 24px;

  @media screen and (max-width: 480px) {
    font-size: 14rem;
    min-width: 200rem;
    margin: 12rem 24rem;
  }
`;

export const HomeFilterDropdownDivider = styled.div`
  width: auto;
  height: 1px;
  margin: 0 18px;
  background: #8c8c8c;

  @media screen and (max-width: 480px) {
    height: 1rem;
    margin: 0 18rem;
  }
`;
