import React, { memo, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  HeaderBarActionItem,
  HeaderLeftLogo,
  HeaderLeftWrapper,
  HeaderRightWrapper,
  MobileHeaderBarWrapper,
} from "./style";
import KTAppMobileMenu from "@/components/mobile-components/mobile-app-menu";
import { Drawer } from "antd";
import { KT_THEME } from "../../common/constants";

const KTMobileHeaderBar = memo((props) => {
  const { theme = KT_THEME.DEFAULT } = props;
  function onEnumClick() {
    setOpenMenu(true);
  }

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <MobileHeaderBarWrapper theme={theme}>
      <HeaderLeftWrapper>
        <HeaderLeftLogo
          src={require(`@/assets/img/nav/${
            theme === KT_THEME.DEFAULT ? "logo-white" : "logo-black"
          }.png`)}
          onClick={() => props.history.push("/")}
        />
      </HeaderLeftWrapper>
      <HeaderRightWrapper>
        <HeaderBarActionItem
          src={
            theme === KT_THEME.DEFAULT
              ? require(`@/assets/img/icons/icon-menu@3x.png`)
              : require(`@/assets/img/icons/icon-menu-b.png`)
          }
          onClick={onEnumClick}
        />
      </HeaderRightWrapper>

      <Drawer
        closeIcon={null}
        placement="right"
        width="374rem"
        drawerStyle={{ header: "none" }}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        bodyStyle={{ padding: "0" }}
      >
        <KTAppMobileMenu onClose={() => setOpenMenu(false)} />
      </Drawer>
    </MobileHeaderBarWrapper>
  );
});

export default withRouter(KTMobileHeaderBar);
