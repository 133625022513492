import React, { memo } from "react";
import {
  ConcertInfoItemBottomWrapper,
  ConcertInfoItemTopWrapper,
  ConcertInfoItemWrapper,
} from "./style";
import { ReactComponent as LikeIcon } from "@/assets/img/svg/icon-like.svg";

const KTConcertInfoItem = memo(({ concert }) => {
  return (
    <ConcertInfoItemWrapper>
      <ConcertInfoItemTopWrapper
        style={{
          backgroundImage:
            "url(" +
            require(`@/assets/img/temporary/${concert.portraitDes}`) +
            ")",
        }}
      >
        {/* <LikeIcon className="like" /> */}
      </ConcertInfoItemTopWrapper>
      <ConcertInfoItemBottomWrapper>
        <div className="name">{concert.name}</div>
        <div className="numberOfEvents">13 Upcoming Events</div>
        <div className="numberInAddress">3 events in Los Angeles</div>
      </ConcertInfoItemBottomWrapper>
    </ConcertInfoItemWrapper>
  );
});

export default KTConcertInfoItem;
