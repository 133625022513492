import React, { memo } from "react";
import {
  PerformerHeaderBackgroundImage,
  PerformerHeaderBackgroundView,
  PerformerHeaderSpace,
  PerformerHeaderWrapper,
} from "./style";

const KTPerformerHeader = memo(({ bgimagePath }) => {
  return (
    <PerformerHeaderWrapper>
      {typeof bgimagePath === "string" && bgimagePath.length > 0 ? (
        <PerformerHeaderBackgroundImage src={bgimagePath} />
      ) : (
        <PerformerHeaderBackgroundView />
      )}

      <PerformerHeaderSpace />
    </PerformerHeaderWrapper>
  );
});

export default KTPerformerHeader;
