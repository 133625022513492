import styled from "styled-components";

export const MobileHomeHeaderWrapper = styled.div`
  height: 52rem;
  margin-top: 25rem;

  padding: 0 20rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 80rem;

  .sender {
    width: 80rem !important;
    height: calc(100% - 6rem);
    text-align: center;

    text-decoration: none;

    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-bottom: 6rem solid transparent;
  }

  .senderActive {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    border-bottom: 6rem solid #fff;
  }
`;
