import React, { memo } from "react";
import {
  ResultInfoBasic,
  ResultInfoCancel,
  ResultInfoCellWrapper,
} from "./style";

const KTResultInfoCell = memo(({ title, onCancelClick }) => {
  return (
    <ResultInfoCellWrapper>
      <ResultInfoBasic>{title}</ResultInfoBasic>
      <ResultInfoCancel onClick={onCancelClick}>Cancel</ResultInfoCancel>
    </ResultInfoCellWrapper>
  );
});

export default KTResultInfoCell;
