import React, { memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChangeSportsTemporalIntervalAction } from "../../store/actionCreators";
import { SportsFilterContentWrapper } from "./style";
import { Affix } from "antd";
import KTFilterContentHeader from "@/components/filter/filter-content-header";
import KTFilterContentDateRange from "@/components/filter/filter-content-date-range";
import { sportsFilterDateIntervals } from "../../local-data";

const KTSportsFilterContent = memo(({ onClose }) => {
  const dispatch = useDispatch();
  const { intervalType, startdt, enddt } = useSelector(
    (state) => ({
      intervalType: state.getIn(["sports", "intervalType"]),
      startdt: state.getIn(["sports", "startdt"]),
      enddt: state.getIn(["sports", "enddt"]),
    }),
    shallowEqual
  );

  function onChangeIntervalValue(ele) {
    dispatch(
      getChangeSportsTemporalIntervalAction(ele.value, ele.startdt, ele.enddt)
    );
  }

  function onResetClick() {
    dispatch(getChangeSportsTemporalIntervalAction("0", undefined, undefined));
  }

  return (
    <SportsFilterContentWrapper>
      <Affix offsetTop={0}>
        <KTFilterContentHeader onClose={onClose} onReset={onResetClick} />
      </Affix>

      <KTFilterContentDateRange
        dateIntervals={sportsFilterDateIntervals}
        startdt={startdt}
        enddt={enddt}
        selectedIntervalValue={intervalType}
        didIntervalValueChange={onChangeIntervalValue}
      />
    </SportsFilterContentWrapper>
  );
});

export default KTSportsFilterContent;
