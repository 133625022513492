import styled from "styled-components";

export const MLyoutModeBarWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10rem;

  margin: 20rem 30rem;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    margin: 20px 30px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    margin: 30px 50px;
  }

  @media screen and (min-width: 1920px) {
    margin: 30px 100px;
  }
`;

export const MLyoutModeBarTopNoEvents = styled.div`
  color: #8c8c8c;
  font-family: Inter;
  font-size: 15rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const MLyoutModeBarBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MLyoutModeBarLeftWrapper = styled.div``;

export const MLyoutModeBarLeftAddressWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 14rem;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) {
    gap: 14px;
  }
`;

export const MLyoutModeBarLeftAddressImage = styled.img`
  width: 18rem;
  height: 18rem;

  @media screen and (min-width: 481px) {
    width: 18px;
    height: 18px;
  }
`;

export const MLyoutModeBarLeftAddressTitle = styled.div`
  color: #284e9f;
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;

  @media screen and (min-width: 481px) {
    font-size: 14px;
  }
`;

export const MLyoutModeBarRightContent = styled.div`
  .layoutModel {
    width: 18rem;
    height: 18rem;
    flex-shrink: 0;
    stroke: #c8c8c8;

    cursor: pointer;
    user-select: none;
  }

  .layoutModelActive {
    stroke-width: 1.2rem;
    stroke: #19192a;
  }

  @media screen and (min-width: 481px) {
    .layoutModel {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      stroke: #c8c8c8;
    }

    .layoutModelActive {
      stroke-width: 1.2px;
      stroke: #19192a;
    }
  }
`;
