import { styled } from "styled-components";

export const EventCellTypeWrapper = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .icon {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
  }

  .title {
    color: #4d4d4d;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EventCellTypeTitle = styled.div`
  color: var(--icon-4d-black, #4d4d4d);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const EventCellTypeImage = styled.img`
  width: 46px;
  height: 46px;
`;
