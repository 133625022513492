import styled, { css } from "styled-components";
import { currValue2 } from "@/utils";

export const SportsCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 178px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(226, 23, 55, 0.06);

  ${({ windowWidth }) => css`
    margin: 0 ${currValue2(22, 72, windowWidth)};
  `}
`;
