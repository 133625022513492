import React, { memo, useContext } from "react";
import {
  AboutRememberPoster,
  AboutRememberSubTitle,
  AboutRememberTitle,
  AboutRememberWrapper,
} from "./style";
import KTWindowSizeContext from "../../../../common/context/windowSizeContext";

const KTAboutRemember = memo(() => {
  const windowWidth = useContext(KTWindowSizeContext)?.windowSize?.width;
  return (
    <AboutRememberWrapper>
      <AboutRememberTitle>
        Tickets to Thrill, Moments to Remember.
      </AboutRememberTitle>
      {windowWidth >= 1024
        ? [
            <AboutRememberSubTitle>
              Under the Kustom Entertainment banner, KustomTickets takes pride
              in our association with the highly acclaimed Country Roots Music
              Festival. Offering both primary and secondary ticket sales, our
              dedication surpasses mere transactions. We strive to deliver
              exceptional service and unwavering support, ensuring a seamless,
              convenient, and unforgettable experience for every attendee.
            </AboutRememberSubTitle>,
            <AboutRememberPoster
              controls
              autoplay="autoplay"
              muted
              playsinline
              loop
              src={require("@/assets/video/about-us.mp4")}
            />,
          ]
        : [
            <AboutRememberPoster
              controls
              autoplay="autoplay"
              muted
              playsinline
              loop
              src={require("@/assets/video/about-us.mp4")}
            />,
            <AboutRememberSubTitle>
              Under the Kustom Entertainment banner, KustomTickets takes pride
              in our association with the highly acclaimed Country Roots Music
              Festival. Offering both primary and secondary ticket sales, our
              dedication surpasses mere transactions. We strive to deliver
              exceptional service and unwavering support, ensuring a seamless,
              convenient, and unforgettable experience for every attendee.
            </AboutRememberSubTitle>,
          ]}
    </AboutRememberWrapper>
  );
});

export default KTAboutRemember;
