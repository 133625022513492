import styled from "styled-components";

export const SCTICancelButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
  user-select: none;
`;

export const SCTICancelButtonIcon = styled.img`
  width: 10px;
  height: 10px;
`;

export const SCTICancelButtonTitle = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
