import React, { memo } from "react";
import { AppHeaderLeftContentLogo, AppHeaderLeftContentWrapper } from "./style";
import { KT_THEME } from "../../../../common/constants";
import KTAppHeaderCenter from "../app-header-center";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const KTAppHeaderLeftContent = memo(({ theme = KT_THEME.DEFAULT }) => {
  return (
    <AppHeaderLeftContentWrapper>
      <NavLink to="/" key="logo">
        <AppHeaderLeftContentLogo
          src={require(`@/assets/img/nav/${
            theme === KT_THEME.DEFAULT ? "logo-white" : "logo-black"
          }.png`)}
        />
      </NavLink>

      <KTAppHeaderCenter theme={theme} />
    </AppHeaderLeftContentWrapper>
  );
});

export default KTAppHeaderLeftContent;
