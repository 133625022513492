import React, { memo } from "react";
import {
  TeamListHorizontalNavigationWrapper,
  TeamListHorizontalWrapper,
} from "./style";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "swiper/modules";
import "swiper/css/grid";
import KTTeamCell from "../team-cell";

import { ReactComponent as ArrowLeft } from "@/assets/img/icons/icon-arrow-circle-left.svg";
import { ReactComponent as ArrowRight } from "@/assets/img/icons/icon-arrow-circle-right.svg";

import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { currValue } from "../../../utils";
SwiperCore.use([Navigation]);

const KTTeamListHorizontal = memo(
  ({ teams, windowWidth = window.screen.width }) => {
    const rowNumber = Math.ceil(teams.length / 2.0);

    const slides = teams.map((item, index) => {
      const bottom = index < rowNumber ? "30px" : "0";
      return (
        <SwiperSlide className="ktSwiperSlide">
          <KTTeamCell
            marginRight={
              windowWidth > 480 ? currValue(30, 40, windowWidth) : "0"
            }
            marginBottom={windowWidth > 480 ? bottom : "0"}
            team={item}
            key={item.title}
          />
        </SwiperSlide>
      );
    });

    let swiper;

    return (
      <TeamListHorizontalWrapper>
        <Swiper
          modules={[Grid]}
          className="ktSwiper"
          slidesPerView={"auto"}
          spaceBetween={windowWidth > 480 ? "0" : "14rem"}
          grabCursor={true}
          grid={{
            fill: "row",
            rows: 2,
          }}
          onInit={(s) => (swiper = s)}
          onResize={(s) => (swiper = s)}
          style={{
            padding:
              windowWidth > 480
                ? `0 ${currValue(30, 60, windowWidth)} 0 ${currValue(
                    50,
                    100,
                    windowWidth
                  )}`
                : "0 20rem",
          }}
        >
          {slides}
        </Swiper>

        {windowWidth > 480
          ? [
              <TeamListHorizontalNavigationWrapper
                style={{
                  padding:
                    windowWidth > 480
                      ? "0 " + currValue(50, 100, windowWidth)
                      : "0 20rem",
                  margin: `${currValue(40, 60, windowWidth)} 0`,
                }}
              >
                <ArrowLeft
                  className="swiperButtonPrev"
                  onClick={() => {
                    if (swiper) {
                      swiper.slidePrev();
                    }
                  }}
                />
                <ArrowRight
                  className="swiperButtonNext"
                  onClick={() => {
                    if (swiper) {
                      swiper.slideNext();
                    }
                  }}
                />
              </TeamListHorizontalNavigationWrapper>,
              <div className="line" />,
            ]
          : null}
      </TeamListHorizontalWrapper>
    );
  }
);

export default KTTeamListHorizontal;
