import React, { memo } from "react";
import { ReactComponent as CloseButton } from "@/assets/img/icons/icon-close-button.svg";
import { ReactComponent as Portrait } from "@/assets/img/icons/icon-defalut-portrait.svg";

import { MAMenuHeaderProfileWrapper, MAMenuHeaderWrapper } from "./style";

const KTMAMenuHeader = memo(({ onClose }) => {
  return (
    <MAMenuHeaderWrapper>
      <CloseButton className="closeButton" onClick={onClose} />
      {/* <MAMenuHeaderProfileWrapper>
        <Portrait className="portrait" />
        <div className="username">Steven Smith</div>
        <div className="email">stevensmith@gmail.com</div>
      </MAMenuHeaderProfileWrapper> */}
    </MAMenuHeaderWrapper>
  );
});

export default KTMAMenuHeader;
