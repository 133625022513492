import styled from "styled-components";

export const DateViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6rem;

  .date {
    color: var(--icon-4d-black, #19192a);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .time {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .week {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divider {
    color: var(--icon-4d-black, #c8c8c8);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
