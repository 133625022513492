import React, { memo, useContext, useEffect, useRef, useState } from "react";

import KTEventCell from "../event-cell";
import { EventsListViewWrapper } from "./style";
import { LAYOUT_MODE_CARD } from "@/common/constants";
import KTEventCellSmall from "../event-cell-small";
import KTEventCellRightImage from "../event-cell-right-image";
import KTWindowSizeContext from "../../../common/context/windowSizeContext";

const KTEventsListView = (props) => {
  const context = useContext(KTWindowSizeContext);
  const componentRef = useRef(null);
  const [componentWidth, setComponentWidth] = useState(
    context?.windowSize?.width
  );

  useEffect(() => {
    setComponentWidth(componentRef.current.offsetWidth);
  }, [context?.windowSize?.width]);

  const { events , layoutMode = LAYOUT_MODE_CARD } = props;

  if (layoutMode === LAYOUT_MODE_CARD) {
    const items = events?.map((item, index) => {
      return <KTEventCellRightImage key={index} event={item} />;
    });
    return (
      <EventsListViewWrapper
        ref={componentRef}
        style={{
          gridTemplateColumns: `repeat(auto-fill, 400px)`,
          rowGap: "60px",
        }}
      >
        {items}
      </EventsListViewWrapper>
    );
  } else {
    const items = events.map((item) => {
      if (componentWidth > 1024) {
        return <KTEventCell key={item.id} event={item} />;
      } else {
        return (
          <KTEventCellSmall
            key={item.id}
            event={item}
            windowWidth={componentWidth}
          />
        );
      }
    });
    return (
      <EventsListViewWrapper
        ref={componentRef}
        style={{
          gridTemplateColumns: "1fr",
          rowGap: "0",
          gap: componentWidth > 1024 ? 0 : "40px",
        }}
      >
        {items}
      </EventsListViewWrapper>
    );
  }
};

export default memo(KTEventsListView);
