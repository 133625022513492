import React, { memo } from "react";
import { FilterContentHeaderWrapper } from "./style";

const KTFilterContentHeader = memo(({ onClose, onReset, windowWidth }) => {
  return (
    <FilterContentHeaderWrapper windowWidth={windowWidth}>
      <div className="reset" onClick={onReset}>
        Reset Filters
      </div>
      <div className="done" onClick={onClose}>
        Done
      </div>
    </FilterContentHeaderWrapper>
  );
});

export default KTFilterContentHeader;
