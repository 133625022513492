import styled from "styled-components";

export const CategoryHeaderWrapper = styled.div`
  position: relative;
`;

export const CategoryHeaderBackgroundImage = styled.img`
  position: absolute;

  top: -70rem;
  height: calc(100% + 70rem);
  width: 100%;
  object-fit: cover;
  z-index: -1;

  @media screen and (min-width: 481px) {
    top: -80px;
    height: calc(100% + 80px);
  }
`;
