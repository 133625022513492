export const ADD_MORE_EVENTS = "SPORTS/ADD_MORE_EVENTS";

export const EMPTY_ADD_MORE_EVENTS = "SPORTS/EMPTY_ADD_MORE_EVENTS";

export const CHANGE_LAYOUT_MODE = "SPORTS/CHANGE_LAYOUT_MODE";

export const CHANGE_PAGENUM = "SPORTS/CHANGE_PAGENUM";

export const CHANGE_SORT_MODE = "SPORTS/CHANGE_SORT_MODE";

export const CHANGE_CATEGORIES = "SPORTS/CHANGE_CATEGORIES";

// 修改时间段
export const CHANGE_TEMPORAL_INTERVAL = "SPORTS/CHANGE_TEMPORAL_INTERVAL";
