import React, { memo } from "react";
import {
  EventCellInfoAddressWrapper,
  EventCellInfoTimeDivider,
  EventCellInfoTimeWrapper,
  EventCellInfoWrapper,
} from "./style";
import { ReactComponent as TimeIcon } from "@/assets/img/svg/icon-time.svg";
import { ReactComponent as AddressIcon } from "@/assets/img/svg/icon-address.svg";
import {
  eventName,
  eventTime,
  eventVenue,
  eventWeek,
} from "../../../../utils/event";

const KTEventCellInfo = memo(({ event }) => {
  return (
    <EventCellInfoWrapper>
      <EventCellInfoTimeWrapper>
        <TimeIcon className="icon" />
        {event.date?.datetime === undefined
          ? [<div className="time">{event.date.text.time}</div>]
          : [
              <div className="time">{eventTime(event)}</div>,
              <EventCellInfoTimeDivider>|</EventCellInfoTimeDivider>,
              <div className="week">{eventWeek(event)}</div>,
            ]}
      </EventCellInfoTimeWrapper>
      <EventCellInfoAddressWrapper>
        <AddressIcon className="icon" />
        <div className="address">{eventVenue(event)}</div>
      </EventCellInfoAddressWrapper>
      <div className="title">{eventName(event)}</div>
    </EventCellInfoWrapper>
  );
});

export default KTEventCellInfo;
