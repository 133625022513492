import React, { memo } from "react";
import { DropdownDtpNoMatchingCellWrapper } from "./style";

const KTDropdownDtpNoMatchingCell = memo(() => {
  return (
    <DropdownDtpNoMatchingCellWrapper>
      {"Sorry, no matches found :("}
    </DropdownDtpNoMatchingCellWrapper>
  );
});

export default KTDropdownDtpNoMatchingCell;
