import styled from "styled-components";

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventPosterWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 400px;
  height: 250px;
  flex-shrink: 0;

  border-radius: 10px;
  background: url(<path-to-image>), lightgray 50% / cover no-repeat;

  .icon {
    width: 28px;
    height: 28px;
    padding: 24px;
  }
`;

export const EventInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  gap: 4px;

  .title {
    color: #000;
    font-family: Merriweather;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 128.523%; /* 23.134px */
  }

  .upcoming {
    color: #c8c8c8;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .inAddress {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;
