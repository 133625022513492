import styled from "styled-components";

export const DropdownAnyLocationCellWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    height: 48rem;
    gap: 14rem;
`;

export const AnyLocationCellIcon = styled.img`
  width: 20rem;
  height: 20rem;
`;

export const AnyLocationCellTitle = styled.div`
  color: var(--icon-4d-black, #4D4D4D);
font-family: Inter;
font-size: 14rem;
font-style: normal;
font-weight: 400;
line-height: normal;
`;
