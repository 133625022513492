import React, { memo } from "react";
import {
  AppFooterListViewGroupTitle,
  AppFooterListViewItemTitle,
  AppFooterListViewWrapper,
} from "./style";

import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const KTAppFooterListView = memo(({ title = "", items = [] }) => {
  const history = useHistory();

  function onItemClick(item) {
    history.push(item.link);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <AppFooterListViewWrapper>
      <AppFooterListViewGroupTitle>{title}</AppFooterListViewGroupTitle>
      {items.map((ele) => {
        return (
          <AppFooterListViewItemTitle onClick={() => onItemClick(ele)}>
            {ele.title}
          </AppFooterListViewItemTitle>
        );
      })}
    </AppFooterListViewWrapper>
  );
});

export default withRouter(KTAppFooterListView);
