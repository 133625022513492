import styled from "styled-components";
import Popup from "reactjs-popup";

export const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    ...;
  }
  // use your custom style for ".popup-content"
  &-content {
    ...;
  }

  &.modal {
    background: orange; // <Thing> tagged with an additional CSS class ".something"
    font-size: 12px;
  }

`;