import React, { memo } from "react";
import { HomeEditorsChoiceWrapper } from "./style";
import KTClassifyHeader from "@/components/classify/classify-header";
import KTClassifySubHeader from "@/components/classify/classify-sub-header";
import KTConcertsList from "@/components/concerts/concerts-list";
import KTTeamListHorizontal from "@/components/team/team-list-horizontal";
import KTClassifySubHeaderMultiple from "@/components/classify/classify-sub-header-multiple";
import { exploreEvents } from "@/common/ephemeral-data";
import KTSpace from "@/components/space";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTEventCellExploreDesktop from "@/components/event/event-cell-explore-desktop";
import { currValue } from "@/utils";
import KTGridView from "@/components/scroll-view/grid-view";
import KTEventCellExplore from "@/components/event/event-cell-explore";
import KTClassifySubMultipleDesktop from "@/components/classify/calssify-sub-multiple-desktop";

const KTHomeEditorsChoice = memo((props) => {
  const { windowSize } = props;

  const concertsSubItems = [
    {
      title: "All",
      emoji: "🤩",
    },
    {
      title: "Concerts",
      emoji: "😊",
    },
    {
      title: "Festivals",
      emoji: "✨",
    },
  ];

  const concerts = [
    {
      name: "Taylor Swift",
      portrait: "artist01.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },
    {
      name: "Artist Name",
      portrait: "artist02.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "William Anderson",
      portrait: "artist03.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Emily Davis",
      portrait: "artist04.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Benjamin Foster",
      portrait: "artist05.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Olivia Parker",
      portrait: "artist06.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist07.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist08.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist09.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist10.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },
  ];

  const sportsSubItems = [
    {
      title: "All",
      emoji: "⛳",
    },
    {
      title: "NFL",
      emoji: "😊",
    },
    {
      title: "MLB",
      emoji: "✨",
    },
    {
      title: "NBA",
      emoji: "⛳",
    },
    {
      title: "NHL",
      emoji: "😊",
    },
    {
      title: "MSL",
      emoji: "✨",
    },
  ];

  const sportsTeams = [
    {
      title: "Inter\nMiami",
      backgroundImageName: "team-background-image-1.svg",
      backgroundColor: "#ECB0C6",
    },
    {
      title: "Kansas City\nChiefs",
      backgroundImageName: "team-background-image-2.svg",
      backgroundColor: "#E31836",
    },
    {
      title: "Minnesota\nWild",
      backgroundImageName: "team-background-image-3.svg",
      backgroundColor: "#004324",
    },
    {
      title: "Manchester\nCity",
      backgroundImageName: "team-background-image-4.svg",
      backgroundColor: "#6CAEDF",
    },
    {
      title: "Chicago\nBears",
      backgroundImageName: "team-background-image-5.svg",
      backgroundColor: "#E64100",
    },
    {
      title: "Boston\nBruins",
      backgroundImageName: "team-background-image-6.svg",
      backgroundColor: "#F4BD50",
    },
    {
      title: "Los Angeles\nLakers",
      backgroundImageName: "team-background-image-7.svg",
      backgroundColor: "#FDB927",
    },
    {
      title: "New York\nYankees",
      backgroundImageName: "team-background-image-8.svg",
      backgroundColor: "#132448",
    },
    {
      title: "Kansas City\nRoyals",
      backgroundImageName: "team-background-image-9.svg",
      backgroundColor: "#004687",
    },
    {
      title: "Chicago\nBulls",
      backgroundImageName: "team-background-image-10.svg",
      backgroundColor: "#BD2C45",
    },
    {
      title: "Los Angeles\nDodgers",
      backgroundImageName: "team-background-image-11.svg",
      backgroundColor: "#005A9C",
    },
  ];

  const exploreSubItems = [
    {
      title: "Examples",
    },
    {
      title: "Cultural",
    },
    {
      title: "immersive theater",
    },
    {
      title: "Stand-up",
    },
    {
      title: "TED-Talks",
    },
    {
      title: "Interactive Art",
    },
    {
      title: "Children’s theatre",
    },
    {
      title: "Opera",
    },
    {
      title: "Festivals",
    },
  ];

  function onArtistClick(item) {
    props.history.push("/artist", { id: "01" });
  }

  const windowWidth = windowSize.width;

  return (
    <HomeEditorsChoiceWrapper>
      {/* Concerts */}
      <KTSpace
        height={windowWidth > 480 ? currValue(60, 80, windowWidth) : "40rem"}
      />
      <KTClassifyHeader
        title={windowSize.width > 480 ? "Music" : "Concerts"}
        windowWidth={windowWidth}
        onClickViewAll={() => console.log("Concerts view all")}
      />
      <KTSpace height={windowWidth > 480 ? "30px" : "30rem"} />
      <KTClassifySubHeader
        items={concertsSubItems}
        windowWidth={windowWidth}
        onChangeItem={(item) => console.log(item)}
      />
      <KTSpace height={windowWidth > 480 ? "46px" : "30rem"} />
      <KTConcertsList
        concerts={concerts}
        windowWidth={windowWidth}
        onConcertClick={onArtistClick}
      />

      {/* Sports */}
      <KTSpace
        height={windowWidth > 480 ? currValue(60, 80, windowWidth) : "54rem"}
      />
      <KTClassifyHeader
        title={"Sports"}
        windowWidth={windowWidth}
        onClickViewAll={() => console.log("Sports view all")}
      />
      <KTSpace height={windowWidth > 480 ? "30px" : "30rem"} />
      <KTClassifySubHeader
        items={sportsSubItems}
        windowWidth={windowWidth}
        onChangeItem={(item) => console.log(item)}
      />
      <KTSpace height={windowWidth > 480 ? "50px" : "30rem"} />
      <KTTeamListHorizontal windowWidth={windowWidth} teams={sportsTeams} />

      {/* Explore New Experiences */}
      <KTSpace
        height={windowWidth > 480 ? currValue(60, 80, windowWidth) : "54rem"}
      />
      <KTClassifyHeader
        title={
          windowWidth > 480
            ? "Explore New Experiences"
            : "Explore New\nExperiences"
        }
        windowWidth={windowWidth}
        onClickViewAll={() => console.log("Explore view all")}
        hiddenViewAll={true}
      />
      <KTSpace height={windowWidth > 480 ? "40px" : "30rem"} />

      {windowWidth > 480 ? (
        <KTClassifySubMultipleDesktop
          windowWidth={windowWidth}
          items={exploreSubItems}
        />
      ) : (
        <KTClassifySubHeaderMultiple items={exploreSubItems} />
      )}

      <KTSpace
        height={windowWidth > 480 ? currValue(60, 100, windowWidth) : "30rem"}
      />

      <KTGridView
        windowWidth={windowWidth}
        items={exploreEvents}
        rowGap={windowWidth > 480 ? "60px" : "30rem"}
        columnGap={windowWidth > 480 ? currValue(30, 80, windowWidth) : "30rem"}
        gridTemplateColumns={
          windowWidth > 480 ? "repeat(auto-fill, 458px)" : "1fr"
        }
        cellOfItem={(item) => {
          return windowWidth > 480 ? (
            <KTEventCellExploreDesktop event={item} />
          ) : (
            <KTEventCellExplore event={item} />
          );
        }}
      />
      <KTSpace height={"30rem"} />
    </HomeEditorsChoiceWrapper>
  );
});

export default withWindowSize(KTHomeEditorsChoice);
