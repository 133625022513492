import styled from "styled-components";

export const AppFooterSubscribeBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  border-radius: 4px;
  background: #f2f2f2;

  width: 440px;
  height: 50px;
  flex-shrink: 0;

  @media screen and (max-width: 1023px) and (min-width: 481px) {
    width: 420px;
    margin-right: 40px;
  }
`;

export const AppFooterSubscribeBarInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .sendInput {
    flex: 1;
    background: #f2f2f2;
    border-radius: 4px 0px 0px 4px;
    border: 0;
    padding: 0;

    color: #797979;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 360px;
  }
`;

export const AppFooterSubscribeBarSendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 50px;
  flex-shrink: 0;

  border-radius: 0px 4px 4px 0px;
  background: #000;

  cursor: pointer;
  user-select: none;
`;

export const AppFooterSubscribeBarSend = styled.img`
  width: 31.333px;
  height: 40px;
  flex-shrink: 0;
`;
