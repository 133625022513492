import styled from "styled-components";

export const EventSimpleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 16rem;
`;

export const EventSimpleContentWrapper = styled.div`
  background-color: black;
  border-radius: 10rem;
  position: relative;
  background-size: cover;
  background-position: center;

  .like {
    position: absolute;
    top: 20rem;
    left: 20rem;
    width: 24rem;
    height: 20rem;
    flex-shrink: 0;
  }
`;

export const EventSimpleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 2rem;

  .title {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 128.523%; /* 16.708px */
    white-space: pre-line;
  }

  .kindName {
    color: #8c8c8c;
    font-family: Inter;
    font-size: 13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 128.523%;
  }
`;
