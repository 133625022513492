import React, { memo } from "react";
import { EventCellBuyButtonWrapper } from "./style";
import { eventBuyButtonTitle } from "../../../../utils/event";

const KTEvnetCellBuyButton = memo(({ event, onClick }) => {
  return (
    <EventCellBuyButtonWrapper onClick={onClick}>
      {eventBuyButtonTitle(event, true)}
    </EventCellBuyButtonWrapper>
  );
});

export default KTEvnetCellBuyButton;
