import React, { memo } from "react";
import { FilterMobBarButtonDrapItem } from "./style";
import { Dropdown } from "antd";
import KTFilterBaritem from "../filter-baritem";

const KTFilterMobBarButton = memo(
  ({ title, switchItems = [], onChangeSwitchItem }) => {
    const onSortModeClick = ({ key }) => {
      const item = switchItems[key];
      onChangeSwitchItem(item);
    };

    const items = switchItems
      .map((ele, index, array) => {
        const resultArr = [
          {
            label: (
              <FilterMobBarButtonDrapItem className="title">
                {ele.title}
              </FilterMobBarButtonDrapItem>
            ),
            key: index,
          },
        ];

        if (index < array.length - 1) {
          resultArr.push({
            type: "divider",
            style: { margin: "0 20rem" },
            key: `divider${ele.sort}`,
          });
        }

        return resultArr;
      })
      .flat();

    return (
      <Dropdown
        autoAdjustOverflow={false}
        menu={{ items, onClick: onSortModeClick }}
        trigger={["click"]}
        overlayStyle={{
          overflow: "hidden",
          borderRadius: "10rem",
          border: "1px solid var(--dvdr-stroke-f2, #F2F2F2)",
          background: " #FFF",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <KTFilterBaritem title={title} />
      </Dropdown>
    );
  }
);

export default KTFilterMobBarButton;
