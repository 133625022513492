import React, { memo, useState } from "react";
import {
  HomeMobileFilterBarWrapper,
  HomeMobileFilterIconMark,
  HomeMobileFilterIconWrapper,
} from "./style";

import { ReactComponent as FilterIcon } from "@/assets/img/icons/icon-filter-option.svg";
import { Drawer } from "antd";
import KTFilterContent from "../filter-content";
import KTFilterSortButton from "./c-components/filter-sort-button";
import { shallowEqual, useSelector } from "react-redux";

const KTHomeMobileFilterBar = memo(({ windowWidth }) => {
  const [filterViewOpen, setFilterViewOpen] = useState(false);

  const { categories = [], intervalType = "0" } = useSelector(
    (state) => ({
      categories: state.getIn(["home", "categories"]),
      intervalType: state.getIn(["home", "intervalType"]),
    }),
    shallowEqual
  );

  const isDefaultFilter = categories.length === 0 && intervalType === "0";

  return (
    <HomeMobileFilterBarWrapper>
      <KTFilterSortButton />
      <HomeMobileFilterIconWrapper>
        <FilterIcon
          className="optionButton"
          onClick={() => setFilterViewOpen(true)}
        />
        {isDefaultFilter ? null : <HomeMobileFilterIconMark />}
      </HomeMobileFilterIconWrapper>

      <Drawer
        closeIcon={null}
        placement="bottom"
        height={`100%`}
        open={filterViewOpen}
        onClose={() => setFilterViewOpen(false)}
        style={{
          borderRadius: "20rem 20rem 0 0",
          background: "#FFF",
        }}
        bodyStyle={{ padding: "0" }}
      >
        <KTFilterContent
          onClose={() => setFilterViewOpen(false)}
          windowWidth={windowWidth}
        />
      </Drawer>
    </HomeMobileFilterBarWrapper>
  );
});

export default KTHomeMobileFilterBar;
