import React, { memo, useCallback, useState } from "react";
import {
  SearchKeywordsDropdownWrapper,
  SearchKeywordsInputWrapper,
} from "./style";
import { Dropdown, Input } from "antd";

import KTResultInfoCell from "./c-components/result-info-cell";
import KTSearchDividerCell from "./c-components/divider-cell";
import KTSearchGroupHeaderBar from "./c-components/group-header-bar";
import KTSearchDivider from "./c-components/search-divider";
import KTSearchPerformer from "./c-components/performer-cell";
import KTSearchEventCell from "./c-components/event-cell";
import KTSearchVenueCell from "./c-components/venue-cell";
import { ReactComponent as SearchButton } from "@/assets/img/svg/icon-search-button.svg";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { getSuggest } from "../../../services/search";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getChangeSearchKeywordsAction } from "../../../pages/result/store/actionCreators";

const KTSearchKeywordsDropdown = memo((props) => {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState({});

  const [inputOnFocus, setInputOnFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [lastCancneSource, setLastCancneSource] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(inputValue.length > 0);
  }, [inputValue]);

  const keywords = suggestions.keywords;
  const events = suggestions.events;
  const performers = suggestions.performers;
  const venues = suggestions.venues;

  const totalResultCount =
    (events?.totalResultCount ?? 0) +
    (performers?.totalResultCount ?? 0) +
    (venues?.totalResultCount ?? 0);

  const items = [];

  const onCancelClick = () => {
    setOpen(false);
  };

  function dropdownRender(menu) {
    const eventsCount = events?.totalResultCount ?? 0;
    const eventsCellCount = eventsCount > 3 ? 3 : eventsCount;
    const eventsCells = events?.results
      ?.slice(0, eventsCellCount)
      .map((ele) => {
        return [
          <KTSearchDivider />,
          <KTSearchEventCell
            event={ele}
            onClick={(event) => {
              setOpen(false);
              const url = window.location.origin + `#/map/${event.id}`;
              window.open(url, "_self", "noopener,noreferrer");
            }}
          />,
        ];
      });

    const performersCount = performers?.totalResultCount ?? 0;
    const performersCellCount = performersCount > 3 ? 3 : performersCount;
    const performersCells = performers?.results
      ?.slice(0, performersCellCount)
      .map((ele) => {
        return [
          <KTSearchDivider />,
          <KTSearchPerformer
            performer={ele}
            onClick={(performer) => {
              setOpen(false);
              props.history.push(`/performer/${performer.id}`);
            }}
          />,
        ];
      });

    const venuesCount = venues?.totalResultCount ?? 0;
    const venuesCellCount = venuesCount > 3 ? 3 : venuesCount;
    const venuesCells = venues?.results
      ?.slice(0, venuesCellCount)
      .map((ele) => {
        return [
          <KTSearchDivider />,
          <KTSearchVenueCell
            venue={ele}
            onClick={(venue) => {
              setOpen(false);
              props.history.push(`/venue/${venue.id}/${venue?.name ?? ""}`);
            }}
          />,
        ];
      });

    return (
      <SearchKeywordsDropdownWrapper>
        <KTResultInfoCell
          title={`${totalResultCount} results for "${keywords}"`}
          onCancelClick={onCancelClick}
        />
        <KTSearchDividerCell />
        <KTSearchGroupHeaderBar
          title={`${performers?.totalResultCount ?? 0}\nPERFORMERS`}
          onExploreMoreClick={() => {
            onCancelClick();
            dispatch(getChangeSearchKeywordsAction(inputValue));
            setOpen(false);
            props.history.push({
              pathname: "/result/performers",
            });
          }}
        />
        {performersCells}

        <KTSearchDividerCell />
        <KTSearchGroupHeaderBar
          title={`${events?.totalResultCount ?? 0}\nEVENTS`}
          onExploreMoreClick={() => {
            onCancelClick();
            dispatch(getChangeSearchKeywordsAction(inputValue));
            setOpen(false);
            props.history.push({
              pathname: "/result/events",
            });
          }}
        />
        {eventsCells}

        <KTSearchDividerCell />
        <KTSearchGroupHeaderBar
          title={`${venues?.totalResultCount ?? 0}\nVenues`}
          onExploreMoreClick={() => {
            onCancelClick();
            dispatch(getChangeSearchKeywordsAction(inputValue));
            setOpen(false);
            props.history.push({
              pathname: "/result/venues",
            });
          }}
        />
        {venuesCells}
      </SearchKeywordsDropdownWrapper>
    );
  }

  const debounce = useCallback((callback, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback.apply(this, args);
      }, delay);
    };
  }, []);

  const handleInputChange = useCallback(
    debounce((value) => {
      onValueChange(value);
    }, 300),
    [debounce]
  );

  function onInputValueChange(e) {
    const value = e.target.value;
    setInputValue(value);
    handleInputChange(value);
  }

  function onValueChange(value) {
    if (!!lastCancneSource) {
      lastCancneSource.cancel();
    }

    const cancelSource = axios.CancelToken.source();
    setLastCancneSource(cancelSource);

    getSuggest(
      value,
      (suggestions) => {
        setSuggestions(suggestions);
      },
      cancelSource
    );
  }

  function onSearchPressEnter() {
    setOpen(false);
    props.history.push({
      pathname: "/result",
    });

    dispatch(getChangeSearchKeywordsAction(inputValue));
    setOpen(false);
  }

  return (
    <Dropdown
      autoAdjustOverflow={false}
      destroyPopupOnHide={false}
      placement={"bottom"}
      trigger={"click"}
      menu={{ items, selectable: true }}
      open={open}
      dropdownRender={dropdownRender}
      onOpenChange={(value) => {
        if (value === false && inputOnFocus === false) {
          setOpen(value);
        }
      }}
    >
      <SearchKeywordsInputWrapper>
        <Input
          bordered={false}
          placeholder={"Search for artists, venues, teams and events"}
          className="input"
          value={inputValue}
          onChange={onInputValueChange}
          onPressEnter={onSearchPressEnter}
          onFocus={() => {
            setInputOnFocus(true);
            setOpen(inputValue.length > 0);
          }}
          onBlur={() => setInputOnFocus(false)}
        />
        <SearchButton className="searchButton" onClick={onSearchPressEnter} />
      </SearchKeywordsInputWrapper>
    </Dropdown>
  );
});

export default withRouter(KTSearchKeywordsDropdown);
