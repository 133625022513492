import { styled } from "styled-components";

export const HomeContentWrapper = styled.div`
  background-color: white;
`;

export const HomeContentLayoutModeWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 26px;
  padding: 0 20px;

  .layoutModel {
    width: 24px;
    height: 24px;
    stroke: #c8c8c8;
  }

  .layoutModelActive {
    stroke: #19192a;
  }

  @media screen and (max-width: 480px) {
    height: 58rem;

    .layoutModel {
      width: 18rem;
      height: 18rem;
      padding: 14rem;
      margin-right: 20rem;
    }
  }

  @media screen and (min-width: 481px) {
    height: 82px;
  }
`;
