import React, { memo } from "react";
import {
  AppHeaderRightContentWrapper,
  AppHeaderRightMenuButton,
} from "./style";
import { headerLinks } from "../../../../common/local-data";
import KTMainMenuDropdown from "../../../dropdown/main-menu-dropdown";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import withWindowSize from "@/components/higher-order/withWindowSize";
import { KT_THEME } from "../../../../common/constants";

const KTAppHeaderRightContent = memo(
  ({ theme = KT_THEME.DEFAULT, windowSize }) => {
    function getChildren() {
      if (windowSize.width > 800) {
        return headerLinks.map((ele) => {
          return (
            <NavLink
              className="menu-item"
              activeClassName="menu-item-active"
              to={ele.link}
            >
              {ele.title}
            </NavLink>
          );
        });
      } else {
        return (
          <KTMainMenuDropdown>
            <AppHeaderRightMenuButton
              src={
                theme === KT_THEME.DEFAULT
                  ? require(`@/assets/img/icons/icon-menu@3x.png`)
                  : require(`@/assets/img/icons/icon-menu-b.png`)
              }
            />
          </KTMainMenuDropdown>
        );
      }
    }
    return (
      <AppHeaderRightContentWrapper theme={theme}>
        {getChildren()}
      </AppHeaderRightContentWrapper>
    );
  }
);

export default withWindowSize(KTAppHeaderRightContent);
