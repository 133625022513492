import React, { memo } from "react";
import { EventCellMobileCardWrapper } from "./style";
import KTEventCellMobileCardAddress from "./c-components/event-cell-mobile-card-address";
import KTEventCellMobileCardContent from "./c-components/event-cell-mobile-card-content";
import { eventVenue } from "../../../utils/event";
import { withRouter } from "react-router-dom";

const KTEventCellMobileCard = memo((props) => {
  const { event } = props;
  function onEventMobileCardContentClick() {
    const url = window.location.origin + `#/map/${event.id}`;
    window.open(url, "_self", "noopener,noreferrer");
  }

  return (
    <EventCellMobileCardWrapper>
      <KTEventCellMobileCardAddress address={eventVenue(event)} />
      <KTEventCellMobileCardContent
        event={event}
        onClick={onEventMobileCardContentClick}
      />
    </EventCellMobileCardWrapper>
  );
});

export default withRouter(KTEventCellMobileCard);
