import styled from "styled-components";

export const SearchEventCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 20rem;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) {
    gap: 20px;
  }
`;

export const SearchEventCellDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 4.5rem;
  padding: 6rem 10rem;
  border-radius: 10rem;
  border: 1rem solid var(--dvdr-stroke-f2, #f2f2f2);
  background: #fff;

  @media screen and (min-width: 481px) {
    gap: 4.5px;
    padding: 6px 10px;
    border-radius: 10px;
    border: 1px solid var(--dvdr-stroke-f2, #f2f2f2);
    background: #fff;
  }
`;

export const SearchEventCellDateTitle = styled.div`
  color: #06c;
  text-align: center;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  white-space: pre-line;

  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;

export const SearchEventCellWeekTitle = styled.div`
  color: #c8c8c8;
  text-align: center;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;

export const SearchEventCellTimeTitle = styled.div`
  color: var(--icon-4d-black, #4d4d4d);
  text-align: center;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 300;
  line-height: 72.023%; /* 9.363px */

  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;

export const SearchEventCellDetaileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6rem;

  @media screen and (min-width: 481px) {
    gap: 6px;
  }
`;

export const SearchEventCellDetaileTitle = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  width: 199rem;

  @media screen and (min-width: 481px) {
    font-size: 14px;
    width: 199px;
  }
`;

export const SearchEventCellDetailePrice = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 12rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  width: 199rem;

  @media screen and (min-width: 481px) {
    font-size: 12px;
    width: 199px;
  }
`;

export const SearchEventCellDetaileVenue = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 12rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  width: 199rem;

  @media screen and (min-width: 481px) {
    font-size: 12px;
    width: 199px;
  }
`;
