import React, { memo } from "react";
import {
  AppFooterSubscribeBarInputWrapper,
  AppFooterSubscribeBarSend,
  AppFooterSubscribeBarSendWrapper,
  AppFooterSubscribeBarWrapper,
} from "./style";
import { Input } from "antd";

const KTAppFooterSubscribeBar = memo(() => {
  return (
    <AppFooterSubscribeBarWrapper>
      <AppFooterSubscribeBarInputWrapper>
        <Input
          className="sendInput"
          placeholder="Send me the latest events and special offers!"
          bordered={false}
        />
      </AppFooterSubscribeBarInputWrapper>
      <AppFooterSubscribeBarSendWrapper>
        <AppFooterSubscribeBarSend
          src={require("@/assets/img/icons/icon-send.png")}
          alt=""
        />
      </AppFooterSubscribeBarSendWrapper>
    </AppFooterSubscribeBarWrapper>
  );
});

export default KTAppFooterSubscribeBar;
