import styled from "styled-components";

export const SearchGroupHeaderBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchGroupHeaderBarTitle = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 16rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  white-space: pre-line;

  @media screen and (min-width: 481px) {
    font-size: 16px;
  }
`;

export const SearchGroupHeaderBarExploreWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4rem;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) {
    gap: 4px;
  }
`;

export const SearchGroupHeaderBarExploreTitle = styled.div`
  color: #06c;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;

export const SearchGroupHeaderBarExploreArrow = styled.img`
  width: 24rem;
  height: 24rem;
  flex-shrink: 0;

  @media screen and (min-width: 481px) {
    width: 24px;
    height: 24px;
  }
`;
