import React, { memo } from "react";
import {
  FAQHeaderContactWay,
  FAQHeaderEmail,
  FAQHeaderMainTitle,
  FAQHeaderOr,
  FAQHeaderPhone,
  FAQHeaderSmallTitle,
  FAQHeaderWrapper,
} from "./style";

const KTFAQHeader = memo(() => {
  return (
    <FAQHeaderWrapper>
      <FAQHeaderSmallTitle>FAQ</FAQHeaderSmallTitle>
      <FAQHeaderMainTitle>FREQUENTLY ASKED QUESTIONS</FAQHeaderMainTitle>
      <FAQHeaderContactWay>
        Can’t find an answer? Email our customer support:{" "}
        <FAQHeaderEmail>support@mytickettracker.com</FAQHeaderEmail>{" "}
        <FAQHeaderOr>or</FAQHeaderOr>{" "}
        <FAQHeaderPhone>833-272-3773</FAQHeaderPhone>
      </FAQHeaderContactWay>
    </FAQHeaderWrapper>
  );
});

export default KTFAQHeader;
