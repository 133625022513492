import styled from "styled-components";

export const EventCellExploreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;
  margin: 0 20rem;
`;

export const EventDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  border-radius: 10rem;
  border: 1rem solid var(--dvdr-stroke-f2, #f2f2f2);
  background: #fff;
  width: fit-content;
  height: fit-content;
  padding: 17rem 12rem;

  .date {
    color: #3c3c3c;
    text-align: center;
    font-family: Merriweather Black;
    font-size: 14rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .week {
    color: var(--txt-c8, #c8c8c8);
    text-align: center;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .time {
    color: var(--tag-stoke-8c, #8c8c8c);
    text-align: center;
    font-family: Inter;
    font-size: 12rem;
    font-style: normal;
    font-weight: 300;
    line-height: 72.023%; /* 8.643px */
  }
`;

export const EventContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 16rem;

  .title {
    color: #000;
    font-family: Merriweather;
    font-size: 16rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .address {
    color: var(--iconb3, #b3b3b3);
    font-family: Inter;
    font-size: 13rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EventContentPosterWrapper = styled.div`
  width: 275rem;
  height: 192rem;
  background-color: black;
  border-radius: 10rem;
  position: relative;
  background-size: cover;
  background-position: center;

  .like {
    position: absolute;
    top: 20rem;
    left: 20rem;
    width: 24rem;
    height: 20rem;
    flex-shrink: 0;
  }
`;
