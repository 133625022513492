import React, { memo, useContext } from "react";
import {
  FAQGroupViewListExpandIcon,
  FAQGroupViewListIssue,
  FAQGroupViewListWrapper,
  FAQGroupViewTitle,
  FAQGroupViewTitleLine,
  FAQGroupViewTitleWrapper,
  FAQGroupViewWrapper,
} from "./style";
import { Collapse } from "antd";
import KTFAQParagraphListView from "../faq-paragraph-list-view";

const KTFAQGroupView = memo(({ group }) => {
  let style = {
    borderTop: "6px solid var(--tag-stroke, #ECF0F4)",
    padding: "26px 0px",
  };

  const collapseItems = group.items.map((ele, index) => {
    return {
      key: index,
      label: <FAQGroupViewListIssue>{ele.issue}</FAQGroupViewListIssue>,
      children: <KTFAQParagraphListView paragraphs={ele.answerParagraphs} />,
      style: style,
    };
  });

  return (
    <FAQGroupViewWrapper>
      <FAQGroupViewTitleWrapper>
        <FAQGroupViewTitleLine />
        <FAQGroupViewTitle>{group.title}</FAQGroupViewTitle>
      </FAQGroupViewTitleWrapper>
      <FAQGroupViewListWrapper>
        <Collapse
          size="small"
          ghost
          items={collapseItems}
          style={{
            padding: "0px",
            paddingInlineStart: "0px",
          }}
          expandIcon={({ isActive }) => {
            const iconSrc = isActive
              ? require("@/assets/img/faq/icon-faq-close.png")
              : require("@/assets/img/faq/icon-faq-open.png");
            return <FAQGroupViewListExpandIcon src={iconSrc} />;
          }}
        />
      </FAQGroupViewListWrapper>
    </FAQGroupViewWrapper>
  );
});

export default KTFAQGroupView;
