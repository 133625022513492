/**
 * 0: Any Dates
 * 1:This Weekend
 * 2:Next Weekend
 * 3:Next 7 Days
 * 4:Next 30 Days
 * 5:Next 60 Days
 * 6:Custom
 */
export const globalFilterDateIntervals = [
  {
    title: "Any Dates",
    value: "0",
  },
  {
    title: "This Weekend",
    value: "1",
  },
  {
    title: "Next Weekend",
    value: "2",
  },
  {
    title: "Next 7 Days",
    value: "3",
  },
  {
    title: "Next 30 Days",
    value: "4",
  },
  {
    title: "Next 60 Days",
    value: "5",
  },
  {
    title: "Custom",
    value: "6",
  },
];
