export const categoryPlaceholderMap = {
  // 音乐
  CONCERTS: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-concert01@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert02@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert03@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert04@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert05@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert06@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert07@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert08@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert09@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert10@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert11@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-concert12@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-concert02@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert03@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert04@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert05@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert06@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert07@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert08@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert09@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert10@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert11@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert12@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-concert13@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-concerts.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-concerts-unselect@3x.png"),
    ],
  },
  Festival: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-festival01@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-festival02@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-festival03@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-festival01@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-festival02@3x.jpg"),
      require("@/assets/img/categorys/placeholder-desktop/image-festival03@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-concerts.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-festivals-unselect@3x.png"),
    ],
  },
  Performer: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-performer01@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-performer02@3x.jpg"),
      require("@/assets/img/categorys/placeholder/bg-performer03@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-concert02@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-concerts.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-concerts-unselect@3x.png"),
    ],
  },

  // 演出
  THEATRE: {
    background: [
      require("@/assets/img/categorys/placeholder/othrshows@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-othershows@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-other.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-shows-unselected@3x.png")],
  },
  BALLET: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-ballet@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-ballet@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-ballet.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-ballet-unselected@3x.png"),
    ],
  },
  BROADWAY: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-broadway@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-broadway@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-broadway.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-broadway-unselected@3x.png"),
    ],
  },
  CIRQUEDUSOLEIL: {
    background: [require("@/assets/img/categorys/placeholder/bg-cds@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-cds@3x.jpg"),
    ],
    desktopIcon: [require("@/assets/img/categorys/desktop-icon/icon-cds.png")],
    mark: [require("@/assets/img/categorys/mark/icon-cds-unselected@3x.png")],
  },
  CHILDRENFAMILY: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-children@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-children@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-children.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-children-unselected@3x.png"),
    ],
  },
  CIRCUS: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-circus@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-circus@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-circus.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-circus-unselected@3x.png"),
    ],
  },
  DANCE: {
    background: [require("@/assets/img/categorys/placeholder/bg-dance@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-dance@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-dance.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-dance-unselected@3x.png")],
  },
  FAIRS: {
    background: [require("@/assets/img/categorys/placeholder/bg-fairs@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-fairs@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-fairs.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-fairs-unselected@3x.png")],
  },
  FILM: {
    background: [require("@/assets/img/categorys/placeholder/bg-film@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-film@3x.jpg"),
    ],
    desktopIcon: [require("@/assets/img/categorys/desktop-icon/icon-film.png")],
    mark: [require("@/assets/img/categorys/mark/icon-film-unselected@3x.png")],
  },
  LASVEGAS: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-lasvegas@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-lasvegas@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-lasvegas.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-lasvegas-unselected@3x.png"),
    ],
  },
  LECTURE: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-lecture@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-lecture@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-lecture.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-lecture-unselected@3x.png"),
    ],
  },
  MAGICSHOWS: {
    background: [require("@/assets/img/categorys/placeholder/bg-magic@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-magicshows@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-magic.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-magic-unselected@3x.png")],
  },
  MUSEUMEXHIBIT: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-museum@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-museum@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-museum.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-museum-unselected@3x.png"),
    ],
  },
  OFFBROADWAY: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-offbroadway@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-offbroadway@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-offbroadway.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-offbroadway-unselected@3x.png"),
    ],
  },
  OPERA: {
    background: [require("@/assets/img/categorys/placeholder/bg-opera@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-opera@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-opera.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-opera-unselected@3x.png")],
  },
  MUSICALPLAY: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-playmusical@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-playmusical@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-other.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-play-unselected@3x.png")],
  },
  RELIGIOUS: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-religious@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-religious@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-religious.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-religious-unselected@3x.png"),
    ],
  },

  // 体育
  SPORTS: {
    background: [
      require("@/assets/img/categorys/placeholder/othersports@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-othersports@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-other.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-other-unselected@3x.png")],
  },
  RACING: {
    background: [
      require("@/assets/img/categorys/placeholder/otherracing@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-otherracing@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-otherracing.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-racing-unselected@3x.png"),
    ],
  },
  AUTO: {
    background: [require("@/assets/img/categorys/placeholder/bg-auto@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-autoracing@3x.jpg"),
    ],
    desktopIcon: [require("@/assets/img/categorys/desktop-icon/icon-auto.png")],
    mark: [require("@/assets/img/categorys/mark/icon-auto-unselected@3x.png")],
  },
  BASEBALL: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-baseball@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-baseball@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-baseball.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-baseball-unselected@3x.png"),
    ],
  },
  BASKETBALL: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-basketball@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-basketball@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-basketball.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-basketball-unselected@3x.png"),
    ],
  },
  BOXING: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-boxing@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-boxing@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-boxing.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-boxing-unselected@3x.png"),
    ],
  },
  FOOTBALL: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-football@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-football@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-football.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-football-unselected@3x.png"),
    ],
  },
  GOLF: {
    background: [require("@/assets/img/categorys/placeholder/bg-golf@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-golf@3x.jpg"),
    ],
    desktopIcon: [require("@/assets/img/categorys/desktop-icon/icon-golf.png")],
    mark: [require("@/assets/img/categorys/mark/icon-golf-unselected@3x.png")],
  },
  GYMNASTICS: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-gymnastics@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-gymnastics@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-gymnastics.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-gymnastics-unselected@3x.png"),
    ],
  },
  HORSE: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-horseracing@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-horseracing@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-horseracing.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-horse-unselected@3x.png")],
  },
  ICEHOCKEY: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-icehockey@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-hockey@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-other.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-hockey-unselected@3x.png"),
    ],
  },
  LACROSSE: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-lacrosse@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-lacrosse@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-lacrosse.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-lacrosse-unselected@3x.png"),
    ],
  },
  MMA: {
    background: [require("@/assets/img/categorys/placeholder/bg-mma@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-mma@3x.jpg"),
    ],
    desktopIcon: [require("@/assets/img/categorys/desktop-icon/icon-mma.png")],
    mark: [require("@/assets/img/categorys/mark/icon-mma-unselected@3x.png")],
  },
  MOTORCYCLE: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-motorcycle@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-motor@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-motorcycle.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-motorcycle-unselected@3x.png"),
    ],
  },
  RODEO: {
    background: [require("@/assets/img/categorys/placeholder/bg-rodeo@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-rodeo@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-rodeo.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-rodeo-unselected@3x.png")],
  },
  RUGBY: {
    background: [require("@/assets/img/categorys/placeholder/bg-rugby@3x.jpg")],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-rugby@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-rugby.png"),
    ],
    mark: [require("@/assets/img/categorys/mark/icon-rugby-unselected@3x.png")],
  },
  SKATING: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-skating@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-skating@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-skating.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-skating-unselected@3x.png"),
    ],
  },
  SOCCER: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-soccer@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-soccer@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-soccer.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-soccer-unselected@3x.png"),
    ],
  },
  SOFTBALL: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-softball@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-softball@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-softball.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-softball-unselected@3x.png"),
    ],
  },
  TENNIS: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-tennis@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-tennis@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-tennis.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-tennis-unselected@3x.png"),
    ],
  },
  VOLLEYBALL: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-volleyball@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-volleyball@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-volleyball.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-volleyball-unselected@3x.png"),
    ],
  },
  WRESTLING: {
    background: [
      require("@/assets/img/categorys/placeholder/bg-wrestling@3x.jpg"),
    ],
    desktop: [
      require("@/assets/img/categorys/placeholder-desktop/image-wrestling@3x.jpg"),
    ],
    desktopIcon: [
      require("@/assets/img/categorys/desktop-icon/icon-wrestling.png"),
    ],
    mark: [
      require("@/assets/img/categorys/mark/icon-wrestling-unselected@3x.png"),
    ],
  },
};

function findImagePath(categoryInfo, map, event_id) {
  let categoryName = categoryInfo?.text?.name ?? "";
  // 去掉空格
  categoryName = categoryName.replace(/\s/g, "");
  // 去掉/
  categoryName = categoryName.replace(/\//g, "");
  // 转为为大写
  categoryName = categoryName.toUpperCase();

  if (categoryName in map) {
    const dict = map[categoryName];
    const backgroundArr = dict.background;
    const dBackgroundArr = dict.desktop;
    const dIconArr = dict.desktopIcon;

    const markArr = dict.mark;

    const b_Index = event_id % backgroundArr.length;
    const background = backgroundArr[b_Index];

    const m_Index = event_id % markArr.length;
    const mark = markArr[m_Index];

    const d_index = event_id % dBackgroundArr.length;
    const desktop = dBackgroundArr[d_index];

    const i_index = event_id % dIconArr.length;
    const desktopIcon = dIconArr[i_index];

    return {
      background: background,
      mark: mark,
      desktop: desktop,
      desktopIcon: desktopIcon,
    };
  }
  return null;
}

export function getCategoryPlaceholderImagePath(
  defaultCategory = {},
  event_id
) {
  /**
     * "defaultCategory": {
                "path": ".1859.1986.1862.",
                "depth": 2,
                "text": {
                    "name": "ALTERNATIVE"
                },
                "ancestors": [
                    {
                        "path": ".1859.1986.",
                        "depth": 1,
                        "text": {
                            "name": "CONCERTS"
                        }
                    }
                ],
                
            },
     * 
    */

  const map = categoryPlaceholderMap;

  const res = findImagePath(defaultCategory, map, event_id);
  if (res != null) {
    return res;
  }

  for (const ele of defaultCategory?.ancestors ?? []) {
    const p_res = findImagePath(ele, map, event_id);
    if (p_res != null) {
      return p_res;
    }
  }

  return {
    background: require("@/assets/img/categorys/placeholder/othrshows@3x.jpg"),
    mark: require("@/assets/img/categorys/mark/icon-other-unselected@3x.png"),
    desktop: require("@/assets/img/categorys/placeholder-desktop/image-othershows@3x.jpg"),
    desktopIcon: require("@/assets/img/categorys/desktop-icon/icon-other.png"),
  };
}
