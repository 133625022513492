import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import KTHome from "../pages/home";
import KTMusics from "../pages/musics";
import KTShows from "../pages/shows";
import KTSports from "../pages/sports";
import KTHomeAllEvents from "../pages/home/c-pages/all-events";
import KTHomeEditorsChoice from "../pages/home/c-pages/editors-choice";
import KTHomeTopSelling from "../pages/home/c-pages/top-selling";
import KTHomeBasedLike from "../pages/home/c-pages/based-like";
import KTCategory from "../pages/category";

import KTResult from "../pages/result";
import KTResultVenues from "../pages/result/c-pages/venues";
import KTResultPerformers from "../pages/result/c-pages/performers";
import KTResultEvents from "../pages/result/c-pages/events";

import KTPerformer from "../pages/performer";
import KTPerformerParking from "../pages/performer/c-pages/parking";
import KTPerformerEvents from "../pages/performer/c-pages/events";
import KTPerformerAbout from "../pages/performer/c-pages/about";
import KTVenue from "../pages/venue";
import KTHomeTrending from "../pages/home/c-pages/trending";
import KTMap from "../pages/map";
import KTFAQ from "../pages/faq";
import KTAboutUs from "../pages/about-us";
import KTTerms from "../pages/terms";

const routes = [
  {
    path: "/",
    exact: true,
    render: () => <Redirect to="/home" />,
  },
  {
    path: "/home",
    component: KTHome,
    routes: [
      {
        path: "/home/all",
        component: KTHomeAllEvents,
      },
      {
        path: "/home/editors",
        component: KTHomeEditorsChoice,
      },
      {
        path: "/home/top",
        component: KTHomeTopSelling,
      },
      {
        path: "/home/like",
        component: KTHomeBasedLike,
      },
      {
        path: "/home/trending",
        component: KTHomeTrending,
      },
      {
        path: "/home",
        expect: true,
        render: () => <Redirect to="/home/all" />,
      },
    ],
  },

  {
    path: "/sweepstake",
    component: KTFAQ,
  },


  {
    path: "/music",
    component: KTMusics,
  },
  {
    path: "/sports",
    component: KTSports,
  },
  {
    path: "/shows",
    component: KTShows,
  },
  {
    path: "/category/:category",
    component: KTCategory,
  },
  {
    path: "/map/:id",
    component: KTMap,
  },
  {
    path: "/venue/:id/:name",
    component: KTVenue,
  },
  {
    path: "/performer/:performer",
    component: KTPerformer,
    routes: [
      {
        path: "/performer/:performer/events",
        component: KTPerformerEvents,
      },
      {
        path: "/performer/:performer/parking",
        component: KTPerformerParking,
      },
      {
        path: "/performer/:performer/about",
        component: KTPerformerAbout,
      },
      {
        path: "/performer/:performer",
        expect: true,
        render: (props) => {
          return <Redirect to={`${props.location.pathname}/events`} />;
        },
      },
    ],
  },
  {
    path: "/result",
    component: KTResult,
    routes: [
      {
        path: "/result/performers",
        component: KTResultPerformers,
      },
      {
        path: "/result/events",
        component: KTResultEvents,
      },
      {
        path: "/result/venues",
        component: KTResultVenues,
      },
      {
        path: "/result",
        expect: true,
        render: (props) => {
          return (
            <Redirect
              to={{
                pathname: "/result/performers",
                search: props?.location?.search ?? "",
                state: props?.location?.state ?? {},
              }}
            />
          );
        },
      },
    ],
  },
  {
    path: "/FAQ",
    component: KTFAQ,
  },
  {
    path: "/about",
    component: KTAboutUs,
  },
  {
    path: "/terms/:mark",
    component: KTTerms,
  },
];

export default routes;
