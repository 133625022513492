import request from "./request";

export function getLocation(keywords = "", callback) {
  request.post("getLocation", { keywords: keywords }).then((response) => {
    if (typeof callback != "function") {
      return;
    }

    const cities = response.cities;

    if (Array.isArray(cities)) {
      callback({ keywords: keywords, list: cities });
    } else {
      callback({ keywords: keywords, list: [] });
    }
  });
}

export function getCityOfLocation(lat, long, callback) {
  if (typeof callback != "function") {
    return;
  }

  if (lat === undefined && long === undefined) {
    callback({});
  }

  request
    .get("getCityOfLocation", { params: { lat: lat, long: long } })
    .then((response) => {
      let data = response;

      data.lat = lat;
      data.long = long;

      callback(data);
    });
}
