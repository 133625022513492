import React, { memo, useContext } from "react";
import { ResultEventsWrapper } from "./style";
import { shallowEqual, useSelector } from "react-redux";
import KTMobileEventList from "../../../../components/mobile-components/mobile-event-list";
import { LAYOUT_MODE_LIST } from "../../../../common/constants";
import KTEventsListView from "@/components/event/events-list-view";
import KTWindowSizeContext from "../../../../common/context/windowSizeContext";

const KTResultEvents = memo(() => {
  const context = useContext(KTWindowSizeContext);

  const { events = [] } = useSelector(
    (state) => ({
      events: state.getIn(["search", "events"]),
    }),
    shallowEqual
  );

  return (
    <ResultEventsWrapper>
      {context?.windowSize?.width > 480 ? (
        <KTEventsListView events={events} layoutMode={LAYOUT_MODE_LIST} />
      ) : (
        <KTMobileEventList events={events} layoutMode={LAYOUT_MODE_LIST} />
      )}
    </ResultEventsWrapper>
  );
});

export default KTResultEvents;
