import { format } from "date-fns";

export function getGlobalDateString(
  globalFilterDateIntervals,
  intervalType,
  startdt,
  enddt
) {
  if (intervalType >= globalFilterDateIntervals.length) {
    return "";
  }

  const item = globalFilterDateIntervals[intervalType];
  if (intervalType < globalFilterDateIntervals.length - 1) {
    return item.title;
  } else {
    // custom
    console.log("startdt: ", startdt);
    console.log("enddt: ", enddt);
    return `${
      startdt instanceof Date ? format(startdt, "MMM. dd") : "Anytime"
    } - ${enddt instanceof Date ? format(enddt, "MMM. dd") : "Anytime"}`;
  }
}
