import React, { memo, useCallback, useEffect, useState } from "react";
import {
  SearchKeywordsDtpDropdownCellDivider,
  SearchKeywordsDtpDropdownDivider,
  SearchKeywordsDtpDropdownInputWrapper,
  SearchKeywordsDtpDropdownListContent,
  SearchKeywordsDtpDropdownWrapper,
} from "./style";
import { Dropdown, Input } from "antd";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getSuggest } from "../../../services/search";
import { getChangeSearchKeywordsAction } from "../../../pages/result/store/actionCreators";
import { ReactComponent as SearchIcon } from "@/assets/img/svg/icon-header-search.svg";
import KTSearchDropdownHeader from "./c-components/content-header";
import KTSearchDropdownFooter from "./c-components/content-footer";
import KTSearchDropdownListView from "./c-components/content-list-view";
import KTSearchDropdownPerformerCell from "./c-components/performer-cell";
import { withRouter } from "react-router-dom";
import KTSearchDropdownEventCell from "./c-components/event-cell";
import KTSearchDropdownVenueCell from "./c-components/venue-cell";
import withWindowSize from "../../higher-order/withWindowSize";

const KTSearchKeywordsDtpDropdown = memo((props) => {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState({});

  const [inputValue, setInputValue] = useState("");
  const [inputOnFocus, setInputOnFocus] = useState(false);
  const [lastCancneSource, setLastCancneSource] = useState();
  const { windowSize } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(inputValue.length > 0);
  }, [inputValue]);

  const keywords = suggestions.keywords;
  const events = suggestions.events;
  const performers = suggestions.performers;
  const venues = suggestions.venues;

  const totalResultCount =
    (events?.totalResultCount ?? 0) +
    (performers?.totalResultCount ?? 0) +
    (venues?.totalResultCount ?? 0);

  const items = [];

  const onCancelClick = () => {
    setOpen(false);
  };

  function dropdownRender(menu) {
    // performers
    const performersCount = performers?.totalResultCount ?? 0;
    const performersCellCount = performersCount > 3 ? 3 : performersCount;
    const performersCells = performers?.results
      ?.slice(0, performersCellCount)
      .map((ele) => {
        return [
          <SearchKeywordsDtpDropdownCellDivider />,
          <KTSearchDropdownPerformerCell
            performer={ele}
            onClick={(performer) => {
              setOpen(false);
              props.history.push(`/performer/${performer.id}`);
            }}
          />,
        ];
      });

    // events
    const eventsCount = events?.totalResultCount ?? 0;
    const eventsCellCount = eventsCount > 3 ? 3 : eventsCount;
    const eventsCells = events?.results
      ?.slice(0, eventsCellCount)
      .map((ele) => {
        return [
          <SearchKeywordsDtpDropdownCellDivider />,
          <KTSearchDropdownEventCell
            event={ele}
            onClick={(event) => {
              setOpen(false);
              const url = window.location.origin + `#/map/${event.id}`;
              window.open(url, "_self", "noopener,noreferrer");
            }}
          />,
        ];
      });

    // venues
    const venuesCount = venues?.totalResultCount ?? 0;
    const venuesCellCount = venuesCount > 3 ? 3 : venuesCount;
    const venuesCells = venues?.results
      ?.slice(0, venuesCellCount)
      .map((ele) => {
        return [
          <SearchKeywordsDtpDropdownCellDivider />,
          <KTSearchDropdownVenueCell
            venue={ele}
            onClick={(venue) => {
              setOpen(false);
              props.history.push(`/venue/${venue.id}/${venue?.name ?? ""}`);
            }}
          />,
        ];
      });

    return (
      <SearchKeywordsDtpDropdownWrapper>
        <KTSearchDropdownHeader
          title={`${totalResultCount} results for "${keywords}"`}
          onCancelClick={onCancelClick}
        />
        <SearchKeywordsDtpDropdownListContent>
          <KTSearchDropdownListView title={`${performersCount}\nPERFORMERS`}>
            {performersCells}
          </KTSearchDropdownListView>

          <KTSearchDropdownListView title={`${eventsCount}\nEVENTS`}>
            {eventsCells}
          </KTSearchDropdownListView>

          <KTSearchDropdownListView title={`${venuesCount}\nVENUES`}>
            {venuesCells}
          </KTSearchDropdownListView>
        </SearchKeywordsDtpDropdownListContent>
        <SearchKeywordsDtpDropdownDivider />
        <KTSearchDropdownFooter onExploreMoreClick={onSearchPressEnter} />
      </SearchKeywordsDtpDropdownWrapper>
    );
  }

  const debounce = useCallback((callback, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback.apply(this, args);
      }, delay);
    };
  }, []);

  const handleInputChange = useCallback(
    debounce((value) => {
      onValueChange(value);
    }, 300),
    [debounce]
  );

  function onInputValueChange(e) {
    const value = e.target.value;
    setInputValue(value);
    handleInputChange(value);
  }

  function onValueChange(value) {
    if (!!lastCancneSource) {
      lastCancneSource.cancel();
    }

    const cancelSource = axios.CancelToken.source();
    setLastCancneSource(cancelSource);

    getSuggest(
      value,
      (suggestions) => {
        setSuggestions(suggestions);
      },
      cancelSource
    );
  }

  function onSearchPressEnter() {
    setOpen(false);
    props.history.push({
      pathname: "/result",
    });

    dispatch(getChangeSearchKeywordsAction(inputValue));
    setOpen(false);
  }

  return (
    <Dropdown
      autoAdjustOverflow={false}
      destroyPopupOnHide={true}
      placement={"bottomLeft"}
      menu={{ items, selectable: true }}
      open={open}
      trigger={"click"}
      dropdownRender={dropdownRender}
      onOpenChange={(value) => {
        if (value === false && inputOnFocus === false) {
          setOpen(value);
        }
      }}
    >
      <SearchKeywordsDtpDropdownInputWrapper>
        <Input
          bordered={false}
          placeholder={
            windowSize.width > 600
              ? "Search Events, Performers and Venues..."
              : "Search events.."
          }
          className="input"
          value={inputValue}
          onChange={onInputValueChange}
          onPressEnter={onSearchPressEnter}
          onFocus={() => {
            setInputOnFocus(true);
            setOpen(inputValue.length > 0);
          }}
          onBlur={() => setInputOnFocus(false)}
        />
        <SearchIcon className="searchButton" onClick={onSearchPressEnter} />
      </SearchKeywordsDtpDropdownInputWrapper>
    </Dropdown>
  );
});

export default withWindowSize(withRouter(KTSearchKeywordsDtpDropdown));
