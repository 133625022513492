import React, { memo } from "react";
import { withRouter } from "react-router-dom";

import { AppHeaderMobileNavWrapper } from "./style";

import { ReactComponent as LogoIcon } from "@/assets/img/svg/icon-nav-logo.svg";
import { ReactComponent as MenuIcon } from "@/assets/img/svg/icon-menu.svg";

const KTAppHeaderMobileNav = memo((props) => {
  const { onClickMenuItem } = props;
  return (
    <AppHeaderMobileNavWrapper>
      <LogoIcon className="logo" onClick={() => props.history.push("/")} />
      <MenuIcon className="menu" onClick={onClickMenuItem} />
    </AppHeaderMobileNavWrapper>
  );
});

export default withRouter(KTAppHeaderMobileNav);
