import React, { memo } from "react";
import {
  SearchDropdownHeaderCancelIcon,
  SearchDropdownHeaderCancelTitle,
  SearchDropdownHeaderCancelWrapper,
  SearchDropdownHeaderTitle,
  SearchDropdownHeaderWrapper,
} from "./style";

const KTSearchDropdownHeader = memo(({ title = "", onCancelClick }) => {
  return (
    <SearchDropdownHeaderWrapper>
      <SearchDropdownHeaderTitle>{title}</SearchDropdownHeaderTitle>
      <SearchDropdownHeaderCancelWrapper onClick={onCancelClick}>
        <SearchDropdownHeaderCancelIcon
          src={require("@/assets/img/icons/icon-cha.png")}
        />
        <SearchDropdownHeaderCancelTitle>
          {"Cancel"}
        </SearchDropdownHeaderCancelTitle>
      </SearchDropdownHeaderCancelWrapper>
    </SearchDropdownHeaderWrapper>
  );
});

export default KTSearchDropdownHeader;
