import React, { memo } from "react";
import { withRouter } from "react-router-dom";

import {
  EventAddressBarWrapper,
  EventContentLeftDateWrapper,
  EventContentLeftWrapper,
  EventContentRightWrapper,
  EventContentWrapper,
  EventPosterWrapper,
  EventWrapper,
} from "./style";

import { ReactComponent as AddressIcon } from "@/assets/img/svg/icon-address.svg";

import {
  eventBuyButtonTitle,
  eventDate,
  eventName,
  eventTime,
  eventVenue,
  eventWeek,
} from "../../../utils/event";

const KTEventCellRightImage = memo((props) => {
  const { event } = props;

  function onBuyTicketClick() {
    // props.history.push(`/map/${event.id}`);

    const url = window.location.origin + `#/map/${event.id}`;
    window.open(url, "_self", "noopener,noreferrer");
  }

  return (
    <EventWrapper>
      <EventAddressBarWrapper>
        <AddressIcon className="icon" />
        <div className="title">{eventVenue(event)}</div>
      </EventAddressBarWrapper>
      <EventContentWrapper>
        <EventPosterWrapper src={event.kt_info?.eventposter? event.kt_info?.eventposter : event.kt_desktop} />
        <EventContentLeftWrapper>
          <EventContentLeftDateWrapper>
            {event.date?.datetime === undefined
              ? [<div className="date">{event.date.text.time}</div>]
              : [
                  <div className="date">{`${eventDate(event)} `}</div>,
                  <div className="divider">|</div>,
                  <div className="time">{`${eventTime(event)}`}</div>,
                  <div className="divider">|</div>,
                  <div className="week">{`${eventWeek(event)} `}</div>,
                ]}
          </EventContentLeftDateWrapper>
          <div className="title">{eventName(event)}</div>
          <div className="buyTicket" onClick={onBuyTicketClick}>
            {eventBuyButtonTitle(event)}
          </div>
        </EventContentLeftWrapper>
        <EventContentRightWrapper></EventContentRightWrapper>
      </EventContentWrapper>
    </EventWrapper>
  );
});

export default withRouter(KTEventCellRightImage);
