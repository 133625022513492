import styled from "styled-components";

export const ResultDtpContentListViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 30px;
`;

export const ResultDtpContentListViewHeader = styled.div`
  position: relative;

  color: #19192a;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  padding-bottom: 10px;
`;

export const ResultDtpContentListViewHeaderLine = styled.div`
  position: absolute;

  left: 0;
  top: 100%;

  width: 60px;
  height: 6px;
  background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);
`;

export const ResultDtpContentListViewContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 40px;
`;
