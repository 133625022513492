import styled from "styled-components";

export const ListViewHorizontalWrapper = styled.div`
  .ktSwiperSlide {
    width: fit-content;
    height: fit-content;
  }
`;


export const NavigationWrapper = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-end;
  gap: 40px;

  .swiperButtonPrev {
    width: 30px;
    height: 30px;
    stroke: #4d4d4d;
    cursor: pointer;
  }

  .swiperButtonPrev.disabled {
    stroke: #c8c8c8;
  }

  .swiperButtonNext {
    width: 30px;
    height: 30px;
    stroke: #4d4d4d;
    cursor: pointer;
  }

  .swiperButtonNext.disabled {
    stroke: #c8c8c8;
  }
`;