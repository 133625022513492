import styled from "styled-components";

export const MainMenuDropdownWrapper = styled.div`
  padding: 0 22px;

  border-radius: 16px;

  background: #fff;
`;

export const MainMenuDropdownTitle = styled.div`
  padding: 16px 0;
  min-width: 156px;

  color: var(--icon-4d-black, #4d4d4d);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
