import styled from "styled-components";

export const WhoWeAreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 90px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    flex-direction: column;
    gap: 30px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20rem;
  }
`;

export const WhoWeAreLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  gap: 30px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 30px;
  }

  @media screen and (max-width: 480px) {
    gap: 20rem;
  }
`;

export const WhoWeAreLeftMainTitle = styled.div`
  color: #19192a;
  font-family: Inter;
  font-size: 80px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 60px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 34px;
  }

  @media screen and (max-width: 480px) {
    font-size: 26rem;
  }
`;

export const WhoWeAreLeftLeftBottom = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    gap: 10px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 10px;
  }

  @media screen and (max-width: 480px) {
    gap: 10rem;
  }
`;

export const WhoWeAreLeftSubTitle = styled.div`
  color: #19192a;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 130.769% */
`;

export const WhoWeAreLeftContent = styled.div`
  color: var(--BLUE-GREY, #a3adbe);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 170% */

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 18px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16rem;
  }
`;

export const WhoWeArePosterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex-shrink: 0;
  max-width: 46%;
  height: auto;

  @media screen and (max-width: 1023px) {
    max-width: 100%;
  }
`;

export const WhoWeArePoster = styled.img`
  width: 100%;
`;
