import React, { memo } from "react";
import { AppTopPublicityWrapper } from "./style";
import withWindowSize from "../higher-order/withWindowSize";

const KTAppTopPublicity = memo(({ windowSize }) => {
  return (
    <AppTopPublicityWrapper>
      {windowSize.width > 800
        ? "Resale ticket prices may be above or below face value."
        : "Resale ticket prices may be above or below face value."}
    </AppTopPublicityWrapper>
  );
});

export default withWindowSize(KTAppTopPublicity);