import styled from "styled-components";

export const FAQHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 10px;
  }

  @media screen and (max-width: 480px) {
    gap: 10rem;
  }
`;

export const FAQHeaderSmallTitle = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 20px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;

export const FAQHeaderMainTitle = styled.div`
  color: #19192a;
  font-family: Inter;
  font-size: 80px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 60px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 34px;
  }

  @media screen and (max-width: 480px) {
    font-size: 26rem;
  }
`;

export const FAQHeaderContactWay = styled.p`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;

export const FAQHeaderEmail = styled.span`
  background: radial-gradient(50% 50% at 50% 50%, #e86f2b 0%, #e82b42 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;

  cursor: pointer;
  
  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;

export const FAQHeaderOr = styled.span`
  color: var(--BLUE-GREY, #a3adbe);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;

export const FAQHeaderPhone = styled.span`
  color: #e9433a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  cursor: pointer;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;
