export const CHANGE_KEYWORDS = "global/KEYWORDS";

// 修改城市
export const CHANGE_CITY = "global/CHANGECITY";

// 修改根据坐标获取的城市信息
export const CHANGE_LOCATION_CITY = "global/CHANGELOCATIONCITY";

// 修改时间段
export const CHANGE_TEMPORAL_INTERVAL = "global/CHANGE_TEMPORAL_INTERVAL";

// ip
export const CHANGE_IP = "global/CHANGEIP";

