import styled from "styled-components";

export const AboutMissionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 66px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    gap: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 20px;
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 480px) {
    gap: 20rem;
    flex-direction: column-reverse;
  }
`;

export const AboutMissionLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    gap: 10px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 10px;
  }

  @media screen and (max-width: 480px) {
    gap: 10rem;
  }
`;

export const AboutMissionLeftTitle = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16rem;
  }
`;

export const AboutMissionLeftSubTitle = styled.div`
  color: var(--black-19192a, #19192a);
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 56px */

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 22px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20rem;
  }
`;

export const AboutMissionLeftMessage = styled.div`
  color: var(--BLUE-GREY, #a3adbe);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;

export const AboutMissionPosterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex-shrink: 0;
  
  width: 44%;
  height: auto;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

export const AboutMissionPoster = styled.img`
  width: 100%;
`;
