import { LAYOUT_MODE_CARD } from "@/common/constants";
import { Map } from "immutable";
import * as actionTypes from "./constants";
import { sportsAllEventsSortModes, sportsCategories } from "../local-data";
import { GLOBAL_PAGESIZE } from "../../../common/constants";

const defaultState = Map({
  showLoadMore: false,
  events: [],
  layoutMode: LAYOUT_MODE_CARD,
  pagenum: 1,
  /**
   * 0- default, popularity, 1 - date , 2 - price low to hight, 3 - price high to low
   */
  sortMode: sportsAllEventsSortModes[0],
  // 筛选分类
  categories: sportsCategories.filter((ele)=>ele.keyWord.length>0),
  /**
   * 0: Any Dates
   * 1:This Weekend
   * 2:Next Weekend
   * 3:Next 7 Days
   * 4:Next 30 Days
   * 5:Next 60 Days
   * 6:Custom
   */
  intervalType: "0",
  startdt: undefined,
  enddt: undefined,
});

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.ADD_MORE_EVENTS:
      const events = state.get("events");
      return state
        .set("events", events.concat(action.events))
        .set("showLoadMore", action?.events?.length === GLOBAL_PAGESIZE);

    case actionTypes.EMPTY_ADD_MORE_EVENTS:
      return state
        .set("events", action.events)
        .set("showLoadMore", action?.events?.length === GLOBAL_PAGESIZE);

    case actionTypes.CHANGE_LAYOUT_MODE:
      return state.set("layoutMode", action.layoutMode);

    case actionTypes.CHANGE_PAGENUM:
      return state.set("pagenum", action.pagenum);

    case actionTypes.CHANGE_SORT_MODE:
      console.log("action.sortMode: ", action.sortMode);
      return state.set("sortMode", action.sortMode);

    case actionTypes.CHANGE_CATEGORIES:
      return state.set("categories", action.categories);

    case actionTypes.CHANGE_TEMPORAL_INTERVAL:
      return state
        .set("intervalType", action.intervalType)
        .set("startdt", action.startdt)
        .set("enddt", action.enddt);

    default:
      return state;
  }
}

export default reducer;
