import styled from "styled-components";

export const SearchDropdownHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchDropdownHeaderTitle = styled.div`
  color: #19192a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const SearchDropdownHeaderCancelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;

  cursor: pointer;
  user-select: none;
`;

export const SearchDropdownHeaderCancelIcon = styled.img`
  width: 10px;
  height: 10px;
`;

export const SearchDropdownHeaderCancelTitle = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
