import React, { memo, useEffect, useState } from "react";
import {
  CurrentLocationCellIcon,
  CurrentLocationCellTitle,
  DropdownCurrentLocationCellWrapper,
} from "./style";
import { shallowEqual, useSelector } from "react-redux";

const KTDropdownCurrentLocationCell = memo(() => {
  const [currTitle, setCurrTitle] = useState("");

  const { locationCity = {} } = useSelector(
    (state) => ({
      locationCity: state.getIn(["global", "locationCity"]),
    }),
    shallowEqual
  );

  useEffect(() => {
    let title = "Current Location";

    if (Object.keys(locationCity).length > 0) {
      title = `${locationCity.postalCode ?? locationCity.postal_code}, ${
        locationCity.name ?? locationCity.city ?? ""
      }, ${locationCity.state}, ${locationCity.country}`;
    }

    setCurrTitle(title);
  }, [locationCity]);

  return (
    <DropdownCurrentLocationCellWrapper>
      <CurrentLocationCellIcon
        src={require("@/assets/img/icons/icon-curr-loc@3x.png")}
      />
      <CurrentLocationCellTitle>{currTitle}</CurrentLocationCellTitle>
    </DropdownCurrentLocationCellWrapper>
  );
});

export default KTDropdownCurrentLocationCell;
