import styled from "styled-components";

export const ClassifySubMultipleDesktopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  background: #fff;

  .title {
    padding: 8px 20px;
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 18.75px */
    border-radius: 10px;
    border: 1px solid var(--icon-4d-black, #4d4d4d);
    background: #fff;
    width: fit-content;
    height: fit-content;

    cursor: pointer;
  }

  .avtive {
    color: #fff;
    border: 1px solid var(--icon-line-black, #19192a);
    background: var(--bg-piclist, #111120);
  }
`;
