import React, { memo } from "react";
import { AboutUsDivider, AboutUsWrapper } from "./style";
import KTWhoWeAre from "./c-components/who-we-are";
import KTAboutRemember from "./c-components/remember";
import KTAboutMission from "./c-components/mission";
import KTAboutGuarantee from "./c-components/guarantee";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTMobileHeaderBar from "../../components/mobile-header-bar";
import { KT_THEME } from "../../common/constants";

const KTAboutUs = memo(({ windowSize }) => {
  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      {windowSize.width <= 480 ? (
        <KTMobileHeaderBar theme={KT_THEME.DARK} />
      ) : null}
      <AboutUsWrapper>
        <KTWhoWeAre />
        <AboutUsDivider />
        <KTAboutRemember />
        <AboutUsDivider />
        <KTAboutMission />
        <AboutUsDivider />
        <KTAboutGuarantee />
      </AboutUsWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTAboutUs);
