import styled from "styled-components";

export const DropdownCurrentLocationCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 15rem 0;

  gap: 14rem;
`;

export const CurrentLocationCellIcon = styled.img`
  width: 20rem;
  height: 20rem;
`;

export const CurrentLocationCellTitle = styled.div`
  color: #06c;
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
