import styled from "styled-components";

export const ClassifyHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #000;
      font-family: Inter;
      font-size: 22rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      white-space: pre-line;
    }

    .viewAll {
      color: #003594;
      font-family: Inter;
      font-size: 13rem;
      font-style: normal;
      font-weight: 400;
      line-height: 128.523%; /* 16.708px */
    }

    .exploreMoreWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      cursor: pointer;
      
      .exploreMore {
        height: 24px;

        color: #003594;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .arrowRight {
        width: 24px;
        height: 24px;
      }
    }
  }

  .line {
    width: 30rem;
    height: 6rem;
    background: linear-gradient(-90deg, #ff9e0b 0%, #ff0b0b 102.63%);
  }

  @media screen and (min-width: 481px) {
    gap: 8px;

    .content {
      .title {
        font-size: 30px;
      }

      .viewAll {
        color: #003594;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }
    }

    .line {
      width: 60px;
      height: 6px;
    }
  }
`;
