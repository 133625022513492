export const LAYOUT_MODE_CARD = "LAYOUT_MODE_CARD";
export const LAYOUT_MODE_LIST = "LAYOUT_MODE_LIST";

export const GLOBAL_PAGESIZE = 50;

export function kt_copyrightText() {
  const now = new Date();
  const currentYear = now.getFullYear();

  return `© Copyright ${currentYear} KUSTOMTICKETS, All rights reserved`;
}

export const KT_THEME = {
  DEFAULT: "Default",
  DARK: "Dark",
};
