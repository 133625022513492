import styled from "styled-components";

export const SearchDividerCellWrapper = styled.div`
  height: 8rem;
  background: #f8f8f8;

  @media screen and (min-width: 481px) {
    height: 8px;
  }
`;
