import React, { memo } from "react";
import {
  ResultDtpContentListViewContent,
  ResultDtpContentListViewHeader,
  ResultDtpContentListViewHeaderLine,
  ResultDtpContentListViewWrapper,
} from "./style";

const KTResultDtpContentListView = memo(({ title, children }) => {
  return (
    <ResultDtpContentListViewWrapper>
      <ResultDtpContentListViewHeader>
        {title}
        <ResultDtpContentListViewHeaderLine />
      </ResultDtpContentListViewHeader>
      <ResultDtpContentListViewContent>
        {children}
      </ResultDtpContentListViewContent>
    </ResultDtpContentListViewWrapper>
  );
});

export default KTResultDtpContentListView;
