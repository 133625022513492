import styled from "styled-components";

export const SearchKeywordsDropdownWrapper = styled.div`
  border-radius: 10rem;
  border: 1rem solid var(--dvdr-stroke-f2, #f2f2f2);
  background: #fff;
  box-shadow: 0px 4rem 20rem 0px rgba(0, 0, 0, 0.1);
  padding: 20rem 30rem;

  display: flex;
  flex-direction: column;

  gap: 20rem;

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 0;
  }

  .ant-divider {
    margin: 0;
    padding-right: 20rem;
  }

  @media screen and (min-width: 481px) {
    border-radius: 10px;
    border: 1px solid #f2f2f2;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    padding: 20px 30px;

    gap: 20px;

    .ant-dropdown-menu {
      padding: 0;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
      padding: 0;
    }

    .ant-divider {
      margin: 0;
      padding-right: 20px;
    }
  }
`;

export const SearchKeywordsInputWrapper = styled.div`
  height: 56rem;
  padding-left: 8rem;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10rem;

  .input {
    flex: 1;
  }

  .searchButton {
    width: 26rem;
    height: 26rem;
    padding: 10rem;
  }

  @media screen and (min-width: 481px) {
    flex: 1;

    height: 56px;
    padding-left: 8px;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;

    .input {
      flex: 1;
    }

    .searchButton {
      width: 26px;
      height: 26px;
      padding: 10px;
    }
  }
`;
