import styled from "styled-components";

export const SearchDropdownVenueCellWrapper = styled.div`
  width: 280px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 20px;

  cursor: pointer;
  user-select: none;
`;

export const SearchDropdownVenueCellPortrait = styled.img`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
`;

export const SearchDropdownVenueCellName = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;
