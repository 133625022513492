import styled from "styled-components";

export const DropdownDtpCurrentLocationCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 20px 0px;
  gap: 10px;
`;

export const CurrentDtpLocationCellIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const CurrentDtpLocationCellTitle = styled.div`
  color: #06c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
