export function isInRange(number, lowerBound, upperBound) {
  return number >= lowerBound && number <= upperBound;
}

export function currValue(min, max, windowWidth) {
  return currValueOfRange(min, max, windowWidth, 1024, 1920);
}

export function currValue2(min, max, windowWidth) {
  return currValueOfRange(min, max, windowWidth, 1024, 1920);
}

export function currValueOfRange(min, max, windowWidth, minWidth, maxWidth) {
  if (windowWidth >= maxWidth) {
    return `${max}px`;
  } else if (windowWidth <= minWidth) {
    return `${min}px`;
  } else {
    const segment = maxWidth - minWidth;
    const progress = (windowWidth - minWidth) / segment;
    const value = (max - min) * progress + min;

    return `${value}px`;
  }
}

export function currValue3(min, mid, max, windowWidth) {
  return currValueOfStage(min, mid, max, windowWidth, 481, 1024, 1920);
}

export function currValueOfStage(
  min,
  mid,
  max,
  windowWidth,
  minWidth,
  midWidth,
  maxWidth
) {
  if (windowWidth >= maxWidth) {
    return `${max}px`;
  } else if (windowWidth <= minWidth) {
    return `${min}px`;
  } else {
    if (windowWidth >= midWidth) {
      const segment = maxWidth - midWidth;
      const progress = (windowWidth - midWidth) / segment;
      const value = (max - mid) * progress + mid;
      return `${value}px`;
    } else {
      const segment = midWidth - minWidth;
      const progress = (windowWidth - minWidth) / segment;
      const value = (mid - min) * progress + min;
      return `${value}px`;
    }
  }
}
