import { styled } from "styled-components";

export const AppHeaderMobileSearchAddressWrapper = styled.div`
  padding: 20rem;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20rem;

  .icon {
    width: 16rem;
    height: 16rem;
  }
`;

export const AppHeaderMobileSearchAddressTitle = styled.div`
  flex: 1;
  color: var(--icon-4d-black, #4d4d4d);
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  height: 17rem;
`;
