import { styled } from "styled-components";

export const AppHeaderNoFeesWrapper = styled.div`
  margin-top: -31px;
  padding-left: 20px;
  margin-bottom: 20px;


  display: flex;
  justify-content: center;
  align-items: center;
  
  color: #FFFFFF;
  font-family: Inter;
  font-size: 23rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  position: relative;
  


`;
