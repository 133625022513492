import styled from "styled-components";

export const SwitchTimeIntervalDropdownWrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 20px 10px rgba(128, 0, 11, 0.03);

  padding: 30px 40px 45px;
`;

export const SwitchTimeIntervalDropdownItemsWrapper = styled.div`
  border-radius: 20px;
  border: 1px solid #c8c8c8;
  background: #fff;
  padding: 8px;
`;

export const SwitchTimeIntervalDropdownCell = styled.div`
  padding: 20px 30px;

  min-width: 200px;

  color: var(--icon-4d-black, #4d4d4d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
