import styled from "styled-components";

export const SearchDtpInputCellWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 8px;

  padding: 8px 16px;

  border-radius: 32px;
  border: 1px solid var(--tag-stroke, #ecf0f4);
  background: #fff;

  .ant-input-affix-wrapper {
    padding: 0;
  }
  input {
    color: var(--tag-stoke-8c, #8c8c8c);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  divider {
    margin: 0;
  }
`;

export const SearchDtpInputCellSearchIcon = styled.img`
  width: 26px;
  height: 26px;
  flex-shrink: 0;
`;
