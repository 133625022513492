import React, { memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppHeaderMobileWrapper, NoFees } from "./style";
import KTAppHeaderMobileNav from "./c-components/app-header-mobile-nav";
import KTAppHeaderMobileSearch from "./c-components/app-header-mobile-search";
import KTAppHeaderNoFees from "./c-components/app-header-mobile-nofees";
import { Drawer } from "antd";
import KTAppMobileMenu from "../mobile-app-menu";

const KTAppHeaderMobile = memo(() => {
  const [openMenu, setOpenMenu] = useState(false);

  function onClickMenuItem() {
    setOpenMenu(true);
  }

  const location = useLocation();
  const hideAppHeader =
    location.pathname.includes("/category/") ||
    location.pathname.includes("/map/") ||
    location.pathname.includes("/performer/") ||
    location.pathname.includes("/terms") ||
    location.pathname.includes("/about") ||
    location.pathname.includes("/FAQ") ||
    location.pathname.includes("/venue/");

  if (hideAppHeader) {
    return null;
  }

  return (
    <AppHeaderMobileWrapper>
      <KTAppHeaderMobileNav onClickMenuItem={onClickMenuItem} />
      <KTAppHeaderNoFees />
      <KTAppHeaderMobileSearch />

      <Drawer
        closeIcon={null}
        placement="right"
        width="374rem"
        drawerStyle={{ header: "none" }}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        bodyStyle={{ padding: "0" }}
      >
        <KTAppMobileMenu onClose={() => setOpenMenu(false)} />
      </Drawer>

    </AppHeaderMobileWrapper>
  );
});

export default KTAppHeaderMobile;
