import { combineReducers } from "redux-immutable";

import { reducer as homeReducer } from "../pages/home/store";
import { reducer as searchReducer } from "../pages/result/store";
import { reducer as musicsReducer } from "../pages/musics/store";
import { reducer as sportsReducer } from "../pages/sports/store";
import { reducer as showsReducer } from "../pages/shows/store";
import { reducer as globalReducer } from "../global/store";

const cRedicer = combineReducers({
  global: globalReducer,
  home: homeReducer,
  search: searchReducer,
  musics: musicsReducer,
  sports: sportsReducer,
  shows: showsReducer,
});

export default cRedicer;
