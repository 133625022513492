import styled from "styled-components";

export const SearchPerformerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20rem;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) {
    gap: 20px;
  }
`;

export const SearchPerformerPortrait = styled.img`
  width: 60rem;
  height: 60rem;
  flex-shrink: 0;

  @media screen and (min-width: 481px) {
    width: 60px;
    height: 60px;
  }
`;

export const SearchPerformerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6rem;
  @media screen and (min-width: 481px) {
    gap: 6px;
  }
`;

export const SearchPerformerName = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  width: 214rem;

  @media screen and (min-width: 481px) {
    font-size: 14px;
    width: 214px;
  }
`;

export const SearchPerformerDetaile = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 12rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (min-width: 481px) {
    font-size: 12px;
  }
`;
