import React, { memo, useEffect, useState } from "react";
import { VenueWrapper } from "./style";
import KTMobileHeaderBar from "@/components/mobile-header-bar";
import KTPerformerHeader from "../performer/c-components/performer-header";
import KTPerformerTitle from "../performer/c-components/performer-title";
import KTMLyoutModeBar from "../../components/layout-mode/m-layout-mode-bar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { LAYOUT_MODE_CARD } from "../../common/constants";
import { getEventsOfVenue } from "../../services/events";
import KTMobileEventList from "../../components/mobile-components/mobile-event-list";
import { shallowEqual, useSelector } from "react-redux";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTEventsListView from "@/components/event/events-list-view";

const KTVenue = memo(({ windowSize }) => {
  const params = useParams();
  const venue = params.id;
  const venueName = params.name;

  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  const [allEvents, setAllEvents] = useState();
  const [layoutMode, setLayouMode] = useState(LAYOUT_MODE_CARD);

  useEffect(() => {
    getEventsOfVenue(1, city, venue, (events) => {
      setAllEvents(events);
    });
  }, [venue, city]);

  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <VenueWrapper>
        {windowSize.width <= 480 ? <KTMobileHeaderBar /> : null}
        <KTPerformerHeader
          bgimagePath={
            windowSize.width <= 480
              ? require("@/assets/img/categorys/search-venue.jpg")
              : require("@/assets/img/categorys/header-venue.jpg")
          }
        />
        <KTPerformerTitle name={venueName} />
        <KTMLyoutModeBar
          layoutMode={layoutMode}
          didChangeLayoutMode={(newLayoutMode) => {
            setLayouMode(newLayoutMode);
          }}
        />
        {windowSize.width > 480 ? (
          <KTEventsListView events={allEvents} layoutMode={layoutMode} />
        ) : (
          <KTMobileEventList events={allEvents} layoutMode={layoutMode} />
        )}
      </VenueWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTVenue);
