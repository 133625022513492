import styled from "styled-components";

export const AppTopPublicityWrapper = styled.div`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: #19192a;

  padding: 12px;

  white-space: pre-line;
`;
