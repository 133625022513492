import React, { memo } from "react";
import { MobileHomeHeaderWrapper } from "./style";
import { headerFilterItems } from "../../../local-data";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const KTMobileHomeHeaderFilter = memo(() => {
  const items = headerFilterItems.map((item) => {
    return (
      <NavLink
        to={item.to}
        className="sender"
        activeClassName="senderActive"
        style={{ maxWidth: item.maxWidth }}
        key={item.title}
      >
        {item.title}
      </NavLink>
    );
  });

  return <MobileHomeHeaderWrapper>{items}</MobileHomeHeaderWrapper>;
});

export default KTMobileHomeHeaderFilter;
