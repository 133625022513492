import { Map } from "immutable";
import * as actionTypes from "./constants";

const defaultState = Map({
  keywords: "",
  locationCity: {},
  city: {},

  /**
   * 0: Any Dates
   * 1:This Weekend
   * 2:Next Weekend
   * 3:Next 7 Days
   * 4:Next 30 Days
   * 5:Next 60 Days
   * 6:Custom
   */
  intervalType: "0",
  startdt: undefined,
  enddt: undefined,
  ip:"",
});

export function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_KEYWORDS:
      return state.set("keywords", action.keywords);
    case actionTypes.CHANGE_CITY:
      return state.set("city", action.city);
    case actionTypes.CHANGE_LOCATION_CITY:
      return state.set("locationCity", action.city);
    case actionTypes.CHANGE_TEMPORAL_INTERVAL:
      return state
        .set("intervalType", action.intervalType)
        .set("startdt", action.startdt)
        .set("enddt", action.enddt);
    case actionTypes.CHANGE_IP:
        return state.set("ip",action.ip);
    default:
      return state;
  }
}

export default reducer;
