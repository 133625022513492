import styled from "styled-components";

export const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 140px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    gap: 100px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 60px;
  }

  @media screen and (max-width: 480px) {
    gap: 30rem;
  }
`;

export const FAQCoontentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 120px;
  padding: 120px 200px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 80px 100px;
    gap: 100px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 50px;
    gap: 80px;
  }

  @media screen and (max-width: 480px) {
    padding: 40rem 20rem;
    gap: 60rem;
  }
`;

export const FAQListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 120px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    gap: 100px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 80px;
  }

  @media screen and (max-width: 480px) {
    gap: 60rem;
  }
`;
