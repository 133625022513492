import styled from "styled-components";

export const FilterBaritemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  padding: 10px 20px;

  gap: 6px;

  cursor: pointer;
  user-select: none;

  border-radius: 30px;
  border: 1px solid var(--tag-stoke-8c, #8c8c8c);
  background: #fff;

  .title {
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    height: 18px;
    line-height: 18px;
  }

  .badgeValue {
    position: absolute;
    right: -10px;
    top: -10px;

    width: 26px;
    height: 26px;
    flex-shrink: 0;

    border: 2px solid white;

    border-radius: 15px;
    background-color: #000;

    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .arrow {
    width: 16px;
    height: 8px;
  }

  @media screen and (max-width: 480px) {
    padding: 9rem 20rem;

    gap: 8rem;

    cursor: pointer;
    user-select: none;

    border-radius: 30rem;
    border: 1px solid var(--dvdr-stroke-f2, #f2f2f2);
    background: #fff;

    .title {
      color: #19192a;
      text-align: center;
      font-family: Inter;
      font-size: 14rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .arrow {
      width: 8rem;
      height: 4rem;
    }
  }
`;
