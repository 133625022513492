import styled from "styled-components";

export const SearchDividerWrapper = styled.div`
  display: flex;

  margin-right: 20rem;

  .ant-divider {
    margin: 0;
  }

  @media screen and (min-width: 481px) {
    margin-right: 20px;
  }
`;
