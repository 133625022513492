import styled from "styled-components";

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const CategoryContentAllLocation = styled.div`
  color: #19192a;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  padding: 20rem;

  @media screen and (min-width: 1920px) {
    padding: 30px 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 30px 50px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 20px 30px;
  }
`;
