import { styled } from "styled-components";

export const EventCellMobileCardContentWrapper = styled.div`
  width: 374rem;
  height: 220rem;
  border-radius: 20rem;
  background: #141415;

  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;

  position: relative;

  .like {
    width: 24rem;
    height: 20rem;
    flex-shrink: 0;
    z-index: 2;
  }
`;

export const EventCellMobileCardContentTitleWrapper = styled.div`
  z-index: 2;
  margin-top: 22rem;
  margin-right: 24rem;

  width: 210rem;
  height: 80rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EventCellMobileCardContentTitle = styled.div`
  color: #fff;
  text-align: right;
  font-family: Merriweather;
  font-size: 16rem;
  font-style: normal;
  font-weight: 800;
  line-height: 20rem;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;

export const EventPosterWrapper = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const EventCellMobileCardDateTime = styled.div`
  margin-top: 24rem;
  margin-right: 24rem;
  display: flex;
  flex-direction: row;
  gap: 8rem;
  z-index: 2;

  .date {
    color: #fff;
    text-align: right;
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 800;
    line-height: 130%; /* 19.5px */
  }

  .time {
    color: #fff;
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  .week {
    color: #fff;
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  .divider {
    color: #fff;
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EventCellMobileCardPrice = styled.div`
  margin-top: 22rem;
  margin-right: 24rem;

  z-index: 2;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 6rem 16rem;

  border-radius: 20rem;
  border: 1.5rem solid #fff;
  background: rgba(25, 25, 42, 0.1);
`;
