import styled from "styled-components";

export const DropdownMatchingCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  min-height: 48rem;
  gap: 14rem;

  color: var(--icon-4d-black, #4d4d4d);
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
