import { styled } from "styled-components";

export const EventCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EventCellContentWrapper = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  border-bottom: 10px solid #f2f2f2;
  background: #fff;
  max-width: 1024px;
`;

export const EventCellLeftWrapper = styled.div`
  display: flex;
  padding: 31px 0;
  justify-content: start;
  align-items: center;
`;

export const EventCellRightWrapper = styled.div`
  margin: 0 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
`;
