import React, { memo, useEffect, useState } from "react";
import {
  CurrentDtpLocationCellIcon,
  CurrentDtpLocationCellTitle,
  DropdownDtpCurrentLocationCellWrapper,
} from "./style";
import { shallowEqual, useSelector } from "react-redux";

const KTDropdownDtpCurrentLocationCell = memo(() => {
  const [currTitle, setCurrTitle] = useState("");

  const { locationCity = {} } = useSelector(
    (state) => ({
      locationCity: state.getIn(["global", "locationCity"]),
    }),
    shallowEqual
  );

  useEffect(() => {
    let title = "Current Location";

    if (Object.keys(locationCity).length > 0) {
      title = `${locationCity.postalCode ?? locationCity.postal_code}, ${
        locationCity.name ?? locationCity.city ?? ""
      }, ${locationCity.state}, ${locationCity.country}`;
    }

    setCurrTitle(title);
  }, [locationCity]);

  return (
    <DropdownDtpCurrentLocationCellWrapper>
      <CurrentDtpLocationCellIcon
        src={require("@/assets/img/icons/icon-curr-loc@3x.png")}
      />
      <CurrentDtpLocationCellTitle>{currTitle}</CurrentDtpLocationCellTitle>
    </DropdownDtpCurrentLocationCellWrapper>
  );
});

export default KTDropdownDtpCurrentLocationCell;
