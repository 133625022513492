import React, { memo } from "react";
import {
  TrendingCategorySilderBarItem,
  TrendingCategorySilderBarWrapper,
} from "./style";
import { mainCategory } from "../../../../../../common/category/category-data";

const KTTrendingCategorySilderBar = memo(({ title, onCategoryClick }) => {
  const barItems = mainCategory.map((ele) => {
    return (
      <TrendingCategorySilderBarItem
        key={`TrendingSilderBarItem-${ele.key}`}
        selected={title === ele.title}
        onClick={() => onCategoryClick(ele)}
      >
        {ele.title}
      </TrendingCategorySilderBarItem>
    );
  });

  return (
    <TrendingCategorySilderBarWrapper>
      {barItems}
    </TrendingCategorySilderBarWrapper>
  );
});

export default KTTrendingCategorySilderBar;
