import React, { memo } from "react";
import { HomeHeaderCityWrapper } from "./style";
import { arrowSvg } from "@/common/icon-svg";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTSwitchCityDtpDropdown from "../../../../../components/dropdown/switch-city-dtp-dropdown";
import { shallowEqual, useSelector } from "react-redux";

const KTHomeHeaderCity = memo(({ windowSize }) => {
  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  function getWrapperTop(windowSize) {
    const windowWidth = windowSize.width;
    if (windowWidth >= 1920) {
      return {
        top: "30px",
      };
    } else if (windowWidth <= 1024) {
      return {
        top: "20px",
      };
    } else {
      const windowRang = 1920 - 1024;
      const value = windowWidth - 1024;
      const top = (value / windowRang) * 10 + 20;

      return { top: `${top}px` };
    }
  }

  const { top } = getWrapperTop(windowSize);

  let cityMessage = "Any Location";

  if (typeof city === "object" && Object.keys(city).length > 0) {
    const name = city.name ?? city.city ?? "";
    const state = city.state ?? "";
    if (name.length === 0 || state.length === 0) {
      cityMessage = `${name}${city.state}`;
    } else {
      cityMessage = `${name}, ${city.state}`;
    }
  }

  return (
    <HomeHeaderCityWrapper
      style={{
        marginTop: top,
      }}
    >
      <div className="message">Explore Events in</div>
      <KTSwitchCityDtpDropdown placement="bottom">
        <div className="city">{cityMessage}</div>
      </KTSwitchCityDtpDropdown>
      {arrowSvg({
        width: "24px",
        height: "12px",
        fill: "#FFF",
        cursor: "pointer",
      })}
    </HomeHeaderCityWrapper>
  );
});

export default withWindowSize(KTHomeHeaderCity);
