import styled from "styled-components";

export const HomeDesktopFilterButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  padding: 10px 20px;

  border-radius: 30px;
  border: 1px solid var(--tag-stoke-8c, #8c8c8c);
  background: #fff;

  cursor: pointer;
  user-select: none;
`;

export const HomeDesktopFilterButtonTitle = styled.div`
  color: ${(props) =>
    props.styled === 1
      ? "var(--icon-4d-black, #4D4D4D)"
      : "var(--tag-stoke-8c, #8C8C8C)"};
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: ${(props) => (props.styled === 1 ? 700 : 400)};
  line-height: normal;
`;

export const HomeDesktopFilterButtonArrow = styled.img`
  width: 16px;
  height: 8px;
`;
