import { styled } from "styled-components";

export const MAMenuNavlinksWrapper = styled.div`
  margin-top: 70rem;
  display: flex;
  flex-direction: column;
  justify-content: start;

  .divider {
    margin: 0 47rem;
    height: 1rem;
    background: var(--dvdr-stroke-f2, #f2f2f2);
  }

  .navlink {
    background-color: white;
  }
  .navlinkActive .title {
    color: #19192a;
    font-family: Inter;
    font-size: 17rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
`;

export const MAMenuNavlinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20rem 47rem;

  .title {
    color: #19192a;
    font-family: Inter;
    font-size: 17rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .arrow {
    width: 8rem;
    height: 16rem;
    flex-shrink: 0;
    stroke-width: 2rem;
    stroke: var(--iconb3, #b3b3b3);
  }
`;
