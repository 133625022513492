import { styled } from "styled-components";

export const EventCellSmallWrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  cursor: pointer;
  user-select: none;

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 6px 20px 0px rgba(242, 242, 242, 0.6);

  position: relative;
`;

export const EventCellSmallMarkImage = styled.img`
  width: 160px;
  height: 160px;

  position: absolute;
  right: 0px;
  bottom: 0px;

  z-index: 0;
`;

export const EventCellSmallDateTime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  z-index: 1;

  .date {
    color: var(--icon-4d-black, #19192a);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .time {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .week {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divider {
    color: var(--icon-4d-black, #c8c8c8);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EventCellSmallAddress = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 34px;
  width: 70%;
`;

export const EventCellSmallTitle = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Merriweather;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  width: 70%;

  min-height: 66px;
`;

export const EventCellSmallBuyButton = styled.div`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 6px 16px;

  border-radius: 20px;
  border: 1.5px solid #fff;
  background: #19192a;
`;
