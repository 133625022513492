import React, { memo } from "react";
import {
  EventSimpleContentWrapper,
  EventSimpleTitleWrapper,
  EventSimpleWrapper,
} from "./style";
import { ReactComponent as LikeIcon } from "@/assets/img/svg/icon-like.svg";

const KTEventSimple = memo(({ event, width = "240rem", height = "150rem" }) => {
  return (
    <EventSimpleWrapper>
      <EventSimpleContentWrapper
        style={{
          width: width,
          height: height,
          backgroundImage:
            "url(" +
            require(`@/assets/img/temporary//${event.imageName}`) +
            ")",
        }}
      >
        {/* <LikeIcon className="like" /> */}
      </EventSimpleContentWrapper>
      <EventSimpleTitleWrapper>
        <div className="title">{event.eventTitle}</div>
        <div className="kindName">{event.kindName}</div>
      </EventSimpleTitleWrapper>
    </EventSimpleWrapper>
  );
});

export default KTEventSimple;
