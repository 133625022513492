import styled from "styled-components";
import { KT_THEME } from "../../../../common/constants";

export const AppHeaderCenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  height: 48px;
  padding: 0 15px;

  border-radius: 32px;
  background: #fff;

  box-shadow: ${(props) =>
    props.theme === KT_THEME.DEFAULT ? "" : "-1px 1px 10px 0px #f2f2f2"};
  border: ${(props) =>
    props.theme === KT_THEME.DEFAULT ? "1px solid #fff" : ""};
`;

export const AppHeaderCenterDivider = styled.div`
  width: 2px;
  height: 20px;

  background-color: #f2f2f2;
`;
