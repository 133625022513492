import React, { memo } from "react";
import {
  EventCellTypeImage,
  EventCellTypeTitle,
  EventCellTypeWrapper,
} from "./style";

const KTEventCellType = memo(({ event }) => {
  let title = event?.defaultCategory?.text?.name ?? "";
  var regex = /\((.*?)\)/;
  var result = regex.exec(title);
  if (result) {
    title = result[1];
  } else {
    if (title.indexOf("/") !== -1) {
      title = title.split("/")[0];
    }
  }

  return (
    <EventCellTypeWrapper>
      <EventCellTypeImage src={event?.kt_desktop_icon} />
      <EventCellTypeTitle>{title}</EventCellTypeTitle>
    </EventCellTypeWrapper>
  );
});

export default KTEventCellType;
