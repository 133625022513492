import React, { memo } from "react";
import {
  EventCellExploreWrapper,
  EventContentPosterWrapper,
  EventContentWrapper,
  EventDateWrapper,
} from "./style";

import { ReactComponent as LikeIcon } from "@/assets/img/svg/icon-like.svg";

const KTEventCellExplore = memo(({ event }) => {
  return (
    <EventCellExploreWrapper>
      <EventDateWrapper>
        <div className="date">Sep 12</div>
        <div className="week">FRI</div>
        <div className="time">6:30 pm</div>
      </EventDateWrapper>
      <EventContentWrapper>
        <EventContentPosterWrapper
          style={{
            backgroundImage:
              "url(" +
              require(`@/assets/img/temporary//${event.imageName}`) +
              ")",
          }}
        >
          {/* <LikeIcon className="like" /> */}
        </EventContentPosterWrapper>
        <div className="title">{event.title}</div>
        <div className="address">{event.address}</div>
      </EventContentWrapper>
    </EventCellExploreWrapper>
  );
});

export default KTEventCellExplore;
