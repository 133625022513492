import React, { memo } from "react";
import { MAMenuAddressWrapper } from "./style";

import { ReactComponent as AddressSvg } from "@/assets/img/svg/icon-address.svg";
import { shallowEqual, useSelector } from "react-redux";

const KTMAMenuAddress = memo(({ onClick }) => {
  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  let cityMessage = "Any Location";

  if (typeof city === "object" && Object.keys(city).length > 0) {
    const name = city.name ?? city.city ?? "";
    const state = city.state ?? "";
    if (name.length === 0 || state.length === 0) {
      cityMessage = `${name}${city.state}`;
    } else {
      cityMessage = `${name}, ${city.state}`;
    }
  }

  return (
    <MAMenuAddressWrapper onClick={onClick}>
      <div className="addressButton">
        <AddressSvg className="icon" />
        <div className="title">{cityMessage}</div>
      </div>
    </MAMenuAddressWrapper>
  );
});

export default KTMAMenuAddress;
