import React, { memo } from "react";
import {
  AboutGuaranteeContent,
  AboutGuaranteeTitle,
  AboutGuaranteeWrapper,
} from "./style";

const KTAboutGuarantee = memo(() => {
  return (
    <AboutGuaranteeWrapper>
      <AboutGuaranteeTitle>100% MONEY-BACK GUARANTEE</AboutGuaranteeTitle>
      <AboutGuaranteeContent>
        Rest assured when making a purchase for any sporting event, concert, or
        theatre show, as we guarantee the validity of your tickets. In the Event
        of cancellation without rescheduling, you’ll receive a 100% refund.
        Additionally, if your order is accepted but the tickets are not deliverd
        or shipped in time for the event, you’ll also receive a 100% refund,
        Your peace of min is our priority.
      </AboutGuaranteeContent>
    </AboutGuaranteeWrapper>
  );
});

export default KTAboutGuarantee;
