import React, { memo } from "react";
import { ContentWrapper, EventCellSimpleWrapper, OptionWrapper } from "./style";

import { ReactComponent as LikeIcon } from "@/assets/img/svg/icon-like.svg";
import { ReactComponent as ShareIcon } from "@/assets/img/svg/icon-share.svg";
import KTDateView from "../../date-view";

const KTEventCellSimple = memo(() => {
  return (
    <EventCellSimpleWrapper>
      <OptionWrapper>
        {/* <LikeIcon className="icon" /> */}
        <ShareIcon className="icon" />
      </OptionWrapper>

      <ContentWrapper>
        <KTDateView />
        <div className="address">
          GEHA Field at Arrowhead Stadium, Kansas City, MO
        </div>
        <div className="title">Taylor Swift with MUNA and Gracie Abrams</div>
        <buyTicket className="buyTicket">Buy 12Ticket</buyTicket>
      </ContentWrapper>
    </EventCellSimpleWrapper>
  );
});

export default KTEventCellSimple;
