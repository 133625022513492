import { styled } from "styled-components";

export const MAMenuAboutWrapper = styled.div`
  margin-left: 47rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 16rem;

  .item {
    color: var(--tag-stoke-8c, #8c8c8c);
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
