import React, { memo } from "react";
import {
  PerformerEventsAddressBar,
  PerformerEventsAllConcert,
  PerformerEventsHudMessage,
  PerformerEventsWrapper,
} from "./style";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTEventsListView from "@/components/event/events-list-view";
import { LAYOUT_MODE_LIST } from "@/common/constants";
import { ReactComponent as AddressIcon } from "@/assets/img/svg/icon-address.svg";
import { currValue } from "../../../../utils";
import { homeContentEventItems } from "../../../../common/ephemeral-data";

const KTPerformerEvents = memo(({ windowSize }) => {
  const windowWidth = windowSize.width;
  return (
    <PerformerEventsWrapper>
      <PerformerEventsHudMessage>
        There is no event near
      </PerformerEventsHudMessage>
      <PerformerEventsAddressBar>
        <AddressIcon className="icon" />
        <div className="addressTitle">Overland Park, KS</div>
      </PerformerEventsAddressBar>
      <PerformerEventsAllConcert>All Concert</PerformerEventsAllConcert>
      <KTEventsListView
        key={LAYOUT_MODE_LIST}
        events={homeContentEventItems}
        layoutMode={LAYOUT_MODE_LIST}
      />
    </PerformerEventsWrapper>
  );
});

export default withWindowSize(KTPerformerEvents);
