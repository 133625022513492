// 修改搜素关键字
export const CHANGE_SEARCH_KEYWORDS = "search/CHANGE_SEARCH_KEYWORDS";

// 修改表演者结果
export const CHANGE_PERFORMERS = "search/CHANGE_PERFORMERS";

// 修改events结果
export const CHANGE_EVENTS = "search/CHANGE_EVENTS";

// 修改场地结果
export const CHANGE_VENUES = "search/CHANGE_VENUES";

// 修改搜索建议
export const CHANGE_SUGGESTIONS = "search/CHANGE_SUGGESTIONS";

// 清空搜素结果
export const EMPTY_SEARCH_RESULTS = "search/EMPTY_SEARCH_RESULTS";
