import request from "./request";

export function getSuggest(keywords = "", callback, cancelSource) {
  if (typeof callback != "function") {
    return;
  }

  if (keywords.length === 0) {
    callback({});
    return;
  }
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post("getSuggest", { keywords: keywords }, config)
    .then((response) => {
      const kt_performers = response.kt_performers ?? [];
      let kt_performers_map = {};

      // 处理 performers 头像数据
      kt_performers.forEach((element) => {
        kt_performers_map[element.id] = element.icon;
      });

      let suggestions = response?.suggestions ?? {};
      suggestions?.performers?.results?.forEach((e) => {
        if (e.id in kt_performers_map) {
          e.icon = kt_performers_map[e.id];
        }
      });

      suggestions.keywords = keywords;
      callback(suggestions);
    });
}
