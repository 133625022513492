import React, { memo } from "react";
import {
  AppHeaderButtonIcon,
  AppHeaderButtonTitle,
  AppHeaderButtonWrapper,
} from "./style";

const KTAppHeaderButton = memo(({ iconSrc = "", title = "", onClick }) => {
  return (
    <AppHeaderButtonWrapper onClick={onClick}>
      <AppHeaderButtonIcon src={iconSrc} />
      <AppHeaderButtonTitle>{title}</AppHeaderButtonTitle>
    </AppHeaderButtonWrapper>
  );
});

export default KTAppHeaderButton;
