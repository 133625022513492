import styled from "styled-components";

export const AppHeaderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  cursor: pointer;
  user-select: none;
`;

export const AppHeaderButtonIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const AppHeaderButtonTitle = styled.div`
  text-align: left;
  min-width: 110px;

  color: var(--icon-4d-black, #4d4d4d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;
