import React, { memo } from "react";
import { withRouter } from "react-router-dom";
import {
  EventCellSmallAddress,
  EventCellSmallBuyButton,
  EventCellSmallDateTime,
  EventCellSmallMarkImage,
  EventCellSmallTitle,
  EventCellSmallWrapper,
} from "./style";

import {
  eventBuyButtonTitle,
  eventDate,
  eventName,
  eventTime,
  eventVenue,
  eventWeek,
} from "../../../utils/event";

const KTEventCellSmall = memo((props) => {
  const { event } = props;
  function onEventClick() {
    const url = window.location.origin + `#/map/${event.id}`;
    window.open(url, "_self", "noopener,noreferrer");
  }
  return (
    <EventCellSmallWrapper onClick={onEventClick}>
      {event.kt_mark ? <EventCellSmallMarkImage src={event.kt_mark} /> : null}
      <EventCellSmallDateTime>
        {event.date.datetime === undefined
          ? [<div className="date">{event.date.text.time}</div>]
          : [
              <div className="date">{`${eventDate(event)} `}</div>,
              <div className="divider">|</div>,
              <div className="time">{`${eventTime(event)}`}</div>,
              <div className="divider">|</div>,
              <div className="week">{`${eventWeek(event)} `}</div>,
            ]}
      </EventCellSmallDateTime>
      <EventCellSmallAddress>{eventVenue(event)}</EventCellSmallAddress>
      <EventCellSmallTitle>{eventName(event)}</EventCellSmallTitle>
      <EventCellSmallBuyButton>
        {eventBuyButtonTitle(event)}
      </EventCellSmallBuyButton>
    </EventCellSmallWrapper>
  );
});

export default withRouter(KTEventCellSmall);
