import React, { memo, useEffect, useState } from "react";

import { AppHeaderWrapper } from "./style";

import withWindowSize from "../higher-order/withWindowSize";
import { KT_THEME } from "../../common/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import KTAppHeaderLeftContent from "./c-components/app-header-left-content";
import KTAppHeaderRightContent from "./c-components/app-header-right-content";

const KTAppHeader = memo(() => {
  const location = useLocation();
  const [theme, setTheme] = useState(KT_THEME.DEFAULT);

  useEffect(() => {
    const darkTheme =
      location.pathname.includes("/map") ||
      location.pathname.includes("/terms") ||
      location.pathname.includes("/about") ||
      location.pathname.includes("/FAQ");
    setTheme(darkTheme ? KT_THEME.DARK : KT_THEME.DEFAULT);
  }, [location]);

  return (
    <AppHeaderWrapper theme={theme}>
      <KTAppHeaderLeftContent theme={theme} />
      <KTAppHeaderRightContent theme={theme} />
    </AppHeaderWrapper>
  );
});

export default withWindowSize(KTAppHeader);
