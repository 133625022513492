import React, { memo } from "react";
import {
  SearchDropdownFooterArrow,
  SearchDropdownFooterContentWrapper,
  SearchDropdownFooterTitle,
  SearchDropdownFooterWrapper,
} from "./style";

const KTSearchDropdownFooter = memo(({ onExploreMoreClick }) => {
  return (
    <SearchDropdownFooterWrapper>
      <SearchDropdownFooterContentWrapper onClick={onExploreMoreClick}>
        <SearchDropdownFooterTitle>Explore More</SearchDropdownFooterTitle>
        <SearchDropdownFooterArrow
          src={require("@/assets/img/icons/icon-arrow-right@3x.png")}
        />
      </SearchDropdownFooterContentWrapper>
    </SearchDropdownFooterWrapper>
  );
});

export default KTSearchDropdownFooter;
