import { styled } from "styled-components";

export const EventCellInfoWrapper = styled.div`
  width: 420px;
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 6px;

  .title {
    color: #19192a;
    font-family: Merriweather;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    max-height: 48px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;

export const EventCellInfoTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;

  .icon {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    stroke: #4d4d4d;
    stroke-width: 0.5;
  }

  .time {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }

  .week {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
`;

export const EventCellInfoTimeDivider = styled.div`
  color: #c8c8c8;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const EventCellInfoAddressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;

  .icon {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    stroke: #4d4d4d;
    stroke-width: 0.5;
  }

  .address {
    color: var(--txt-c8, #c8c8c8);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;
