import styled from "styled-components";

export const ClassifyTitleSilderBarWrapper = styled.div`
  .swiperSlide {
    width: fit-content;
    height: fit-content;
  }

  padding: 15rem 24rem;

  background: #fff;
  box-shadow: 0px 6rem 20rem 0px rgba(242, 242, 242, 0.4);

  @media screen and (min-width: 1920px) {
    padding: 30px 100px;
    box-shadow: 0px 4px 20px 0px rgba(242, 242, 242, 0.4);

    gap: 20px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 30px 50px;
    box-shadow: 0px 4px 20px 0px rgba(242, 242, 242, 0.4);

    gap: 20px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 15px 30px;
    box-shadow: 0px 4px 20px 0px rgba(242, 242, 242, 0.4);

    gap: 10px;
  }
`;

export const ClassifyTitleSilderBarItem = styled.div`
  cursor: pointer;
  user-select: none;

  display: flex;
  padding: 8rem 14rem;
  justify-content: center;
  align-items: center;
  gap: 10rem;

  border-radius: 10rem;
  border: 1rem solid var(--BLUE-GREY, #a3adbe);
  background: ${(props) => (props.selected ? "#19192A" : "#fff")};

  color: ${(props) => (props.selected ? "#fff" : "#5f7595")};
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */

  @media screen and (min-width: 481px) {
    padding: 8px 14px;
    font-size: 14px;
    gap: 10px;
    border-radius: 10px;

    border: 1px solid var(--BLUE-GREY, #a3adbe);
  }
`;
