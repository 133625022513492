import React, { memo, useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { HomeContentWrapper } from "./style";
import KTEventsListView from "@/components/event/events-list-view";
import {
  getChangeHomeLayoutModeAction,
  getHomeEventsAction,
} from "../../../../store/actionCreators";

import KTMobileEventList from "@/components/mobile-components/mobile-event-list";
import axios from "axios";
import KTMLyoutModeBar from "../../../../../../components/layout-mode/m-layout-mode-bar";
import KTWindowSizeContext from "../../../../../../common/context/windowSizeContext";
import KTEventLoadMore from "../../../../../../components/event/event-load-more";

const KTHomeContent = memo(() => {
  const context = useContext(KTWindowSizeContext);
  const windowSize = context.windowSize;

  const [lastCancneSource, setLastCancneSource] = useState();

  const {
    sortMode,
    layoutMode,
    showLoadMore = false,
    events = [],
    pagenum = 1,
    city = {},
    categories = [],
    intervalType = "0",
    startdt = undefined,
    enddt = undefined,
  } = useSelector(
    (state) => ({
      sortMode: state.getIn(["home", "sortMode"]),
      layoutMode: state.getIn(["home", "layoutMode"]),
      showLoadMore: state.getIn(["home", "showLoadMore"]),
      events: state.getIn(["home", "events"]),
      pagenum: state.getIn(["home", "pagenum"]),
      city: state.getIn(["global", "city"]),
      categories: state.getIn(["home", "categories"]),
      intervalType: state.getIn(["home", "intervalType"]),
      startdt: state.getIn(["home", "startdt"]),
      enddt: state.getIn(["home", "enddt"]),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    getHomeEvents(1);
  }, [city, sortMode, categories, intervalType, startdt, enddt]);

  const onclickChangeLayoutMode = (mode) => {
    dispatch(getChangeHomeLayoutModeAction(mode));
  };

  const onclickLoadMoreButton = () => {
    getHomeEvents(pagenum + 1);
  };

  function getHomeEvents(pageNumber) {
    if (!!lastCancneSource) {
      lastCancneSource.cancel();
    }

    const cancelSource = axios.CancelToken.source();
    setLastCancneSource(cancelSource);
    dispatch(
      getHomeEventsAction(
        pageNumber,
        city,
        sortMode,
        cancelSource,
        categories,
        intervalType,
        startdt,
        enddt
      )
    );
  }

  return (
    <HomeContentWrapper>
      {windowSize.width < 1024 ? (
        <KTMLyoutModeBar
          layoutMode={layoutMode}
          didChangeLayoutMode={(newLayoutMode) => {
            onclickChangeLayoutMode(newLayoutMode);
          }}
        />
      ) : null}

      {windowSize.width > 480 ? (
        <KTEventsListView
          key={layoutMode}
          events={events}
          layoutMode={layoutMode}
        />
      ) : (
        <KTMobileEventList events={events} layoutMode={layoutMode} />
      )}
      {showLoadMore ? (
        <KTEventLoadMore onLoadMoreClick={onclickLoadMoreButton} />
      ) : null}
    </HomeContentWrapper>
  );
});

export default KTHomeContent;
