import React, { memo } from "react";
import {
  ResultDtpContentDivider,
  ResultDtpContentEventsHeader,
  ResultDtpContentEventsHeaderLine,
  ResultDtpContentLeftWrapper,
  ResultDtpContentRightWrapper,
  ResultDtpContentWrapper,
} from "./style";

import { withRouter } from "react-router-dom";
import KTEventsListView from "@/components/event/events-list-view";
import { LAYOUT_MODE_LIST } from "../../../../common/constants";
import { shallowEqual, useSelector } from "react-redux";
import KTResultDtpContentListView from "./c-components/list-view";
import KTSearchDropdownPerformerCell from "../../../../components/dropdown/search-keywords-dtp-dropdown/c-components/performer-cell";
import KTSearchDropdownVenueCell from "../../../../components/dropdown/search-keywords-dtp-dropdown/c-components/venue-cell";

const KTResultDtpContent = memo((props) => {
  const { suggestions = {} } = props;

  const eventResult = suggestions?.events ?? {};
  const performerResult = suggestions?.performers ?? {};
  const venueResult = suggestions?.venues ?? {};

  const {
    events = [],
    performers = [],
    venues = [],
  } = useSelector(
    (state) => ({
      events: state.getIn(["search", "events"]),
      performers: state.getIn(["search", "performers"]),
      venues: state.getIn(["search", "venues"]),
    }),
    shallowEqual
  );

  const performerCells = performers.map((ele) => {
    return [
      <KTSearchDropdownPerformerCell
        key={`KTSearchPerformer-${ele.id}`}
        performer={ele}
        onClick={(performer) => {
          props.history.push(`/performer/${performer.id}`);
        }}
      />,
    ];
  });

  const venueCells = venues.map((ele) => {
    return [
      <KTSearchDropdownVenueCell
        key={`KTSearchVenueCell-${ele.id}`}
        venue={ele}
        onClick={(venue) => {
          props.history.push(`/venue/${venue.id}/${venue?.name ?? ""}`);
        }}
      />,
    ];
  });

  return (
    <ResultDtpContentWrapper>
      <ResultDtpContentLeftWrapper>
        <KTResultDtpContentListView
          title={`${performerResult?.totalResultCount ?? ""} Related Artists`}
        >
          {performerCells}
        </KTResultDtpContentListView>
        <KTResultDtpContentListView
          title={`${venueResult?.totalResultCount ?? ""} Related Venues`}
        >
          {venueCells}
        </KTResultDtpContentListView>
      </ResultDtpContentLeftWrapper>
      <ResultDtpContentDivider />
      <ResultDtpContentRightWrapper>
        <ResultDtpContentEventsHeader>
          {`${eventResult?.totalResultCount ?? ""} Related Events`}
          <ResultDtpContentEventsHeaderLine />
        </ResultDtpContentEventsHeader>
        <KTEventsListView events={events} layoutMode={LAYOUT_MODE_LIST} />
      </ResultDtpContentRightWrapper>
    </ResultDtpContentWrapper>
  );
});

export default withRouter(KTResultDtpContent);
