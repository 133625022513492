import styled from "styled-components";

export const ResultHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
`;

export const ResultHeaderBackgroundImage = styled.img`
  position: absolute;

  top: -184rem;
  height: calc(100% + 184rem);
  width: 100%;
  object-fit: cover;
  z-index: -1;

  @media screen and (min-width: 481px) {
    top: -80px;
    height: calc(100% + 80px);
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
`;

export const ResultHeaderTitle = styled.div`
  margin: 30rem 20rem;

  color: #fff;
  font-family: Inter;
  font-size: 20rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media screen and (min-width: 481px) {
    margin: 100px 50px;

    color: #fff;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    text-align: center;
  }
`;
