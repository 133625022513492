import React, { memo } from "react";
import { SpaceWrapper } from "./style";

const KTSpace = memo(
  ({
    width = "auto",
    height = "auto",
    backgroundColor = "rgba(0, 0, 0, 0)",
  }) => {
    return (
      <SpaceWrapper
        width={width}
        height={height}
        backgroundColor={backgroundColor}
      />
    );
  }
);

export default KTSpace;
