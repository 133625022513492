import React, { memo, useContext } from "react";
import {
  TrendingAddressBarBottomLine,
  TrendingAddressBarLeftAnyLocation,
  TrendingAddressBarLeftLocation,
  TrendingAddressBarLeftTitle,
  TrendingAddressBarLeftWrapper,
  TrendingAddressBarRightArrow,
  TrendingAddressBarRightTitle,
  TrendingAddressBarRightWrapper,
  TrendingAddressBarWrapper,
} from "./style";
import { shallowEqual, useSelector } from "react-redux";
import KTSwitchCityOtherDropdown from "../../../../../../components/dropdown/switch-city-other-dropdown";
import KTSwitchCityDtpDropdown from "../../../../../../components/dropdown/switch-city-dtp-dropdown";
import KTWindowSizeContext from "../../../../../../common/context/windowSizeContext";

const KTTrendingAddressBar = memo(({ onExploreMoreClick }) => {
  const context = useContext(KTWindowSizeContext);
  const windowWidth = context.windowSize.width;

  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  let cityMessage = "Any Location";
  let isAny = true;

  if (typeof city === "object" && Object.keys(city).length > 0) {
    const name = city.name ?? city.city ?? "";
    const state = city.state ?? "";
    if (name.length === 0 || state.length === 0) {
      cityMessage = `${name}${city.state}`;
    } else {
      cityMessage = `${name}, ${city.state}`;
    }
  }

  const cityElement = (
    <TrendingAddressBarLeftWrapper>
      <TrendingAddressBarLeftTitle>
        {isAny ? "Trending in" : "Trending Near"}
      </TrendingAddressBarLeftTitle>
      {isAny ? (
        <TrendingAddressBarLeftAnyLocation>
          {cityMessage}
        </TrendingAddressBarLeftAnyLocation>
      ) : (
        <TrendingAddressBarLeftLocation>
          {cityMessage}
        </TrendingAddressBarLeftLocation>
      )}
    </TrendingAddressBarLeftWrapper>
  );

  return (
    <TrendingAddressBarWrapper>
      {windowWidth > 480 ? (
        <KTSwitchCityDtpDropdown>{cityElement}</KTSwitchCityDtpDropdown>
      ) : (
        <KTSwitchCityOtherDropdown>{cityElement}</KTSwitchCityOtherDropdown>
      )}

      {windowWidth < 1024 ? (
        <TrendingAddressBarRightWrapper onClick={onExploreMoreClick}>
          <TrendingAddressBarRightTitle>
            Explore More
          </TrendingAddressBarRightTitle>
          <TrendingAddressBarRightArrow
            src={require("@/assets/img/icons/arrow-heng-right@3x.png")}
          />
        </TrendingAddressBarRightWrapper>
      ) : null}
      <TrendingAddressBarBottomLine />
    </TrendingAddressBarWrapper>
  );
});

export default KTTrendingAddressBar;
