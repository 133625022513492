import styled from "styled-components";

export const EventCellBasicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow: visible;
  margin: 0px 10rem 34rem 10rem;

  padding: 20rem;

  border-radius: 10rem;
  background: #fff;
  box-shadow: 0px 8rem 15rem 6rem rgba(226, 23, 55, 0.05);

  .address {
    margin-top: 3rem;
    color: var(--txt-c8, #c8c8c8);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .title {
    margin-top: 20rem;
    color: #000;
    font-family: Merriweather Black;
    font-size: 15rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
`;

export const EventCellBasicsDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6rem;

  .date {
    color: var(--icon-4d-black, #19192a);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .time {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .week {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divider {
    color: var(--icon-4d-black, #c8c8c8);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
