import { dateString } from "./date-format";

export function eventName(event) {
  return event?.text?.name ?? "";
}

export function eventVenue(event) {
  const venueName = event.venue?.text?.name || "noun";
  if (event.venue?.text?.name) {
    let arrInfos = [venueName];

    if (event?.city?.text?.name) {
      arrInfos.push(event?.city?.text?.name);
    }

    if (event?.country?.alphaCode) {
      arrInfos.push(event?.country?.alphaCode);
    }
    return arrInfos.join(", ");
  }
  return "noun";
}

export function eventDate(event) {
  return dateString(event.date?.datetime, "MMM. d");
}

export function eventTime(event) {
  return dateString(event.date?.datetime, "h:mm a", true);
}

export function eventWeek(event) {
  return dateString(event.date?.datetime, "EEE").toUpperCase();
}

export function eventDay(event) {
  return dateString(event.date?.datetime, "d");
}

export function eventMonth(event) {
  return dateString(event.date?.datetime, "MMM");
}

export function eventYear(event) {
  return dateString(event.date?.datetime, "yyyy");
}

export function eventBuyButtonTitle(event, lineFeed = false) {
  return ((typeof event.pricingInfo === "undefined") ) //|| event.id ===5897033 || event.id===5572844)  // special super bowl changes, remove it after super bowl
    ? "Buy\nTicket" 
      : "Buy\nTicket";

    // : `From${lineFeed ? "\n" : ""} ${
    //     event.pricingInfo?.lowPrice?.text?.formatted ?? ""
    //   }`;
}
