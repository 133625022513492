import styled from "styled-components";

export const SearchInputCellWrapper = styled.div`
  .ant-input-affix-wrapper {
    padding: 0;
  }
  input {
    color: #19192a;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding: 0;
    height: 48rem;
  }

  divider {
    margin: 0;
  }
`;
