import styled from "styled-components";

export const CategoriesSilderBarWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20rem 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20rem;

  border-radius: 20rem 20rem 0px 0px;
  border-bottom: 1rem solid #fafafa;
  background: #fff;
  box-shadow: 0px 6rem 20rem 0px rgba(242, 242, 242, 0.4);

  .ktSwiper {
    padding: 0 20rem;
    width: calc(100% - 40rem);
    height: auto;
  }

  .ktSwiperSlide {
    width: fit-content;
    height: fit-content;
  }
`;

export const CategoriesSilderBarTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;

  padding: 0 20rem;
`;

export const CategoriesSilderBarTitle = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 22rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

export const CategoriesSilderBarTitleBottomLine = styled.div`
  width: 60rem;
  height: 6rem;
  background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);
`;

export const CategoriesSwiperSlideWrapper = styled.div`
  width: 78rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8rem;
`;

export const CategoriesSwiperSlideIcon = styled.img`
  width: 55rem;
  height: 55rem;
`;

export const CategoriesSwiperSlideTitle = styled.div`
  height: 34rem;

  color: ${(props) =>
    props.selected
      ? "var(--icon-4d-black, #4D4D4D)"
      : "var(--iconb3, #8C8C8C)"};
  text-align: center;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  white-space: pre-line;
`;
