import styled from "styled-components";

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const EventAddressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  .icon {
    width: 22.001px;
    height: 22px;
    flex-shrink: 0;
    padding: 0 20px;
  }

  .title {
    display: flex;
    width: 306px;
    height: 34px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EventContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  height: 280px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  background: #141415;
  position: relative;
  z-index: 0;
`;

export const EventPosterWrapper = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const EventContentLeftWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px 30px;
  gap: 34px;
  z-index: 2;

  .title {
    display: flex;
    align-items: center;

    color: #fff;
    font-family: Merriweather;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    width: 200px;
    height: 60px;
    padding: 5px 0;
  }

  .buyTicket {
    display: flex;
    padding: 7px 30px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 2px solid #fff;
    background: rgba(25, 25, 42, 0.1);

    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    cursor: pointer;
    user-select: none;
  }

  .buyTicket:hover {
    font-weight: 800;
  }
`;

export const EventContentLeftOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  z-index: 2;

  .option {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
`;

export const EventContentLeftDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 18px;
  gap: 8px;

  .date {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .time {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .week {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divider {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EventContentRightWrapper = styled.div`
  flex-shrink: 0;
  width: 140px;
`;
