import styled from "styled-components";

export const SwitchCityOtherDropdownWrapper = styled.div`
  border-radius: 10rem;
  border: 1rem solid var(--dvdr-stroke-f2, #f2f2f2);
  background: #fff;
  box-shadow: 0px 4rem 20rem 0px rgba(0, 0, 0, 0.1);
  padding: 6rem 30rem 20rem 30rem;

  max-width: ${(props) => `${props.maxWidth - 60}rem`};

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 0;
  }
`;
