import React, { memo, useEffect, useState } from "react";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTMobileEventList from "@/components/mobile-components/mobile-event-list";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ShowsWrapper } from "./style";
import KTSpace from "@/components/space";
import KTMainCategoryHeader from "../../components/main-category-header";
import KTCategoriesSilderBar from "../../components/categories-silder-bar";
import KTEventsListView from "@/components/event/events-list-view";
import {
  getChangeShowsCategoriesAction,
  getChangeShowsLayoutModeAction,
  getShowsEventsAction,
} from "./store/actionCreators";
import { showsGenres } from "./local-data";
import KTShowsMobFilterBar from "./c-components/shows-mob-filter-bar";
import KTMLyoutModeBar from "../../components/layout-mode/m-layout-mode-bar";
import axios from "axios";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import KTMusicsCategory from "../musics/c-components/musics-category";
import { Affix } from "antd";
import KTShowsDtpFiterBar from "./c-components/shows-dtp-filter-bar";
import KTEventLoadMore from "../../components/event/event-load-more";

const KTShows = memo(({ windowSize }) => {
  const dispatch = useDispatch();
  const [lastCancneSource, setLastCancneSource] = useState();

  const {
    showLoadMore = false,
    sortMode,
    layoutMode,
    events = [],
    pagenum = 1,
    city = {},
    categories = [],
    intervalType = "0",
    startdt = undefined,
    enddt = undefined,
  } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
      sortMode: state.getIn(["shows", "sortMode"]),
      layoutMode: state.getIn(["shows", "layoutMode"]),
      showLoadMore: state.getIn(["shows", "showLoadMore"]),
      events: state.getIn(["shows", "events"]),
      pagenum: state.getIn(["shows", "pagenum"]),
      categories: state.getIn(["shows", "categories"]),
      intervalType: state.getIn(["shows", "intervalType"]),
      startdt: state.getIn(["shows", "startdt"]),
      enddt: state.getIn(["shows", "enddt"]),
    }),
    shallowEqual
  );

  useEffect(() => {
    getShowsEvents(1);
  }, [dispatch, city, sortMode, categories, intervalType, startdt, enddt]);

  const onclickLoadMoreButton = () => {
    getShowsEvents(pagenum + 1);
  };

  function getShowsEvents(pageNumber) {
    if (!!lastCancneSource) {
      lastCancneSource.cancel();
    }

    const cancelSource = axios.CancelToken.source();
    setLastCancneSource(cancelSource);

    dispatch(
      getShowsEventsAction(
        pageNumber,
        city,
        sortMode,
        cancelSource,
        categories,
        intervalType,
        startdt,
        enddt
      )
    );
  }

  function onChangeSelectdCategories(categories) {
    dispatch(getChangeShowsCategoriesAction(categories));
  }

  const onclickChangeLayoutMode = (mode) => {
    dispatch(getChangeShowsLayoutModeAction(mode));
  };

  const windowWidth = windowSize.width;

  const headerImageSrc =
    windowWidth > 480
      ? require("@/assets/img/shows/dtp-header-shows.jpg")
      : require("@/assets/img/shows/mob-header-shows.jpg");

  const headerTitle = windowWidth > 1024 ? "Theatre Tickets" : null;

  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <ShowsWrapper>
        <KTMainCategoryHeader
          backgroundSrc={headerImageSrc}
          title={headerTitle}
        />

        {windowWidth > 480 ? (
          <KTMusicsCategory
            categories={showsGenres.filter((ele) => ele.keyWord.length > 0)}
            selectedCategories={categories}
            onChangeSelectdCategories={onChangeSelectdCategories}
          />
        ) : (
          <KTCategoriesSilderBar
            title="Categories"
            categories={showsGenres}
            selectedCategories={categories}
            onChangeSelectdCategories={onChangeSelectdCategories}
          />
        )}

        {windowWidth > 1024
          ? [
              <KTSpace height="40px" />,
              <Affix offsetTop={0}>
                <KTShowsDtpFiterBar />
              </Affix>,
            ]
          : [
              <KTSpace height="14rem" />,
              <Affix offsetTop={0}>
                <KTShowsMobFilterBar />
              </Affix>,
              <KTMLyoutModeBar
                layoutMode={layoutMode}
                didChangeLayoutMode={(newLayoutMode) => {
                  onclickChangeLayoutMode(newLayoutMode);
                }}
              />,
            ]}

        {windowWidth > 480 ? (
          <KTEventsListView
            key={layoutMode}
            events={events}
            layoutMode={layoutMode}
          />
        ) : (
          <KTMobileEventList events={events} layoutMode={layoutMode} />
        )}
        {showLoadMore ? (
          <KTEventLoadMore onLoadMoreClick={onclickLoadMoreButton} />
        ) : null}
      </ShowsWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTShows);
