import styled from "styled-components";

export const TeamCellWrapper = styled.div`
  position: relative;
  width: 139rem;
  height: 80rem;
  flex-shrink: 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;

  .like {
    position: absolute;
    left: 10rem;
    top: 12rem;
    width: 24rem;
    height: 20rem;
    flex-shrink: 0;
  }

  .title {
    color: #fff;
    text-align: center;
    text-shadow: 0px 4rem 4rem rgba(0, 0, 0, 0.1);
    font-family: Inter;
    font-size: 16rem;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 16px */
    white-space: pre-line;
  }

  @media screen and (min-width: 481px) {
    width: 230px;
    height: 138px;
    flex-shrink: 0;

    .like {
      left: 10px;
      top: 10px;
      width: 28px;
      height: 28px;
      flex-shrink: 0;
    }

    .title {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      font-size: 26px;
      line-height: 125%; /* 32.5px */
    }
  }
`;
