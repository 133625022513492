import React, { memo, useState } from "react";

import { Divider, Dropdown } from "antd";
import KTSearchInputCell from "./c-components/search-input";
import { SwitchCityDropdownWrapper } from "./style";
import KTDropdownCurrentLocationCell from "./c-components/current-location-cell";
import KTDropdownAnyLocationCell from "./c-components/any-location-cell";
import KTDropdownMatchingCell from "./c-components/matching-cell";
import KTDropdownNoMatchingCell from "./c-components/no-matches-cell";
import { getLocation } from "../../../services/location";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChangeCityAction } from "../../../global/store/actionCreators";

const KTSwitchCityDropdown = memo((props) => {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [arrResults, setArrResults] = useState([]);

  const { maxWidth = Infinity } = props;

  const { locationCity = {} } = useSelector(
    (state) => ({
      locationCity: state.getIn(["global", "locationCity"]),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const onCellItemClick = (e) => {
    switch (e.key) {
      case "1":
        dispatch(getChangeCityAction(locationCity));

        break;
      case "2":
        dispatch(getChangeCityAction({}));

        break;
      default:
        const element = arrResults[e.key - 3];
        dispatch(getChangeCityAction(element));

        break;
    }
  };

  var items = [
    {
      label: <KTDropdownCurrentLocationCell />,
      key: 1,
    },
    {
      type: "divider",
    },
  ];

  if (searchKeywords.length === 0) {
    items.push({
      label: <KTDropdownAnyLocationCell />,
      key: 2,
    });

    items.push({
      type: "divider",
    });
  } else {
    arrResults.forEach((e, index) => {
      const title = `${e.postalCode}, ${e.name}, ${e.state}, ${e.country}`;
      items.push({
        label: <KTDropdownMatchingCell title={title} />,
        key: index + 3,
      });

      items.push({
        type: "divider",
      });
    });
  }

  const onSearchKeyChange = (value) => {
    setSearchKeywords(value);

    getLocation(value, (res) => {
      const r_list = res.list;

      setArrResults(r_list);
    });
  };

  function dropdownRender(menu) {
    return (
      <SwitchCityDropdownWrapper maxWidth={maxWidth}>
        <KTSearchInputCell onValueChange={onSearchKeyChange} />
        {React.cloneElement(menu, {
          style: { boxShadow: "none" },
        })}
        {searchKeywords.length > 0 && arrResults.length === 0
          ? [<KTDropdownNoMatchingCell />, <Divider style={{ margin: 0 }} />]
          : null}
      </SwitchCityDropdownWrapper>
    );
  }

  return (
    <Dropdown
      autoAdjustOverflow={false}
      destroyPopupOnHide={false}
      placement={"bottom"}
      trigger={["click"]}
      menu={{ items, onClick: onCellItemClick, selectable: true }}
      dropdownRender={dropdownRender}
    >
      {props.children}
    </Dropdown>
  );
});

export default KTSwitchCityDropdown;
