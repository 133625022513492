import React, { memo } from "react";
import {
  AppFooterCopyrightBarCopyright,
  AppFooterCopyrightBarLeftWrapper,
  AppFooterCopyrightBarLogo,
  AppFooterCopyrightBarRightWrapper,
  AppFooterCopyrightBarWrapper,
  AppFooterCopyrightSocialContact,
} from "./style";
import { KT_THEME, kt_copyrightText } from "../../../../common/constants";
import { appSocials } from "../../../../common/local-data";

const KTAppFooterCopyrightBar = memo(({ theme = KT_THEME.DEFAULT }) => {
  function onSocialItemClick(item) {
    window.open(item.link, "_blank");
  }

  return (
    <AppFooterCopyrightBarWrapper>
      <AppFooterCopyrightBarLeftWrapper>
        <AppFooterCopyrightBarLogo
          src={require(`@/assets/img/nav/${
            theme === KT_THEME.DEFAULT ? "logo-black" : "logo-white"
          }.png`)}
        />
        <AppFooterCopyrightBarCopyright>
          {kt_copyrightText()}
        </AppFooterCopyrightBarCopyright>
      </AppFooterCopyrightBarLeftWrapper>
      <AppFooterCopyrightBarRightWrapper>
        {appSocials.map((item) => {
          return (
            <AppFooterCopyrightSocialContact
              src={item.image}
              onClick={() => {
                onSocialItemClick(item);
              }}
            />
          );
        })}
      </AppFooterCopyrightBarRightWrapper>
    </AppFooterCopyrightBarWrapper>
  );
});

export default KTAppFooterCopyrightBar;
