import React, { memo } from "react";
import { KTEventLoadMoreTitle, KTEventLoadMoreWrapper } from "./style";

const KTEventLoadMore = memo(({ onLoadMoreClick }) => {
  return (
    <KTEventLoadMoreWrapper>
      <KTEventLoadMoreTitle onClick={onLoadMoreClick}>Load More</KTEventLoadMoreTitle>
    </KTEventLoadMoreWrapper>
  );
});

export default KTEventLoadMore;
