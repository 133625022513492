import React, { memo, useEffect, useState } from "react";
import { MusicsWrapper } from "./style";
import KTMusicsCategory from "./c-components/musics-category";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTEventsListView from "@/components/event/events-list-view";
import KTMobileEventList from "@/components/mobile-components/mobile-event-list";
import KTSpace from "@/components/space";
import KTMainCategoryHeader from "../../components/main-category-header";
import KTCategoriesSilderBar from "../../components/categories-silder-bar";
import { musicsGenres } from "./local-data";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getChangeMusicCategoriesAction,
  getChangeMusicLayoutModeAction,
  getMusicEventsAction,
} from "./store/actionCreators";
import KTMusicsMobFilterBar from "./c-components/musics-mob-filter-bar";
import KTMLyoutModeBar from "../../components/layout-mode/m-layout-mode-bar";
import axios from "axios";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import KTMusicsDtpFiterBar from "./c-components/musics-dtp-filter-bar";
import { Affix } from "antd";
import KTEventLoadMore from "../../components/event/event-load-more";

const KTMusics = memo(({ windowSize }) => {
  const dispatch = useDispatch();
  const [lastCancneSource, setLastCancneSource] = useState();

  const {
    sortMode,
    layoutMode,
    showLoadMore = false,
    events = [],
    pagenum = 1,
    city = {},
    categories = [],
    intervalType = "0",
    startdt = undefined,
    enddt = undefined,
  } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
      sortMode: state.getIn(["musics", "sortMode"]),
      layoutMode: state.getIn(["musics", "layoutMode"]),
      showLoadMore: state.getIn(["musics", "showLoadMore"]),
      events: state.getIn(["musics", "events"]),
      pagenum: state.getIn(["musics", "pagenum"]),
      categories: state.getIn(["musics", "categories"]),
      intervalType: state.getIn(["musics", "intervalType"]),
      startdt: state.getIn(["musics", "startdt"]),
      enddt: state.getIn(["musics", "enddt"]),
    }),
    shallowEqual
  );

  useEffect(() => {
    console.log("Musics useEffect");
    getMusicEvents(1);
  }, [dispatch, city, sortMode, categories, intervalType, startdt, enddt]);

  const onclickLoadMoreButton = () => {
    getMusicEvents(pagenum + 1);
  };

  function getMusicEvents(pageNumber) {
    if (!!lastCancneSource) {
      lastCancneSource.cancel();
    }

    const cancelSource = axios.CancelToken.source();
    setLastCancneSource(cancelSource);

    dispatch(
      getMusicEventsAction(
        pageNumber,
        city,
        sortMode,
        cancelSource,
        categories,
        intervalType,
        startdt,
        enddt
      )
    );
  }

  function onChangeSelectdCategories(categories) {
    dispatch(getChangeMusicCategoriesAction(categories));
  }

  const onclickChangeLayoutMode = (mode) => {
    dispatch(getChangeMusicLayoutModeAction(mode));
  };

  const windowWidth = windowSize.width;

  const headerSrc =
    windowWidth > 480
      ? require("@/assets/img/music/music-header-bg.jpg")
      : require("@/assets/img/music/mob-header-music.jpg");

  const headerTitle = windowWidth > 1024 ? "Music Tickets" : null;

  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <MusicsWrapper>
        <KTMainCategoryHeader backgroundSrc={headerSrc} title={headerTitle} />

        {windowWidth > 480 ? (
          <KTMusicsCategory
            categories={musicsGenres.filter((ele) => ele.keyWord.length > 0)}
            selectedCategories={categories}
            onChangeSelectdCategories={onChangeSelectdCategories}
          />
        ) : (
          <KTCategoriesSilderBar
            title="Genres"
            categories={musicsGenres}
            selectedCategories={categories}
            onChangeSelectdCategories={onChangeSelectdCategories}
          />
        )}

        {windowWidth > 1024
          ? [
              <KTSpace height="40px" />,
              <Affix offsetTop={0}>
                <KTMusicsDtpFiterBar />
              </Affix>,
            ]
          : [
              <KTSpace height="14rem" />,
              <Affix offsetTop={0}>
                <KTMusicsMobFilterBar />
              </Affix>,
              <KTMLyoutModeBar
                layoutMode={layoutMode}
                didChangeLayoutMode={(newLayoutMode) => {
                  onclickChangeLayoutMode(newLayoutMode);
                }}
              />,
            ]}

        {windowWidth > 480 ? (
          <KTEventsListView
            key={layoutMode}
            events={events}
            layoutMode={layoutMode}
          />
        ) : (
          <KTMobileEventList events={events} layoutMode={layoutMode} />
        )}
        {showLoadMore ? (
          <KTEventLoadMore onLoadMoreClick={onclickLoadMoreButton} />
        ) : null}
      </MusicsWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTMusics);
