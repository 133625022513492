import React, { memo } from "react";
import {
  AppFooterContentLeftWrapper,
  AppFooterContentRightWrapper,
  AppFooterContentWrapper,
} from "./style";
import KTAppFooterSubscribeBar from "../app-footer-subscribe-bar";
import KTAppFooterListView from "../app-footer-list-view";
import {
  HELPFUL_LINKS_ITEMS,
  SITE_MAP_ITEMS,
} from "../../../../common/local-data";

const KTAppFooterContent = memo(() => {
  return (
    <AppFooterContentWrapper>
      <AppFooterContentLeftWrapper>
        <KTAppFooterSubscribeBar />
      </AppFooterContentLeftWrapper>
      <AppFooterContentRightWrapper>
        <KTAppFooterListView title="SITE MAP" items={SITE_MAP_ITEMS} />
        <KTAppFooterListView
          title="HELPFUL LINKS"
          items={HELPFUL_LINKS_ITEMS}
        />
      </AppFooterContentRightWrapper>
    </AppFooterContentWrapper>
  );
});

export default KTAppFooterContent;
