import React, { memo } from "react";
import { withRouter } from "react-router-dom";
import { ResultPerformersWrapper } from "./style";
import KTSearchPerformer from "../../../../components/dropdown/search-keywords-dropdown/c-components/performer-cell";
import KTSearchDivider from "../../../../components/dropdown/search-keywords-dropdown/c-components/search-divider";
import { shallowEqual, useSelector } from "react-redux";

const KTResultPerformers = memo((props) => {
  const { performers = [] } = useSelector(
    (state) => ({
      performers: state.getIn(["search", "performers"]),
    }),
    shallowEqual
  );

  const cells = performers.map((ele) => {
    return [
      <KTSearchPerformer
        key={`KTSearchPerformer-${ele.id}`}
        performer={ele}
        onClick={(performer) => {
          props.history.push(`/performer/${performer.id}`);
        }}
      />,
      <KTSearchDivider key={`KTSearchDivider-${ele.id}`} />,
    ];
  });

  return <ResultPerformersWrapper>{cells}</ResultPerformersWrapper>;
});

export default withRouter(KTResultPerformers);
