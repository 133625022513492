import React, { memo } from "react";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { ResultSilderBarItemBottomLine, ResultSilderBarWrapper } from "./style";

const KTResultSilderBar = memo((props) => {
  const { suggestions = {} } = props;
  const location = useLocation();
  const events = suggestions?.events ?? {};
  const performers = suggestions?.performers ?? {};
  const venues = suggestions?.venues ?? {};

  const navItems = [
    {
      name: `${performers?.totalResultCount ?? ""} \n PERFORMERS`,
      to: "/result/performers",
      key: "RESULTPERFORMERS",
    },
    {
      name: `${events?.totalResultCount ?? ""} \n EVENTS`,
      to: "/result/events",
      key: "RESULTPEREVENTS",
    },
    {
      name: `${venues?.totalResultCount ?? ""} \n VENUES`,
      to: "/result/venues",
      key: "RESULTPERVENUES",
    },
  ];

  const showSelectItems = navItems.map((ele) => {
    return (
      <NavLink
        exact
        className="navlink"
        activeClassName="navlinkActive"
        to={ele.to}
        key={ele.key}
      >
        {ele.name}
        {location.pathname.includes(ele.to) ? (
          <ResultSilderBarItemBottomLine />
        ) : null}
      </NavLink>
    );
  });

  return <ResultSilderBarWrapper>{showSelectItems}</ResultSilderBarWrapper>;
});

export default withRouter(KTResultSilderBar);
