import React, { memo } from "react";
import { DropdownNoMatchingCellWrapper } from "./style";

const KTDropdownNoMatchingCell = memo(() => {
  return (
    <DropdownNoMatchingCellWrapper>
      {"Sorry, no matches found :("}
    </DropdownNoMatchingCellWrapper>
  );
});

export default KTDropdownNoMatchingCell;
