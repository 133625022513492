import React, { memo, useState } from "react";
import { AppHeaderCenterDivider, AppHeaderCenterWrapper } from "./style";
import { KT_THEME } from "../../../../common/constants";
import KTAppHeaderButton from "../app-header-button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getGlobalDateString } from "../../../../utils/tool-time-intervcal";
import { globalFilterDateIntervals } from "../../../../global/local-data";
import KTSearchKeywordsDtpDropdown from "../../../dropdown/search-keywords-dtp-dropdown";
import KTSwitchCustomTimeIntervalDropdown from "../../../dropdown/switch-custom-time-interval-dropdown";
import { getChangeGlobalTemporalIntervalAction } from "../../../../global/store/actionCreators";
import { getChangeHomeTemporalIntervalAction } from "../../../../pages/home/store/actionCreators";
import { getChangeMusicTemporalIntervalAction } from "../../../../pages/musics/store/actionCreators";
import { getChangeSportsTemporalIntervalAction } from "../../../../pages/sports/store/actionCreators";
import { getChangeShowsTemporalIntervalAction } from "../../../../pages/shows/store/actionCreators";
import KTSwitchTimeIntervalDropdown from "../../../dropdown/switch-time-interval-dropdown";
import KTSwitchCityDtpDropdown from "../../../dropdown/switch-city-dtp-dropdown";
import withWindowSize from "@/components/higher-order/withWindowSize";

const KTAppHeaderCenter = memo(({ theme = KT_THEME.DEFAULT, windowSize }) => {
  const dispatch = useDispatch();
  const [customTimeIntervalOpen, setCustomTimeIntervalOpen] = useState(false);

  const { city, intervalType, startdt, enddt } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
      intervalType: state.getIn(["global", "intervalType"]),
      startdt: state.getIn(["global", "startdt"]),
      enddt: state.getIn(["global", "enddt"]),
    }),
    shallowEqual
  );

  let cityMessage = "Any Location";

  if (typeof city === "object" && Object.keys(city).length > 0) {
    const name = city.name ?? city.city ?? "";
    const state = city.state ?? "";
    if (name.length === 0 || state.length === 0) {
      cityMessage = `${name}${city.state}`;
    } else {
      cityMessage = `${name}, ${city.state}`;
    }
  }

  const dateMessage = getGlobalDateString(
    globalFilterDateIntervals,
    intervalType,
    startdt,
    enddt
  );

  function getCustomTimeIntervalItems(windowWidth) {
    return windowWidth > 1300
      ? [
          <KTSwitchCustomTimeIntervalDropdown
            open={customTimeIntervalOpen}
            onCancelClick={() => {
              setCustomTimeIntervalOpen(false);
            }}
            startdt={startdt}
            enddt={enddt}
            didIntervalValueChange={(ele) => {
              dispatch(
                getChangeGlobalTemporalIntervalAction(
                  "6",
                  ele.startdt,
                  ele.enddt
                )
              );

              dispatch(
                getChangeHomeTemporalIntervalAction("6", ele.startdt, ele.enddt)
              );

              dispatch(
                getChangeMusicTemporalIntervalAction(
                  "6",
                  ele.startdt,
                  ele.enddt
                )
              );

              dispatch(
                getChangeSportsTemporalIntervalAction(
                  "6",
                  ele.startdt,
                  ele.enddt
                )
              );

              dispatch(
                getChangeShowsTemporalIntervalAction(
                  "6",
                  ele.startdt,
                  ele.enddt
                )
              );
            }}
          >
            <KTSwitchTimeIntervalDropdown
              onCustomIntervalClick={() => {
                setCustomTimeIntervalOpen(true);
              }}
            >
              <KTAppHeaderButton
                iconSrc={require(`@/assets/img/icons/icon-calendar.png`)}
                title={dateMessage}
              />
            </KTSwitchTimeIntervalDropdown>
          </KTSwitchCustomTimeIntervalDropdown>,
          <AppHeaderCenterDivider />,
        ]
      : [];
  }

  function getSwitchCityItems(windowWidth) {
    return windowWidth > 1140
      ? [
          <KTSwitchCityDtpDropdown>
            <KTAppHeaderButton
              iconSrc={require(`@/assets/img/icons/icon-location.png`)}
              title={cityMessage}
            />
          </KTSwitchCityDtpDropdown>,

          <AppHeaderCenterDivider />,
        ]
      : [];
  }

  return (
    <AppHeaderCenterWrapper theme={theme}>
      {getSwitchCityItems(windowSize.width)}
      {getCustomTimeIntervalItems(windowSize.width)}
      <KTSearchKeywordsDtpDropdown />

    </AppHeaderCenterWrapper>
  );
});

export default withWindowSize(KTAppHeaderCenter);
