import React, { memo } from "react";
import { ClassifyHeaderWrapper } from "./style";
import { ReactComponent as ArrowRight } from "@/assets/img/icons/icon-arrow-right.svg";
import { currValue } from "../../../utils";

const KTClassifyHeader = memo(
  ({
    title,
    onClickViewAll,
    hiddenViewAll,
    windowWidth = window.screen.width,
  }) => {
    return (
      <ClassifyHeaderWrapper
        style={{
          padding:
            windowWidth > 480 ? '0 ' + currValue(50, 100, windowWidth) : "0 20rem",
        }}
      >
        <div className="content">
          <div className="title">{title}</div>
          {hiddenViewAll ? null : windowWidth > 480 ? (
            <div className="exploreMoreWrapper" onClick={onClickViewAll}>
              <div className="exploreMore">Explore More</div>
              <ArrowRight className="arrowRight" />
            </div>
          ) : (
            <div className="viewAll" onClick={onClickViewAll}>
              View All
            </div>
          )}
        </div>
        <div className="line" />
      </ClassifyHeaderWrapper>
    );
  }
);

export default KTClassifyHeader;
