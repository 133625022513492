import { getEventsOfKeywords } from "../../../services/events";
import { getPerformersOfKeywords } from "../../../services/performers";
import { getSuggest } from "../../../services/search";
import * as actionTypes from "./constants";

// CHANGE_SEARCH_KEYWORDS
const changeSearchKeywords = (keywords) => ({
  type: actionTypes.CHANGE_SEARCH_KEYWORDS,
  keywords: keywords,
});
export const getChangeSearchKeywordsAction = (keywords) => {
  return (dispatch) => {
    dispatch(changeSearchKeywords(keywords));
    dispatch(emptySearchResults());
    if (keywords.length > 0) {
      // 获取对应歌手列表
      getPerformersOfKeywords(1, keywords, (performers) => {
        dispatch(changePerformers(performers));
      });

      // 获取对应events列表
      getEventsOfKeywords(1, keywords, (events) => {
        dispatch(changeEvents(events));
      });

      // 获取对应建议信息
      getSuggest(keywords, (suggestions) => {
        dispatch(changeSuggestions(suggestions));
        const venues = suggestions.venues.results ?? [];
        dispatch(changeVenues(venues));
      });
    }
  };
};

// CHANGE_PERFORMERS
const changePerformers = (performers) => ({
  type: actionTypes.CHANGE_PERFORMERS,
  performers: performers,
});
export const getChangePerformersAction = (performers) => {
  return (dispatch) => {
    dispatch(changePerformers(performers));
  };
};

// CHANGE_EVENTS
const changeEvents = (events) => ({
  type: actionTypes.CHANGE_EVENTS,
  events: events,
});
export const getChangeEventsAction = (events) => {
  return (dispatch) => {
    dispatch(changeEvents(events));
  };
};

// CHANGE_VENUES
const changeVenues = (venues) => ({
  type: actionTypes.CHANGE_VENUES,
  venues: venues,
});
export const getChangeeVenuesAction = (venues) => {
  return (dispatch) => {
    dispatch(changeVenues(venues));
  };
};

// CHANGE_SUGGESTIONS
const changeSuggestions = (suggestions) => ({
  type: actionTypes.CHANGE_SUGGESTIONS,
  suggestions: suggestions,
});
export const getChangeeSuggestionsAction = (suggestions) => {
  return (dispatch) => {
    dispatch(changeSuggestions(suggestions));
  };
};

// EMPTY_SEARCH_RESULTS
const emptySearchResults = () => ({
  type: actionTypes.EMPTY_SEARCH_RESULTS,
});
export const getEmptySearchResultsAction = () => {
  return (dispatch) => {
    dispatch(emptySearchResults());
  };
};
