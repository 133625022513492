import React, { memo } from "react";
import {
  FilterCategorieIcon,
  FilterCategorieItem,
  FilterCategorieTitle,
  FilterCategoriesHeaderWrapper,
  FilterCategoriesListWrapper,
  FilterCategoriesWrapper,
} from "./style";

const KTFilterCategories = memo(
  ({ categories = [], selectedCategories = [], onChangeSelectdCategories }) => {
    function onCategoryClick(category) {
      const selectedTitles = selectedCategories.map((ele) => ele.title);
      if (category.keyWord.length === 0) {
        // All
        if (selectedCategories.length === categories.length - 1) {
          onChangeSelectdCategories([]);
        } else {
          onChangeSelectdCategories([...categories.slice(1)]);
        }
      } else {
        if (selectedTitles.includes(category.title)) {
          onChangeSelectdCategories(
            selectedCategories.filter((ele) => ele.title !== category.title)
          );
        } else {
          onChangeSelectdCategories([category, ...selectedCategories]);
        }
      }
    }

    const cells = categories.map((ele) => {
      const selectedTitles = selectedCategories.map((ele) => ele.title);
      let selected = selectedTitles.includes(ele.title);
      if (ele.keyWord.length === 0) {
        // All
        selected = selectedCategories.length === categories.length - 1;
      }

      const imgSrc = selected
        ? require(`@/assets/img/${ele.selectedImageName}`)
        : require(`@/assets/img/${ele.normalImageName}`);
      return (
        <FilterCategorieItem
          key={ele.title}
          onClick={() => onCategoryClick(ele)}
        >
          <FilterCategorieIcon src={imgSrc} />
          <FilterCategorieTitle selected={selected}>
            {ele.title}
          </FilterCategorieTitle>
        </FilterCategorieItem>
      );
    });

    return (
      <FilterCategoriesWrapper>
        <FilterCategoriesHeaderWrapper>
          <div className="title">Categories</div>
          <div className="divider" />
        </FilterCategoriesHeaderWrapper>
        <FilterCategoriesListWrapper>{cells}</FilterCategoriesListWrapper>
      </FilterCategoriesWrapper>
    );
  }
);

export default KTFilterCategories;
