import styled from "styled-components";

export const PerformerEventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 30px 0;
`;

export const PerformerEventsHudMessage = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PerformerEventsAddressBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  cursor: pointer;

  .icon {
    width: 18px;
    height: 18px;
    stroke: #284e9f;
  }

  .addressTitle {
    color: #284e9f;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }
`;

export const PerformerEventsAllConcert = styled.div`
  align-self: flex-start;
  margin-top: 40px;
  margin-left: 200px;
  color: var(--icon-line-black, #19192a);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
