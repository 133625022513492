import styled from "styled-components";

export const FilterCategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterCategoriesHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20rem;
  gap: 10rem;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10rem 0;
    flex-shrink: 0;

    color: #000;
    font-family: Inter;
    font-size: 20rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .divider {
    height: 1rem;
    background: #f2f2f2;
  }

  @media screen and (min-width: 481px) {
    padding: 20px 50px;
    gap: 12px;

    .title {
      width: 200px;
      height: 22px;

      font-size: 26px;
    }
    .divider {
      height: 1px;
    }
  }
`;

export const FilterCategoriesListWrapper = styled.div`
  display: grid;
  padding: 0 10rem;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 78rem);
  row-gap: 18rem;
  column-gap: auto;

  padding: 20rem 30rem;

  @media screen and (min-width: 481px) {
    grid-template-columns: repeat(auto-fill, 78px);

    padding: 0 10px;
    row-gap: 18px;
    padding: 20px 30px;
  }
`;

export const FilterCategorieItem = styled.div`
  width: 78rem;
  min-height: 78rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  @media screen and (min-width: 481px) {
    width: 78px;
    min-height: 78px;
    gap: 4px;
  }
`;

export const FilterCategorieIcon = styled.img`
  width: 40rem;
  height: 40rem;

  @media screen and (min-width: 481px) {
    width: 40px;
    height: 40px;
  }
`;

export const FilterCategorieTitle = styled.div`
  width: 78rem;
  height: 34rem;
  flex-shrink: 0;

  color: ${(props) =>
    props.selected
      ? "var(--icon-4d-black, #4D4D4D)"
      : "var(--iconb3, #b3b3b3)"};
  text-align: center;
  font-family: Inter;
  font-size: 12rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  white-space: pre-line;

  @media screen and (min-width: 481px) {
    width: 78px;
    height: 34px;

    font-size: 12px;
  }
`;
