import React, { memo } from "react";
import {
  MusicsCategoryItemIcon,
  MusicsCategoryItemMark,
  MusicsCategoryItemTitle,
  MusicsCategoryItemWrapper,
  MusicsCategoryListWrapper,
  MusicsCategorySelectAll,
  MusicsCategorySelectAllWrapper,
  MusicsCategoryWrapper,
} from "./style";

const KTMusicsCategory = memo(
  ({ categories, selectedCategories, onChangeSelectdCategories }) => {
    console.log(categories);
    console.log(selectedCategories);

    function onCategoryClick(category) {
      const selectedTitles = selectedCategories.map((ele) => ele.title);
      if (category.keyWord.length === 0) {
        // All
        if (selectedCategories.length === categories.length - 1) {
          onChangeSelectdCategories([]);
        } else {
          onChangeSelectdCategories([...categories.slice(1)]);
        }
      } else {
        if (selectedTitles.includes(category.title)) {
          onChangeSelectdCategories(
            selectedCategories.filter((ele) => ele.title !== category.title)
          );
        } else {
          onChangeSelectdCategories([category,...selectedCategories]);
        }
      }
    }

    function onclickSelectAllItem() {
      if (selectedCategories.length === categories.length) {
        onChangeSelectdCategories([]);
      } else {
        onChangeSelectdCategories([...categories]);
      }
    }

    const items = categories.map((ele) => {
      const selectedTitles = selectedCategories.map((ele) => ele.title);
      let selected = selectedTitles.includes(ele.title);
      const imgSrc = selected ? ele.d_selectedImageName : ele.d_normalImageName;
      return (
        <MusicsCategoryItemWrapper
          onClick={() => onCategoryClick(ele)}
          key={`MusicsCategoryItemWrapper${ele.title}`}
        >
          <MusicsCategoryItemMark selected={selected} />
          <MusicsCategoryItemIcon src={imgSrc} />
          <MusicsCategoryItemTitle selected={selected}>
            {ele.title}
          </MusicsCategoryItemTitle>
        </MusicsCategoryItemWrapper>
      );
    });

    return (
      <MusicsCategoryWrapper>
        <MusicsCategorySelectAllWrapper>
          <MusicsCategorySelectAll onClick={() => onclickSelectAllItem()}>
            {console.log(categories)}
            {console.log(selectedCategories)}
            {categories.length === selectedCategories.length
              ? "Unselect All"
              : "Select All"}

          </MusicsCategorySelectAll>
        </MusicsCategorySelectAllWrapper>
        <MusicsCategoryListWrapper>{items}</MusicsCategoryListWrapper>
      </MusicsCategoryWrapper>
    );
  }
);

export default KTMusicsCategory;
