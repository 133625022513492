import React, { memo } from "react";
import { HomeHeaderFilterWrapper } from "./style";
import { headerFilterItems } from "../../../local-data";
import withWindowSize from "@/components/higher-order/withWindowSize";
import { NavLink } from "react-router-dom";

const KTHomeHeaderFilter = memo(({ windowSize }) => {
  function calculateSize(windowSize) {
    const windowWidth = windowSize.width;
    if (windowWidth >= 1920) {
      return {
        wrapperMarginTop: "60px",
        wrapperGap: "120px",
      };
    } else if (windowWidth <= 480) {
      return {
        wrapperMarginTop: "20rem",
        wrapperGap: "20rem",
      };
    } else if (windowWidth <= 1024) {
      return {
        wrapperMarginTop: "30px",
        wrapperGap: "40px",
      };
    } else {
      const ratio = (windowWidth - 1024) / (1920 - 1024);

      const wrapperMarginTop = ratio * 30 + 30;
      const wrapperGap = ratio * 80 + 40;

      return {
        wrapperMarginTop: `${wrapperMarginTop}px`,
        wrapperGap: `${wrapperGap}px`,
      };
    }
  }

  const { wrapperMarginTop, wrapperGap } = calculateSize(windowSize);

  const items = headerFilterItems.map((item) => {
    return (
      <NavLink
        to={item.to}
        className="item"
        activeClassName="active"
        style={{ maxWidth: item.maxWidth }}
        key={item.title}
      >
        {item.title}
      </NavLink>
    );
  });

  return (
    <HomeHeaderFilterWrapper
      style={{
        marginTop: wrapperMarginTop,
        gap: wrapperGap,
      }}
    >
      {items}
    </HomeHeaderFilterWrapper>
  );
});

export default withWindowSize(KTHomeHeaderFilter);
