import React, { memo } from "react";
import {
  SCTICancelButtonIcon,
  SCTICancelButtonTitle,
  SCTICancelButtonWrapper,
} from "./style";

const KTSCTICancelButton = memo(({ onCancelClick }) => {
  return (
    <SCTICancelButtonWrapper onClick={onCancelClick}>
      <SCTICancelButtonIcon
        src={require("@/assets/img/icons/icon-close-suctom.png")}
      />
      <SCTICancelButtonTitle>Cancel</SCTICancelButtonTitle>
    </SCTICancelButtonWrapper>
  );
});

export default KTSCTICancelButton;
