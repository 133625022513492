import React, { memo } from "react";
import { MapWrapper } from "./style";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const KTMap = memo(() => {
  const params = useParams();

  return (
    <MapWrapper>
      <div id="tn-maps" />
      <Helmet>
        <script>{"window.Seatics={}"}</script>
        {/* {params.id === "203518" ? (  // this is test code, comment out in production
          <script src="https://mapwidget3.seatics.com/js?eventId=203518&websiteConfigId=27263"></script>
        ) : ( */}
          <script 
            // src={`https://mapwidget3-sandbox.seatics.com/js?eventId=${params.id}&websiteConfigId=27263`}
             src={`https://mapwidget3.seatics.com/js?eventId=${params.id}&websiteConfigId=27263`}
            ></script>
        {/* )} */}
      </Helmet>
    </MapWrapper>
  );
});

export default KTMap;
