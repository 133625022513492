import React, { memo } from "react";
import { EventCellDateWrapper } from "./style";
import { eventDay, eventMonth, eventYear } from "../../../../utils/event";

const KTEventCellDate = memo(({ event }) => {
  let items = [];
  if (event.date?.datetime === undefined) {
    items = [<div className="month">{event.date.text.time}</div>];
  } else {
    const now = new Date();
    const currentYear = now.getFullYear();
    const year = parseInt(eventYear(event));

    if (currentYear !== year) {
      items = [
        <div className="day">{eventDay(event)}</div>,
        <div className="month">{eventMonth(event)}</div>,
        <div className="year">{eventYear(event)}</div>,
      ];
    } else {
      items = [
        <div className="day">{eventDay(event)}</div>,
        <div className="month">{eventMonth(event)}</div>,
      ];
    }
  }

  return <EventCellDateWrapper>{items}</EventCellDateWrapper>;
});

export default KTEventCellDate;
