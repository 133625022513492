import React, { memo, useContext, useEffect, useState } from "react";
import { HomeTrendingMobileWrapper } from "./style";
import KTTrendingAddressBar from "./c-components/trending-address-bar";
import KTSpace from "@/components/space";
import KTTrendingCategorySilderBar from "./c-components/trending-category-silder-bar";
import KTClassifySubHeader from "@/components/classify/classify-sub-header";
import { getEventsOfTrending } from "../../../../services/events";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import { subcategoryMap } from "../../../../common/category/category-data";
import KTClassifyTitleSilderBar from "../../../../components/classify/classify-title-silder-bar";
import KTMobileEventList from "../../../../components/mobile-components/mobile-event-list";
import KTEventsListView from "@/components/event/events-list-view";
import { withRouter } from "react-router-dom";
import KTWindowSizeContext from "../../../../common/context/windowSizeContext";
import { Affix } from "antd";

const KTHomeTrending = memo((props) => {
  const context = useContext(KTWindowSizeContext);
  const windowWidth = context.windowSize.width;

  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );
  const [category, setCategory] = useState({ title: "Music", key: "MUSICS" });
  const [subcategory, setSubcategory] = useState({
    title: "All",
    emoji: "🤩",
    key: "",
  });

  const [lastCancneSource, setLastCancneSource] = useState();
  const [evnets, setEvents] = useState();

  useEffect(() => {
    if (!!lastCancneSource) {
      lastCancneSource.cancel();
    }

    const cancelSource = axios.CancelToken.source();
    setLastCancneSource(cancelSource);

    getEventsOfTrending(
      1,
      category.key,
      subcategory.key,
      city,
      (evnets) => {
        setEvents(evnets);
      },
      cancelSource
    );
  }, [category, subcategory, city]);

  function onSubcategoryClick(item) {
    setSubcategory(item);
  }

  function onExploreMoreClick() {
    switch (category.key) {
      case "MUSIC":
        props.history.push("/music");
        break;
      case "SPORTS":
        props.history.push("/sports");
        break;
      case "SHOWS":
        props.history.push("/shows");
        break;

      default:
        break;
    }
  }

  return (
    <HomeTrendingMobileWrapper>
      <KTSpace height={windowWidth > 480 ? "40px" : "32rem"} />
      <KTTrendingAddressBar onExploreMoreClick={onExploreMoreClick} />
      <Affix offsetTop={0}>
        <KTTrendingCategorySilderBar
          title={category.title}
          onCategoryClick={(item) => {
            setSubcategory(subcategoryMap[category.title][0]);
            setCategory(item);
          }}
        />
        {category.title === "Shows"
          ? [
              <KTClassifyTitleSilderBar
                items={subcategoryMap[category.title]}
                selectedItem={subcategory}
                onSelectedItemClick={(item) => onSubcategoryClick(item)}
              />,
            ]
          : [
              <KTSpace
                height={windowWidth > 480 ? "0px" : "15rem"}
                backgroundColor="#fff"
              />,
              <KTClassifySubHeader
                windowWidth={windowWidth}
                items={subcategoryMap[category.title]}
                selectedItem={subcategory}
                onSelectedItemClick={(item) => onSubcategoryClick(item)}
              />,
            ]}
      </Affix>

      {windowWidth > 480 ? (
        <KTEventsListView events={evnets} />
      ) : (
        <KTMobileEventList events={evnets} />
      )}
    </HomeTrendingMobileWrapper>
  );
});

export default withRouter(KTHomeTrending);
