import { styled } from "styled-components";

export const EventCellDateWrapper = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .day {
    color: #000;
    text-align: center;
    font-family: Merriweather;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 35px;
  }

  .month {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  .year {
    color: #c8c8c8;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`;
