import styled from "styled-components";

export const SportsCategoryCellWrapper = styled.div`
  padding-top: 20px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  .name {
    width: 100%;
    height: 34px;
    flex-shrink: 0;

    color: var(--iconb3, #b3b3b3);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    user-select: none;
  }

  .nameActive {
    color: var(--icon-4d-black, #4d4d4d);
  }

  .bottomLine {
    margin-top: 6px;
    background: white;
    width: 80px;
    height: 10px;
  }

  .bottomLineActive {
    background: var(--icon-line-black, #19192a);
  }

  @media screen and (max-width: 480px) {
    width: 56rem;
    height: 98rem;
    gap: 10rem;

    .name {
      font-size: 13rem;
    }
  }
`;
