import { message } from "antd";
import React, { memo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCityOfLocation } from "../../services/location";
import {
  getChangeCityAction,
  getChangeLocationCityAction,
} from "../../global/store/actionCreators";

const KTGlobal = memo(() => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getCityOfLocation(
            position.coords.latitude,
            position.coords.longitude,
            (item) => {
              dispatch(getChangeLocationCityAction(item));
              dispatch(getChangeCityAction(item));
            }
          );
        },
        (error) => {
          messageApi.open({ type: "warning", content: error.message });
        }
      );
    } else {
      messageApi.open({
        type: "warning",
        content: "Geolocation is not supported by this browser.",
      });
    }
  }, []);
  return <>{contextHolder}</>;
});

export default KTGlobal;
