import React, { memo, useState } from "react";
import {
  MusicsMobFilterBarIconMark,
  MusicsMobFilterBarIconWrapper,
  MusicsMobFilterBarWrapper,
} from "./style";
import { ReactComponent as FilterIcon } from "@/assets/img/icons/icon-filter-option.svg";
import { Drawer } from "antd";
import KTMusicsFilterContent from "../musics-mob-filter-content";
import KTFilterMobBarButton from "../../../../components/filter/filter-mob-bar-button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { musicsAllEventsSortModes } from "../../local-data";
import { getChangeMusicSortModeAction } from "../../store/actionCreators";

const KTMusicsMobFilterBar = memo(() => {
  const [filterViewOpen, setFilterViewOpen] = useState(false);

  const dispatch = useDispatch();

  const { sortMode, intervalType = "0" } = useSelector(
    (state) => ({
      sortMode: state.getIn(["musics", "sortMode"]),
      intervalType: state.getIn(["musics", "intervalType"]),
    }),
    shallowEqual
  );

  function onChangeSortModeItem(mode) {
    dispatch(getChangeMusicSortModeAction(mode));
  }

  const isDefaultFilter = intervalType === "0";

  return (
    <MusicsMobFilterBarWrapper>
      <KTFilterMobBarButton
        title={sortMode.title}
        switchItems={musicsAllEventsSortModes}
        onChangeSwitchItem={onChangeSortModeItem}
      />
      <MusicsMobFilterBarIconWrapper>
        <FilterIcon
          className="optionButton"
          onClick={() => setFilterViewOpen(true)}
        />
        {isDefaultFilter ? null : <MusicsMobFilterBarIconMark />}
      </MusicsMobFilterBarIconWrapper>

      <Drawer
        closeIcon={null}
        placement="bottom"
        height={`100%`}
        open={filterViewOpen}
        onClose={() => setFilterViewOpen(false)}
        style={{
          borderRadius: "20rem 20rem 0 0",
          background: "#FFF",
        }}
        bodyStyle={{ padding: "0" }}
      >
        <KTMusicsFilterContent onClose={() => setFilterViewOpen(false)} />
      </Drawer>
    </MusicsMobFilterBarWrapper>
  );
});

export default KTMusicsMobFilterBar;
