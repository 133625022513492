import React, { memo } from "react";
import {
  EventCellExploreDesktopBottomWrapper,
  EventCellExploreDesktopTopWrapper,
  EventCellExploreDesktopWrapper,
} from "./style";

import { ReactComponent as LikeIcon } from "@/assets/img/svg/icon-like.svg";
import { ReactComponent as ShareIcon } from "@/assets/img/svg/icon-share.svg";

const KTEventCellExploreDesktop = memo(({ event }) => {

  const date =
    event.title === "Exclusive Sale: Talk Show" ? "Oct. 15,2024" : "Oct. 15";

  return (
    <EventCellExploreDesktopWrapper>
      <EventCellExploreDesktopTopWrapper
        style={{
          backgroundImage:
            "url(" +
            require(`@/assets/img/temporary//${event.imageName}`) +
            ")",
        }}
      >
        <div className="optionBar">
          {/* <LikeIcon className="icon" /> */}
          <ShareIcon className="icon" />
        </div>

        <div className="dateBar">
          <div className="date">{date}</div>
          <div className="divider"> | </div>
          <div className="time">01:10 pm</div>
          <div className="divider"> | </div>
          <div className="week">MON</div>
        </div>
      </EventCellExploreDesktopTopWrapper>
      <EventCellExploreDesktopBottomWrapper>
        <div className="title">{event.title}</div>
        <div className="address">{event.address}</div>
      </EventCellExploreDesktopBottomWrapper>
    </EventCellExploreDesktopWrapper>
  );
});

export default KTEventCellExploreDesktop;
