import React, { memo, useEffect, useState } from "react";
import { HomeHeaderBGImage, HomeHeaderWrapper, NoFees } from "./style";
import KTHomeHeaderCarousel from "./home-header-carousel";
import KTHomeHeaderCity from "./home-header-city";
import KTHomeHeaderFilter from "./home-header-filter";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTMobileHomeHeaderFilter from "./mobile-home-header-filter";
import axios from 'axios';
import { shallowEqual, useSelector,useDispatch } from "react-redux";
import { getIPAction } from "../../../../global/store/actionCreators";
import SWpopup  from "../popup";

const KTHomeHeader = memo(({ windowSize }) => {
  const bgImageSrc =
    windowSize.width > 480
      ? require("@/assets/img/home/kt-home-header-bg.jpg")
      : require("@/assets/img/home/mobild-bg-header.jpg");
  // const [currentIP, setCurrentIP] = useState("");
  // const [PopupFlag, setPopupFlag] = useState(0);
  
  // const getIP = async () => {
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   console.log("reading ip is:", res.data.ip);
  //   setCurrentIP(res.data.ip );
  //   // return(res.data.ip );
  // };

  //   useEffect(() => {
  //     getIP();
  //   },[]);
  //     const saveUser=async()=> {};

  //     const { ip } = useSelector(
  //       (state) => ({
  //         ip: state.getIn(["global", "ip"]),
  //       }),
  //       shallowEqual
  //     );
  //     console.log("current ip is:", ip);
  //     const dispatch = useDispatch();
  //     if (ip !== currentIP && currentIP !=="") {
  //       dispatch(getIPAction (currentIP));
  //       console.log("call set ip", currentIP);
  //       setPopupFlag(1);
  //     }

      return (
        <HomeHeaderWrapper>
      {/* {PopupFlag===1 ? (
            <SWpopup></SWpopup>
          ):null} */}


      <HomeHeaderBGImage src={bgImageSrc} alt="" />
      {windowSize.width > 480 ?  <NoFees>No Fees, Better Price!</NoFees> :null }
      <KTHomeHeaderCarousel />

      {windowSize.width > 480 ? <KTHomeHeaderCity /> : null}
      {/* { ip===''? console.log("no ip"):console.log("got ip")} */}
      {windowSize.width > 480 ? (
        <KTHomeHeaderFilter />
      ) : 
      (
         <KTMobileHomeHeaderFilter />
      )
      }
    </HomeHeaderWrapper>
    
  );
});

export default withWindowSize(KTHomeHeader);
