import React, { memo } from "react";
import { KTSCTICalendarWrapper } from "./style";
import { DayPicker } from "react-day-picker";

const KTSCTICalendar = memo(({ startdt, enddt, didIntervalValueChange }) => {
  const pastMonth = new Date();
  return (
    <KTSCTICalendarWrapper>
      <DayPicker
        id="home-all-filter-daterange"
        mode="range"
        defaultMonth={pastMonth}
        selected={{ from: startdt, to: enddt }}
        onSelect={(range) => {
          didIntervalValueChange({
            startdt: range?.from,
            enddt: range?.to,
          });
        }}
      />
    </KTSCTICalendarWrapper>
  );
});

export default KTSCTICalendar;
