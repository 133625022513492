import styled from "styled-components";

export const AboutGuaranteeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  align-items: center;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    gap: 20px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 20px;
  }

  @media screen and (max-width: 480px) {
    gap: 20rem;
  }
`;

export const AboutGuaranteeTitle = styled.div`
  color: var(--non-selectable, #5f7595);
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: 34px; /* 113.333% */

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 20px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20rem;
  }
`;

export const AboutGuaranteeContent = styled.div`
  color: var(--BLUE-GREY, #a3adbe);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */

  max-width: 930px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 14px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;
