import styled from "styled-components";

export const TermsWrapper = styled.div``;

export const TermsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 120px 200px;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 80px 100px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 50px;
  }

  @media screen and (max-width: 480px) {
    padding: 40rem 20rem;

    font-size: 16rem;
  }
`;
