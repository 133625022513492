import React, { memo } from "react";
import { FAQCoontentWrapper, FAQListWrapper, FAQWrapper } from "./style";
import KTFAQHeader from "./c-components/faq-header";
import KTFAQGroupView from "./c-components/faq-group-view";
import { faqGroupDatas } from "./local-data";
import withWindowSize from "../../components/higher-order/withWindowSize";
import KTMobileHeaderBar from "../../components/mobile-header-bar";
import { KT_THEME } from "../../common/constants";

const KTFAQ = memo(({ windowSize }) => {
  return (
    <FAQWrapper>
      {windowSize.width <= 480 ? (
        <KTMobileHeaderBar theme={KT_THEME.DARK} />
      ) : null}
      <FAQCoontentWrapper>
        <KTFAQHeader />
        <FAQListWrapper>
          {faqGroupDatas.map((group) => {
            return <KTFAQGroupView key={group.title} group={group} />;
          })}
        </FAQListWrapper>
      </FAQCoontentWrapper>
    </FAQWrapper>
  );
});

export default withWindowSize(KTFAQ);
