import React, { memo, useEffect, useState } from "react";
import { PerformerWrapper } from "./style";
import KTPerformerHeader from "./c-components/performer-header";
import KTMobileHeaderBar from "../../components/mobile-header-bar";
import KTPerformerTitle from "./c-components/performer-title";
import KTMLyoutModeBar from "../../components/layout-mode/m-layout-mode-bar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { LAYOUT_MODE_CARD } from "../../common/constants";
import { getEventsOfPerformer } from "../../services/events";
import { getPerformerOfId } from "../../services/performers";
import { shallowEqual, useSelector } from "react-redux";
import KTMobileEventList from "../../components/mobile-components/mobile-event-list";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import KTEventsListView from "@/components/event/events-list-view";

const KTPerformer = memo(({ windowSize }) => {
  const params = useParams();
  const performer_id = params.performer;

  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  const [allEvents, setAllEvents] = useState([]);
  const [layoutMode, setLayouMode] = useState(LAYOUT_MODE_CARD);
  const [performer, setPerformer] = useState();

  useEffect(() => {
    getEventsOfPerformer(1, city, performer_id, (events) => {
      setAllEvents(events);
    });
    console.log( allEvents);
    if (allEvents.length===0 ) {
      getEventsOfPerformer(1, {}, performer_id, (events) => {
        setAllEvents(events);
      });
      console.log( allEvents);
    }

    getPerformerOfId(performer_id, (performer_info) => {
      setPerformer(performer_info);
    });
  }, [performer_id, city]);


  console.log("performer:", performer);
  const ancestors = performer?.defaultCategory?.ancestors ?? [];
  const category = ancestors[ancestors.length - 1]?.text?.name ?? "";
  let bgimagePath;
  if (performer?.kt_info?.perfomerposter) {
    bgimagePath = 
    windowSize.width > 480
    ? performer.kt_info?.perfomerposter
    : performer.kt_info?.performerposter_mobile
  }
  else 
  if (category === "CONCERTS") {
    bgimagePath =
      windowSize.width > 480
        ? require("@/assets/img/music/music-header-bg.jpg")
        : require("@/assets/img/categorys/search-music.jpg");
  }
  if (category === "Sports") {
    bgimagePath =
      windowSize.width > 480
        ? require("@/assets/img/sports/dtp-header-sports.jpg")
        : require("@/assets/img/categorys/search-sports.jpg");
  }
  if (category === "Theatre") {
    bgimagePath =
      windowSize.width > 480
        ? require("@/assets/img/shows/dtp-header-shows.jpg")
        : require("@/assets/img/categorys/search-shows.jpg");
  }

  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <PerformerWrapper>
        {windowSize.width <= 480 ? <KTMobileHeaderBar /> : null}
        <KTPerformerHeader bgimagePath={bgimagePath} />
        <KTPerformerTitle name={performer?.text?.name ?? ""} />
        <KTMLyoutModeBar
          layoutMode={layoutMode}
          didChangeLayoutMode={(newLayoutMode) => {
            setLayouMode(newLayoutMode);
          }}
        />
        {windowSize.width > 480 ? (
          <KTEventsListView events={allEvents} layoutMode={layoutMode} />
        ) : (
          <KTMobileEventList events={allEvents} layoutMode={layoutMode} />
        )}
      </PerformerWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTPerformer);
