import React, { memo } from "react";
import { EventGridWrapper } from "./style";
import KTEventCellExplore from "../event-cell-explore";

const KTEventGrid = memo(
  ({
    events,
    rowGap = "30rem",
    columnGap = "30rem",
    gridTemplateColumns = "1fr",
    padding = undefined,
    cellOfEvent = (event) => {
      return <KTEventCellExplore event={event} />;
    },
  }) => {
    const cells = events.map((event) => {
      return cellOfEvent(event);
    });

    return (
      <EventGridWrapper
        style={{
          rowGap: rowGap,
          columnGap: columnGap,
          gridTemplateColumns: gridTemplateColumns,
          padding: padding,
        }}
      >
        {cells}
      </EventGridWrapper>
    );
  }
);

export default KTEventGrid;
