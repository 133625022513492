import React, { memo } from "react";
import { MAMenuSocialItem, MAMenuSocialWrapper } from "./style";
import { appSocials } from "../../../../../common/local-data";

const KTMAMenuSocial = memo(() => {
  function onSocialItemClick(item) {
    window.open(item.link, "_blank");
  }
  return (
    <MAMenuSocialWrapper>
      {appSocials.map((item) => {
        return (
          <MAMenuSocialItem
            src={item.image}
            onClick={() => {
              onSocialItemClick(item);
            }}
          />
        );
      })}
    </MAMenuSocialWrapper>
  );
});

export default KTMAMenuSocial;
