import styled from "styled-components";

export const MainCategoryHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  height: 64rem;

  @media screen and (min-width: ${481}px) and (max-width: ${1919}px) {
    height: 220px;
  }

  @media screen and (min-width: ${1920}px) {
    height: 240px;
  }
`;

export const MainCategoryHeaderTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: Alegreya Sans SC;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  

  @media screen and (min-width: 1920px) {
    padding: 0 60px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 0 50px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 0 20px;
  }
`;

export const MainCategoryHeaderBackground = styled.img`
  position: absolute;

  object-fit: cover;
  object-position: right;

  top: -184rem;
  bottom: 20rem;

  width: 100%;
  height: calc(100% + 184rem + 20rem);

  z-index: -1;

  @media screen and (min-width: ${481}px) and (max-width: ${1919}px) {
    top: -80px;
    bottom: 180px;

    width: 100%;
    height: calc(100% + 180px + 80px);
  }

  @media screen and (min-width: ${1920}px) {
    top: -80px;
    bottom: 100px;

    width: 100%;
    height: calc(100% + 180px + 80px);
  }
`;
