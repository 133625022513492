import React, { memo } from "react";
import { ConcertItemWrapper } from "./style";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const KTConcertItem = memo(({ concert }) => {
  return (
    <ConcertItemWrapper>
      <Avatar
        src={require(`../../../assets/img/temporary/${concert.portrait}`)}
        size={80}
        icon={<UserOutlined />}
      ></Avatar>
      <div className="name">{concert.name}</div>
    </ConcertItemWrapper>
  );
});

export default KTConcertItem;
