import styled from "styled-components";

export const TopSellingFilterBarWrapper = styled.div`
  .line {
    height: 1px;
    background: #ececec;
    margin: 0 100px;
  }
`;

export const TopSellingFilterBarContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TopSellingFilterSubMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  gap: 30px;

  .item {
    color: var(--icon-line-black, #19192a);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 50px;

    border-radius: 30px;
    border: 1px solid #19192a;
    background: #fff;

    cursor: pointer;
  }

  .selected {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 30px;
    background: #19192a;
  }
`;

export const TopSellingFilterFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 30px;
  border-radius: 10px;
  border: 2px solid #19192a;
  cursor: pointer;

  .icon {
    width: 18px;
    height: 14px;
    display: flex;
    padding: 5px 3px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .title {
    color: var(--icon-line-black, #19192a);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
