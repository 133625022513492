import React, { memo } from "react";
import { withRouter } from "react-router-dom";
import { ResultVenuesWrapper } from "./style";
import KTSearchDivider from "../../../../components/dropdown/search-keywords-dropdown/c-components/search-divider";
import { shallowEqual, useSelector } from "react-redux";
import KTSearchVenueCell from "../../../../components/dropdown/search-keywords-dropdown/c-components/venue-cell";

const KTResultVenues = memo((props) => {
  const { venues = [] } = useSelector(
    (state) => ({
      venues: state.getIn(["search", "venues"]),
    }),
    shallowEqual
  );

  const cells = venues.map((ele) => {
    return [
      <KTSearchVenueCell
        key={`KTSearchVenueCell-${ele.id}`}
        venue={ele}
        onClick={(venue) => {
          props.history.push(`/venue/${venue.id}/${venue?.name ?? ""}`);
        }}
      />,
      <KTSearchDivider key={`KTSearchDivider-${ele.id}`} />,
    ];
  });
  return <ResultVenuesWrapper>{cells}</ResultVenuesWrapper>;
});

export default withRouter(KTResultVenues);
