import React, { memo } from "react";
import { MAMenuNavlinkWrapper, MAMenuNavlinksWrapper } from "./style";
import { mobileHeaderLinks } from "@/common/local-data";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as Arrow } from "@/assets/img/icons/icon-arrow.svg";

const KTMAMenuNavlinks = memo(({ onClose }) => {
  function onClickNavlink() {
    onClose();
  }
  return (
    <MAMenuNavlinksWrapper>
      {mobileHeaderLinks.map((item, index) => {
        return (
          <NavLink
            exact
            className="navlink"
            activeClassName="navlinkActive"
            to={item.link}
            key={item.title}
            onClick={onClickNavlink}
          >
            <MAMenuNavlinkWrapper>
              <div className="title">{item.title}</div>
              <Arrow className="arrow" />
            </MAMenuNavlinkWrapper>
            <div className="divider" />
          </NavLink>
        );
      })}
    </MAMenuNavlinksWrapper>
  );
});

export default KTMAMenuNavlinks;
