import React, { memo } from "react";
import {
  FilterContentDateNodeWrapper,
  FilterContentDateRangeCalendarWrapper,
  FilterContentDateRangeHeaderWrapper,
  FilterContentDateRangeListWrapper,
  FilterContentDateRangeWrapper,
} from "./style";
import classNames from "classnames";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "@/assets/css/custom-daypicker.css";
import { format } from "date-fns";

const pastMonth = new Date();
const KTFilterContentDateRange = memo(
  ({
    dateIntervals = [],
    startdt,
    enddt,
    selectedIntervalValue = "0",
    didIntervalValueChange,
    windowWidth,
  }) => {
    function onclickRange(item) {
      if (item.value === 6) {
        // 自定义
        item.startdt = startdt;
        item.enddt = enddt;
        didIntervalValueChange(item);
      } else {
        item.startdt = undefined;
        item.enddt = undefined;
        didIntervalValueChange(item);
      }
    }

    function dateRangeItems() {
      return dateIntervals.map((item) => {
        return (
          <div
            key={item.title}
            className={classNames("item", {
              itemSelected: selectedIntervalValue === item.value,
            })}
            onClick={() => onclickRange(item)}
          >
            {item.title}
          </div>
        );
      });
    }

    function dateRangeCalendar() {
      return selectedIntervalValue === "6" ? (
        <FilterContentDateRangeCalendarWrapper windowWidth={windowWidth}>
          <div className="headerWrapper">
            <div className="headerDateNodeWrapper">
              <FilterContentDateNodeWrapper>
                <div className="title">From</div>
                <div className="content">
                  {startdt instanceof Date
                    ? format(startdt, "yyyy-MM-dd")
                    : null}
                </div>
              </FilterContentDateNodeWrapper>
              <FilterContentDateNodeWrapper>
                <div className="title">To</div>
                <div className="content">
                  {enddt instanceof Date ? format(enddt, "yyyy-MM-dd") : null}
                </div>
              </FilterContentDateNodeWrapper>
            </div>
          </div>
          <DayPicker
            id="home-all-filter-daterange"
            mode="range"
            defaultMonth={pastMonth}
            selected={{ from: startdt, to: enddt }}
            onSelect={(range) => {
              didIntervalValueChange({
                value: selectedIntervalValue,
                startdt: range?.from,
                enddt: range?.to,
              });
            }}
          />
        </FilterContentDateRangeCalendarWrapper>
      ) : null;
    }
    return (
      <FilterContentDateRangeWrapper windowWidth={windowWidth}>
        <FilterContentDateRangeHeaderWrapper windowWidth={windowWidth}>
          <div className="title">Date Range</div>
          <div className="divider" />
        </FilterContentDateRangeHeaderWrapper>
        <FilterContentDateRangeListWrapper windowWidth={windowWidth}>
          {dateRangeItems()}
        </FilterContentDateRangeListWrapper>
        {dateRangeCalendar()}
      </FilterContentDateRangeWrapper>
    );
  }
);

export default KTFilterContentDateRange;
