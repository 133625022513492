import { styled } from "styled-components";

export const HomeHeaderFilterWrapper = styled.div`
  margin-top: 60px;
  height: 68px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;

  .item {
    display: table-cell;
    vertical-align: top;
    padding: 0 16px;
    height: 58px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    white-space: pre-line;
  }

  .active {
    font-weight: 900;
    border-bottom: 10px solid #fff;
  }

  @media screen and (max-width: ${480}px) {
    .item {
      display: table-cell;
      vertical-align: top;
      height: 46rem;
      padding: 0 0rem;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 13rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      width: 80rem;
    }

    .active {
      font-weight: 900;
      border-bottom: 6px solid #fff;
      text-decoration: none;
    }
  }
`;
