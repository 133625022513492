import React, { memo } from "react";
import { currValue } from "@/utils";

import {
  TopSellingFilterBarContentWrapper,
  TopSellingFilterBarWrapper,
  TopSellingFilterFilterWrapper,
  TopSellingFilterSubMenuWrapper,
} from "./style";
import { ReactComponent as FilterIcon } from "@/assets/img/icons/icon-filter-o.svg";

import classNames from "classnames";

const KTTopSellingFilterBar = memo(
  ({
    selectedTitle,
    didChangeSelectedTitle,
    windowWidth = window.screen.width,
  }) => {
    const subMenus = ["Music", "Sports", "Shows"].map((title) => {
      return (
        <div
          className={classNames("item", {
            selected: selectedTitle === title,
          })}
          onClick={() => didChangeSelectedTitle(title)}
        >
          {title}
        </div>
      );
    });

    return (
      <TopSellingFilterBarWrapper>
        <TopSellingFilterBarContentWrapper
          style={{ padding: `30px ${currValue(50, 100, windowWidth)}` }}
        >
          <TopSellingFilterSubMenuWrapper>
            {subMenus}
          </TopSellingFilterSubMenuWrapper>
          <TopSellingFilterFilterWrapper>
            <FilterIcon className="icon" />
            <div className="title">Filter</div>
          </TopSellingFilterFilterWrapper>
        </TopSellingFilterBarContentWrapper>

        <div
          className="line"
          style={{ margin: `0 ${currValue(50, 100, windowWidth)}` }}
        ></div>
      </TopSellingFilterBarWrapper>
    );
  }
);

export default KTTopSellingFilterBar;
