import styled from "styled-components";

export const AppFooterContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 60px;

  padding: 90px 0;

  @media screen and (max-width: 1919px) and (min-width: 1024px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 1023px) and (min-width: 481px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;

    padding: 60px 0;
  }
`;

export const AppFooterContentLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-end;
`;

export const AppFooterContentRightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 140px;

  @media screen and (max-width: 1919px) and (min-width: 1024px) {
    gap: 60px;
  }
  @media screen and (max-width: 1023px) and (min-width: 481px) {
    gap: 60px;
  }
`;
