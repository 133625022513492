import styled from "styled-components";

export const HomeDesktopFilterBarWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #f2f2f2;
  margin: 0 100px;

  background: #fff;

  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin: 0 50px;
  }

  @media screen and (max-width: 1023px) {
    margin: 0 40px;
  }
`;

export const HomeDesktopFilterBarLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

export const HomeDesktopFilterBarRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  .layoutModel {
    width: 22px;
    height: 22px;
    stroke: #b3b3b3;

    cursor: pointer;
    user-select: none;
  }

  .activeLayoutModel {
    stroke: #19192a;
  }
`;

export const HomeDesktopFilterBarDivider = styled.div`
  width: 1px;
  height: 26.667px;
  background: #c8c8c8;
`;

export const HomeDesktopFilterDropdownWrapper = styled.div`
  display: inline-flex;
  padding: 0px 30px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  min-width: 130px;
  gap: 20px;

  border-radius: 20px;
  border: 1px solid var(--tag-stoke-8c, #8c8c8c);
  background: #fff;

  max-height: 480px;
  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HomeDesktopFilterDropdownTitle = styled.div`
  color: var(--icon-4d-black, #4d4d4d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 20px 0;
`;
