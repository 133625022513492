import React, { memo } from "react";
import {
  SearchEventCellDateTitle,
  SearchEventCellDateWrapper,
  SearchEventCellDetaileTitle,
  SearchEventCellDetaileWrapper,
  SearchEventCellTimeTitle,
  SearchEventCellWeekTitle,
  SearchEventCellWrapper,
} from "./style";
import { format, parseISO } from "date-fns";

const KTSearchEventCell = memo(({ event, onClick }) => {
  const now = new Date();
  const currentYear = now.getFullYear();

  const date = parseISO(`${event.date} ${event?.time ?? "00:00:00"}`);
  const year = parseInt(format(date, "yyyy"));
  const mmdd = format(date, "MMM dd");

  let dateMessage = mmdd;
  if (currentYear !== year) {
    dateMessage = `${mmdd}\n${year}`;
  }

  const week = format(date, "EEE");
  const time = format(date, "h:mm a");

  return (
    <SearchEventCellWrapper
      onClick={() => {
        onClick(event);
      }}
    >
      <SearchEventCellDateWrapper>
        <SearchEventCellDateTitle>{dateMessage}</SearchEventCellDateTitle>
        <SearchEventCellWeekTitle>{week}</SearchEventCellWeekTitle>
        <SearchEventCellTimeTitle>{time}</SearchEventCellTimeTitle>
      </SearchEventCellDateWrapper>
      <SearchEventCellDetaileWrapper>
        <SearchEventCellDetaileTitle>
          {event?.name ?? ""}
        </SearchEventCellDetaileTitle>
      </SearchEventCellDetaileWrapper>
    </SearchEventCellWrapper>
  );
});

export default KTSearchEventCell;
