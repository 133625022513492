import styled from "styled-components";

export const ClassifySubHeaderWrapper = styled.div`
  .swiperSlide {
    width: fit-content;
    height: fit-content;
  }

  background: #fff;
  padding: 15rem 24rem;
  box-shadow: 0px 6rem 20rem 0px rgba(242, 242, 242, 0.4);

  @media screen and (min-width: 1920px) {
    padding: 30px 100px;
    box-shadow: 0px 4px 20px 0px rgba(242, 242, 242, 0.4);
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 30px 50px;
    box-shadow: 0px 4px 20px 0px rgba(242, 242, 242, 0.4);
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 15px 30px;
    box-shadow: 0px 4px 20px 0px rgba(242, 242, 242, 0.4);
  }
`;

export const ClassifySubHeaderSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;

  .title {
    display: flex;
    height: 22rem;
    justify-content: center;
    align-items: center;
    color: #c8c8c8;

    text-align: center;
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    cursor: pointer;
  }

  .titleSelected {
    color: var(--icon-line-black, #19192a);
  }

  .emoji {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media screen and (min-width: 481px) {
    gap: 6px;

    .title {
      height: 22px;
      font-size: 18px;
      line-height: 22px;
    }

    .emoji {
      font-size: 17px;
    }
  }
`;
