import styled from "styled-components";

export const FilterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  padding: 20rem 0;

  @media screen and (min-width: 481px) {
    padding: 20px 0;
  }
`;
