import React,{memo, useState} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import withWindowSize from "@/components/higher-order/withWindowSize";
import { StyledPopup } from './style';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const SWpopup=  memo(({ windowSize })=> {
    
    console.log("come to popup");
    const [open, setOpen] = useState(true);
  const closeModal = () => setOpen(true);
  function ToSweep ()  {

    <NavLink to='/sweepstake'> </NavLink>
    
    // let path = `/#/sweepstake`;
    // let history = useHistory();
    // history.push(path);
    // const newState = { page: 'sweepstake' }; 
    // window.history.replaceState(newState,  
    //         'Redirect', '/#/sweepstake'); 
    // window.location.replace('/#/sweepstake'); 
    closeModal();

};
  return (
    <div style={{fontSize:20, color:'red'}}>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <a className="close" onClick={closeModal}>
            &times;
          </a>
          <div style={{fontSize:45, color:'blue'}}>
          This is sweepstake award pop up test window
          <p>
          {/* <button color='red' onClick =  { */}
                <NavLink to='/sweepstake' style={{fontSize:45, color:'red'} }> Take ME! </NavLink>
            {/* } > </button> */}
            </p>
          </div>
        </div>
      </Popup>
    </div>
  );
}
);

export default withWindowSize(SWpopup);

