export const likeSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 23" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4921 4.39519C10.1929 1.77392 6.35871 1.0688 3.4779 3.4691C0.59709 5.8694 0.191513 9.88259 2.45383 12.7214C4.33479 15.0817 10.0272 20.0598 11.8929 21.671C12.1016 21.8513 12.206 21.9414 12.3277 21.9768C12.434 22.0077 12.5503 22.0077 12.6565 21.9768C12.7782 21.9414 12.8826 21.8513 13.0913 21.671C14.957 20.0598 20.6495 15.0817 22.5304 12.7214C24.7927 9.88259 24.4367 5.84415 21.5063 3.4691C18.576 1.09405 14.7914 1.77392 12.4921 4.39519Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const shareSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 23"
    fill="none"
    {...props}
  >
    <path
      d="M21 4.33571H17.4444C15.5776 4.33571 14.6442 4.33571 13.9311 4.69929C13.3039 5.01909 12.794 5.52939 12.4744 6.15705C12.1111 6.87059 12.1111 7.80468 12.1111 9.67286V11.0071M21 4.33571L17.6667 1M21 4.33571L17.6667 7.67143M8.77778 1H6.33333C4.46649 1 3.53307 1 2.82003 1.36357C2.19282 1.68338 1.68289 2.19368 1.36331 2.82133C1 3.53488 1 4.46897 1 6.33714V15.6771C1 17.5453 1 18.4794 1.36331 19.193C1.68289 19.8206 2.19282 20.3309 2.82003 20.6507C3.53307 21.0143 4.46649 21.0143 6.33333 21.0143H15.6667C17.5335 21.0143 18.4669 21.0143 19.18 20.6507C19.8072 20.3309 20.3171 19.8206 20.6367 19.193C21 18.4794 21 17.5453 21 15.6771V13.231"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const addressSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M10.7305 5.8776C10.9686 5.4212 11.1029 4.906 11.1029 4.36C11.1029 2.505 9.55133 1 7.63895 1C5.72658 1 4.17504 2.505 4.17504 4.36C4.17504 4.9312 4.32225 5.4688 4.58205 5.9406L7.63898 10.5718L10.6699 6.03995M6.23027 8.4382L0.999756 11.36L3.30903 12.9L6.33996 13.04L8.21625 15L14.9998 11.5L12.5461 9.4H9.51522L8.9379 8.8666M12.8349 9.81995L8.64929 12.0599M3.30902 12.76L5.47397 11.64M8.93791 4.42999C8.93791 5.08721 8.38865 5.61999 7.7111 5.61999C7.03356 5.61999 6.4843 5.08721 6.4843 4.42999C6.4843 3.77277 7.03356 3.23999 7.7111 3.23999C8.38865 3.23999 8.93791 3.77277 8.93791 4.42999Z"
      stroke="#4D4D4D"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const timeSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.76551 3.8V8L10.5635 9.4M14.7605 8C14.7605 11.866 11.6287 15 7.76551 15C3.90228 15 0.770508 11.866 0.770508 8C0.770508 4.13401 3.90228 1 7.76551 1C11.6287 1 14.7605 4.13401 14.7605 8Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const arrowSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" {...props}>
    <path d="M6.48351 5.43305C6.10774 5.75848 5.54998 5.75848 5.1742 5.43305L0.928226 1.75593C0.228322 1.14979 0.656995 -1.06442e-06 1.58288 -9.89595e-07L10.0748 -3.03362e-07C11.0007 -2.28541e-07 11.4294 1.14979 10.7295 1.75593L6.48351 5.43305Z" />
  </svg>
);

export const profileHeaderSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 30"
    fill="none"
    {...props}
  >
    <path
      d="M31.5251 10.66C29.672 5.053 24.3255 1 18.0206 1C11.1582 1 5.43191 5.7985 4.10772 12.179"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.10773 17.821C5.43192 24.2015 11.1582 29 18.0206 29C24.8829 29 30.0275 24.95 31.6676 19"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.7381 19.024C33.5397 19.0087 34.9842 17.2064 34.9647 14.9981C34.9452 12.7899 33.4692 11.0124 31.6676 11.0278"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.17261 12.136C2.56487 12.064 1.20315 13.2685 1.13085 14.8265C1.05855 16.3845 2.30307 17.7056 3.91081 17.7776"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0573 12.486C19.0573 12.486 16.9236 10.743 13.4339 11.2015"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2031 12.017L14.5913 15.5975L16.7001 16.0805"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8765 14.9815L18.9085 14.775L18.9404 14.5685"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7654 14.2815L11.808 14.075L11.8506 13.8685"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1085 22.1944L14.6483 21.8059"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0175 6.5895C22.0175 6.5895 22.2483 8.504 20.6437 8.504C19.039 8.504 15.8297 5.2 19.039 5.55C22.2483 5.9 17.6083 7.279 15.5741 6.5895C13.5399 5.9 14.9138 4.5 14.9138 4.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const appHeaderSearchButtonSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="none"
    {...props}
  >
    <circle cx="13" cy="13" r="12.5" fill="black" stroke="black" />
    <path
      d="M15.7566 12.6488C15.7566 14.5711 14.2178 16.1151 12.3371 16.1151C10.4564 16.1151 8.91754 14.5711 8.91754 12.6488C8.91754 10.7266 10.4564 9.18262 12.3371 9.18262C14.2178 9.18262 15.7566 10.7266 15.7566 12.6488Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M17.4493 17.4673C17.7793 17.7176 18.2469 17.6508 18.4937 17.3181C18.7404 16.9855 18.6729 16.5128 18.3429 16.2626L17.4493 17.4673ZM14.6696 15.3592L17.4493 17.4673L18.3429 16.2626L15.5632 14.1544L14.6696 15.3592Z"
      fill="white"
    />
  </svg>
);

export const calendarSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
  >
    <path
      d="M14 6.6H1M10.3889 1V3.8M4.61111 1V3.8M6.41667 9.4L7.5 8.7V12.2M6.59722 12.2H8.40278M4.46667 15H10.5333C11.7468 15 12.3535 15 12.817 14.7711C13.2247 14.5698 13.5561 14.2485 13.7638 13.8534C14 13.4042 14 12.8161 14 11.64V5.76C14 4.58389 14 3.99583 13.7638 3.54662C13.5561 3.15148 13.2247 2.83022 12.817 2.62889C12.3535 2.4 11.7468 2.4 10.5333 2.4H4.46667C3.25322 2.4 2.6465 2.4 2.18302 2.62889C1.77534 2.83022 1.44388 3.15148 1.23615 3.54662C1 3.99583 1 4.58389 1 5.76V11.64C1 12.8161 1 13.4042 1.23615 13.8534C1.44388 14.2485 1.77534 14.5698 2.18302 14.7711C2.6465 15 3.25322 15 4.46667 15Z"
      stroke="#4D4D4D"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const dividerHSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2 22"
    fill="none"
    {...props}
  >
    <path d="M1 1L0.999999 21" strokeLinecap="round" />
  </svg>
);

export const logoSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 37"
    fill="none"
    {...props}
  >
    <path
      d="M13.2483 7.67487C13.8348 5.87607 12.875 3.93475 11.1045 3.33881C9.33389 2.74287 7.42303 3.71798 6.83645 5.51678L1.06209 23.2241C0.475496 25.0229 1.4353 26.9642 3.20588 27.5601C4.97645 28.1561 6.8873 27.181 7.47389 25.3822L13.2483 7.67487Z"
      fill="white"
    />
    <path
      d="M25.7629 7.02015C26.9984 6.0893 27.2575 4.31756 26.3417 3.06286C25.4258 1.80815 23.6818 1.54561 22.4463 2.47646L15.3479 7.82436C14.1124 8.75521 13.8533 10.5269 14.7692 11.7816C15.685 13.0364 17.4291 13.2989 18.6646 12.3681L25.7629 7.02015Z"
      fill="white"
    />
    <path
      d="M10.668 19.6806C10.668 19.6806 10.5959 17.5784 18.2353 12.6445C18.4605 12.4981 18.51 12.1825 18.3434 11.9697C16.8214 10.0437 15.959 8.7788 15.5785 8.1978C15.4367 7.9805 15.1327 7.95305 14.9594 8.14291C14.2704 8.90004 12.7079 11.4185 10.668 19.6806Z"
      fill="white"
    />
    <path
      d="M23.2183 34.5961L22.721 34.9708C21.3863 35.9763 19.5015 35.6906 18.5131 34.3365L10.9558 23.9832C9.72862 22.302 10.0759 19.9246 11.7306 18.6779C13.0183 17.7077 14.8387 17.9822 15.7936 19.2903L23.8445 30.3198C24.8343 31.6757 24.553 33.5906 23.2202 34.5947L23.2183 34.5961Z"
      fill="white"
    />
  </svg>
);

export const menuSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    {...props}
  >
    <path
      d="M1 8.5H23M1 1H23M1 16H23"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
