import React, { memo } from "react";
import {
  AddressBarBottomWrapper,
  AddressBarContent,
  AddressBarIcon,
  AddressBarNoEventsPrompt,
  AddressBarTitle,
  AddressBarWrapper,
} from "./style";
import { shallowEqual, useSelector } from "react-redux";
import KTSwitchCityDtpDropdown from "../dropdown/switch-city-dtp-dropdown";

const KTAddressBar = memo(({ showNoEventsPrompt = false }) => {
  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  let cityMessage = "Any Location";

  if (typeof city === "object" && Object.keys(city).length > 0) {
    const name = city.name ?? city.city ?? "";
    const state = city.state ?? "";
    if (name.length === 0 || state.length === 0) {
      cityMessage = `${name}${city.state}`;
    } else {
      cityMessage = `${name}, ${city.state}`;
    }
  }
  return (
    <AddressBarWrapper>
      {showNoEventsPrompt ? (
        <AddressBarNoEventsPrompt>
          There is no event near
        </AddressBarNoEventsPrompt>
      ) : null}

      <AddressBarBottomWrapper>
        <KTSwitchCityDtpDropdown>
          <AddressBarContent>
            <AddressBarIcon
              src={require("@/assets/img/icons/icon-location-blue.png")}
            />
            <AddressBarTitle>{cityMessage}</AddressBarTitle>
          </AddressBarContent>
        </KTSwitchCityDtpDropdown>
      </AddressBarBottomWrapper>
    </AddressBarWrapper>
  );
});

export default KTAddressBar;
