import * as actionTypes from "./constants";

const changeKeywords = (keywords) => ({
  type: actionTypes.CHANGE_KEYWORDS,
  keywords: keywords,
});

export const getChangeKeywordsAction = (keywords) => {
  return (dispatch) => {
    dispatch(changeKeywords(keywords));
  };
};

const getLocationInfo = (location) => ({
  type: actionTypes.CHANGE_KEYWORDS,
  location: location,
});

export const getLocationInfoAction = (location) => {
  return (dispatch) => {
    dispatch(getLocationInfo(location));
  };
};

const getIP = (ip) => ({
  type: actionTypes.CHANGE_IP,
  ip: ip,
});

export const getIPAction = (ip) => {
  return (dispatch) => {
    dispatch(getIP(ip));
  };
};




const changeCity = (city) => ({
  type: actionTypes.CHANGE_CITY,
  city: city,
});

export const getChangeCityAction = (city) => {
  return (dispatch) => {
    dispatch(changeCity(city));
  };
};

const changeLocationCity = (city) => ({
  type: actionTypes.CHANGE_LOCATION_CITY,
  city: city,
});

export const getChangeLocationCityAction = (city) => {
  return (dispatch) => {
    dispatch(changeLocationCity(city));
  };
};

// 时间段
const changeGlobalTemporalInterval = (
  intervalType,
  startdt = null,
  enddt = null
) => ({
  type: actionTypes.CHANGE_TEMPORAL_INTERVAL,
  intervalType: intervalType,
  startdt: startdt,
  enddt: enddt,
});

export const getChangeGlobalTemporalIntervalAction = (
  intervalType,
  startdt = null,
  enddt = null
) => {
  return (dispatch) => {
    dispatch(changeGlobalTemporalInterval(intervalType, startdt, enddt));
  };
};
