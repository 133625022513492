export const ADD_MORE_EVENTS = "home/ADD_MORE_EVENTS";

export const EMPTY_ADD_MORE_EVENTS = "home/EMPTY_ADD_MORE_EVENTS";

export const CHANGE_LAYOUT_MODE = "home/CHANGE_LAYOUT_MODE";

export const CHANGE_PAGENUM = "home/CHANGE_PAGENUM";

export const CHANGE_SORT_MODE = "home/CHANGE_SORT_MODE";

export const CHANGE_CATEGORIES = "home/CHANGE_CATEGORIES";

// 修改时间段
export const CHANGE_TEMPORAL_INTERVAL = "home/CHANGE_TEMPORAL_INTERVAL";
