import React, { memo } from "react";
import {
  FAQParagraphListViewContent,
  FAQParagraphListViewKindOne,
  FAQParagraphListViewKindThree,
  FAQParagraphListViewKindTwo,
  FAQParagraphListViewKindZero,
  FAQParagraphListViewLine,
  FAQParagraphListViewWrapper,
} from "./style";

const KTFAQParagraphListView = memo(({ paragraphs }) => {
  return (
    <FAQParagraphListViewWrapper>
      <FAQParagraphListViewLine />
      <FAQParagraphListViewContent>
        {paragraphs.map((ele) => {
          switch (ele.kind) {
            case 0:
              return (
                <FAQParagraphListViewKindZero>
                  {ele.content}
                </FAQParagraphListViewKindZero>
              );

            case 1:
              return (
                <FAQParagraphListViewKindOne>
                  {ele.content}
                </FAQParagraphListViewKindOne>
              );
            case 2:
              return (
                <FAQParagraphListViewKindTwo>
                  {ele.content}
                </FAQParagraphListViewKindTwo>
              );

            case 3:
              return (
                <FAQParagraphListViewKindThree>
                  {ele.content}
                </FAQParagraphListViewKindThree>
              );

            default:
              break;
          }

          return (
            <FAQParagraphListViewKindZero>
              {ele.content}
            </FAQParagraphListViewKindZero>
          );
        })}
      </FAQParagraphListViewContent>
    </FAQParagraphListViewWrapper>
  );
});

export default KTFAQParagraphListView;
