import React, { memo } from "react";
import { withRouter } from "react-router-dom";

import {
  EventCellContentWrapper,
  EventCellLeftWrapper,
  EventCellRightWrapper,
  EventCellWrapper,
} from "./style";

import KTEventCellDate from "./event-cell-date";
import KTEventCellDivider from "./event-cell-divider";
import KTEventCellType from "./event-cell-type";
import KTEventCellInfo from "./event-cell-info";
import KTEvnetCellBuyButton from "./event-cell-buy";

const KTEventCell = memo((props) => {
  const { event } = props;

  function onBuyButtonClick() {
    const url = window.location.origin + `#/map/${event.id}`;
    window.open(url, "_self", "noopener,noreferrer");
  }

  return (
    <EventCellWrapper>
      <EventCellContentWrapper>
        <EventCellLeftWrapper>
          <KTEventCellDate event={event} />
          <KTEventCellDivider />
          <KTEventCellType event={event} />
          <KTEventCellDivider />
          <KTEventCellInfo event={event} />
        </EventCellLeftWrapper>
        <EventCellRightWrapper>
          <KTEvnetCellBuyButton event={event} onClick={onBuyButtonClick} />
        </EventCellRightWrapper>
      </EventCellContentWrapper>
    </EventCellWrapper>
  );
});

export default withRouter(KTEventCell);
