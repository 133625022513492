export const ADD_MORE_EVENTS = "SHOWS/ADD_MORE_EVENTS";

export const EMPTY_ADD_MORE_EVENTS = "SHOWS/EMPTY_ADD_MORE_EVENTS";

export const CHANGE_LAYOUT_MODE = "SHOWS/CHANGE_LAYOUT_MODE";

export const CHANGE_PAGENUM = "SHOWS/CHANGE_PAGENUM";

export const CHANGE_SORT_MODE = "SHOWS/CHANGE_SORT_MODE";

export const CHANGE_CATEGORIES = "SHOWS/CHANGE_CATEGORIES";

// 修改时间段
export const CHANGE_TEMPORAL_INTERVAL = "SHOWS/CHANGE_TEMPORAL_INTERVAL";
