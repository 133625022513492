import React, { memo } from "react";

import { ReactComponent as Arrow } from "@/assets/img/icons/icon-arrow-bottom.svg";
import { FilterBaritemWrapper } from "./style";

const KTFilterBaritem = memo(
  ({
    title = "",
    hiddenArrow = false,
    isActive = false,
    isSelected = false,
    badgeValue,
    onClick,
  }) => {
    return (
      <FilterBaritemWrapper
        onClick={onClick}
        style={{
          background: isSelected ? "#19192A" : "#fff",
        }}
      >
        <div
          className="title"
          style={{
            color: isSelected ? "#fff" : isActive ? "#4D4D4D" : "#8C8C8C",

            fontWeight: isActive ? 700 : 400,
          }}
        >
          {title}
        </div>
        {hiddenArrow ? null : (
          <Arrow stroke={isActive ? "#4D4D4D" : "#8C8C8C"} className="arrow" />
        )}
        {badgeValue === undefined ? null : (
          <div className="badgeValue">{badgeValue}</div>
        )}
      </FilterBaritemWrapper>
    );
  }
);

export default KTFilterBaritem;
