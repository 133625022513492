import React, { memo } from "react";
import { AppHeaderMobileSearchWrapper } from "./style";
import KTAppHeaderMobileSearchAddress from "./c-components/app-header-mobile-search-address";
import KTAppHeaderMobileSearchInput from "./c-components/app-header-mobile-search-input";
import KTSwitchCityDropdown from "../../../../dropdown/switch-city-dropdown";

const KTAppHeaderMobileSearch = memo(() => {
  return (
    <AppHeaderMobileSearchWrapper>
      <KTSwitchCityDropdown>
        <KTAppHeaderMobileSearchAddress onClick={(e) => e.preventDefault()} />
      </KTSwitchCityDropdown>

      <div className="divider" />

      <KTAppHeaderMobileSearchInput />
    </AppHeaderMobileSearchWrapper>
  );
});

export default KTAppHeaderMobileSearch;
