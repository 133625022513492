import React, { memo, useContext } from "react";
import { CategoryHeaderBackgroundImage, CategoryHeaderWrapper } from "./style";
import KTSpace from "@/components/space";
import KTWindowSizeContext from "../../../../common/context/windowSizeContext";

const KTCategoryHeader = memo(({ headerImageSrc = "" }) => {
  const context = useContext(KTWindowSizeContext);
  return (
    <CategoryHeaderWrapper>
      <CategoryHeaderBackgroundImage src={headerImageSrc} />
      <KTSpace height={context?.windowSize.width > 480 ? "420px" : "150rem"} />
    </CategoryHeaderWrapper>
  );
});

export default KTCategoryHeader;
