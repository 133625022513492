export const homeHeaderPromoItems = [
  {
    imageName: "image-concert-n.png",
    imageHoverName: "image-concert-h.png",
    smallImageName: "image-_mobile-concert-n.png",
    smallImageHoverName: "image-_mobile-concert-h.png",
    url: "",
    title: "Concert",
  },
  {
    imageName: "image-mlb-n.png",
    imageHoverName: "image-mlb-h.png",
    smallImageName: "image-_mobile-mlb-n.png",
    smallImageHoverName: "image-_mobile-mlb-h.png",
    url: "",
    title: "MLB",
  },
  {
    imageName: "image-nba-n.png",
    imageHoverName: "image-nba-h.png",
    smallImageName: "image-_mobile-nfl-n.png",
    smallImageHoverName: "image-_mobile-nfl-h.png",
    url: "",
    title: "NBA",
  },
  {
    imageName: "image-ncaa-n.png",
    imageHoverName: "image-ncaa-h.png",
    smallImageName: "image-_mobile-ncaa-n.png",
    smallImageHoverName: "image-_mobile-ncaa-h.png",
    url: "",
    title: "NCAA",
  },
  
  
  {
    imageName: "image-nfl-n.png",
    imageHoverName: "image-nfl-h.png",
    smallImageName: "image-_mobile-nba-n.png",
    smallImageHoverName: "image-_mobile-nba-h.png",
    url: "",
    title: "NFL",
  },
  {
    imageName: "image-nhl-n.png",
    imageHoverName: "image-nhl-h.png",
    smallImageName: "image-_mobile-nhl-n.png",
    smallImageHoverName: "image-_mobile-nhl-h.png",
    url: "",
    title: "NHL",
  },
  {
    imageName: "image-msl-n.png",
    imageHoverName: "image-msl-h.png",
    smallImageName: "image-_mobile-msl-n.png",
    smallImageHoverName: "image-_mobile-msl-h.png",
    url: "",
    title: "Soccer",
  },
];

export const homeContentEventItems = [
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-0.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-1.png",
    leftImageName: "event-poster-left-2.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-2.png",
    leftImageName: "event-poster-left-3.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-3.png",
    leftImageName: "event-poster-left-4.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-4.png",
    leftImageName: "event-poster-left-5.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-5.png",
    leftImageName: "event-poster-left-6.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-6.png",
    leftImageName: "event-poster-left-7.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-7.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-8.png",
    leftImageName: "event-poster-left-2.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-9.png",
    leftImageName: "event-poster-left-3.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-10.png",
    leftImageName: "event-poster-left-4.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-11.png",
    leftImageName: "event-poster-left-5.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-12.png",
    leftImageName: "event-poster-left-6.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-13.png",
    leftImageName: "event-poster-left-7.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-14.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-15.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-16.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-17.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-18.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-19.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-20.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
  {
    id: "asdasda",
    event_title: "Taylor Swift with Gracie Abrams and MUNA...",
    imageName: "event-poster-21.png",
    leftImageName: "event-poster-left-1.png",
    addr1: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    timestamp: 1692370227000,
  },
];

export const homeAllFilterCategories = [
  {
    title: "ALL",
    iconNameNormal: "home-filter-categorie-all-n.jpg",
    iconNameSelected: "home-filter-categorie-all-s.jpg",
  },
  {
    title: "Concerts",
    iconNameNormal: "home-filter-categorie-concerts-n.jpg",
    iconNameSelected: "home-filter-categorie-concerts-s.jpg",
  },
  {
    title: "Festivals",
    iconNameNormal: "home-filter-categorie-festivals-n.jpg",
    iconNameSelected: "home-filter-categorie-festivals-s.jpg",
  },
  {
    title: "Football",
    iconNameNormal: "home-filter-categorie-football-n.jpg",
    iconNameSelected: "home-filter-categorie-football-s.jpg",
  },
  {
    title: "Baseball",
    iconNameNormal: "home-filter-categorie-baseball-n.jpg",
    iconNameSelected: "home-filter-categorie-baseball-s.jpg",
  },
  {
    title: "Hockey",
    iconNameNormal: "home-filter-categorie-hockey-n.jpg",
    iconNameSelected: "home-filter-categorie-hockey-s.jpg",
  },
  {
    title: "Basketball",
    iconNameNormal: "home-filter-categorie-basketball-n.jpg",
    iconNameSelected: "home-filter-categorie-basketball-s.jpg",
  },
  {
    title: "Tennis",
    iconNameNormal: "home-filter-categorie-tennis-n.jpg",
    iconNameSelected: "home-filter-categorie-tennis-s.jpg",
  },
  {
    title: "Auto-Racing",
    iconNameNormal: "home-filter-categorie-autoracing-n.jpg",
    iconNameSelected: "home-filter-categorie-autoracing-s.jpg",
  },
  {
    title: "Fighting",
    iconNameNormal: "home-filter-categorie-fighting-n.jpg",
    iconNameSelected: "home-filter-categorie-fighting-s.jpg",
  },
  {
    title: "Soccer",
    iconNameNormal: "home-filter-categorie-soccer-n.jpg",
    iconNameSelected: "home-filter-categorie-soccer-s.jpg",
  },
  {
    title: "Golf",
    iconNameNormal: "home-filter-categorie-golf-n.jpg",
    iconNameSelected: "home-filter-categorie-golf-s.jpg",
  },
  {
    title: "Horse-Racing",
    iconNameNormal: "home-filter-categorie-horseracing-n.jpg",
    iconNameSelected: "home-filter-categorie-horseracing-s.jpg",
  },
  {
    title: "Olympics",
    iconNameNormal: "home-filter-categorie-olympics-n.jpg",
    iconNameSelected: "home-filter-categorie-olympics-s.jpg",
  },
  {
    title: "Theater",
    iconNameNormal: "home-filter-categorie-theater-n.jpg",
    iconNameSelected: "home-filter-categorie-theater-s.jpg",
  },
  {
    title: "Comedy",
    iconNameNormal: "home-filter-categorie-comedy-n.jpg",
    iconNameSelected: "home-filter-categorie-comedy-s.jpg",
  },
  {
    title: "Family",
    iconNameNormal: "home-filter-categorie-family-n.jpg",
    iconNameSelected: "home-filter-categorie-family-s.jpg",
  },
];

export const homeAllFilterDateRange = [
  {
    title: "Any Dates",
  },
  {
    title: "This Weekend",
  },
  {
    title: "Next 7 Days",
  },
  {
    title: "Next 30 Days",
  },
  {
    title: "Next 60 Days",
  },
  {
    title: "Custom",
  },
];

export const exploreEvents = [
  {
    id: "1",
    title: "Exclusive SaleInteractive Arts",
    eventTitle: "Exclusive SaleInteractive",
    kindName: "Arts",
    address: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    time: "2023-09-12 18:30",
    imageName: "event-explore-1.jpg",
  },
  {
    id: "2",
    title: "Country Roots Festival - Kansas City",
    eventTitle: "Country Roots Festival",
    kindName: "Kansas City",
    address: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    time: "2023-11-24 17:00",
    imageName: "event-explore-2.jpg",
  },
  {
    id: "3",
    title: "Exclusive Sale: Talk Show",
    eventTitle: "Exclusive Sale",
    kindName: "Talk Show",
    address: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    time: "2023-010-10 16:20",
    imageName: "event-explore-3.jpg",
  },
  {
    id: "4",
    title: "Exclusive Sale: Talk Show",
    eventTitle: "Exclusive Sale",
    kindName: "Talk Show",
    address: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    time: "2023-012-12 14:30",
    imageName: "event-explore-4.jpg",
  },
  {
    id: "5",
    title: "Exclusive Sale: Talk Show",
    eventTitle: "Exclusive Sale",
    kindName: "Talk Show",
    address: "GEHA Field at Arrowhead Stadium, Kansas City, MO",
    time: "2023-012-12 14:30",
    imageName: "event-explore-5.jpg",
  },
];
