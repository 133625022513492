/**
 * kind 3：图片
 * kind 4：超链接
 *
 */
export const faqGroupDatas = [
  {
    title: "Ordering Tickets",
    items: [
      {
        issue: "How do I search for tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              'You can type the name of the performer, team, or venue and hit "Enter" on your keyboard or select from the displayed suggestions. Your search results will be sorted in chronological order. ',
          },
          {
            kind: 0,
            content:
              "You may search multiple terms together like artist and desired venue or date for more specific results.",
          },
          {
            kind: 0,
            content:
              "Once you find your desired event click on the event name. This will take you to a page displaying all available tickets for that event, sorted by date.",
          },
          {
            kind: 0,
            content:
              "On the top right corner of the ticket options, you will find a 'Filters' box where you may filter results by number of tickets or pricing or change the sorting options.",
          },
          {
            kind: 0,
            content:
              "To purchase your preferred selection, simply click the listing and follow the prompts to complete your purchase.",
          },
        ],
      },
      {
        issue: "Can I purchase tickets as a gift?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Tickets listed on our site do not require the ticket holder to provide identification when attending the event so all tickets are guaranteed to be fully transferable. ",
          },
          {
            kind: 0,
            content:
              "The delivery method of the specific listing will be displayed at the time of purchase. We do not recommend purchasing tickets with delivery methods 'Will Call' or 'Near Term Special Delivery' if they are to be gifted. If purchased, please contact us immediately with the name of the intended recipient. A name change for these delivery methods can be requested but cannot be guaranteed due to possible restrictions from the event organizer and may require you, as the purchaser, to pickup the tickets and provide to your recipient. ",
          },
        ],
      },
      {
        issue: "How will I receive my tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Ticket delivery options are determined by the event organizers. While multiple options may be available for any given event, it is important to review each ticket listing you may be interested in. Each ticket listing has its own delivery method depending on how the seller will be receiving their tickets from the box office, team, or artist. The ticket delivery method will display during the checkout process and often can't be changed.  ",
          },
          {
            kind: 0,
            content:
              "Below are the different types of delivery and more information on each can be found by selecting the method.",
          },

          {
            kind: 1,
            content: "Email Delivery/Instant Download",
          },
          {
            kind: 2,
            content:
              "E-tickets and instant download are forms of electronic delivery. With E-Tickets and “Instant Download,” your tickets will be delivered as a PDF file.  You must download the tickets to present them to the venue (either on the phone or printed depending on the type of ticket). All tickets are guaranteed in time for the event, this is backed by our 100% moneyback guarantee.",
          },
          {
            kind: 2,
            content:
              "With instant download, tickets are delivered, in most cases, within twenty minutes of the time you place your ticket order.",
          },

          {
            kind: 1,
            content: "Electronic Transfer",
          },
          {
            kind: 2,
            content:
              "You will display the tickets via an application on your smartphone to gain entry to your event.  Once the tickets have been transferred to you, you will receive an email notification of the delivery, and the instructions needed to access your tickets. Please be sure to keep an eye on any spam/junk folders to ensure you don’t miss these instructions.  If you are having trouble with the application, please contact the platform's customer service for assistance.",
          },

          {
            kind: 1,
            content: "Mobile E-tickets",
          },
          {
            kind: 2,
            content:
              "Direct Transfer is a form of mobile entry. This means the tickets will be transferred to you directly utilizing the official ticketing platform as determined by the organizers. This may be a team or venue account manager or simply the ticketing distribution platform (SeatGeek, AXS, Ticketmaster) and varies by event. You will need a smart mobile device to gain entry. This requirement cannot be controlled or waived. Once the tickets have been transferred to you, you will receive an email notification of the delivery, and the instructions needed to access your tickets. Please be sure to keep an eye on any spam/junk folders to ensure you don’t miss these instructions. If you need further assistance, you may reach out to us for live support via phone.",
          },

          {
            kind: 1,
            content: "Fed-Ex Shipping",
          },
          {
            kind: 2,
            content:
              "A signature is typically required when FedEx attempts to deliver your tickets. We generally expect the sellers who list tickets on our exchange to require a signature for ticket delivery. This is to ensure that tickets (irreplaceable items) are physically received by someone, instead of being left out in the open. This may include an office or apartment manager if necessary.",
          },
          {
            kind: 2,
            content:
              "Fedex will not be delivered to a PO Box and a physical address is required. Be sure to review and provide full address information to prevent any delays in delivery for incomplete details.",
          },
          {
            kind: 2,
            content:
              "Fedex will make three (3) attempts to deliver should you be unavailable upon initial delivery. Fedex will notify you of the attempt(s), typically leaving a door tag that may supplement your signature in person and / or provide the opportunity to pick up the package at a designated Fedex facility nearby.",
          },
          {
            kind: 2,
            content:
              "Should the package be undeliverable or not picked up, your package may be returned to your seller's office. If time permits, your tickets may be shipped back out to you for an additional delivery fee.",
          },
          {
            kind: 2,
            content:
              "Neither our website or the seller is responsible should tickets be undeliverable or returned and the event missed.",
          },

          {
            kind: 1,
            content: "AXS Mobile ID",
          },
          {
            kind: 2,
            content:
              "AXS is a form of electronic delivery which allows tickets to be delivered via direct transfer from the ticket seller to you so you can display the tickets on a smart device at the venue. Please set up an account on www.AXS.com or by their mobile application with the email you used to place your order and verify the account. Once the tickets have been transferred to you, you will be able to display on your phone at the venue from the AXS application.",
          },
          {
            kind: 2,
            content:
              "AXS tickets have the extra security that the barcodes change every minute and cannot be duplicated. In this manner, it is important to note that screenshots of your tickets/account will not allow you entry into the event.",
          },
          {
            kind: 2,
            content:
              "AXS Mobile ID is associated with the ticket account and not the specific tickets. Therefore, you will only have one (1) barcode, the Mobile ID, for all the tickets in the account.",
          },
          {
            kind: 2,
            content:
              "If you would like additional information on AXS, please visit www.axs.com. ",
          },
          {
            kind: 2,
            content:
              "Please be advised that on some listings, you may see 'Flash Seats' in the notes. AXS is the platform for these listings.",
          },

          {
            kind: 1,
            content: "Near Term Special Delivery",
          },
          {
            kind: 2,
            content:
              "Near term special delivery means that the tickets will be delivered to you by one of the following methods:",
          },
          {
            kind: 3,
            content: "Email",
          },
          {
            kind: 3,
            content: "Electronic Transfer",
          },
          {
            kind: 3,
            content: "Will Call",
          },
          {
            kind: 3,
            content: "Local Pick-Up",
          },
          {
            kind: 2,
            content:
              "Near term special delivery is generally listed when the event purchased for is happening within the next 48 hours, however sometimes there can be tickets listed with this method if the lister knows that there will be a 24-48 hour delay in the release of the tickets for distribution. Please note, the buyer does not have the choice of which method they prefer, and the above list is not inclusive of all the different ways the delivery can be fulfilled. You will be emailed with more information about the delivery of the tickets after your order has been processed and accepted. The supplier that listed your tickets is a ticket selling professional that does this for a living, and you can rest assured that you will be in good hands regardless of how the tickets get to you. Orders placed using near term special delivery are also still covered by all of our guarantees; tickets are always guaranteed to be valid and delivered in time for the event, or you will receive your money back. *PLEASE NOTE: If you have purchased tickets as a gift for someone else, or you are not the person going to the event, please make sure to contact us immediately so that we can take note of any name changes that may be needed for will call or local pick-up. Name changes are not always a guarantee, but generally are not an issue as long as we have enough time to accommodate them.",
          },

          {
            kind: 1,
            content: "Local Pickup",
          },

          {
            kind: 1,
            content: "Will Call",
          },
          {
            kind: 2,
            content:
              "Unfortunately, while we understand that sometimes clients would prefer a different delivery method, such requests cannot be accommodated. All tickets are listed using the specific delivery format available as per the event organizers. Tickets may be available in multiple formats from the venue or box office (for example, hard-stock physical tickets vs. mobile entry ticket), however once selected and purchased, the ticket type cannot be changed. Tickets can only be provided to you in the method that they were originally purchased and listed as.  If you require a change of shipping address, please reach out to us by phone or by email to see if we can accommodate your request.",
          },

          {
            kind: 0,
            content:
              "You will be notified if there is a change to your delivery method. Please note that delivery changes are often a result of changes from the event organizers and cannot be controlled. ",
          },
          {
            kind: 0,
            content:
              "Some listings will specifically provide information on an expected delivery date. Such dates are simply estimates and are subject to change. Tickets may be made available sooner, or in some cases (usually related to event schedule changes) later. All tickets are guaranteed in time for the event.",
          },
        ],
      },
      {
        issue: "Why can't I purchase the number of tickets that I want?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Some event organizers place restrictions on the amount of tickets that can be purchased by a given individual. Common limitations are four (4) or eight (8) tickets. When sellers list tickets, they will list their available tickets. At times, you will see listings with such availability as '2 or 4', which is to avoid being left with a single ticket, which are often difficult to sell. ",
          },
          {
            kind: 0,
            content:
              "If the listing you are looking at does not have the number of tickets you need, please look for another option that may provide your desired amount. You may filter the ticket listings for ticket quantity by selecting the 'Filters' option in the top right corner of the listings display.\nShould you not be able to find your full quantity of desired tickets in one listing, you may purchase multiple listings. Please note that multiple listings cannot be combined into one purchase. Tickets purchased across multiple orders are not guaranteed to be together, even if in the same row.",
          },
        ],
      },
      {
        issue: "Can I choose my seat numbers?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Seat numbers cannot be chosen by the purchaser. The companies that list on our website do not list the seat number for privacy and security reasons. The seat numbers become available at the time of delivery. ",
          },
          {
            kind: 0,
            content:
              "Tickets purchased within an individual order are guaranteed to be side by side and have an unobstructed view by venue definition, unless otherwise stated in the seller’s notes. Notes can be viewed by selecting the information (i) icon on any listing and will also display during checkout once selected. Please note that mobile devices may limit visibility of the notes due to screen size and formatting. All purchasers are responsible to ensure they have viewed all subfields as necessary during their purchase process.",
          },
          {
            kind: 0,
            content:
              "One slight exception to choosing seats is that a listing may be noted as being 'Aisle' seating, which guarantees that tickets purchased will include the aisle seat at either end of the row.",
          },
        ],
      },
      {
        issue: "Will my seats be together?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "All seats placed under a single ticket order will be located together unless otherwise noted.",
          },
        ],
      },
      {
        issue: "How can I add more tickets seated together?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Tickets are only guaranteed to be seated together if they're part of the same order. If you need to add more tickets seated together, please contact our customer support.",
          },
        ],
      },
    ],
  },
  {
    title: "Ticket Listing Information",
    items: [
      {
        issue:
          "What are “E-tickets” and “Instant Download” tickets? Do I need to print these tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "With E-Tickets and “Instant Download,” your tickets will be delivered as a PDF file. While some venues may allow display on a mobile device, this should be considered a 'backup' option and you should download and print the tickets to bring with you to ensure no issues. If necessary, you may find printing options while traveling at hotels, libraries, or  print shops (Staples, FedEx, Kinkos, etc.).",
          },
          {
            kind: 0,
            content:
              "All tickets are guaranteed in time for the event, this is backed by our 100% moneyback guarantee. With instant download, tickets are delivered, in most cases, within twenty minutes of the time you placed your ticket order. ",
          },
        ],
      },
      {
        issue: "Do I need to print my tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "If your tickets are not marked mobile entry, then we cannot guarantee that the venue will be able to scan the barcodes unless they are printed.",
          },
        ],
      },
      {
        issue:
          'What is considered "Obstructed View" or "Limited View?" What will be blocking my view?',
        answerParagraphs: [
          {
            kind: 0,
            content:
              "A ticket listing will be considered obstructed or limited or side view, if this warning is printed on the ticket itself. Obstructed or partial view is defined and labeled by the venue and neither our website or the seller will have specific information as to the issue. Obstructions can include but, are not limited to, stage props, speakers, sound boards, and structural elements. If a ticket is considered obstructed by venue definition, it will be stated in the seller's notes. ",
          },
          {
            kind: 0,
            content:
              "In the rare event that you receive tickets that are labeled as obstructed but not listed that way at the time of purchase, please contact us to investigate. We will do our best to ensure your tickets are corrected.",
          },
          {
            kind: 0,
            content:
              "To view the seller’s notes for a particular listing on a standard pc, select the correct city, date, and time for the event you would like to attend, select the ticket listing you are interested in and click the Icon to view the listing details.",
          },
        ],
      },
      {
        issue:
          'What is “General Admission”/”GA” and” Standing Room Only” /"SRO?” Why do I see a seat number printed on the ticket?',
        answerParagraphs: [
          {
            kind: 0,
            content:
              "With General Admission (GA) and Standing Room Only (SRO), you will have access to the area specified on a first come first served basis. You will not receive a reserved seat. In most cases, seating will not be available when you purchase a General Admission ticket, and you will be expected to stand for the duration of the event. Venues sometimes label GA/SRO tickets with numbers for inventory purposes only. In the case of GA/SRO ticket listings, you can ignore the seat number printed on the event ticket.  ",
          },
        ],
      },
      {
        issue:
          "Do you sell ADA/wheelchair-accessible seating? How am I able to determine if tickets available are ADA/wheelchair-accessible?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "For limited events we do have tickets listed for sale for ADA/wheelchair -accessibility. It can be difficult to find ADA/Wheelchair-accessible tickets for resale due to restrictions put on handicap accessible purchases by the primary market and event promoters.",
          },
          {
            kind: 0,
            content:
              "The only tickets guaranteed to be ADA/Wheelchair-accessible, are those marked as ADA/wheelchair-accessible on the ticket listing itself.",
          },
          {
            kind: 0,
            content: "Please see the example below:",
          },
          {
            kind: 0,
            content:
              "The following listing is marked with a WC which stands for Wheelchair.  Additionally, the tickets are labeled as ADA/Wheelchair-accessible in the seller’s notes.",
          },
          {
            kind: 3,
            content: "",
          },
          {
            kind: 0,
            content:
              "Seller’s notes:  To view the seller’s notes for a particular listing on a standard pc, select the correct city, date, and time for the event you would like to attend, select the ticket listing you are interested in and click the  Icon to view the listing details.",
          },
          {
            kind: 3,
            content: "",
          },
          {
            kind: 0,
            content:
              "While sections in the venue may be indicated to include ADA / wheelchair accessible options, the only tickets guaranteed to be ADA/Wheelchair-accessible on our site are those marked as ADA/wheelchair-accessible on the ticket listing itself.",
          },
          {
            kind: 0,
            content:
              "We cannot exchange any tickets after an order is placed so if accommodations are needed after purchase or for other accessibility issues (such as interpreters or visual accommodations), it is best to contact the venue directly for further assistance.",
          },
        ],
      },
      {
        issue: "What is “Zone Seating”? Can I request a specific row or seat?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Zone listings will include the following label: “Important: Zone Seating.” With zone listings, you may purchase a specific section and /or row or the listing will advise simply an area (for example: Section 1, Rows A through H). You may receive tickets exactly as purchased if available. However, if not, you will be provided with equal or ",
          },
          {
            kind: 0,
            content:
              "upgraded seating with no additional communication required. This may mean a change in the section and / or row as compared to the actual purchase.",
          },
          {
            kind: 0,
            content: "Please see an example of a zone listing below.",
          },
          {
            kind: 3,
            content: "",
          },
          {
            kind: 0,
            content:
              "The following disclaimer will also be attached to the ticket listing when you click on",
          },
          {
            kind: 3,
            content: "",
          },
          {
            kind: 0,
            content: "the disclaimer:",
          },
          {
            kind: 0,
            content:
              "While sections in the venue may be indicated to include ADA / wheelchair accessible options, the only tickets guaranteed to be ADA/Wheelchair-accessible on our site are those marked as ADA/wheelchair-accessible on the ticket listing itself.",
          },
          {
            kind: 0,
            content:
              "We cannot exchange any tickets after an order is placed so if accommodations are needed after purchase or for other accessibility issues (such as interpreters or visual accommodations), it is best to contact the venue directly for further assistance.",
          },
        ],
      },
    ],
  },
  {
    title: "Payment and Orders",
    items: [
      {
        issue:
          "As a Canadian purchaser, will I be charged in Canadian or US currency?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "The tickets are sold by licensed reselling companies, who are all based in the US. All prices for tickets are in USD. The difference between USD and any other currency is automatically applied by your financial institution.",
          },
          {
            kind: 0,
            content:
              "Regarding event ticket insurance, clients will be provided the opportunity to select event ticket insurance on the checkout page, when this option is available.  Canadian clients will be charged in CAD for event ticket insurance only.",
          },
          {
            kind: 0,
            content:
              "Please note: insurance is billed separately by the independent insurance provider. You will be provided policy information from the provider directly. All policy questions or claim assistance requests should be addressed with the provider. ",
          },
        ],
      },
      {
        issue: "What Payment Methods are Accepted?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "The payment methods currently accepted include the four major credit cards: Visa, MasterCard, American Express and Discover. We also accept payment from the following third-party payment platforms: PayPal, Apple Pay, UCB, Diners Club International and Venmo. ",
          },
          {
            kind: 0,
            content:
              "Clients will also have the option to use Affirm as a payment option, pending approval. Clients who are approved and use Affirm as a payment method, are subject to Affirm’s policies, fees, and terms of service.",
          },
          {
            kind: 0,
            content:
              "Please note that we are unable to charge multiple payment methods when placing an order. The full purchase must be made to a single payment option. If multiple payments are necessary, multiple purchases will need to be made. Tickets purchased across multiple orders cannot be guaranteed together.",
          },
        ],
      },
      {
        issue: "When Will My Credit Card be Charged?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "For clients within the United States, credit cards will be preauthorized and charged by the fulfiller responsible for fulfilling the order immediately upon submitting the order to be processed.  The fulfiller may charge or authorize the user’s credit card in advance of confirming ticket availability. If tickets are ultimately found to be unavailable, the user’s credit card will not be charged or the user will receive a full refund for the amount charged.",
          },
          {
            kind: 0,
            content:
              "International clients may be subject to delayed processing. The site recommends contacting your credit card company or financial institution prior to placing your request for tickets, to prevent unnecessary delays or holds.",
          },
        ],
      },
    ],
  },
  {
    title: "E-TICKET DELIVERY",
    items: [
      {
        issue: "What are “E-tickets” and “Instant Download” tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "E-tickets and instant download are forms of electronic delivery. With E-Tickets and “Instant Download,” your tickets will be delivered as a PDF file.  You must download the tickets to present them to the venue (either on the phone or printed depending on the type of ticket). All tickets are guaranteed in time for the event, this is backed by our 100% moneyback guarantee.",
          },
          {
            kind: 0,
            content:
              "With instant download, tickets are delivered, in most cases, within twenty minutes of the time you place your ticket order.",
          },
        ],
      },
      {
        issue: "What is a QR code?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "A QR Code is a two-dimensional barcode that is readable by smartphones. So, if you see a barcode like the one below this means your tickets will need to be displayed on a smartphone for entry into your event.",
          },
        ],
      },
      {
        issue: "Can you resend the link I need to download my E-tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Yes, we can resend e-ticket download instructions. This request can be made in several ways.  You can contact us by phone, by email or by webchat to make this request. Additionally, if you have access to your receipt, you will be able to access your tickets by clicking “Track My Order” and signing in with the email address used to place the order and either your pin or password.",
          },
          {
            kind: 0,
            content:
              "Please be sure to check any junk / spam folders if needed as incoming messages may be filtered there.",
          },
        ],
      },
      {
        issue: "How do I download my e-tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "To download your E-Tickets please take the following steps:",
          },
          {
            kind: 0,
            content:
              "1. Go to https://mtt.tickettransaction.com\n2. Enter your email address (Enter Email address on order!) and PIN (located in your receipt, around the 'Track My Order' button)  to sign in.\n3. Under Current Orders, click the large green button to start the download process.\n4. On the order summary page, click the large “Download and Print Your eTickets” or “View & Print” button.\n5. Your eTickets will appear in your browser; we recommend saving and printing the eTickets. If you aren’t able to see your eTickets you’ll want to make sure your browser’s pop-up blocker is disabled or you may try using a different browser.",
          },
          {
            kind: 0,
            content:
              "If you need to download Adobe Reader, you can do so, for free, here:",
          },
          {
            kind: 4,
            content: "http://www.adobe.com/products/reader.html",
          },
        ],
      },
      {
        issue:
          "Can I present my e-tickets or instant download tickets on my smart device?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "We are not affiliated with the venue and therefore cannot guarantee the e-tickets will scan from an electronic device. Typically, if the ticket has a rectangular barcode you will be required to print them. Unless it states differently on the ticket, if the barcode is a square, digitized one, you will need to present them on the phone. Either way, it is best to print out the tickets to ensure entry and consider a smart device as a 'backup' option.",
          },
          {
            kind: 0,
            content:
              "If you are unable to print your tickets at home, I would recommend that you ask a friend if they have a printer. If you do not know anyone who has a printer, you can always utilize a print shop (Staples, FedEx, Kinkos, etc.) or locate your local library to get the tickets printed.",
          },
        ],
      },
      {
        issue: "Can I print my tickets if they're mobile entry?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "If your tickets are mobile entry, then we can't guarantee the venue will be able to scan the QR codes unless they are displayed on a mobile phone. Plan ahead and make sure your smartphone is fully charged.",
          },
        ],
      },
      {
        issue: "Do I need to print my tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "If your tickets are not marked mobile entry, then we cannot guarantee that the venue will be able to scan the barcodes unless they are printed.",
          },
        ],
      },
      {
        issue:
          "How do I get my tickets that were electronically transferred to me?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "With electronic transfer, your ticket seller will be transferring the tickets into your possession using the name, email address, and phone number provided at the time of purchase. Your ticket seller will be sending you an email containing instructions on how to retrieve your tickets prior to the event. You will NOT print your tickets, but rather display them on your mobile device to gain entry to your event.\nWe recommend that you download the specified app and create an account so you are prepared to accept the transfer as soon as the tickets are made available.\nIf you don’t see this email, please check your junk/spam folder. If you are still unable to find it, please contact our customer support here. (<< Email here)",
          },
        ],
      },
    ],
  },
  {
    title: "Shipping",
    items: [
      {
        issue: "When can I expect my tickets to be shipped?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "You can check your receipt for an estimated ship date for your tickets, if not noted at the time of purchase. Ticket delivery may occur sooner than the date listed, depending on availability. Your tickets are always guaranteed to be delivered in time for the event, regardless of the delivery date provided. This is backed by our 100% moneyback guarantee. ",
          },
          {
            kind: 0,
            content:
              "Once your tickets ship, you will receive a tracking number via email. We encourage you to check your spam/junk/promotion folders, in addition to your primary inbox, to ensure you do not overlook the email containing the tracking number. This will better ensure you do not miss the delivery of your tickets. FedEx will make three delivery attempts over the course of the next three business days and usually requires a signature to release or leave the package.",
          },
        ],
      },
      {
        issue:
          "My tickets are shipping via “Express Delivery” Why haven’t I received my order?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              'Tickets are not always available for immediate delivery due to venue delays and restrictions set by promoters. "Express delivery" simply refers to expedited delivery by the courier once shipped. Typically, this is one (1) or two (2) business days but may vary, particularly for international delivery. All tickets are guaranteed in time for the event. This is backed up by our 100% moneyback guarantee.',
          },
          {
            kind: 0,
            content:
              "Once your tickets ship, you will receive a tracking number via email. We encourage you to check your spam/junk/promotion folders, in addition to your primary inbox, to ensure you do not overlook the email containing the tracking number. This will better ensure you do not miss the delivery of your tickets.",
          },
        ],
      },
      {
        issue: "How do I change my shipping address?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Updating the shipping address is typically not an issue as long as there is enough time. Please reach out to us asap via email or phone, so we can provide the updated information to your supplier's office. Your tickets will ship as soon as they become available. I would recommend you contact us by phone regarding any time sensitive requests. ",
          },
          {
            kind: 0,
            content:
              "Please note that all changes are internal and will not be visible in your order purchases on your account.",
          },
        ],
      },
      {
        issue: "Will I have to sign for my FedEx package?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Yes, a signature is typically required when FedEx attempts to deliver your tickets. We generally expect the sellers who list tickets on our exchange to require a signature for ticket delivery. This is to ensure that tickets (irreplaceable items) are physically received by someone, instead of being left out in the open. This may include an office or apartment manager if necessary.",
          },
          {
            kind: 0,
            content:
              "Fedex will not delivered to a PO Box and a physical address is required. Be sure to review and provide full address information to prevent any delays in delivery for incomplete details.",
          },
          {
            kind: 0,
            content:
              "Fedex will make three (3) attempts to deliver should you be unavailable upon initial delivery. Fedex will notify you of the attempt(s), typically leaving a door tag that may supplement your signature in person and / or provide the opportunity to pick up the package at a designated Fedex facility nearby.",
          },
          {
            kind: 0,
            content:
              "Should the package be undeliverable or not picked up, your package may be returned to your seller's office. If time permits, your tickets may be shipped back out to you for an additional delivery fee.",
          },
          {
            kind: 0,
            content:
              "Neither our website or the seller is responsible should tickets be undeliverable or returned and the event missed.",
          },
        ],
      },
      {
        issue:
          "Can I have my tickets delivered via email or held at Will Call instead of being shipped?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Unfortunately, while we understand that sometimes clients would prefer a different delivery method, such requests cannot be accommodated. All tickets are listed using the specific delivery format available as per the event organizers. Tickets may be available in multiple formats from the venue or box office (for example, hard-stock physical tickets vs. mobile entry ticket), however once selected and purchased, the ticket type cannot be changed. Tickets can only be provided to you in the method that they were originally purchased and listed as.  If you require a change of shipping address, please reach out to us by phone or by email to see if we can accommodate your request. ",
          },
        ],
      },
      {
        issue:
          "Why did I receive PDF tickets via mail rather than hard stock tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "In some cases, your seller selected to have tickets shipped because they expected to receive hard stock tickets for their primary source. At times, venues will change their form of delivery and provide e-tickets. Sellers, however, are still expected to fulfill the order via the delivery method selected at the time of purchase, whenever possible. Your tickets, whether in PDF format or hard stock, are guaranteed to be valid. This is backed up by our 100% moneyback guarantee.",
          },
        ],
      },
      {
        issue: "What is Near Term Special Delivery, and how does it work?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Near term special delivery means that the tickets will be delivered to you by one of the following methods:",
          },
          {
            kind: 1,
            content: "Email\nElectronic Transfer\nWill Call\nLocal Pick-Up",
          },
          {
            kind: 0,
            content:
              "Near term special delivery is generally listed when the event purchased for is happening within the next 48 hours, however sometimes there can be tickets listed with this method if the lister knows that there will be a 24-48 hour delay in the release of the tickets for distribution. Please note, the buyer does not have the choice of which method they prefer, and the above list is not inclusive of all the different ways the delivery can be fulfilled. You will be emailed with more information about the delivery of the tickets after your order has been processed and accepted. The supplier that listed your tickets is a ticket selling professional that does this for a living, and you can rest assured that you will be in good hands regardless of how the tickets get to you. Orders placed using near term special delivery are also still covered by all of our guarantees; tickets are always guaranteed to be valid and delivered in time for the event, or you will receive your money back.\n*PLEASE NOTE: If you have purchased tickets as a gift for someone else, or you are not the person going to the event, please make sure to contact us immediately so that we can take note of any name changes that may be needed for will call or local pick-up. Name changes are not always a guarantee, but generally are not an issue as long as we have enough time to accommodate them.",
          },
        ],
      },
    ],
  },
  {
    title: "Direct Transfer and AXS Mobile ID",
    items: [
      {
        issue: "What is Direct Transfer?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Direct Transfer is a form of mobile entry. This means the tickets will be transferred to you directly utilizing the official ticketing platform as determined by the organizers. This may be a team or venue account manager or simply the ticketing distribution platform (SeatGeek, AXS, Ticketmaster) and varies by event. You will need a smart mobile device to gain entry. This requirement cannot be controlled or waived.",
          },
          {
            kind: 0,
            content:
              "Once the tickets have been transferred to you, you will receive an email notification of the delivery, and the instructions needed to access your tickets. Please be sure to keep an eye on any spam/junk folders to ensure you don’t miss these instructions. If you need further assistance, you may reach out to us via phone or email.",
          },
        ],
      },
      {
        issue:
          "I am receiving an error message that my tickets have been accepted by another email. What should I do?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "This error means the ticket transfer was already accepted into an associated account, so you would need to locate which account. As the acceptance process is entirely user initiated, we have no way to know what account may have been utilized to complete and accept the transfer. Please check any and all possible Ticketmaster accounts that you or anyone with access to the invitation may have that could have accepted the tickets,even if it feels like an off chance.",
          },
          {
            kind: 0,
            content:
              "Recalling and retransferring the tickets in this case is not possible since they have already been claimed and ownership has changed. The transfer link will only allow the tickets to be accepted once and once the tickets are accepted the supplier no longer has access to the tickets.",
          },
        ],
      },
      {
        issue:
          "Can I gift or transfer my Electronic Transfer tickets to someone else?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Gifting or transferring mobile tickets to another party is typically not an issue. Once your ticket seller has transferred the tickets to you, you will need to instruct the recipient to download the corresponding app and either create or log in to their account. There will be an option to transfer your tickets within the app and you can simply send the tickets to their account.",
          },
          {
            kind: 0,
            content:
              "If you are unsure if your ticket has been transferred already, please let us know and we will check for you. If you need any assistance with accessing or transferring your tickets, please contact us for live support.",
          },
        ],
      },
      {
        issue: "How do I present Electronic Transfer tickets at the venue?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "You will display the tickets via an application on your smartphone to gain entry to your event.   Once the tickets have been transferred to you, you will receive an email notification of the delivery, and the instructions needed to access your tickets. Please be sure to keep an eye on any spam/junk folders to ensure you don’t miss these instructions.\nIf you are having trouble with the application, please contact the platform's customer service for assistance.",
          },
        ],
      },
    ],
  },
  {
    title: "Event Change and Issues",
    items: [
      {
        issue: "What should I do if my event has been canceled or rescheduled?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "If the event gets canceled entirely, we guarantee a full refund minus delivery fees. Your seller can let you know of any additional instructions, for example, if tickets need to be returned. If your event is postponed and will be rescheduled for a later date, your original tickets will remain valid for entry to the rescheduled date. If the venue reissues new tickets for the rescheduled event, the ticket seller will provide them to you.",
          },
        ],
      },
      {
        issue: "What should I do if my event is postponed?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "For postponed events, ticket access may become restricted after the originally scheduled date. If you have not printed or saved the tickets, please contact our customer support team for further assistance.",
          },
        ],
      },
      {
        issue: "Why can't I see my tickets even though it says they are ready?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Try both the 'View and Print' and 'Download and Print' buttons. Disable your pop-up blocker. Make sure Adobe Acrobat Reader is installed and up-to-date, especially if you receive an error saying the PDF is invalid.",
          },
        ],
      },
      {
        issue:
          "Can I view my tickets now without worrying that I won't be able to print them later? Is there a limit to how many times I can print them?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Once uploaded, your tickets remain available until your event. You can print or view them as many times as you'd like, whenever is convenient for you.",
          },
        ],
      },
      {
        issue: "What if I receive the wrong tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "If you believe that you received the incorrect tickets, please reach out to our customer support team here at your earliest convenience.",
          },
        ],
      },
      {
        issue: "Why is there a different name on my tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "It is common for a different name to appear on the tickets when buying from a ticket resale marketplace. The name you are seeing on the tickets is the name of the ticket seller who originally purchased the tickets. This will not affect your ability to enter your event.",
          },
        ],
      },
      {
        issue: "How can I change my shipping address?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "We provide your seller with your preferred shipping address the moment you order. If your shipping address changes, please contact our customer support as soon as possible and they will communicate with the ticket seller to get your address updated.",
          },
        ],
      },
      {
        issue:
          "Why haven't I received my tickets even though the delivery date has passed?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Our sellers list the date they plan to send the tickets. When there's a delay, it's often due to security restrictions to protect the integrity of the tickets. If your tickets are not already on their way, please notify our contact center so we can check up on your order! ",
          },
        ],
      },
      {
        issue: "Why is there a different price on my tickets?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Tickets sold on our website are sold at market value, i.e. how much the tickets were worth at that time. This can be different than the face value printed on the ticket. Many tickets increase in price as the event nears and tickets become harder to find.",
          },
        ],
      },
    ],
  },
  {
    title: "General Information",
    items: [
      {
        issue: "Do you have a guarantee on your site to protect the purchaser?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "Yes, we do! We have a 100% Money-Back Guarantee.\nThe website's 100% Money-Back Guarantee is as follows: \nYou will receive a refund for your tickets if: \nYour order was accepted but not delivered by the seller.\nYour order was accepted but not shipped in time for the event.\nYour tickets were not valid for entry.*",
          },
          {
            kind: 0,
            content:
              'Notes: \n*Verified proof must be provided in letter form from the venue. Written or stamped "voids" do not constitute verified proof." ',
          },
        ],
      },
      {
        issue: "Is my information secure?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "All User account pages are protected with Secure Socket Layer (SSL) encryption. Users are fully responsible for maintaining the confidentiality of their username and password. If needed, passwords can be changed at any time by following the prompt to reset the information. ",
          },
          {
            kind: 0,
            content:
              "Additionally, whenever personal information or other sensitive information (such as credit card data) is collected, we strive to protect that information by encrypting and transmitting that data to Site in a secure way. The provider is committed to not re-identifying sensitive information collected by Site.",
          },
          {
            kind: 0,
            content:
              "We do not sell or otherwise distribute your information to any third parties but your Fulfiller.",
          },
        ],
      },
      {
        issue: "Can I change/update my email address on my order?",
        answerParagraphs: [
          {
            kind: 0,
            content:
              "A new email address can affect the delivery of any current orders you may have. To ensure electronic tickets are not delivered to the wrong spot, the best choice is to reach out to our contact center.",
          },
        ],
      },
    ],
  },
];
