import React, { memo } from "react";
import {
  SearchDropdownPerformerCellName,
  SearchDropdownPerformerCellPortrait,
  SearchDropdownPerformerCellWrappler,
} from "./style";

const KTSearchDropdownPerformerCell = memo(({ performer, onClick }) => {
  const portrait =
    performer.icon ?? require("@/assets/img/icons/icon-artist-default@3x.png");

  return (
    <SearchDropdownPerformerCellWrappler
      onClick={() => {
        onClick(performer);
      }}
    >
      <SearchDropdownPerformerCellPortrait src={portrait} />
      <SearchDropdownPerformerCellName>
        {performer?.name ?? performer?.text?.name ?? ""}
      </SearchDropdownPerformerCellName>
    </SearchDropdownPerformerCellWrappler>
  );
});

export default KTSearchDropdownPerformerCell;
