import { addDays, endOfWeek, format, startOfWeek } from "date-fns";
import { getEvents } from "../../../services/events";
import * as actionTypes from "./constants";

const addShowsEventsAction = (events) => ({
  type: actionTypes.ADD_MORE_EVENTS,
  events: events,
});

const emptyAndAddShowsEventsAction = (events) => ({
  type: actionTypes.EMPTY_ADD_MORE_EVENTS,
  events: events,
});

export const getShowsEventsAction = (
  pagenum = 1,
  city = {},
  sortMode,
  cancelSource,
  categories,
  intervalType,
  startdt,
  enddt
) => {
  const getInterval = (intervalType, startdt, enddt) => {
    /**
     * 0: Any Dates
     * 1:This Weekend
     * 2:Next Weekend
     * 3:Next 7 Days
     * 4:Next 30 Days
     * 5:Next 60 Days
     * 6:Custom
     */
    const today = new Date();

    switch (intervalType) {
      case "0":
        return { startdt: undefined, enddt: undefined };
      case "1":
        const monday = startOfWeek(today, { weekStartsOn: 1 });
        const sunday = endOfWeek(today, { weekStartsOn: 1 });

        return {
          startdt: format(monday, "yyyy-MM-dd"),
          enddt: format(sunday, "yyyy-MM-dd"),
        };

      case "2":
        const nextMonday = addDays(startOfWeek(today, { weekStartsOn: 1 }), 7);
        const nextSunday = addDays(endOfWeek(today, { weekStartsOn: 1 }), 7);

        return {
          startdt: format(nextMonday, "yyyy-MM-dd"),
          enddt: format(nextSunday, "yyyy-MM-dd"),
        };
      case "3":
        const next7day = addDays(today, 7);

        return {
          startdt: format(today, "yyyy-MM-dd"),
          enddt: format(next7day, "yyyy-MM-dd"),
        };
      case "4":
        const next30day = addDays(today, 30);

        return {
          startdt: format(today, "yyyy-MM-dd"),
          enddt: format(next30day, "yyyy-MM-dd"),
        };

      case "5":
        const next60day = addDays(today, 60);

        return {
          startdt: format(today, "yyyy-MM-dd"),
          enddt: format(next60day, "yyyy-MM-dd"),
        };
      case "6":
        return {
          startdt: startdt instanceof Date ? format(startdt, "yyyy-MM-dd") : "",
          enddt: enddt instanceof Date ? format(enddt, "yyyy-MM-dd") : "",
        };

      default:
        break;
    }
    return { startdt: undefined, enddt: undefined };
  };

  const subcategory = categories.map((ele) => ele.keyWord).join(",");
  const interval = getInterval(intervalType, startdt, enddt);

  const category = "SHOWS";

  return (dispatch) => {
    getEvents(
      pagenum,
      city,
      sortMode,
      category,
      subcategory,
      interval.startdt,
      interval.enddt,
      (events) => {
        dispatch(getChangeShowsPagenumAction(pagenum));
        if (pagenum === 1) {
          dispatch(emptyAndAddShowsEventsAction(events));
        } else {
          dispatch(addShowsEventsAction(events));
        }
      },
      cancelSource
    );
  };
};

const changeShowsPagenum = (pagenum) => ({
  type: actionTypes.CHANGE_PAGENUM,
  pagenum: pagenum,
});

export const getChangeShowsPagenumAction = (pagenum) => {
  return (dispatch) => {
    dispatch(changeShowsPagenum(pagenum));
  };
};

const changeShowsLayoutMode = (layoutMode) => ({
  type: actionTypes.CHANGE_LAYOUT_MODE,
  layoutMode: layoutMode,
});

export const getChangeShowsLayoutModeAction = (layoutMode) => {
  return (dispatch) => {
    dispatch(changeShowsLayoutMode(layoutMode));
  };
};

// 修改排序
const changeShowsSortMode = (sortMode) => ({
  type: actionTypes.CHANGE_SORT_MODE,
  sortMode: sortMode,
});

export const getChangeShowsSortModeAction = (sortMode) => {
  return (dispatch) => {
    dispatch(changeShowsSortMode(sortMode));
  };
};

// 筛选分类
const changeShowsCategories = (categories) => ({
  type: actionTypes.CHANGE_CATEGORIES,
  categories: categories,
});

export const getChangeShowsCategoriesAction = (categories) => {
  return (dispatch) => {
    dispatch(changeShowsCategories(categories));
  };
};

// 时间段
const changeShowsTemporalInterval = (
  intervalType,
  startdt = null,
  enddt = null
) => ({
  type: actionTypes.CHANGE_TEMPORAL_INTERVAL,
  intervalType: intervalType,
  startdt: startdt,
  enddt: enddt,
});

export const getChangeShowsTemporalIntervalAction = (
  intervalType,
  startdt = null,
  enddt = null
) => {
  return (dispatch) => {
    dispatch(changeShowsTemporalInterval(intervalType, startdt, enddt));
  };
};
