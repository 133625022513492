import React, { memo } from "react";
import {
  SearchPerformerName,
  SearchPerformerPortrait,
  SearchPerformerWrapper,
} from "./style";

const KTSearchPerformer = memo(({ performer, onClick }) => {
  const portrait =
    performer.icon ?? require("@/assets/img/icons/icon-artist-default@3x.png");

  return (
    <SearchPerformerWrapper
      onClick={() => {
        onClick(performer);
      }}
    >
      <SearchPerformerPortrait src={portrait} />
      <SearchPerformerName>
        {performer?.name ?? performer?.text?.name ?? ""}
      </SearchPerformerName>
    </SearchPerformerWrapper>
  );
});

export default KTSearchPerformer;
