import styled from "styled-components";

export const ConcertInfoItemWrapper = styled.div`
  display: flex;
  width: 300px;
  height: 350px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
`;

export const ConcertInfoItemTopWrapper = styled.div`
  width: 300px;
  height: 220px;
  flex-shrink: 0;
  border-radius: 20px 20px 0px 0px;
  background: #d9d9d9;
  background-size: cover;
  background-position: center;

  .like {
    width: 28px;
    height: 28px;
    margin: 24px;
    cursor: pointer;
  }
`;

export const ConcertInfoItemBottomWrapper = styled.div`
  display: flex;
  width: 250px;
  padding: 24px 24px 34px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 0px 0px 20px 20px;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  margin-bottom: 1px;

  .name {
    display: flex;
    width: 252px;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    color: var(--icon-line-black, #19192a);
    font-family: Merriweather;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .numberOfEvents {
    color: #c8c8c8;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .numberInAddress {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;
