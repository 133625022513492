import React, { memo } from "react";
import { FilterContentWrapper } from "./style";
import KTFilterContentHeader from "@/components/filter/filter-content-header";
import KTFilterContentDateRange from "@/components/filter/filter-content-date-range";
import KTFilterCategories from "./c-components/categories";

import { Affix } from "antd";
import { allEventsFilterCategorys } from "../../../../../../common/category/category-data";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getChangeHomeCategoriesAction,
  getChangeHomeTemporalIntervalAction,
} from "../../../../store/actionCreators";
import { homeFilterDateIntervals } from "../../../../local-data";

const KTFilterContent = memo(({ onClose, windowWidth }) => {
  const dispatch = useDispatch();
  const { categories, intervalType, startdt, enddt } = useSelector(
    (state) => ({
      categories: state.getIn(["home", "categories"]),
      intervalType: state.getIn(["home", "intervalType"]),
      startdt: state.getIn(["home", "startdt"]),
      enddt: state.getIn(["home", "enddt"]),
    }),
    shallowEqual
  );

  function onChangeSelectdCategories(categories) {
    dispatch(getChangeHomeCategoriesAction(categories));
  }

  function onChangeIntervalValue(ele) {
    dispatch(
      getChangeHomeTemporalIntervalAction(ele.value, ele.startdt, ele.enddt)
    );
  }

  function onResetClick() {
    dispatch(getChangeHomeCategoriesAction([]));
    dispatch(getChangeHomeTemporalIntervalAction("0", undefined, undefined));
  }

  return (
    <FilterContentWrapper>
      <Affix offsetTop={0}>
        <KTFilterContentHeader
          onClose={onClose}
          onReset={onResetClick}
          windowWidth={windowWidth}
        />
      </Affix>

      <KTFilterCategories
        categories={allEventsFilterCategorys}
        selectedCategories={categories}
        onChangeSelectdCategories={onChangeSelectdCategories}
      />
      <KTFilterContentDateRange
        windowWidth={windowWidth}
        dateIntervals={homeFilterDateIntervals}
        startdt={startdt}
        enddt={enddt}
        selectedIntervalValue={intervalType}
        didIntervalValueChange={onChangeIntervalValue}
      />
      {/* <KTFilterContentVenue
        venues={musicFilterVenues}
        windowWidth={windowWidth} 
      /> */}
    </FilterContentWrapper>
  );
});

export default KTFilterContent;
