import React, { memo, useCallback, useState } from "react";
import { SearchInputCellWrapper } from "./style";
import { Divider, Input } from "antd";

const KTSearchInputCell = memo(({ onValueChange }) => {
  const [inputValue, setInputValue] = useState("");

  const debounce = useCallback((callback, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback.apply(this, args);
      }, delay);
    };
  }, []);

  const handleInputChange = useCallback(
    debounce((value) => {
      onValueChange(value);
    }, 300),
    [debounce]
  );

  function onInputValueChange(e) {
    const value = e.target.value;
    setInputValue(value);
    handleInputChange(value);
  }

  return (
    <SearchInputCellWrapper>
      <Input
        value={inputValue}
        onChange={onInputValueChange}
        className="input"
        bordered={false}
        placeholder={"Search Location"}
        allowClear
      />
      <Divider
        style={{
          margin: "0",
        }}
      />
    </SearchInputCellWrapper>
  );
});

export default KTSearchInputCell;
