/**
 * 0- default, popularity, 1 - date , 2 - price low to hight, 3 - price high to low
 */
export const showsAllEventsSortModes = [
  { title: "Sort by date", sort: 1 },
  { title: "Sort by Popularity", sort: 0 },
  { title: "Price low to high", sort: 2 },
  { title: "Price high to low", sort: 3 },
];

/**
 * 0: Any Dates
 * 1:This Weekend
 * 2:Next Weekend
 * 3:Next 7 Days
 * 4:Next 30 Days
 * 5:Next 60 Days
 * 6:Custom
 */
export const showsFilterDateIntervals = [
  {
    title: "Any Dates",
    value: "0",
  },
  {
    title: "This Weekend",
    value: "1",
  },
  {
    title: "Next Weekend",
    value: "2",
  },
  {
    title: "Next 7 Days",
    value: "3",
  },
  {
    title: "Next 30 Days",
    value: "4",
  },
  {
    title: "Next 60 Days",
    value: "5",
  },
  {
    title: "Custom",
    value: "6",
  },
];

export const showsGenres = [
  {
    title: "All Shows",
    normalImageName: require("@/assets/img/shows/genres/icon-all-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-all-selected@3x.png"),
    keyWord: "",
  },

  {
    title: "Broadway",
    normalImageName: require("@/assets/img/shows/genres/icon-broadway-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-broadway-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-broadway-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-broadway-selected.png"),
    keyWord: "Broadway",
  },
  {
    title: "Musical\n/Play",
    normalImageName: require("@/assets/img/shows/genres/icon-musical-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-musical-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-musical-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-musical-selected.png"),
    keyWord: "Musical,Play",
  },
  {
    title: "Off-Broadway",
    normalImageName: require("@/assets/img/shows/genres/icon-offboradway-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-offboradway-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-offbroadway-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-offbroadway-selected.png"),
    keyWord: "Electronica/Techno",
  },
  {
    title: "Comedy",
    normalImageName: require("@/assets/img/shows/genres/icon-comedy-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-comedy-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-comedy-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-comedy-selected.png"),
    keyWord: "Comedy",
  },
  {
    title: "Dance",
    normalImageName: require("@/assets/img/shows/genres/icon-dance-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-dance-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-dance-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-dance-selected.png"),
    keyWord: "Dance",
  },
  {
    title: "Cirque du soleil",
    normalImageName: require("@/assets/img/shows/genres/icon-cds-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-cds-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-cds-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-cds-selected.png"),
    keyWord: "Cirque du soleil",
  },
  {
    title: "Ballet",
    normalImageName: require("@/assets/img/shows/genres/icon-ballet-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-ballet-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-ballet-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-ballet-selected.png"),
    keyWord: "Ballet",
  },
  {
    title: "Children\n/Family",
    normalImageName: require("@/assets/img/shows/genres/icon-children-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-children-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-children-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-children-selected.png"),
    keyWord: "Children,Family",
  },
  {
    title: "Magic Shows",
    normalImageName: require("@/assets/img/shows/genres/icon-magic-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-magic-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-magic-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-magic-selected.png"),
    keyWord: "Magic Shows",
  },
  {
    title: "Circus",
    normalImageName: require("@/assets/img/shows/genres/icon-circus-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-circus-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-circus-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-circus-selected.png"),
    keyWord: "Circus",
  },
  {
    title: "Fairs\n/Festivals",
    normalImageName: require("@/assets/img/shows/genres/icon-fairs-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-fairs-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-fairs-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-fairs-selected.png"),
    keyWord: "Fairs,Festivals",
  },
  {
    title: "Opera",
    normalImageName: require("@/assets/img/shows/genres/icon-opera-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-opera-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-opera-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-opera-selected.png"),
    keyWord: "Opera",
  },
  {
    title: "Film",
    normalImageName: require("@/assets/img/shows/genres/icon-film-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-film-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-film-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-film-selected.png"),
    keyWord: "Film",
  },
  {
    title: "Las Vegas Shows",
    normalImageName: require("@/assets/img/shows/genres/icon-lasvegas-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-lasvegas-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-lasvegas-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-lasvegas-selected.png"),
    keyWord: "Las Vegas Shows",
  },
  {
    title: "Museum\n/Exhibit",
    normalImageName: require("@/assets/img/shows/genres/icon-museum-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-museum-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-museum-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-museum-selected.png"),
    keyWord: "Museum,Exhibit",
  },
  {
    title: "Religious",
    normalImageName: require("@/assets/img/shows/genres/icon-religious-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-religious-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-religious-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-religious-selected.png"),
    keyWord: "Religious",
  },
  {
    title: "Lecture",
    normalImageName: require("@/assets/img/shows/genres/icon-lecture-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-lecture-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-lecture-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-lecture-selected.png"),
    keyWord: "Lecture",
  },
  {
    title: "Taped Programs",
    normalImageName: require("@/assets/img/shows/genres/icon-tp-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-tp-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-tp-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-tp-selected.png"),
    keyWord: "Taped Programs",
  },
  {
    title: "Adult",
    normalImageName: require("@/assets/img/shows/genres/icon-adult-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-adult-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-adult-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-adult-selected.png"),
    keyWord: "Adult",
  },
  {
    title: "Other",
    normalImageName: require("@/assets/img/shows/genres/icon-other-unselected@3x.png"),
    selectedImageName: require("@/assets/img/shows/genres/icon-other-selected@3x.png"),
    d_normalImageName: require("@/assets/img/shows/d-genres/icon-other-unselected.png"),
    d_selectedImageName: require("@/assets/img/shows/d-genres/icon-other-selected.png"),
    keyWord: "Other",
  },
];
