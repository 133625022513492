import styled from "styled-components";

export const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 120px;
  margin: 100px 200px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    margin: 80px 100px;
    gap: 50px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    margin: 50px;
    gap: 50px;
  }

  @media screen and (max-width: 480px) {
    margin: 40rem 20rem;
    gap: 30rem;
  }
`;

export const AboutUsDivider = styled.div`
  height: 1px;
  background: #c8c8c8;
`;
