import React, { memo } from "react";
import {
  AppHeaderMobileSearchAddressTitle,
  AppHeaderMobileSearchAddressWrapper,
} from "./style";
import { ReactComponent as AddressIcon } from "@/assets/img/svg/icon-address.svg";
import { shallowEqual, useSelector } from "react-redux";

const KTAppHeaderMobileSearchAddress = memo(({ onClick }) => {
  const { city } = useSelector(
    (state) => ({
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  let cityMessage = "Any Location";

  if (typeof city === "object" && Object.keys(city).length > 0) {
    cityMessage = `${city.postalCode ?? city.postal_code}, ${
      city.name ?? city.city ?? ""
    }, ${city.state}, ${city.country}`;
  }

  return (
    <AppHeaderMobileSearchAddressWrapper onClick={onClick}>
      <AddressIcon className="icon" />
      <AppHeaderMobileSearchAddressTitle>
        {cityMessage}
      </AppHeaderMobileSearchAddressTitle>
    </AppHeaderMobileSearchAddressWrapper>
  );
});

export default KTAppHeaderMobileSearchAddress;
