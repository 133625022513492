import React, { memo, useState } from "react";
import { PromoCardWrappler } from "./style";
import { withRouter } from "react-router-dom";

const KTPromoCard = memo((props) => {
  const [isOnMouseOver, setIsOnMouseOver] = useState(false);

  const { item, itemWidth, itemHeight, isSmall } = props;

  function onMouseOver() {
    setIsOnMouseOver(true);
  }

  function onMouseOut() {
    setIsOnMouseOver(false);
  }

  function onClick() {
    props.history.push(item.path);
  }

  const srcName = isSmall
    ? isOnMouseOver
      ? item.poster
      : item.poster
    : isOnMouseOver
    ? item.imageHoverName
    : item.imageName;
  return (
    <PromoCardWrappler
      style={{
        width: itemWidth,
        height: itemHeight,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage:
          "url(" + require(`@/assets/img/home/middle-categorys/${srcName}`) + ")",
      }}
      onMouseOver={() => onMouseOver()}
      onMouseOut={() => onMouseOut()}
      onClick={onClick}
    >
      {/* <img
        src={require(`../../assets/img/promo-images/${srcName}`)}
        alt=""
        onMouseOver={() => onMouseOver()}
        onMouseOut={() => onMouseOut()}
      ></img> */}
    </PromoCardWrappler>
  );
});

export default withRouter(KTPromoCard);

// export default class KTPromoCard extends PureComponent {
//   constructor(props) {
//     super(props);

//     this.state = {
//       isOnMouseOver: false,
//     };
//   }
//   render() {
//     const { item } = this.props;
//     const srcName = this.state.isOnMouseOver
//       ? item.imageActiveName
//       : item.imageName;
//     return (
//       <PromoCardWrappler>
//         <img
//           src={require(`../../assets/img/promo-images/${srcName}`)}
//           alt=""
//           onMouseOver={(item) => this.onMouseOver(item)}
//           onMouseOut={(item) => this.onMouseOut(item)}
//         ></img>
//       </PromoCardWrappler>
//     );
//   }

// }
