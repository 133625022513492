import React, { memo } from "react";
import {
  CategoriesSilderBarTitle,
  CategoriesSilderBarTitleBottomLine,
  CategoriesSilderBarTitleWrapper,
  CategoriesSilderBarWrapper,
  CategoriesSwiperSlideIcon,
  CategoriesSwiperSlideTitle,
  CategoriesSwiperSlideWrapper,
} from "./style";
import { Swiper, SwiperSlide } from "swiper/react";

const KTCategoriesSilderBar = memo(
  ({
    title = "",
    categories = [],
    selectedCategories = [],
    onChangeSelectdCategories,
  }) => {
    function onCategoryClick(category) {
      const selectedTitles = selectedCategories.map((ele) => ele.title);
      if (category.keyWord.length === 0) {
        // All
        if (selectedCategories.length === categories.length - 1) {
          onChangeSelectdCategories([]);
        } else {
          onChangeSelectdCategories([...categories.slice(1)]);
        }
      } else {
        if (selectedTitles.includes(category.title)) {
          onChangeSelectdCategories(
            selectedCategories.filter((ele) => ele.title !== category.title)
          );
        } else {
          onChangeSelectdCategories([category, ...selectedCategories]);
        }
      }
    }

    const slides = categories.map((ele) => {
      const selectedTitles = selectedCategories.map((ele) => ele.title);
      let selected = selectedTitles.includes(ele.title);
      if (ele.keyWord.length === 0) {
        // All
        selected = selectedCategories.length === categories.length - 1;
      }

      const imgSrc = selected ? ele.selectedImageName : ele.normalImageName;

      return (
        <SwiperSlide
          className="ktSwiperSlide"
          onClick={() => onCategoryClick(ele)}
        >
          <CategoriesSwiperSlideWrapper>
            <CategoriesSwiperSlideIcon src={imgSrc} />
            <CategoriesSwiperSlideTitle selected={selected}>
              {ele.title}
            </CategoriesSwiperSlideTitle>
          </CategoriesSwiperSlideWrapper>
        </SwiperSlide>
      );
    });

    return (
      <CategoriesSilderBarWrapper>
        <CategoriesSilderBarTitleWrapper>
          <CategoriesSilderBarTitle>{title}</CategoriesSilderBarTitle>
          <CategoriesSilderBarTitleBottomLine />
        </CategoriesSilderBarTitleWrapper>
        <Swiper
          className="ktSwiper"
          slidesPerView={"auto"}
          spaceBetween={"7rem"}
        >
          {slides}
        </Swiper>
      </CategoriesSilderBarWrapper>
    );
  }
);

export default KTCategoriesSilderBar;
