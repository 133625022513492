import React, { memo } from "react";
import { AppHeaderNoFeesWrapper } from "./style";

const KTAppHeaderNoFees = memo(() => {
  return (
    <AppHeaderNoFeesWrapper>No Fees, Better Price!
    </AppHeaderNoFeesWrapper>
  );
});

export default KTAppHeaderNoFees;
