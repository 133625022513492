import React, { memo, useState } from "react";
import { ClassifySubMultipleDesktopWrapper } from "./style";
import classNames from "classnames";
import { currValue } from "@/utils";

const KTClassifySubMultipleDesktop = memo(
  ({ items, windowWidth = window.screen.width, hiddenLine = false }) => {
    const [selectedItems, setSelectedItems] = useState([]);

    function onclickItem(item) {
      if (selectedItems.includes(item.title)) {
        setSelectedItems(
          selectedItems.filter((element) => element !== item.title)
        );
      } else {
        setSelectedItems([item.title, ...selectedItems]);
      }
    }

    const childrens = items.map((item) => {
      return (
        <div
          onClick={() => onclickItem(item)}
          className={classNames("title", {
            avtive: selectedItems.includes(item.title),
          })}
        >
          {item.title}
        </div>
      );
    });

    return (
      <ClassifySubMultipleDesktopWrapper
        style={{
          margin: `0 ${currValue(50, 100, windowWidth)}`,
          paddingBottom: "40px",
          borderBottom: hiddenLine
            ? ""
            : "1px solid var(--dvdr-stroke-f2, #f2f2f2)",
        }}
      >
        {childrens}
      </ClassifySubMultipleDesktopWrapper>
    );
  }
);

export default KTClassifySubMultipleDesktop;
