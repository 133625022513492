import styled from "styled-components";

export const FilterContentHeaderWrapper = styled.div`
  padding: 22rem 20rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  border-radius: 20rem 20rem 0px 0px;
  background: #fff;
  box-shadow: 0px 4rem 20rem 0px rgba(242, 242, 242, 0.4);

  .reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    color: #003594;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;
  }

  .done {
    display: flex;

    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #19192a;
    text-align: center;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    cursor: pointer;
  }

  @media screen and (min-width: 481px) {
    padding: 22px 50px;

    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 4px 20px 0px rgba(242, 242, 242, 0.4);

    .reset {
      font-size: 14px;
    }

    .done {
      font-size: 14px;
    }
  }
`;
