import styled from "styled-components";

export const TeamListHorizontalWrapper = styled.div`
  .ktSwiper {
    
  }

  .ktSwiperSlide {
    width: fit-content;
    height: fit-content;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #ececec;
  }

  @media screen and (min-width: 481px) {
    .ktSwiper {
      padding: 0 60px 0 100px;
    }
  }
`;

export const TeamListHorizontalNavigationWrapper = styled.div`
  margin: 60px 0;
  padding: 0 100px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-end;
  gap: 40px;

  .swiperButtonPrev {
    width: 30px;
    height: 30px;
    stroke: #4d4d4d;
    cursor: pointer;
  }

  .swiperButtonPrev.disabled {
    stroke: #c8c8c8;
  }

  .swiperButtonNext {
    width: 30px;
    height: 30px;
    stroke: #4d4d4d;
    cursor: pointer;
  }

  .swiperButtonNext.disabled {
    stroke: #c8c8c8;
  }
`;
