import React, { memo } from "react";
import { MAMenuAboutWrapper } from "./style";
import { mobileAppFooterHelpfulLinks } from "@/common/local-data";
import { withRouter } from "react-router-dom";

const KTMAMenuAbout = memo((props) => {
  const { onClose } = props;
  return (
    <MAMenuAboutWrapper>
      {mobileAppFooterHelpfulLinks.map((item) => {
        return (
          <div
            className="item"
            key={item.title}
            onClick={() => {
              onClose();
              props.history.push(item.link);
            }}
          >
            {item.title}
          </div>
        );
      })}
    </MAMenuAboutWrapper>
  );
});

export default withRouter(KTMAMenuAbout);
