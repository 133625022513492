import { format, parseISO } from "date-fns";

export function dateString(datetime, formatted, toLower = false) {
  if (typeof datetime !== "string" || datetime.length === 0) {
    return "";
  }
  const dateObject = parseISO(datetime);

  const result = format(dateObject, formatted);
  return toLower ? result.toLowerCase() : result;
}
