import React, { memo } from "react";
import {
  SearchGroupHeaderBarExploreArrow,
  SearchGroupHeaderBarExploreTitle,
  SearchGroupHeaderBarExploreWrapper,
  SearchGroupHeaderBarTitle,
  SearchGroupHeaderBarWrapper,
} from "./style";

const KTSearchGroupHeaderBar = memo(({ title, onExploreMoreClick }) => {
  return (
    <SearchGroupHeaderBarWrapper>
      <SearchGroupHeaderBarTitle>{title}</SearchGroupHeaderBarTitle>
      <SearchGroupHeaderBarExploreWrapper onClick={onExploreMoreClick}>
        <SearchGroupHeaderBarExploreTitle>
          Explore More{" "}
        </SearchGroupHeaderBarExploreTitle>
        <SearchGroupHeaderBarExploreArrow
          src={require("@/assets/img/icons/icon-arrow-right@3x.png")}
        />
      </SearchGroupHeaderBarExploreWrapper>
    </SearchGroupHeaderBarWrapper>
  );
});

export default KTSearchGroupHeaderBar;
