import styled from "styled-components";

export const ConcertItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 16rem;

  .portrait {
    width: 80rem;
    height: 80rem;
  }

  .name {
    width: 68rem;
    color: var(--icon-4d-black, #4d4d4d);
    text-align: center;
    font-family: Inter;
    font-size: 13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 128.523%; /* 16.708px */
  }
`;
