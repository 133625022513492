import styled from "styled-components";

export const AppFooterCopyrightBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  min-height: 120px;
  gap: 20px;
`;

export const AppFooterCopyrightBarLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 56px;

  @media screen and (max-width: 1023px) and (min-width: 481px) {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
`;

export const AppFooterCopyrightBarLogo = styled.img`
  width: 74px;
  height: 40px;
  flex-shrink: 0;
`;

export const AppFooterCopyrightBarCopyright = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 1023px) and (min-width: 481px) {
    font-size: 12px;
  }
`;

export const AppFooterCopyrightBarRightWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 20px;
`;

export const AppFooterCopyrightSocialContact = styled.img`
  width: 40px;
  height: 40px;

  cursor: pointer;
  user-select: none;
`;
