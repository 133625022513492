import { getCategoryPlaceholderImagePath } from "../common/category/category-placeholder";
import { GLOBAL_PAGESIZE } from "../common/constants";
import request from "./request";

export function getEvents(
  pagenum = "1",
  city = {},
  sortMode,
  category,
  subcategory,
  startdt,
  enddt,
  callback,
  cancelSource
) {
  console.log("category: ", category, "subcategory: ", subcategory);
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getEvents",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        category: category,
        subcategory: subcategory,
        lat: city.lat ?? city.latitude ?? "",
        long: city.long ?? city.longitude ?? "",
        sort: sortMode.sort,
        startdt: startdt,
        enddt: enddt,
      },
      config
    )
    .then((res) => {
      let tn_events = res.tn_events;
      const kt_events = res.kt_events;

      if (Array.isArray(tn_events) === false) {
        callback([]);
        return;
      }

      let kt_event_map = new Map();
      kt_events.forEach((element) => {
        kt_event_map.set(element.id, element);
      });

      tn_events.forEach((element) => {
        if (kt_event_map.has(element.id)) {
          element.kt_info = kt_event_map.get(element.id);
        }
        // 匹配默认图
        const res = getCategoryPlaceholderImagePath(
          element.defaultCategory,
          element.id
        );
        element.kt_placeholder = res.background;
        element.kt_desktop = res.desktop;
        element.kt_mark = res.mark;
        element.kt_desktop_icon = res.desktopIcon;
      });

      console.log("tn_events: ", tn_events);

      if (typeof callback === "function") {
        callback(tn_events);
      }
    });
}

export function getEventsOfKeywords(
  pagenum = "1",
  keywords = "",
  callback,
  cancelSource
) {
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getEvents",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        keywords: keywords,
      },
      config
    )
    .then((res) => {
      let tn_events = res.tn_events;
      const kt_events = res.kt_events;

      if (Array.isArray(tn_events) === false) {
        callback([]);
        return;
      }

      let kt_event_map = new Map();
      kt_events.forEach((element) => {
        kt_event_map.set(element.id, element);
      });

      tn_events.forEach((element) => {
        if (kt_event_map.has(element.id)) {
          element.kt_info = kt_event_map.get(element.id);
        }
        // 匹配默认图
        const res = getCategoryPlaceholderImagePath(
          element.defaultCategory,
          element.id
        );
        element.kt_placeholder = res.background;
        element.kt_desktop = res.desktop;
        element.kt_mark = res.mark;
        element.kt_desktop_icon = res.desktopIcon;
      });

      if (typeof callback === "function") {
        callback(tn_events);
      }
    });
}

export function getEventsOfCategory(
  pagenum = "1",
  city = {},
  category = "",
  subcategory = "",
  keywords ="",
  callback,
  cancelSource
) {
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getEvents",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        lat: city.lat ?? city.latitude ?? "",
        long: city.long ?? city.longitude ?? "",
        keywords: keywords,
        category: category,
        subcategory: subcategory,
      },
      config
    )
    .then((res) => {
      let tn_events = res.tn_events;
      const kt_events = res.kt_events;

      if (Array.isArray(tn_events) === false) {
        callback([]);
        return;
      }

      let kt_event_map = new Map();
      kt_events.forEach((element) => {
        kt_event_map.set(element.id, element);
      });

      tn_events.forEach((element) => {
        if (kt_event_map.has(element.id)) {
          element.kt_info = kt_event_map.get(element.id);
        }
        // 匹配默认图
        const res = getCategoryPlaceholderImagePath(
          element.defaultCategory,
          element.id
        );
        element.kt_placeholder = res.background;
        element.kt_desktop = res.desktop;
        element.kt_mark = res.mark;
        element.kt_desktop_icon = res.desktopIcon;
      });

      if (typeof callback === "function") {
        callback(tn_events);
      }
    });
}

export function getEventsOfPerformer(
  pagenum = "1",
  city = {},
  performerid = "",
  callback,
  cancelSource
) {
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getEvents",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        lat: city.lat ?? city.latitude ?? "",
        long: city.long ?? city.longitude ?? "",
        performerid: performerid,
      },
      config
    )
    .then((res) => {
      let tn_events = res.tn_events;
      const kt_events = res.kt_events;

      if (Array.isArray(tn_events) === false) {
        callback([]);
        return;
      }

      let kt_event_map = new Map();
      kt_events.forEach((element) => {
        kt_event_map.set(element.id, element);
      });

      tn_events.forEach((element) => {
        if (kt_event_map.has(element.id)) {
          element.kt_info = kt_event_map.get(element.id);
        }
        // 匹配默认图
        const res = getCategoryPlaceholderImagePath(
          element.defaultCategory,
          element.id
        );
        element.kt_placeholder = res.background;
        element.kt_desktop = res.desktop;
        element.kt_mark = res.mark;
        element.kt_desktop_icon = res.desktopIcon;
      });

      if (typeof callback === "function") {
        callback(tn_events);
      }
    });
}

export function getEventsOfVenue(
  pagenum = "1",
  city = {},
  venueid = "",
  callback,
  cancelSource
) {
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getEvents",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        lat: city.lat ?? city.latitude ?? "",
        long: city.long ?? city.longitude ?? "",
        venueid: venueid,
      },
      config
    )
    .then((res) => {
      let tn_events = res.tn_events;
      const kt_events = res.kt_events;

      if (Array.isArray(tn_events) === false) {
        callback([]);
        return;
      }

      let kt_event_map = new Map();
      kt_events.forEach((element) => {
        kt_event_map.set(element.id, element);
      });

      tn_events.forEach((element) => {
        if (kt_event_map.has(element.id)) {
          element.kt_info = kt_event_map.get(element.id);
        }
        // 匹配默认图
        const res = getCategoryPlaceholderImagePath(
          element.defaultCategory,
          element.id
        );
        element.kt_placeholder = res.background;
        element.kt_desktop = res.desktop;
        element.kt_mark = res.mark;
        element.kt_desktop_icon = res.desktopIcon;
      });

      if (typeof callback === "function") {
        callback(tn_events);
      }
    });
}

export function getEventsOfTrending(
  pagenum = "1",
  category = "",
  subcategory = "",
  city = {},
  callback,
  cancelSource
) {
  console.log(category, subcategory);
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getTopSelling",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        category: category,
        subcategory: subcategory,
        lat: city.lat ?? city.latitude ?? "",
        long: city.long ?? city.longitude ?? "",
      },
      config
    )
    .then((res) => {
      let tn_events = res.tn_TopSellingEvents;
      const kt_events = res.kt_events;

      if (Array.isArray(tn_events) === false) {
        callback([]);
        return;
      }

      let kt_event_map = new Map();
      kt_events.forEach((element) => {
        kt_event_map.set(element.id, element);
      });

      tn_events.forEach((element) => {
        if (kt_event_map.has(element.id)) {
          element.kt_info = kt_event_map.get(element.id);
        }
        // 匹配默认图
        const res = getCategoryPlaceholderImagePath(
          element.defaultCategory,
          element.id
        );
        element.kt_placeholder = res.background;
        element.kt_desktop = res.desktop;
        element.kt_mark = res.mark;
        element.kt_desktop_icon = res.desktopIcon;
      });

      if (typeof callback === "function") {
        callback(tn_events);
      }
    });
}
