import styled from "styled-components";

export const FilterMobBarButtonWrapper = styled.div``;

export const FilterMobBarButtonDrapItem = styled.div`
  color: #8c8c8c;
  text-align: left;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 200px;
  margin: 12px 24px;
`;

