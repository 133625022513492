import { styled } from "styled-components";

export const EventCellMobileListWrapper = styled.div`
  display: flex;
  padding: 20rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 10rem;

  width: calc(100% - 80rem);

  border-radius: 20rem;
  background: #fff;
  box-shadow: 0px 6rem 20rem 0px rgba(242, 242, 242, 0.6);

  position: relative;
`;

export const EventCellMobileListMark = styled.img`
  position: absolute;
  right: 0rem;
  bottom: 0rem;
  width: 160rem;
  height: 160rem;
  flex-shrink: 0;
  z-index: 1;
  object-fit: cover;
`;

export const EventCellMobileListDateTime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14rem;
  min-height: 17rem;

  z-index: 2;

  .date {
    color: var(--icon-4d-black, #19192a);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .time {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .week {
    color: var(--icon-4d-black, #4d4d4d);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .divider {
    color: var(--icon-4d-black, #c8c8c8);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EventCellMobileListVenue = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 17rem;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  width: 240rem;

  height: 34rem;

  z-index: 2;
`;

export const EventCellMobileListTitle = styled.div`
  height: 66rem;
  flex-direction: column;
  justify-content: center;

  width: 240rem;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  color: #000;
  font-family: Merriweather Black;
  font-size: 15rem;
  font-style: normal;
  font-weight: 900;
  line-height: 22rem;

  z-index: 2;
`;

export const EventCellMobileListPrice = styled.div`
  border-radius: 20rem;
  border: 1.5rem solid #fff;
  background: #19192a;
  padding: 6rem 16rem;

  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  z-index: 2;
`;
