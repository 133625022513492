import styled from "styled-components";

export const EventCellSimpleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  height: 232px;

  padding: 24px 30px;

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(226, 23, 55, 0.06);

  gap: 20px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  .icon {
    width: 28px;
    height: 28px;
    stroke: #19192a;
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  .address {
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: var(--tag-stoke-8c, #8c8c8c);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 36px;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: #000;
    font-family: Merriweather Black;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    height: 66px;
  }

  .buyTicket {
    padding: 10px 30px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 20px;
    border: 2px solid #fff;
    background: #000;
    height: 18px;

    cursor: pointer;
  }
`;
