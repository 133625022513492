import React, { memo, useContext } from "react";
import {
  MLyoutModeBarBottomWrapper,
  MLyoutModeBarLeftAddressImage,
  MLyoutModeBarLeftAddressTitle,
  MLyoutModeBarLeftAddressWrapper,
  MLyoutModeBarLeftWrapper,
  MLyoutModeBarRightContent,
  MLyoutModeBarTopNoEvents,
  MLyoutModeBarWrapper,
} from "./style";

import { ReactComponent as CardLayoutIcon } from "@/assets/img/svg/icon-card-layout.svg";
import { ReactComponent as ListLayoutIcon } from "@/assets/img/svg/icon-list-layout.svg";
import { LAYOUT_MODE_CARD, LAYOUT_MODE_LIST } from "../../../common/constants";
import { shallowEqual, useSelector } from "react-redux";
import KTSwitchCityOtherDropdown from "../../dropdown/switch-city-other-dropdown";
import KTSwitchCityDtpDropdown from "../../dropdown/switch-city-dtp-dropdown";
import KTWindowSizeContext from "../../../common/context/windowSizeContext";

const KTMLyoutModeBar = memo(
  ({
    layoutMode,
    didChangeLayoutMode,
    hiddenLayoutMode = false,
    showNoEventsPrompt = false,
  }) => {
    const windowWidth = useContext(KTWindowSizeContext)?.windowSize?.width;

    const { city } = useSelector(
      (state) => ({
        city: state.getIn(["global", "city"]),
      }),
      shallowEqual
    );

    let cityMessage = "Any Location";

    if (typeof city === "object" && Object.keys(city).length > 0) {
      const name = city.name ?? city.city ?? "";
      const state = city.state ?? "";
      if (name.length === 0 || state.length === 0) {
        cityMessage = `${name}${city.state}`;
      } else {
        cityMessage = `${name}, ${city.state}`;
      }
    }

    const addressCom = (
      <MLyoutModeBarLeftAddressWrapper>
        <MLyoutModeBarLeftAddressImage
          src={require("@/assets/img/icons/icon-location-blue.png")}
        />
        <MLyoutModeBarLeftAddressTitle>
          {cityMessage}
        </MLyoutModeBarLeftAddressTitle>
      </MLyoutModeBarLeftAddressWrapper>
    );

    return (
      <MLyoutModeBarWrapper>
        {showNoEventsPrompt ? (
          <MLyoutModeBarTopNoEvents>
           There is no event near
          </MLyoutModeBarTopNoEvents>
        ) : null}

        <MLyoutModeBarBottomWrapper>
          <MLyoutModeBarLeftWrapper>
            {windowWidth > 480 ? (
              <KTSwitchCityDtpDropdown>{addressCom}</KTSwitchCityDtpDropdown>
            ) : (
              <KTSwitchCityOtherDropdown>
                {addressCom}
              </KTSwitchCityOtherDropdown>
            )}
          </MLyoutModeBarLeftWrapper>
          {hiddenLayoutMode ? null : (
            <MLyoutModeBarRightContent>
              {layoutMode === LAYOUT_MODE_LIST
                ? [
                    <ListLayoutIcon
                      className="layoutModel layoutModelActive"
                      onClick={() => didChangeLayoutMode(LAYOUT_MODE_CARD)}
                    />,
                  ]
                : [
                    <CardLayoutIcon
                      className="layoutModel layoutModelActive"
                      onClick={() => didChangeLayoutMode(LAYOUT_MODE_LIST)}
                    />,
                  ]}
            </MLyoutModeBarRightContent>
          )}
        </MLyoutModeBarBottomWrapper>
      </MLyoutModeBarWrapper>
    );
  }
);

export default KTMLyoutModeBar;
