import React, { memo, useState } from "react";
import { ClassifySubHeaderMultipleWrapper } from "./style";

import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";

const KTClassifySubHeaderMultiple = memo(({ items }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  function onclickItem(item) {
    if (selectedItems.includes(item.title)) {
      setSelectedItems(
        selectedItems.filter((element) => element !== item.title)
      );
    } else {
      setSelectedItems([item.title, ...selectedItems]);
    }
  }

  const slides = items.map((item) => {
    return (
      <SwiperSlide className="ktSwiperSlide" onClick={() => onclickItem(item)}>
        <div
          className={classNames("title", {
            avtive: selectedItems.includes(item.title),
          })}
        >
          {item.title}
        </div>
      </SwiperSlide>
    );
  });

  return (
    <ClassifySubHeaderMultipleWrapper>
      <Swiper
        className="ktSwiper"
        slidesPerView={"auto"}
        spaceBetween={"14rem"}
      >
        {slides}
      </Swiper>
    </ClassifySubHeaderMultipleWrapper>
  );
});

export default KTClassifySubHeaderMultiple;
