import React, { memo } from "react";
import { MobileEventListEmptyPrompt, MobileEventListWrapper } from "./style";
import { LAYOUT_MODE_CARD } from "@/common/constants";
import KTEventCellMobileCard from "../event-cell-mobile-card";
import KTEventCellMobileList from "../event-cell-mobile-list";

const KTMobileEventList = memo(
  ({
    events,
    layoutMode = LAYOUT_MODE_CARD,
    noEventsPrompt = "We didn't find any matches.\nPlease try again.",
  }) => {
    const cells = events?.map((event) => {
      if (layoutMode === LAYOUT_MODE_CARD) {
        return (
          <KTEventCellMobileCard
            key={`KTEventCellMobileCard${event.id}`}
            event={event}
          />
        );
      } else {
        return (
          <KTEventCellMobileList
            key={`KTEventCellMobileList${event.id}`}
            event={event}
          />
        );
      }
    });

    return (
      <MobileEventListWrapper>
        {cells?.length > 0 ? (
          cells
        ) : events === undefined ||
          events === null ||
          noEventsPrompt?.length === 0 ? null : (
          <MobileEventListEmptyPrompt>
            {noEventsPrompt}
          </MobileEventListEmptyPrompt>
        )}
      </MobileEventListWrapper>
    );
  }
);

export default KTMobileEventList;
