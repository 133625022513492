import styled from "styled-components";

export const PerformerHeaderWrapper = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const PerformerHeaderBackgroundImage = styled.img`
  position: absolute;

  top: -70rem;
  height: calc(100% + 70rem);
  width: 100%;
  object-fit: cover;
  z-index: -1;

  @media screen and (min-width: 481px) {
    top: -80px;
    height: calc(100% + 80px);
  }
`;

export const PerformerHeaderBackgroundView = styled.div`
  position: absolute;

  top: -70rem;
  height: calc(100% + 70rem);
  width: 100%;
  object-fit: cover;
  z-index: -1;
  background-color: #000;

  @media screen and (min-width: 481px) {
    top: -80px;
    height: calc(100% + 80px);
  }
`;

export const PerformerHeaderSpace = styled.div`
  height: 150rem;

  @media screen and (min-width: 481px) {
    height: 320px;
  }
`;
