import React, { memo } from "react";
import { EventCellBasicsDateWrapper, EventCellBasicsWrapper } from "./style";

const KTEventCellBasics = memo(
  ({ event, width = "192rem", height = "114rem" }) => {
    return (
      <EventCellBasicsWrapper style={{ width: width, height: height }}>
        <EventCellBasicsDateWrapper>
          <div className="date">Oct. 18</div>
          <div className="divider"> | </div>
          <div className="time">01:10 pm</div>
          <div className="divider"> | </div>
          <div className="week">MON</div>
        </EventCellBasicsDateWrapper>
        <div className="address">{event.address}</div>
        <div className="title">{event.eventTitle}</div>
      </EventCellBasicsWrapper>
    );
  }
);

export default KTEventCellBasics;
