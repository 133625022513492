import styled from "styled-components";

export const TrendingCategorySilderBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 16rem;

  border-bottom: 1rem solid #f2f2f2;

  margin: 0 20rem;
  padding: 30rem 0;

  background-color: #fff;

  @media screen and (min-width: 1920px) {
    margin: 0 100px;
    padding: 26px 0;

    gap: 30px;
    border-bottom: 1px solid #f2f2f2;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    margin: 0 50px;
    padding: 30px 0;

    gap: 30px;
    border-bottom: 1px solid #f2f2f2;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    margin: 0 24px;
    padding: 26px 0;

    gap: 16px;
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const TrendingCategorySilderBarItem = styled.div`
  cursor: pointer;
  user-select: none;

  color: ${(props) => (props.selected ? "#fff" : "#a3adbe")};
  text-align: center;
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  padding: 6rem 20rem;

  border-radius: 30rem;
  border: ${(props) =>
    props.selected
      ? "1rem solid var(--BLUE-GREY, #19192a)"
      : "1rem solid var(--BLUE-GREY, #a3adbe)"};
  background: ${(props) =>
    props.selected ? "var(--black-19192a, #19192a)" : "#fff"};

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 30px;

    border: ${(props) =>
      props.selected
        ? "1px solid var(--BLUE-GREY, #19192a)"
        : "1px solid var(--BLUE-GREY, #a3adbe)"};
  }

  @media screen and (min-width: 1024px) {
    font-size: 16px;
    padding: 10px 50px;
    border-radius: 30px;

    border: ${(props) =>
      props.selected
        ? "1px solid var(--BLUE-GREY, #19192a)"
        : "1px solid var(--BLUE-GREY, #a3adbe)"};
  }
`;
