import styled from "styled-components";

export const FAQGroupViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 160px;

  max-width: 1384px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 30rem;
  }
`;

export const FAQGroupViewTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 300px;
  gap: 30px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    width: 120px;
    gap: 30px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    width: 120px;
    gap: 30px;
  }

  @media screen and (max-width: 480px) {
    width: 120rem;
    gap: 30rem;
  }
`;

export const FAQGroupViewTitleLine = styled.div`
  width: 300px;
  height: 6px;
  background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    width: 120px;
    height: 6px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    width: 120px;
    height: 6px;
  }

  @media screen and (max-width: 480px) {
    width: 120rem;
    height: 6rem;
  }
`;

export const FAQGroupViewTitle = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 125%; /* 30px */

  @media screen and (max-width: 480px) {
    font-size: 24rem;
  }
`;

export const FAQGroupViewListWrapper = styled.div`
  flex: 1;
`;

export const FAQGroupViewListIssue = styled.div`
  color: #19192a;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16rem;
  }
`;

export const FAQGroupViewListExpandIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 60px;
  padding: 0;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  margin-inline-start: 0px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }

  @media screen and (max-width: 480px) {
    width: 30rem;
    height: 30rem;
    margin-right: 30rem;
  }
`;
