import styled from "styled-components";

export const SportsMobFilterBarWrapper = styled.div`
  height: 74rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: #fff;

  margin: 0 20rem;

  border-bottom: 1rem solid #f2f2f2;

  @media screen and (min-width: 481px) {
    height: 74px;
    margin: 0 20px;

    border-bottom: 1px solid #f2f2f2;
  }
`;

export const SportsMobFilterBarIconWrapper = styled.div`
  position: relative;

  .optionButton {
    padding: 6rem;
    width: 22rem;
    height: 22rem;

    border-radius: 6rem;
    border: 1rem solid var(--dvdr-stroke-f2, #f2f2f2);
    background: #fff;

    cursor: pointer;
    user-select: none;
  }

  @media screen and (min-width: 481px) {
    .optionButton {
      width: 22px;
      height: 22px;
      padding: 6px;
      border-radius: 6px;
      border: 1px solid var(--dvdr-stroke-f2, #f2f2f2);
      background: #fff;
    }
  }
`;

export const SportsMobFilterBarIconMark = styled.div`
  position: absolute;

  top: -1.5rem;
  right: -1.5rem;
  width: 12rem;
  height: 12rem;

  border-radius: 6rem;

  flex-shrink: 0;

  background-color: #19192a;

  @media screen and (min-width: 481px) {
    top: -1.5px;
    right: -1.5px;
    width: 12px;
    height: 12px;

    border-radius: 6px;
  }
`;
