import styled from "styled-components";

export const FAQParagraphListViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  padding-left: 90px;
  gap: 30px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding-left: 60px;
    gap: 20px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 60rem;
    gap: 20rem;
  }
`;

export const FAQParagraphListViewLine = styled.div`
  width: 6px;
  height: 86px;

  flex-shrink: 0;

  background: #f1f1f1;

  @media screen and (max-width: 480px) {
    width: 6rem;
    height: 86rem;
  }
`;

export const FAQParagraphListViewContent = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 20px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 14px;
  }

  @media screen and (max-width: 480px) {
    gap: 14rem;
  }
`;

export const FAQParagraphListViewKindZero = styled.p`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;

  white-space: pre-line;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
    line-height: 26px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
    line-height: 26px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
    line-height: 26rem;
  }
`;

export const FAQParagraphListViewKindOne = styled.li`
  list-style: disc;

  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */

  margin-left: 28px;

  white-space: pre-line;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
    line-height: 26px;
    margin-left: 25px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
    line-height: 26px;
    margin-left: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
    line-height: 26rem;
    margin-left: 22rem;
  }
`;

export const FAQParagraphListViewKindTwo = styled.li`
  list-style: none;

  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */

  margin-left: 28px;

  white-space: pre-line;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
    line-height: 26px;
    margin-left: 25px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
    line-height: 26px;
    margin-left: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
    line-height: 26rem;
    margin-left: 22rem;
  }
`;

export const FAQParagraphListViewKindThree = styled.li`
  list-style: circle;

  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */

  margin-left: 56px;

  white-space: pre-line;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
    line-height: 26px;
    margin-left: 25px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
    line-height: 26px;
    margin-left: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
    line-height: 26rem;
    margin-left: 22rem;
  }
`;
