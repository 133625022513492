import styled from "styled-components";

export const MAMenuCopyrightWrapper = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 22rem 34rem 32rem 34rem;
`;
