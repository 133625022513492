import React, { memo } from "react";
import {
  ClassifyTitleSilderBarItem,
  ClassifyTitleSilderBarWrapper,
} from "./styled";
import { Swiper, SwiperSlide } from "swiper/react";

const KTClassifyTitleSilderBar = memo(
  ({ items = [], selectedItem, onSelectedItemClick }) => {
    const swiperSlides = items.map((item) => {
      return (
        <SwiperSlide
          key={`SwiperSlide${item.title}`}
          className="swiperSlide"
          onClick={() => onSelectedItemClick(item)}
        >
          <ClassifyTitleSilderBarItem
            selected={selectedItem.title === item.title}
          >
            {item.title}
          </ClassifyTitleSilderBarItem>
        </SwiperSlide>
      );
    });

    return (
      <ClassifyTitleSilderBarWrapper>
        <Swiper slidesPerView={"auto"} spaceBetween={"10rem"}>
          {swiperSlides}
        </Swiper>
      </ClassifyTitleSilderBarWrapper>
    );
  }
);

export default KTClassifyTitleSilderBar;
