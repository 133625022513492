import styled from "styled-components";

export const SwitchCityDtpDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 20px 10px rgba(128, 0, 11, 0.03);
  padding: 30px 40px;

  gap: 14px;

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 0;
  }
`;

export const SwitchCityDtpDropdownItemsWrapper = styled.div`
  border-radius: 20px;
  border: 1px solid var(--tag-stroke, #ecf0f4);
  background: #fff;

  padding: 0px 30px;
`;
