import { styled } from "styled-components";

export const EventCellBuyButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: var(--icon-line-black, #19192a);
  cursor: pointer;
  white-space: pre-line;
`;
