import React, { memo } from "react";
import { HomeBasedLikeWrapper } from "./style";
import KTSpace from "@/components/space";
import KTClassifyHeader from "@/components/classify/classify-header";
import KTConcertsList from "@/components/concerts/concerts-list";
import KTEventListHorizontal from "@/components/event/event-list-horizontal";
import { exploreEvents } from "@/common/ephemeral-data";
import KTClassifySubHeaderMultiple from "@/components/classify/classify-sub-header-multiple";
import KTEventGrid from "@/components/event/event-grid";
import KTEventCellMobileCard from "@/components/mobile-components/event-cell-mobile-card";
import { homeContentEventItems } from "@/common/ephemeral-data";
import KTEventCellBasics from "@/components/event/event-cell-basics";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTTeamListHorizontal from "@/components/team/team-list-horizontal";
import KTEventCellShow from "@/components/event/event-cell-show";
import { currValue } from "@/utils";
import KTListViewHorizontal from "@/components/scroll-view/list-view-horizontal";
import KTEventCellSimple from "@/components/event/event-cell-simple";
import KTEventCellRightImage from "@/components/event/event-cell-right-image";
import KTClassifySubMultipleDesktop from "@/components/classify/calssify-sub-multiple-desktop";
import KTGridView from "@/components/scroll-view/grid-view";
import KTEventCellExploreDesktop from "@/components/event/event-cell-explore-desktop";

const KTHomeBasedLike = memo(({ windowSize }) => {
  const concerts = [
    {
      name: "Taylor Swift",
      portrait: "artist01.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },
    {
      name: "Artist Name",
      portrait: "artist02.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "William Anderson",
      portrait: "artist03.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Emily Davis",
      portrait: "artist04.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Benjamin Foster",
      portrait: "artist05.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Olivia Parker",
      portrait: "artist06.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist07.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist08.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist09.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist10.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },
  ];

  const exploreSubItems = [
    {
      title: "Examples",
    },
    {
      title: "Cultural",
    },
    {
      title: "immersive theater",
    },
    {
      title: "Stand-up",
    },
    {
      title: "TED-Talks",
    },
    {
      title: "Interactive Art",
    },
    {
      title: "Children’s theatre",
    },
    {
      title: "Opera",
    },
    {
      title: "Festivals",
    },
  ];

  const sportsTeams = [
    {
      title: "Inter\nMiami",
      backgroundImageName: "team-background-image-1.svg",
      backgroundColor: "#ECB0C6",
    },
    {
      title: "Kansas City\nChiefs",
      backgroundImageName: "team-background-image-2.svg",
      backgroundColor: "#E31836",
    },
    {
      title: "Minnesota\nWild",
      backgroundImageName: "team-background-image-3.svg",
      backgroundColor: "#004324",
    },
    {
      title: "Manchester\nCity",
      backgroundImageName: "team-background-image-4.svg",
      backgroundColor: "#6CAEDF",
    },
    {
      title: "Chicago\nBears",
      backgroundImageName: "team-background-image-5.svg",
      backgroundColor: "#E64100",
    },
    {
      title: "Boston\nBruins",
      backgroundImageName: "team-background-image-6.svg",
      backgroundColor: "#F4BD50",
    },
    {
      title: "Los Angeles\nLakers",
      backgroundImageName: "team-background-image-7.svg",
      backgroundColor: "#FDB927",
    },
    {
      title: "New York\nYankees",
      backgroundImageName: "team-background-image-8.svg",
      backgroundColor: "#132448",
    },
    {
      title: "Kansas City\nRoyals",
      backgroundImageName: "team-background-image-9.svg",
      backgroundColor: "#004687",
    },
    {
      title: "Chicago\nBulls",
      backgroundImageName: "team-background-image-10.svg",
      backgroundColor: "#BD2C45",
    },
    {
      title: "Los Angeles\nDodgers",
      backgroundImageName: "team-background-image-11.svg",
      backgroundColor: "#005A9C",
    },
  ];

  const upcomingSubItems = [
    {
      title: "All",
      emoji: "🤩",
    },
    {
      title: "Broadway",
      emoji: "🏈",
    },
    {
      title: "Children’s Theater",
      emoji: "⚾",
    },
    {
      title: "Opera",
      emoji: "🏀",
    },
    {
      title: "Musical",
      emoji: "🏑",
    },
    {
      title: "Stand-up",
      emoji: "⚽",
    },
    {
      title: "Comedy",
      emoji: "🎾",
    },
    {
      title: "Circus Shows",
      emoji: "⛳",
    },
    {
      title: "Dance",
      emoji: "🥊",
    },
    {
      title: "Cultural",
      emoji: "🥊",
    },
    {
      title: "Interactive/immersive Arts",
      emoji: "🥊",
    },
    {
      title: "Public Speaking",
      emoji: "🥊",
    },
  ];

  const windowWidth = windowSize.width;

  const children =
    windowWidth > 480
      ? [
          <KTSpace height={"80px"} />,
          <KTClassifyHeader
            title={"For you"}
            windowWidth={windowWidth}
            hiddenViewAll={true}
          />,
          <KTSpace height={"56px"} />,
          <KTConcertsList concerts={concerts} windowWidth={windowWidth} />,

          // <KTSpace height={"80px"} />,
          // <KTClassifyHeader
          //   title={"Based on Your Likes"}
          //   windowWidth={windowWidth}
          //   hiddenViewAll={true}
          // />,

          <KTSpace height={"50px"} />,
          <KTTeamListHorizontal
            windowWidth={windowWidth}
            teams={sportsTeams}
          />,

          <KTSpace height={"80px"} />,
          <KTClassifyHeader
            title={"Guess You May Like"}
            windowWidth={windowWidth}
            hiddenViewAll={true}
          />,
          <KTSpace height={"50px"} />,
          <KTListViewHorizontal
            items={exploreEvents}
            windowWidth={windowWidth}
            spaceBetween={"38px"}
            cellOfItem={(event) => {
              return <KTEventCellShow event={event} />;
            }}
            showSwiperControl={true}
            showBottomLie={true}
          />,

          <KTSpace height={"80px"} />,
          <KTClassifyHeader
            title={"Country Concerts"}
            windowWidth={windowWidth}
            hiddenViewAll={true}
          />,
          <KTSpace height={"50px"} />,
          <KTConcertsList concerts={concerts} windowWidth={windowWidth} />,

          <KTSpace height={"80px"} />,
          <KTClassifyHeader
            title={"Upcoming Events \n in Overland Park"}
            windowWidth={windowWidth}
            hiddenViewAll={true}
          />,
          <KTSpace height={"50px"} />,
          <KTListViewHorizontal
            items={exploreEvents}
            windowWidth={windowWidth}
            spaceBetween={"40px"}
            swiperPadding={`6px ${currValue(50, 100, windowWidth)}`}
            cellOfItem={(event) => {
              return <KTEventCellSimple event={event} />;
            }}
            showSwiperControl={true}
            showBottomLie={true}
          />,

          <KTSpace height={"80px"} />,
          <KTClassifyHeader
            title={"Upcoming NFL Games"}
            windowWidth={windowWidth}
            hiddenViewAll={true}
          />,
          <KTSpace height={"50px"} />,
          <KTListViewHorizontal
            items={homeContentEventItems}
            windowWidth={windowWidth}
            spaceBetween={"40px"}
            cellOfItem={(event) => {
              return <KTEventCellRightImage event={event} />;
            }}
            showSwiperControl={true}
            showBottomLie={true}
          />,

          <KTSpace height={"80px"} />,
          <KTClassifyHeader
            title={"Upcoming Events \n in Overland Park"}
            windowWidth={windowWidth}
            hiddenViewAll={true}
          />,
          <KTSpace height={"30px"} />,
          <KTClassifySubMultipleDesktop
            items={upcomingSubItems}
            windowWidth={windowWidth}
            hiddenLine={true}
            onChangeItem={(item) => console.log(item)}
          />,
          <KTSpace height={"30px"} />,
          <KTGridView
            windowWidth={windowWidth}
            items={exploreEvents}
            rowGap={"60px"}
            columnGap={currValue(40, 80, windowWidth)}
            gridTemplateColumns={"repeat(auto-fill, 458px)"}
            cellOfItem={(item) => {
              return <KTEventCellExploreDesktop event={item} />;
            }}
          />,
          <KTSpace height={"240px"} />,
        ]
      : [
          <KTSpace height={"40rem"} />,
          <KTClassifyHeader
            title={"Rock Concerts"}
            onClickViewAll={() => console.log("Concerts view all")}
          />,
          <KTSpace height={"30rem"} />,
          <KTConcertsList concerts={concerts} />,

          <KTSpace height={"54rem"} />,
          <KTClassifyHeader
            title={"Upcoming NFL Games\nNear You"}
            onClickViewAll={() => console.log("Concerts view all")}
          />,
          <KTSpace height={"30rem"} />,
          <KTEventListHorizontal
            events={homeContentEventItems}
            cellOfEvent={(event) => {
              return <KTEventCellMobileCard event={event} />;
            }}
          />,

          <KTSpace height={"28rem"} />,
          <KTClassifyHeader
            title={"Upcoming Events for\nSwifties"}
            onClickViewAll={() => console.log("Concerts view all")}
          />,
          <KTSpace height={"30rem"} />,
          <KTEventListHorizontal
            events={exploreEvents}
            padding={"0 10rem"}
            spaceBetween={0}
            cellOfEvent={(event) => {
              return <KTEventCellBasics event={event} />;
            }}
          />,

          <KTSpace height={"20rem"} />,
          <KTClassifyHeader
            title={"Trending Events Near\nOverland Park"}
            onClickViewAll={() => console.log("Explore view all")}
            hiddenViewAll={true}
          />,
          <KTSpace height={"30rem"} />,
          <KTClassifySubHeaderMultiple items={exploreSubItems} />,
          <KTSpace height={"30rem"} />,
          <KTEventGrid events={exploreEvents} />,
          <KTSpace height={"30rem"} />,
        ];

  return <HomeBasedLikeWrapper>{children}</HomeBasedLikeWrapper>;
});

export default withWindowSize(KTHomeBasedLike);
