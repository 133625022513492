import React, { memo, useEffect, useState } from "react";
import { TermsContentWrapper, TermsWrapper } from "./style";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTMobileHeaderBar from "../../components/mobile-header-bar";
import { KT_THEME } from "../../common/constants";
import TN_Policy_Maker from "./legacyScript.js";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";

const KTTerms = memo(({ windowSize }) => {
  const params = useParams();
  const mark = params.mark;
  const [htmlContent, setHtmlContent] = useState({ __html: <div /> });

  useEffect(() => {
    setHtmlContent({ __html: TN_Policy_Maker.PrintPolicies() });

    let timer;

    if (mark === "policy") {
      const jumpToLocation = () => {
        const element = document.getElementById("privacyPolicy");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };

      const delay = 300;
      timer = setTimeout(jumpToLocation, delay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [mark]);

  return (
    <TermsWrapper>
      {windowSize.width <= 480 ? (
        <KTMobileHeaderBar theme={KT_THEME.DARK} />
      ) : null}
      <TermsContentWrapper dangerouslySetInnerHTML={htmlContent} />
    </TermsWrapper>
  );
});

export default withWindowSize(KTTerms);
