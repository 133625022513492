import React, { memo } from "react";
import { TeamCellWrapper } from "./style";
import { ReactComponent as LikeIcon } from "@/assets/img/svg/icon-like.svg";

const KTTeamCell = memo(({ team, marginRight, marginBottom }) => {
  return (
    <TeamCellWrapper
      style={{
        marginRight: marginRight,
        marginBottom: marginBottom,
        backgroundColor: team.backgroundColor,
        backgroundImage:
          "url(" +
          require(`@/assets/img/temporary/${team.backgroundImageName}`) +
          ")",
      }}
    >
      {/* <LikeIcon className="like" /> */}
      <div className="title">{team.title}</div>
    </TeamCellWrapper>
  );
});

export default KTTeamCell;
