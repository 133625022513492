import React, { memo, useCallback, useState } from "react";
import {
  SearchDtpInputCellSearchIcon,
  SearchDtpInputCellWrapper,
} from "./style";
import { Input } from "antd";

const KTSearchDtpInputCell = memo(({ onValueChange }) => {
  const [inputValue, setInputValue] = useState("");

  const debounce = useCallback((callback, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback.apply(this, args);
      }, delay);
    };
  }, []);

  const handleInputChange = useCallback(
    debounce((value) => {
      onValueChange(value);
    }, 300),
    [debounce]
  );

  function onInputValueChange(e) {
    const value = e.target.value;
    setInputValue(value);
    handleInputChange(value);
  }

  return (
    <SearchDtpInputCellWrapper>
      <Input
        value={inputValue}
        onChange={onInputValueChange}
        className="input"
        bordered={false}
        placeholder={"Search Location"}
      />
      <SearchDtpInputCellSearchIcon
        src={require("@/assets/img/icons/icon-nav-search.png")}
      />
    </SearchDtpInputCellWrapper>
  );
});

export default KTSearchDtpInputCell;
