import styled from "styled-components";

export const AppFooterListViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 190px;

  gap: 20px;
`;

export const AppFooterListViewGroupTitle = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const AppFooterListViewItemTitle = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
`;
