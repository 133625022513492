import styled from "styled-components";

export const TrendingAddressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8rem 20rem;

  position: relative;

  @media screen and (min-width: 1920px) {
    padding: 8px 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 8px 50px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 8px 30px;
  }
`;

export const TrendingAddressBarLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 2rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 8px;
  }
`;

export const TrendingAddressBarLeftTitle = styled.div`
  color: #19192a;
  font-family: Inter;
  font-size: 18rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 30px;
  }
`;

export const TrendingAddressBarLeftLocation = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 18rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 30px;
  }
`;
export const TrendingAddressBarLeftAnyLocation = styled.div`
  color: var(--BLUE-GREY, #a3adbe);
  font-family: Inter;
  font-size: 18rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 30px;
  }
`;

export const TrendingAddressBarRightWrapper = styled.div`
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4rem;
  @media screen and (min-width: 481px) {
    gap: 0;
  }
`;

export const TrendingAddressBarRightTitle = styled.div`
  color: #003594;
  font-family: Inter;
  font-size: 13rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;

export const TrendingAddressBarRightArrow = styled.img`
  width: 24rem;
  height: 24rem;

  @media screen and (min-width: 481px) {
    width: 24px;
    height: 24px;
  }
`;

export const TrendingAddressBarBottomLine = styled.div`
  position: absolute;

  top: 100%;

  width: 60rem;
  height: 6rem;
  background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);

  @media screen and (min-width: 481px) {
    width: 60px;
    height: 6px;
    background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);
  }
`;
