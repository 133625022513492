import styled from "styled-components";

export const SearchDropdownFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchDropdownFooterContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 14px 0;

  gap: 20px;

  cursor: pointer;
  user-select: none;
`;

export const SearchDropdownFooterTitle = styled.div`
  color: #06c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
`;

export const SearchDropdownFooterArrow = styled.img`
  width: 24px;
  height: 24px;
`;
