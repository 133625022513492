import { Affix } from "antd";
import React, { memo } from "react";

import KTHomeContent from "./c-components/home-content";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTHomeMobileFilterBar from "./c-components/home-mobile-filter-bar";
import KTHomeDesktopFilterBar from "./c-components/home-desktop-filter-bar";

const KTHomeAllEvents = memo(({ windowSize }) => {
  return (
    <div>
      <Affix offsetTop={0}>
        {windowSize.width >= 1024 ? (
          <KTHomeDesktopFilterBar />
        ) : (
          <KTHomeMobileFilterBar windowWidth={windowSize.width} />
        )}
      </Affix>
      <KTHomeContent />
    </div>
  );
});

export default withWindowSize(KTHomeAllEvents);
