import React, { memo } from "react";
import {
  SearchDropdownListViewTitle,
  SearchDropdownListViewTopLine,
  SearchDropdownListViewWrapper,
} from "./style";

const KTSearchDropdownListView = memo(({ title, children }) => {
  return (
    <SearchDropdownListViewWrapper>
      <SearchDropdownListViewTopLine />
      <SearchDropdownListViewTitle>{title}</SearchDropdownListViewTitle>
      {children}
    </SearchDropdownListViewWrapper>
  );
});

export default KTSearchDropdownListView;
