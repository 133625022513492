import React, { memo } from "react";
import {
  AboutMissionLeftMessage,
  AboutMissionLeftSubTitle,
  AboutMissionLeftTitle,
  AboutMissionLeftWrapper,
  AboutMissionPoster,
  AboutMissionPosterWrapper,
  AboutMissionWrapper,
} from "./style";

const KTAboutMission = memo(() => {
  return (
    <AboutMissionWrapper>
      <AboutMissionLeftWrapper>
        <AboutMissionLeftTitle>MISSION</AboutMissionLeftTitle>
        <AboutMissionLeftSubTitle>
          At KustomTickets, our mission is to create personalized and seamless
          connections between enthusiasts and events, aiming to revolutionize
          the ticketing industry.
        </AboutMissionLeftSubTitle>
        <AboutMissionLeftMessage>
          We stand on the pillars of innovation, commitment, and an unwavering
          dedication to transforming ordinary events into extraordinary
          memories.
        </AboutMissionLeftMessage>
      </AboutMissionLeftWrapper>
      <AboutMissionPosterWrapper>
        <AboutMissionPoster
          src={require("@/assets/img/about-us/about-mission-poster.png")}
        />
      </AboutMissionPosterWrapper>
    </AboutMissionWrapper>
  );
});

export default KTAboutMission;
