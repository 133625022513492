import React, { memo } from "react";
import { MobileAppMenuSpace, MobileAppMenuWrapper } from "./style";
import KTMAMenuHeader from "./c-components/ma-menu-header";
import KTMAMenuAddress from "./c-components/ma-menu-address";
import KTMAMenuNavlinks from "./c-components/ma-menu-navlinks";
import KTMAMenuAbout from "./c-components/ma-menu-about";
import KTSwitchCityOtherDropdown from "@/components/dropdown/switch-city-other-dropdown";
import KTMAMenuSocial from "./c-components/ma-meun-social";
import KTMAMenuCopyright from "./c-components/ma-menu-copyright";

const KTMobileAppMenu = memo(({ onClose }) => {
  return (
    <MobileAppMenuWrapper>
      <KTMAMenuHeader onClose={onClose} />
      <KTSwitchCityOtherDropdown>
        <KTMAMenuAddress onClick={(e) => e.preventDefault()} />
      </KTSwitchCityOtherDropdown>
      <KTMAMenuNavlinks onClose={onClose} />
      <MobileAppMenuSpace />
      <KTMAMenuAbout onClose={onClose} />
      <KTMAMenuSocial />
      <KTMAMenuCopyright />
    </MobileAppMenuWrapper>
  );
});

export default KTMobileAppMenu;
