import styled from "styled-components";

export const AddressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
  padding: 30px 100px;

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 30px 50px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 20px 30px;
  }
`;

export const AddressBarNoEventsPrompt = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const AddressBarBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AddressBarContent = styled.div`
  display: flex;
  flex-direction: row;

  gap: 20px;

  cursor: pointer;
  user-select: none;
`;

export const AddressBarIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const AddressBarTitle = styled.div`
  color: #284e9f;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
`;
