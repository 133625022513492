import styled from "styled-components";

export const SearchDropdownEventCellWrapper = styled.div`
  width: 280px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 20px;

  cursor: pointer;
  user-select: none;
`;

export const SearchDropdownEventCellDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-width: 55px;

  gap: 4.5px;
  padding: 6px 10px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fff;
`;

export const SearchDropdownEventCellDateTitle = styled.div`
  color: #06c;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  white-space: pre-line;
`;

export const SearchDropdownEventCellWeekTitle = styled.div`
  color: #c8c8c8;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SearchDropdownEventCellTimeTitle = styled.div`
  color: var(--icon-4d-black, #4d4d4d);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 72.023%; /* 9.363px */
`;

export const SearchDropdownEventCellTitle = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;
