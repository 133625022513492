import React, { useEffect, useState } from "react";
// import { debounce } from "@/util/debounce";

export default function withWindowSize(Component) {
  return function WithWindowSize(props) {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      // const handleResize = debounce(() => {
      //   setWindowSize({
      //     width: window.innerWidth,
      //     height: window.innerHeight,
      //   });
      // }, 10);

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return <Component windowSize={windowSize} {...props} />;
  };
}
