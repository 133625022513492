import { styled } from "styled-components";

// 选择城市
export const HomeHeaderCityWrapper = styled.div`
  height: 42px;
  margin-top: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .message {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .city {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
`;
