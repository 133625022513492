import { Map } from "immutable";
import * as actionTypes from "./constants";

const defaultState = Map({
  keywords: "",
  performers: [],
  events: [],
  venues: [],
  suggestions: {},
});

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_SEARCH_KEYWORDS:
      return state.set("keywords", action.keywords);

    case actionTypes.CHANGE_PERFORMERS:
      return state.set("performers", action.performers);

    case actionTypes.CHANGE_EVENTS:
      console.log("action.events: ", action.events);
      return state.set("events", action.events);

    case actionTypes.CHANGE_VENUES:
      return state.set("venues", action.venues);

    case actionTypes.CHANGE_SUGGESTIONS:
      return state.set("suggestions", action.suggestions);

    case actionTypes.EMPTY_SEARCH_RESULTS:
      state.set("performers", []);
      state.set("events", []);
      state.set("venues", []);
      return state;

    default:
      return state;
  }
}

export default reducer;
