import React, { memo } from "react";
import { EventInfoWrapper, EventPosterWrapper, EventWrapper } from "./style";
import { ReactComponent as LikeIcon } from "@/assets/img/svg/icon-like.svg";

const KTEventCellShow = memo(({ event }) => {
  return (
    <EventWrapper>
      <EventPosterWrapper>
        {/* <LikeIcon className="icon" /> */}
      </EventPosterWrapper>
      <EventInfoWrapper>
        <div className="title">Hamilton</div>
        <div className="upcoming">24 Upcoming Events</div>
        <div className="inAddress">0 Events in Overland Park</div>
      </EventInfoWrapper>
    </EventWrapper>
  );
});

export default KTEventCellShow;
