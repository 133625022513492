import styled from "styled-components";

export const AppHeaderLeftContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    gap: 26px;
  }
`;

export const AppHeaderLeftContentLogo = styled.img`
  width: 74px;
  height: 40px;
  flex-shrink: 0;
`;
