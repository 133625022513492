import styled from "styled-components";

export const SearchDropdownListViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const SearchDropdownListViewTopLine = styled.div`
  width: 280px;
  height: 10px;

  background: #f8f8f8;
`;

export const SearchDropdownListViewTitle = styled.div`
  color: var(--tag-tex, #343c46);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  white-space: pre-line;
`;
