import React, { memo } from "react";
import { EventListHorizontalWrapper } from "./style";
import { Swiper, SwiperSlide } from "swiper/react";

const KTEventListHorizontal = memo(
  ({ events, cellOfEvent, spaceBetween = "14rem", padding = "0 20rem" }) => {
    const slides = events.map((event) => {
      return (
        <SwiperSlide className="ktSwiperSlide">
          {cellOfEvent(event)}
        </SwiperSlide>
      );
    });

    return (
      <EventListHorizontalWrapper>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={spaceBetween}
          style={{ padding: padding }}
        >
          {slides}
        </Swiper>
      </EventListHorizontalWrapper>
    );
  }
);

export default KTEventListHorizontal;
