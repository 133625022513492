import { styled } from "styled-components";

export const MAMenuAddressWrapper = styled.div`
  margin-top: 34rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .addressButton {
    width: 212rem;
    flex-shrink: 0;
    border-radius: 6rem;
    border: 1rem solid #c8c8c8;
    background: #fff;
    padding: 14rem 30rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20rem;

    .icon {
      width: 16rem;
      height: 16rem;
      stroke: #4d4d4d;
    }

    .title {
      color: var(--icon-4d-black, #4d4d4d);
      font-family: Inter;
      font-size: 14rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
