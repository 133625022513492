import React, { memo } from "react";
import {
  HomeDesktopFilterButtonArrow,
  HomeDesktopFilterButtonTitle,
  HomeDesktopFilterButtonWrapper,
} from "./style";

const KTHomeDesktopFilterButton = memo(({ title, styled = 0, onClick }) => {
  const arrowSrc =
    styled === 0
      ? require("@/assets/img/icons/icon-arrow-bottom-gray.png")
      : require("@/assets/img/icons/icon-arrow-bottom-black.png");
  return (
    <HomeDesktopFilterButtonWrapper onClick={onClick}>
      <HomeDesktopFilterButtonTitle styled={styled}>
        {title}
      </HomeDesktopFilterButtonTitle>
      <HomeDesktopFilterButtonArrow src={arrowSrc} />
    </HomeDesktopFilterButtonWrapper>
  );
});

export default KTHomeDesktopFilterButton;
