import styled from "styled-components";

export const ResultDtpContentWrapper = styled.div`
  margin: 40px 0px 40px 70px;
  display: flex;
  flex-direction: row;

  gap: 50px;

  @media screen and (min-width: 1920px) {
    margin: 40px 0px 40px 100px;

    gap: 120px;
  }
`;

export const ResultDtpContentLeftWrapper = styled.div`
  width: 280px;

  display: flex;
  flex-direction: column;

  gap: 60px;
`;

export const ResultDtpContentDivider = styled.div`
  width: 1px;
  height: auto;
  background: #f2f2f2;
`;

export const ResultDtpContentRightWrapper = styled.div`
  flex: 1;
`;

export const ResultDtpContentEventsHeader = styled.div`
  position: relative;

  color: #19192a;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  padding-bottom: 10px;
`;

export const ResultDtpContentEventsHeaderLine = styled.div`
  position: absolute;

  left: 0;
  top: 100%;

  width: 60px;
  height: 6px;
  background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);
`;
