import React, { memo, useState } from "react";
import {
  ShowsMobFilterBarIconMark,
  ShowsMobFilterBarIconWrapper,
  ShowsMobFilterBarWrapper,
} from "./style";
import { ReactComponent as FilterIcon } from "@/assets/img/icons/icon-filter-option.svg";
import { Drawer } from "antd";
import KTFilterMobBarButton from "../../../../components/filter/filter-mob-bar-button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChangeShowsSortModeAction } from "../../store/actionCreators";
import { showsAllEventsSortModes } from "../../local-data";
import KTShowsFilterContent from "../shows-mob-filter-content";

const KTShowsMobFilterBar = memo(() => {
  const [filterViewOpen, setFilterViewOpen] = useState(false);

  const dispatch = useDispatch();

  const { sortMode, intervalType = "0" } = useSelector(
    (state) => ({
      sortMode: state.getIn(["shows", "sortMode"]),
      intervalType: state.getIn(["shows", "intervalType"]),
    }),
    shallowEqual
  );

  function onChangeSortModeItem(mode) {
    dispatch(getChangeShowsSortModeAction(mode));
  }

  const isDefaultFilter = intervalType === "0";

  return (
    <ShowsMobFilterBarWrapper>
      <KTFilterMobBarButton
        title={sortMode.title}
        switchItems={showsAllEventsSortModes}
        onChangeSwitchItem={onChangeSortModeItem}
      />
      <ShowsMobFilterBarIconWrapper>
        <FilterIcon
          className="optionButton"
          onClick={() => setFilterViewOpen(true)}
        />
        {isDefaultFilter ? null : <ShowsMobFilterBarIconMark />}
      </ShowsMobFilterBarIconWrapper>

      <Drawer
        closeIcon={null}
        placement="bottom"
        height={`100%`}
        open={filterViewOpen}
        onClose={() => setFilterViewOpen(false)}
        style={{
          borderRadius: "20rem 20rem 0 0",
          background: "#FFF",
        }}
        bodyStyle={{ padding: "0" }}
      >
        <KTShowsFilterContent onClose={() => setFilterViewOpen(false)} />
      </Drawer>
    </ShowsMobFilterBarWrapper>
  );
});

export default KTShowsMobFilterBar;
