import React, { memo } from "react";
import {
  EventCellMobileListDateTime,
  EventCellMobileListMark,
  EventCellMobileListPrice,
  EventCellMobileListTitle,
  EventCellMobileListVenue,
  EventCellMobileListWrapper,
} from "./style";
import { withRouter } from "react-router-dom";
import {
  eventBuyButtonTitle,
  eventDate,
  eventName,
  eventTime,
  eventVenue,
  eventWeek,
} from "../../../utils/event";

const KTEventCellMobileList = memo((props) => {
  const { event } = props;
  function onEventClick() {
    const url = window.location.origin + `#/map/${event.id}`;
    window.open(url, "_self", "noopener,noreferrer");
  }

  return (
    <EventCellMobileListWrapper onClick={onEventClick}>
      <EventCellMobileListDateTime>
        {event?.date?.datetime === undefined
          ? [<div className="date">{event.date.text.time}</div>]
          : [
              <div className="date">{`${eventDate(event)} `}</div>,
              <div className="divider">|</div>,
              <div className="time">{`${eventTime(event)}`}</div>,
              <div className="divider">|</div>,
              <div className="week">{`${eventWeek(event)} `}</div>,
            ]}
      </EventCellMobileListDateTime>
      <EventCellMobileListVenue>{eventVenue(event)}</EventCellMobileListVenue>
      <EventCellMobileListTitle>{eventName(event)}</EventCellMobileListTitle>
      <EventCellMobileListPrice>
        {eventBuyButtonTitle(event)}
      </EventCellMobileListPrice>
      <EventCellMobileListMark src={event.kt_mark} />
    </EventCellMobileListWrapper>
  );
});

export default withRouter(KTEventCellMobileList);
