import React, { memo } from "react";
import {
  DropdownDtpAnyLocationCellWrapper,
  DtpAnyLocationCellIcon,
  DtpAnyLocationCellTitle,
} from "./style";

const KTDropdownDtpAnyLocationCell = memo(() => {
  return (
    <DropdownDtpAnyLocationCellWrapper>
      <DtpAnyLocationCellIcon
        src={require("@/assets/img/icons/icon-any.png")}
      />
      <DtpAnyLocationCellTitle>Any Location</DtpAnyLocationCellTitle>
    </DropdownDtpAnyLocationCellWrapper>
  );
});

export default KTDropdownDtpAnyLocationCell;
