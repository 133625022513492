import React, { memo } from "react";
import { GridViewWrapper } from "./style";
import { currValue } from "@/utils";

const KTGridView = memo(
  ({
    windowWidth = window.screen.width,
    items,
    rowGap = undefined,
    columnGap = undefined,
    gridTemplateColumns = "1fr",
    padding = windowWidth > 480
      ? `0 ${currValue(50, 100, windowWidth)}`
      : "0",
    cellOfItem = (item) => {
      return <div item={item} />;
    },
  }) => {
    const cells = items.map((item) => {
      return cellOfItem(item);
    });

    return (
      <GridViewWrapper
        style={{
          rowGap: rowGap,
          columnGap: columnGap,
          gridTemplateColumns: gridTemplateColumns,
          padding: padding,
        }}
      >
        {cells}
      </GridViewWrapper>
    );
  }
);

export default KTGridView;
