import React, { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import KTConcertItem from "../concerts-item";
import { ConcertsListNavigationWrapper, ConcertsListWrapper } from "./style";
import KTConcertInfoItem from "../concert-info-item";

import { ReactComponent as ArrowLeft } from "@/assets/img/icons/icon-arrow-circle-left.svg";
import { ReactComponent as ArrowRight } from "@/assets/img/icons/icon-arrow-circle-right.svg";

import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { currValue } from "@/utils";
SwiperCore.use([Navigation]);

const KTConcertsList = memo(
  ({ concerts, windowWidth = window.screen.width, onConcertClick }) => {
    const items = concerts.map((item, index) => {
      return (
        <SwiperSlide
          key={index}
          className="swiperSlide"
          onClick={() => onConcertClick(item)}
        >
          {windowWidth > 480 ? (
            <KTConcertInfoItem concert={item} />
          ) : (
            <KTConcertItem concert={item} />
          )}
        </SwiperSlide>
      );
    });

    let swiper;

    return (
      <ConcertsListWrapper>
        <Swiper
          className="swiper"
          slidesPerView={"auto"}
          spaceBetween={
            windowWidth > 480 ? currValue(40, 50, windowWidth) : "30rem"
          }
          onInit={(s) => (swiper = s)}
          onResize={(s) => (swiper = s)}
          style={{
            padding:
              windowWidth > 480
                ? "0 " + currValue(50, 100, windowWidth)
                : "0 20rem",
          }}
        >
          {items}
        </Swiper>
        {windowWidth > 480
          ? [
              <ConcertsListNavigationWrapper
                style={{
                  padding:
                    windowWidth > 480
                      ? "0 " + currValue(50, 100, windowWidth)
                      : "0 20rem",
                  margin: `${currValue(40, 60, windowWidth)} 0`,
                }}
              >
                <ArrowLeft
                  className="swiperButtonPrev"
                  onClick={() => {
                    if (swiper) {
                      swiper.slidePrev();
                    }
                  }}
                />
                <ArrowRight
                  className="swiperButtonNext"
                  onClick={() => {
                    if (swiper) {
                      swiper.slideNext();
                    }
                  }}
                />
              </ConcertsListNavigationWrapper>,
              <div className="line" />,
            ]
          : null}
      </ConcertsListWrapper>
    );
  }
);

export default KTConcertsList;
