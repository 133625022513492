import { styled } from "styled-components";

export const MobileEventListWrapper = styled.div`
  padding: 10rem 0;
  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 40rem;
`;

export const MobileEventListEmptyPrompt = styled.div`
  width: 294rem;
  padding: 100rem 0;
  color: var(--iconb3, #b3b3b3);
  font-family: Inter;
  font-size: 15rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  white-space: pre-line;
`;
