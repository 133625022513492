import styled from "styled-components";

export const ResultSilderBarWrapper = styled.div`
  padding: 20rem;

  display: flex;
  flex-direction: row;
  gap: 50rem;

  background: #fff;
  box-shadow: 0px 6rem 20rem 0px rgba(242, 242, 242, 0.4);

  .navlink {
    color: var(--BLUE-GREY, #a3adbe);
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    white-space: "pre-line";
  }

  .navlinkActive {
    color: var(--tag-tex, #343c46);
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-decoration: none;
  }

  @media screen and (min-width: 481px) {
    padding: 20px 40px;

    display: flex;
    flex-direction: row;
    gap: 60px;

    background: #fff;
    box-shadow: 0px 6px 20px 0px rgba(242, 242, 242, 0.4);

    .navlink {
      color: var(--BLUE-GREY, #a3adbe);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      white-space: "pre-line";
    }

    .navlinkActive {
      color: var(--tag-tex, #343c46);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-decoration: none;
    }
  }
`;

export const ResultSilderBarItemBottomLine = styled.div`
  background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);
  width: 60rem;
  height: 6rem;

  @media screen and (min-width: 481px) {
    margin-top: 6px;

    background: linear-gradient(90deg, #ff9e0b 0%, #ff0b0b 102.63%);
    width: 60px;
    height: 6px;
  }
`;
