import React, { memo } from "react";
import {
  EventCellMobileCardContentTitle,
  EventCellMobileCardContentTitleWrapper,
  EventCellMobileCardContentWrapper,
  EventCellMobileCardDateTime,
  EventCellMobileCardPrice,
  EventPosterWrapper,
} from "./style";

import {
  eventBuyButtonTitle,
  eventDate,
  eventName,
  eventTime,
  eventWeek,
} from "../../../../../utils/event";

const KTEventCellMobileCardContent = memo(({ event, onClick }) => {
  let posterSrc = null;
  if (event.kt_info?.eventposter_mobile) {
    posterSrc = event.kt_info?.eventposter_mobile;
  }
  if (posterSrc === null && event.kt_placeholder) {
    posterSrc = event.kt_placeholder;
  }

  return (
    <EventCellMobileCardContentWrapper onClick={onClick}>
      {posterSrc ? <EventPosterWrapper src={posterSrc} /> : null}

      <EventCellMobileCardDateTime>
        {event.date.datetime === undefined
          ? [<div className="date">{event.date.text.time}</div>]
          : [
              <div className="date">{`${eventDate(event)} `}</div>,
              <div className="divider">|</div>,
              <div className="time">{`${eventTime(event)}`}</div>,
              <div className="divider">|</div>,
              <div className="week">{`${eventWeek(event)} `}</div>,
            ]}
      </EventCellMobileCardDateTime>
      <EventCellMobileCardContentTitleWrapper>
        <EventCellMobileCardContentTitle>
          {eventName(event)}
        </EventCellMobileCardContentTitle>
      </EventCellMobileCardContentTitleWrapper>

      <EventCellMobileCardPrice>
        {eventBuyButtonTitle(event)}
      </EventCellMobileCardPrice>
    </EventCellMobileCardContentWrapper>
  );
});

export default KTEventCellMobileCardContent;
