import React, { memo } from "react";
import {
  SearchDropdownEventCellDateTitle,
  SearchDropdownEventCellDateWrapper,
  SearchDropdownEventCellTimeTitle,
  SearchDropdownEventCellTitle,
  SearchDropdownEventCellWeekTitle,
  SearchDropdownEventCellWrapper,
} from "./style";
import { format, parseISO } from "date-fns";

const KTSearchDropdownEventCell = memo(({ event, onClick }) => {
  const now = new Date();
  const currentYear = now.getFullYear();

  const date = parseISO(`${event.date} ${event?.time ?? "00:00:00"}`);
  const year = parseInt(format(date, "yyyy"));
  const mmdd = format(date, "MMM dd");

  let dateMessage = mmdd;
  if (currentYear !== year) {
    dateMessage = `${mmdd}\n${year}`;
  }

  const week = format(date, "EEE");
  const time = format(date, "h:mm a");

  return (
    <SearchDropdownEventCellWrapper
      onClick={() => {
        onClick(event);
      }}
    >
      <SearchDropdownEventCellDateWrapper>
        <SearchDropdownEventCellDateTitle>
          {dateMessage}
        </SearchDropdownEventCellDateTitle>
        <SearchDropdownEventCellWeekTitle>
          {week}
        </SearchDropdownEventCellWeekTitle>
        <SearchDropdownEventCellTimeTitle>
          {time}
        </SearchDropdownEventCellTimeTitle>
      </SearchDropdownEventCellDateWrapper>
      <SearchDropdownEventCellTitle>
        {event?.name ?? ""}
      </SearchDropdownEventCellTitle>
    </SearchDropdownEventCellWrapper>
  );
});

export default KTSearchDropdownEventCell;
