import React, { memo } from 'react'
import { SearchDividerWrapper } from './style'
import { Divider } from 'antd'

const KTSearchDivider = memo(() => {
  return (
    <SearchDividerWrapper>
        <Divider/>
    </SearchDividerWrapper>
  )
})

export default KTSearchDivider