import React, { memo, useState } from "react";

import { Dropdown } from "antd";
import {
  SwitchCityDtpDropdownItemsWrapper,
  SwitchCityDtpDropdownWrapper,
} from "./style";
import { getLocation } from "../../../services/location";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChangeCityAction } from "../../../global/store/actionCreators";
import KTSearchDtpInputCell from "./c-components/dtp-search-input";
import KTDropdownDtpCurrentLocationCell from "./c-components/dtp-current-location-cell";
import KTDropdownDtpAnyLocationCell from "./c-components/dtp-any-location-cell";
import KTDropdownDtpMatchingCell from "./c-components/dtp-matching-cell";
import KTDropdownDtpNoMatchingCell from "./c-components/dtp-no-matches-cell";

const KTSwitchCityDtpDropdown = memo((props) => {
  const [searchKeywords, setSearchKeywords] = useState("");
  const [arrResults, setArrResults] = useState([]);

  const { placement = "bottomLeft" } = props;

  const { locationCity = {} } = useSelector(
    (state) => ({
      locationCity: state.getIn(["global", "locationCity"]),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const onCellItemClick = (e) => {
    switch (e.key) {
      case "1":
        dispatch(getChangeCityAction(locationCity));

        break;
      case "2":
        dispatch(getChangeCityAction({}));

        break;
      default:
        const element = arrResults[e.key - 3];
        dispatch(getChangeCityAction(element));

        break;
    }
  };

  var items = [
    {
      label: <KTDropdownDtpCurrentLocationCell />,
      key: 1,
    },
    {
      type: "divider",
    },
  ];

  if (searchKeywords.length === 0) {
    items.push({
      label: <KTDropdownDtpAnyLocationCell />,
      key: 2,
    });
  } else {
    arrResults.forEach((e, index) => {
      const title = `${e.name}, ${e.state}`;
      items.push({
        label: <KTDropdownDtpMatchingCell title={title} />,
        key: index + 3,
      });

      if (index < arrResults.length - 1) {
        items.push({
          type: "divider",
        });
      }
    });
  }

  const onSearchKeyChange = (value) => {
    setSearchKeywords(value);

    getLocation(value, (res) => {
      const r_list = res.list;

      setArrResults(r_list);
    });
  };

  function dropdownRender(menu) {
    return (
      <SwitchCityDtpDropdownWrapper>
        <KTSearchDtpInputCell onValueChange={onSearchKeyChange} />
        <SwitchCityDtpDropdownItemsWrapper>
          {React.cloneElement(menu, {
            style: { boxShadow: "none" },
          })}
          {searchKeywords.length > 0 && arrResults.length === 0
            ? [<KTDropdownDtpNoMatchingCell />]
            : null}
        </SwitchCityDtpDropdownItemsWrapper>
      </SwitchCityDtpDropdownWrapper>
    );
  }

  return (
    <Dropdown
      autoAdjustOverflow={false}
      destroyPopupOnHide={false}
      placement={placement}
      trigger={["click"]}
      menu={{ items, onClick: onCellItemClick, selectable: true }}
      dropdownRender={dropdownRender}
    >
      {props.children}
    </Dropdown>
  );
});

export default KTSwitchCityDtpDropdown;
