import styled from "styled-components";

export const SwitchCustomTimeIntervalDropdownWrapper = styled.div`
  padding: 30px 40px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 15px 20px 10px rgba(128, 0, 11, 0.03);

  display: flex;
  flex-direction: row;

  gap: 57px;
`;

export const SCTimeIntervalLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SCTimeIntervalRigthWrapper = styled.div``;
