import React, { memo } from "react";
import {
  SCTimeIntervalLeftWrapper,
  SCTimeIntervalRigthWrapper,
  SwitchCustomTimeIntervalDropdownWrapper,
} from "./style";
import { Dropdown } from "antd";
import KTSCTIDatePointMessage from "./c-components/scti-date-point-message";
import KTSCTICancelButton from "./c-components/scti-cancel-button";
import KTSpace from "@/components/space";
import KTSCTICalendar from "./c-components/scti-calendar";
import { format } from "date-fns";

const KTSwitchCustomTimeIntervalDropdown = memo(
  ({
    children,
    startdt,
    enddt,
    didIntervalValueChange,
    open,
    onCancelClick,
  }) => {
    const items = [];

    function dropdownRender() {
      return (
        <SwitchCustomTimeIntervalDropdownWrapper>
          <SCTimeIntervalLeftWrapper>
            <KTSCTICancelButton onCancelClick={onCancelClick} />
            <KTSpace height={"90px"} />
            <KTSCTIDatePointMessage
              title={"From"}
              content={
                startdt instanceof Date ? format(startdt, "yyyy-MM-dd") : null
              }
            />
            <KTSpace height={"10px"} />
            <KTSCTIDatePointMessage
              title={"To"}
              content={
                enddt instanceof Date ? format(enddt, "yyyy-MM-dd") : null
              }
            />
          </SCTimeIntervalLeftWrapper>
          <SCTimeIntervalRigthWrapper>
            <KTSCTICalendar
              startdt={startdt}
              enddt={enddt}
              didIntervalValueChange={didIntervalValueChange}
            />
          </SCTimeIntervalRigthWrapper>
        </SwitchCustomTimeIntervalDropdownWrapper>
      );
    }
    return (
      <Dropdown
        autoAdjustOverflow={false}
        destroyPopupOnHide={false}
        placement={"bottomLeft"}
        trigger={["click"]}
        menu={{ items }}
        dropdownRender={dropdownRender}
        open={open}
        onOpenChange={(value) => {
          if (value === false) {
            onCancelClick();
          }
        }}
      >
        {children}
      </Dropdown>
    );
  }
);

export default KTSwitchCustomTimeIntervalDropdown;
