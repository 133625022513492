import React, { memo } from "react";
import KTHomeHeader from "./c-components/home-header";
import { renderRoutes } from "react-router-config";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import withWindowSize from "@/components/higher-order/withWindowSize";

const KTHome = memo(({ route, windowSize }) => {
  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <KTHomeHeader />
      {renderRoutes(route.routes)}
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTHome);
