/**
 * 0- default, popularity, 1 - date , 2 - price low to hight, 3 - price high to low
 */
export const musicsAllEventsSortModes = [
  { title: "Sort by date", sort: 1 },
  { title: "Sort by Popularity", sort: 0 },
  { title: "Price low to high", sort: 2 },
  { title: "Price high to low", sort: 3 },
];

/**
 * 0: Any Dates
 * 1:This Weekend
 * 2:Next Weekend
 * 3:Next 7 Days
 * 4:Next 30 Days
 * 5:Next 60 Days
 * 6:Custom
 */
export const musicFilterDateIntervals = [
  {
    title: "Any Dates",
    value: "0",
  },
  {
    title: "This Weekend",
    value: "1",
  },
  {
    title: "Next Weekend",
    value: "2",
  },
  {
    title: "Next 7 Days",
    value: "3",
  },
  {
    title: "Next 30 Days",
    value: "4",
  },
  {
    title: "Next 60 Days",
    value: "5",
  },
  {
    title: "Custom",
    value: "6",
  },
];

export const musicsGenres = [
  {
    title: "All",
    normalImageName: require("@/assets/img/music/genres/icon-all-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-all-unselected@3x-1.png"),
    keyWord: "",
  },

  {
    title: "Festival\n/Tour",
    normalImageName: require("@/assets/img/music/genres/icon-festival-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-festival-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-fest-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-fest-selected.png"),
    keyWord: "Festival / Tour",
  },
  {
    title: "Pop\n/Rock",
    normalImageName: require("@/assets/img/music/genres/icon-pop-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-pop-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-pop-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-pop-selected.png"),
    keyWord: "Pop / Rock",
  },
  {
    title: "Rap\n/Hip-hop",
    normalImageName: require("@/assets/img/music/genres/icon-rap-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-rap-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-rap-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-rap-selected.png"),
    keyWord: "Rap,Hip Hop",
  },
  {
    title: "Country\n/Folk",
    normalImageName: require("@/assets/img/music/genres/icon-country-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-country-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-country-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-country-selected.png"),
    keyWord: "Country,Folk",
  },
  {
    title: "R&B\n/Soul",
    normalImageName: require("@/assets/img/music/genres/icon-rnb-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-rnb-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-rnb-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-rnb-selected.png"),
    keyWord: "RB-Soul",
  },
  {
    title: "Alternative",
    normalImageName: require("@/assets/img/music/genres/icon-alternative-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-alternative-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-alternative-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-alternative-selected.png"),
    keyWord: "Alternative",
  },
  {
    title: "Jazz\n/Blues",
    normalImageName: require("@/assets/img/music/genres/icon-jazz-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-jazz-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-jazz-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-jazz-selected.png"),
    keyWord: "Jazz,Blues",
  },
  {
    title: "Electronica\n/Techno",
    normalImageName: require("@/assets/img/music/genres/icon-elec-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-elec-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-elec-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-elec-selected.png"),
    keyWord: "Techno / Electronica",
  },
  {
    title: "Latin",
    normalImageName: require("@/assets/img/music/genres/icon-latin-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-latin-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-latin-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-latin-selected.png"),
    keyWord: "Latin",
  },
  {
    title: "Classical",
    normalImageName: require("@/assets/img/music/genres/icon-classical-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-classical-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-classical-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-classical-selected.png"),
    keyWord: "Classical",
  },
  {
    title: "Reggae",
    normalImageName: require("@/assets/img/music/genres/icon-reggae-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-reggae-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-reggae-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-reggae-selected.png"),
    keyWord: "Reggae / Reggaeton",
  },
  {
    title: "Hard Rock",
    normalImageName: require("@/assets/img/music/genres/icon-hardrock-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-hardrock-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-hardrock-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-hardrock-selected.png"),
    keyWord: "Hard Rock",
  },
  {
    title: "Holiday",
    normalImageName: require("@/assets/img/music/genres/icon-holiday-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-holiday-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-holiday-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-holiday-selected.png"),
    keyWord: "Holiday",
  },
  {
    title: "World",
    normalImageName: require("@/assets/img/music/genres/icon-world-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-world-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-world-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-world-selected.png"),
    keyWord: "World",
  },
  {
    title: "Bluegrass",
    normalImageName: require("@/assets/img/music/genres/icon-bluegrass-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-bluegrass-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-bluegrass-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-bluegrass-selected.png"),
    keyWord: "Bluegrass",
  },
  {
    title: "New Age",
    normalImageName: require("@/assets/img/music/genres/icon-newage-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-newage-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-newage-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-newage-selected.png"),
    keyWord: "New Age",
  },
  {
    title: "Religious",
    normalImageName: require("@/assets/img/music/genres/icon-religious-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-religious-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-religious-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-religious-selected.png"),
    keyWord: "Religious",
  },
  {
    title: "Children\n/Family",
    normalImageName: require("@/assets/img/music/genres/icon-children-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-children-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-children-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-children-selected.png"),
    keyWord: "Children,Family",
  },
  {
    title: "Las Vegas Shows",
    normalImageName: require("@/assets/img/music/genres/icon-lasvegas-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-lasvegas-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-lasvegas-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-lasvegas-selected.png"),
    keyWord: "Las Vegas Shows",
  },
  {
    title: "Other",
    normalImageName: require("@/assets/img/music/genres/icon-other-unselected@3x.png"),
    selectedImageName: require("@/assets/img/music/genres/icon-other-selected@3x.png"),
    d_normalImageName: require("@/assets/img/music/d-genres/icon-others-unselected.png"),
    d_selectedImageName: require("@/assets/img/music/d-genres/icon-others-selected.png"),
    keyWord: "Other",
  },
];
