import { styled } from "styled-components";

export const MAMenuHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 20rem;

  .closeButton {
    width: 36rem;
    height: 36rem;
    flex-shrink: 0;
  }
`;

export const MAMenuHeaderProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  padding-right: 10rem;
  height: 100rem;

  .portrait {
    width: 60rem;
    height: 60rem;
    flex-shrink: 0;
    border-radius: 60rem;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat, #ffdbb8;
  }

  .username {
    margin-top: 10rem;
    color: #000;
    text-align: right;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .email {
    margin-top: 4rem;
    color: var(--iconb3, #b3b3b3);
    text-align: right;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
