import React, { memo } from "react";
import {
  SCTIDatePointMessageContent,
  SCTIDatePointMessageTitle,
  SCTIDatePointMessageWrapper,
} from "./styled";

const KTSCTIDatePointMessage = memo(({ title, content }) => {
  return (
    <SCTIDatePointMessageWrapper>
      <SCTIDatePointMessageTitle>{title}</SCTIDatePointMessageTitle>
      <SCTIDatePointMessageContent>{content}</SCTIDatePointMessageContent>
    </SCTIDatePointMessageWrapper>
  );
});

export default KTSCTIDatePointMessage;
