import React, { memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChangeMusicTemporalIntervalAction } from "../../store/actionCreators";
import { MusicsFilterContentWrapper } from "./style";
import { Affix } from "antd";
import KTFilterContentHeader from "@/components/filter/filter-content-header";
import KTFilterContentDateRange from "@/components/filter/filter-content-date-range";
import { musicFilterDateIntervals } from "../../local-data";

const KTMusicsFilterContent = memo(({ onClose }) => {
  const dispatch = useDispatch();
  const { intervalType, startdt, enddt } = useSelector(
    (state) => ({
      intervalType: state.getIn(["musics", "intervalType"]),
      startdt: state.getIn(["musics", "startdt"]),
      enddt: state.getIn(["musics", "enddt"]),
    }),
    shallowEqual
  );

  function onChangeIntervalValue(ele) {
    dispatch(
      getChangeMusicTemporalIntervalAction(ele.value, ele.startdt, ele.enddt)
    );
  }

  function onResetClick() {
    dispatch(getChangeMusicTemporalIntervalAction("0", undefined, undefined));
  }

  return (
    <MusicsFilterContentWrapper>
      <Affix offsetTop={0}>
        <KTFilterContentHeader onClose={onClose} onReset={onResetClick} />
      </Affix>

      <KTFilterContentDateRange
        // windowWidth={windowWidth}
        dateIntervals={musicFilterDateIntervals}
        startdt={startdt}
        enddt={enddt}
        selectedIntervalValue={intervalType}
        didIntervalValueChange={onChangeIntervalValue}
      />
    </MusicsFilterContentWrapper>
  );
});

export default KTMusicsFilterContent;
