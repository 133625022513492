import styled from "styled-components";

export const ResultInfoCellWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
`;

export const ResultInfoBasic = styled.div`
  color: var(--tag-stoke-8c, #8c8c8c);
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (min-width: 481px) {
    font-size: 14px;
  }
`;

export const ResultInfoCancel = styled.div`
  color: var(--icon-4d-black, #4d4d4d);
  font-family: Inter;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) {
    font-size: 14px;
  }
`;
