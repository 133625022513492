import styled from "styled-components";

export const SCTIDatePointMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 157px;
  padding: 10px 0px;

  border-bottom: 1px solid #f2f2f2;
`;

export const SCTIDatePointMessageTitle = styled.div`
  color: #b3b3b3;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SCTIDatePointMessageContent = styled.div`
  color: #c8c8c8;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  height: 20px;
`;
