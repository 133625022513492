import React, { memo } from "react";
import { renderRoutes } from "react-router-config";

import routes from "./router";

import store from "./store";

import AppHeader from "@/components/app-header";
import AppFooter from "components/app-footer";
import { Provider } from "react-redux";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTAppHeaderMobile from "./components/mobile-components/app-header-mobile";
import { HashRouter } from "react-router-dom";
import KTGlobal from "./components/global";
import KTAppTopPublicity from "./components/app-top-publicity";



const App = memo(({ windowSize }) => {

  return (
    <Provider store={store}>
      <HashRouter>
        <KTGlobal />
        <KTAppTopPublicity />
        {windowSize.width > 480 ? <AppHeader /> : <KTAppHeaderMobile />}
        {renderRoutes(routes)}
        {windowSize.width > 480 ? <AppFooter /> : null}
      </HashRouter>
    </Provider>
  );
});

export default withWindowSize(App);
