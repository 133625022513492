import React, { memo } from "react";

import { AppFooterDivider, AppFooterWrapper } from "./style";
import KTAppFooterContent from "./c-components/app-footer-content";
import KTAppFooterCopyrightBar from "./c-components/app-footer-copyright-bar";

const KTAppFooter = memo(() => {
  return (
    <AppFooterWrapper>
      <AppFooterDivider />
      <KTAppFooterContent />
      <AppFooterDivider />
      <KTAppFooterCopyrightBar />
    </AppFooterWrapper>
  );
});

export default KTAppFooter;
