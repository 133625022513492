import React, { memo } from "react";
import {
  MainCategoryHeaderBackground,
  MainCategoryHeaderTitle,
  MainCategoryHeaderWrapper,
} from "./style";

const KTMainCategoryHeader = memo(({ backgroundSrc, title }) => {
  return (
    <MainCategoryHeaderWrapper>
      {title?.length > 0 ? (
        <MainCategoryHeaderTitle>{title}</MainCategoryHeaderTitle>
      ) : null}
      <MainCategoryHeaderBackground src={backgroundSrc} />
    </MainCategoryHeaderWrapper>
  );
});

export default KTMainCategoryHeader;
