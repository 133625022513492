import styled from "styled-components";

export const MusicsCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 0 60px;

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 20px 20px 0px rgba(242, 242, 242, 0.5);

  @media screen and (min-width: 1920px) {
    margin: 0 60px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    margin: 0 50px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    margin: 0;
  }
`;

export const MusicsCategorySelectAllWrapper = styled.div`
  display: flex;
  flex: row;
  padding: 30px 50px;

  @media screen and (min-width: 1920px) {
    padding: 30px 50px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    padding: 30px 30px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 30px 35px;
  }
`;

export const MusicsCategorySelectAll = styled.div`
  color: #003594;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
  user-select: none;
`;

export const MusicsCategoryListWrapper = styled.div`
  padding: 10px 60px 50px;

  display: grid;
  row-gap: 64px;
  column-gap: 46px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 78px);

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 10px 40px 20px;
    row-gap: 20px;
    column-gap: 30px;
  }
`;

export const MusicsCategoryItemWrapper = styled.div`
  width: 78px;
  height: 84px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  cursor: pointer;
  user-select: none;

  position: relative;
`;

export const MusicsCategoryItemMark = styled.div`
  width: 15px;
  height: 15px;

  border-radius: 7.5px;
  position: absolute;
  right: 0px;
  top: 0;
  background-color: ${(props) => (props.selected ? "#E82B42" : "#C8C8C8")};
`;

export const MusicsCategoryItemIcon = styled.img`
  width: 46px;
  height: 46px;
`;

export const MusicsCategoryItemTitle = styled.div`
  width: 100%;
  height: 34px;

  white-space: pre-line;

  color: ${(props) =>
    props.selected ? "var(--icon-4d-black, #4d4d4d)" : "#B3B3B3"};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
