import styled from "styled-components";
import { KT_THEME } from "../../common/constants";

export const AppHeaderWrapper = styled.div`
  height: 79px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.theme === KT_THEME.DEFAULT ? "1px solid #fff" : "1px solid #F2F2F2"};

  padding: 0 50px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    padding: 0 24px;
  }
`;
