import React, { memo, useState } from "react";
import { HomeTopSellingWrapper } from "./style";
import KTClassifyHeader from "@/components/classify/classify-header";
import KTClassifySubHeader from "@/components/classify/classify-sub-header";
import KTConcertsList from "@/components/concerts/concerts-list";
import KTSpace from "@/components/space";
import KTEventListHorizontal from "@/components/event/event-list-horizontal";
import { exploreEvents } from "@/common/ephemeral-data";
import KTEventSimple from "@/components/event/event-simple";
import KTMobileEventList from "@/components/mobile-components/mobile-event-list";
import { LAYOUT_MODE_CARD } from "@/common/constants";
import { homeContentEventItems } from "@/common/ephemeral-data";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTTopSellingFilterBar from "./c-components/top-selling-filter-bar";
import KTGridView from "@/components/scroll-view/grid-view";
import KTConcertInfoItem from "@/components/concerts/concert-info-item";
import { currValue } from "@/utils";
import KTEventCellRightImage from "@/components/event/event-cell-right-image";
import KTClassifySubMultipleDesktop from "../../../../components/classify/calssify-sub-multiple-desktop";
import KTEventCellShow from "@/components/event/event-cell-show";

const KTHomeTopSelling = memo(({ windowSize }) => {
  const [selectedTitle, setSelectedTitle] = useState("Music");

  const concerts = [
    {
      name: "Taylor Swift",
      portrait: "artist01.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },
    {
      name: "Artist Name",
      portrait: "artist02.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "William Anderson",
      portrait: "artist03.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Emily Davis",
      portrait: "artist04.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Benjamin Foster",
      portrait: "artist05.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Olivia Parker",
      portrait: "artist06.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist07.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist08.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist09.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },

    {
      name: "Artist Name",
      portrait: "artist10.png",
      portraitDes: "elizeu-dias-aZ3qiq1eTRk-unsplash.png",
    },
  ];

  const musicSubItems = [
    {
      title: "All",
      emoji: "🤩",
    },
    {
      title: "Concerts",
      emoji: "⚾",
    },
    {
      title: "Festivals",
      emoji: "🏀",
    },
  ];

  const sportsSubItems = [
    {
      title: "All",
      emoji: "🤩",
    },
    {
      title: "NFL",
      emoji: "🏈",
    },
    {
      title: "MLB",
      emoji: "⚾",
    },
    {
      title: "NBA",
      emoji: "🏀",
    },
    {
      title: "NHL",
      emoji: "🏑",
    },
    {
      title: "MSL",
      emoji: "⚽",
    },
    {
      title: "Tennis",
      emoji: "🎾",
    },
    {
      title: "Golf",
      emoji: "⛳",
    },
    {
      title: "MMA",
      emoji: "🥊",
    },
  ];

  const showsSubItems = [
    {
      title: "All",
      emoji: "🤩",
    },
    {
      title: "Broadway",
      emoji: "🏈",
    },
    {
      title: "Children’s Theater",
      emoji: "⚾",
    },
    {
      title: "Opera",
      emoji: "🏀",
    },
    {
      title: "Musical",
      emoji: "🏑",
    },
    {
      title: "Stand-up",
      emoji: "⚽",
    },
    {
      title: "Comedy",
      emoji: "🎾",
    },
    {
      title: "Circus Shows",
      emoji: "⛳",
    },
    {
      title: "Dance",
      emoji: "🥊",
    },
    {
      title: "Cultural",
      emoji: "🥊",
    },
    {
      title: "Interactive/immersive Arts",
      emoji: "🥊",
    },
    {
      title: "Public Speaking",
      emoji: "🥊",
    },
  ];

  const windowWidth = windowSize.width;

  const musicChilden = [
    <KTSpace height={"30px"} />,
    <KTClassifySubHeader
      items={musicSubItems}
      windowWidth={windowWidth}
      onChangeItem={(item) => console.log(item)}
    />,
    <KTSpace height={"70px"} />,
    <KTGridView
      windowWidth={windowWidth}
      items={concerts}
      rowGap={"40px"}
      columnGap={currValue(12, 55, windowWidth)}
      gridTemplateColumns={"repeat(auto-fill, 300px)"}
      cellOfItem={(item) => {
        return <KTConcertInfoItem concert={item} />;
      }}
    />,
    <KTSpace height={"240px"} />,
  ];

  const sportsChilden = [
    <KTSpace height={"30px"} />,
    <KTClassifySubHeader
      items={sportsSubItems}
      windowWidth={windowWidth}
      onChangeItem={(item) => console.log(item)}
    />,
    <KTSpace height={"70px"} />,
    <KTGridView
      windowWidth={windowWidth}
      items={homeContentEventItems}
      rowGap={"80px"}
      columnGap={currValue(40, 60, windowWidth)}
      gridTemplateColumns={"repeat(auto-fill, 400px)"}
      cellOfItem={(item) => {
        return <KTEventCellRightImage event={item} />;
      }}
    />,
    <KTSpace height={"240px"} />,
  ];

  const showsChilden = [
    <KTSpace height={"30px"} />,
    <KTClassifySubMultipleDesktop
      items={showsSubItems}
      windowWidth={windowWidth}
      hiddenLine={true}
      onChangeItem={(item) => console.log(item)}
    />,
    <KTSpace height={"30px"} />,
    <KTGridView
      windowWidth={windowWidth}
      items={homeContentEventItems}
      rowGap={"50px"}
      columnGap={currValue(40, 60, windowWidth)}
      gridTemplateColumns={"repeat(auto-fill, 400px)"}
      cellOfItem={(item) => {
        return <KTEventCellShow event={item} />;
      }}
    />,
    <KTSpace height={"240px"} />,
  ];

  function getHomeTopSellingWrapperChilden(windowWidth) {
    if (windowWidth > 480) {
      let children = [
        <KTSpace height={"80px"} />,
        <KTClassifyHeader
          title={"Top Selling in the states "}
          hiddenViewAll={true}
          windowWidth={windowWidth}
        />,
        <KTTopSellingFilterBar
          selectedTitle={selectedTitle}
          windowWidth={windowWidth}
          didChangeSelectedTitle={(title) => setSelectedTitle(title)}
        />,
      ];

      if (selectedTitle === "Music") {
        children.push([...musicChilden]);
      }

      if (selectedTitle === "Sports") {
        children.push([...sportsChilden]);
      }

      if (selectedTitle === "Shows") {
        children.push([...showsChilden]);
      }

      return children;
    } else {
      return [
        <KTSpace height={"40rem"} />,
        <KTClassifyHeader
          title={"Concerts"}
          onClickViewAll={() => console.log("Concerts view all")}
        />,
        <KTSpace height={"30rem"} />,
        <KTConcertsList concerts={concerts} />,

        <KTSpace height={"54rem"} />,
        <KTClassifyHeader
          title={"Festivals"}
          onClickViewAll={() => console.log("Festivals view all")}
        />,
        <KTSpace height={"30rem"} />,
        <KTEventListHorizontal
          events={exploreEvents}
          cellOfEvent={(event) => {
            return <KTEventSimple event={event} />;
          }}
        />,
        <KTSpace height={"54rem"} />,
        <KTClassifyHeader
          title={"Shows"}
          onClickViewAll={() => console.log("Shows view all")}
        />,
        <KTSpace height={"30rem"} />,
        <KTEventListHorizontal
          events={exploreEvents}
          cellOfEvent={(event) => {
            return <KTEventSimple event={event} />;
          }}
        />,

        <KTSpace height={"54rem"} />,
        <KTClassifyHeader
          title={"Sports"}
          onClickViewAll={() => console.log("Sports view all")}
        />,
        <KTSpace height={"30rem"} />,
        <KTClassifySubHeader
          items={sportsSubItems}
          onChangeItem={(item) => console.log(item)}
        />,
        <KTSpace height={"16rem"} />,
        <KTMobileEventList events={[]} layoutMode={LAYOUT_MODE_CARD} />,
      ];
    }
  }

  return (
    <HomeTopSellingWrapper>
      {getHomeTopSellingWrapperChilden(windowWidth)}
    </HomeTopSellingWrapper>
  );
});

export default withWindowSize(KTHomeTopSelling);
