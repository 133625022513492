import { GLOBAL_PAGESIZE } from "../common/constants";
import request from "./request";

export function getPerformers(
  pagenum = "1",
  performer = "",
  performerid = "",
  callback,
  cancelSource
) {
  console.log("tn_performers: ", performer);
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getPerformers",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        performer: performer,
        performerid: performerid,
      },
      config
    )
    .then((res) => {
      let tn_performers = res.tn_performers;
      const kt_performers = res.kt_performers;

      console.log("tn_performers: ", tn_performers);

      let kt_performers_map = {};

      // 处理 performers 头像数据
      kt_performers.forEach((element) => {
        kt_performers_map[element.id] = element;
      });

      tn_performers.forEach((e) => {
        if (e.id in kt_performers_map) {
          e.kt_info = kt_performers_map[e.id];
        }
      });

      if (typeof callback === "function") {
        callback(tn_performers);
      }
    });
}

export function getPerformersOfKeywords(
  pagenum = "1",
  keywords = "",
  callback,
  cancelSource
) {
  console.log("getPerformersOfKeywords: ", keywords);
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  request
    .post(
      "getPerformers",
      {
        pagenum: pagenum,
        pagesize: GLOBAL_PAGESIZE,
        performer: keywords,
      },
      config
    )
    .then((res) => {
      let tn_performers = res.tn_performers;
      const kt_performers = res.kt_performers;

      console.log("tn_performers: ", tn_performers);

      let kt_performers_map = {};

      // 处理 performers 头像数据
      // 处理 performers 头像数据
      kt_performers.forEach((element) => {
        kt_performers_map[element.id] = element;
      });

      tn_performers.forEach((e) => {
        if (e.id in kt_performers_map) {
          e.kt_info = kt_performers_map[e.id];
        }
      });

      if (typeof callback === "function") {
        callback(tn_performers);
      }
    });
}

export function getPerformerOfId(performerid = "", callback, cancelSource) {
  const config = cancelSource ? { cancelToken: cancelSource.token } : {};
  console.log();
  request
    .post(
      "getPerformers",
      {
        pagenum: 1,
        pagesize: GLOBAL_PAGESIZE,
        performerid: performerid,
      },
      config
    )
    .then((res) => {
      let tn_performers = res.tn_performers;
      const kt_performers = res.kt_performers;

      let kt_performers_map = {};

      // 处理 performers 头像数据
      // 处理 performers 头像数据
      kt_performers.forEach((element) => {
        kt_performers_map[element.id] = element;
      });

      tn_performers.forEach((e) => {
        if (e.id in kt_performers_map) {
          e.kt_info = kt_performers_map[e.id];
        }
      });

      if (typeof callback === "function") {
        callback(tn_performers[0]);
      }
    });
}
