import React, { memo } from "react";
import {
  SwitchTimeIntervalDropdownCell,
  SwitchTimeIntervalDropdownItemsWrapper,
  SwitchTimeIntervalDropdownWrapper,
} from "./style";
import { Dropdown } from "antd";
import { globalFilterDateIntervals } from "../../../global/local-data";
import { useDispatch } from "react-redux";
import { getChangeGlobalTemporalIntervalAction } from "../../../global/store/actionCreators";
import { getChangeHomeTemporalIntervalAction } from "../../../pages/home/store/actionCreators";
import { getChangeMusicTemporalIntervalAction } from "../../../pages/musics/store/actionCreators";
import { getChangeSportsTemporalIntervalAction } from "../../../pages/sports/store/actionCreators";
import { getChangeShowsTemporalIntervalAction } from "../../../pages/shows/store/actionCreators";

const KTSwitchTimeIntervalDropdown = memo(
  ({ placement = "bottomLeft", children, onCustomIntervalClick }) => {
    const dispatch = useDispatch();

    const items = globalFilterDateIntervals
      .map((ele, index) => {
        return [
          {
            label: (
              <SwitchTimeIntervalDropdownCell>
                {ele.title}
              </SwitchTimeIntervalDropdownCell>
            ),
            key: index,
          },
          {
            type: "divider",
            style: { margin: "0px 20px" },
          },
        ];
      })
      .flat();

    const onCellItemClick = ({ key }) => {
      const dateRange = globalFilterDateIntervals[key];
      dispatch(getChangeGlobalTemporalIntervalAction(dateRange.value));
      dispatch(getChangeHomeTemporalIntervalAction(dateRange.value));
      dispatch(getChangeMusicTemporalIntervalAction(dateRange.value));
      dispatch(getChangeSportsTemporalIntervalAction(dateRange.value));
      dispatch(getChangeShowsTemporalIntervalAction(dateRange.value));
      if (dateRange.value === "6") {
        onCustomIntervalClick();
      }
    };

    function dropdownRender(menu) {
      return (
        <SwitchTimeIntervalDropdownWrapper>
          <SwitchTimeIntervalDropdownItemsWrapper>
            {React.cloneElement(menu, {
              style: { boxShadow: "none" },
            })}
          </SwitchTimeIntervalDropdownItemsWrapper>
        </SwitchTimeIntervalDropdownWrapper>
      );
    }

    return (
      <Dropdown
        destroyPopupOnHide={false}
        placement={placement}
        trigger={["click"]}
        menu={{ items, onClick: onCellItemClick, selectable: true }}
        dropdownRender={dropdownRender}
      >
        {children}
      </Dropdown>
    );
  }
);

export default KTSwitchTimeIntervalDropdown;
