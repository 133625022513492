import React, { memo, useState } from "react";
import {
  HomeDesktopFilterBarDivider,
  HomeDesktopFilterBarLeftWrapper,
  HomeDesktopFilterBarRightWrapper,
  HomeDesktopFilterBarWrapper,
  HomeDesktopFilterDropdownTitle,
  HomeDesktopFilterDropdownWrapper,
} from "./style";
import KTHomeDesktopFilterButton from "./c-components/home-desktop-filter-button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReactComponent as CardLayoutIcon } from "@/assets/img/icons/icon-layout-card.svg";
import { ReactComponent as ListLayoutIcon } from "@/assets/img/icons/icon-layout-list.svg";
import classNames from "classnames";
import {
  LAYOUT_MODE_CARD,
  LAYOUT_MODE_LIST,
} from "../../../../../../common/constants";
import {
  getChangeHomeCategoriesAction,
  getChangeHomeLayoutModeAction,
  getChangeHomeSortModeAction,
  getChangeHomeTemporalIntervalAction,
} from "../../../../store/actionCreators";
import { Dropdown } from "antd";
import { homeAllEventsSortModes } from "../../../../../../common/home/home-data";
import {
  allEventsFilterDesktopCategorys,
  homeFilterDateIntervals,
} from "../../../../local-data";
import KTSwitchCityDtpDropdown from "../../../../../../components/dropdown/switch-city-dtp-dropdown";
import { getGlobalDateString } from "../../../../../../utils/tool-time-intervcal";
import KTSwitchCustomTimeIntervalDropdown from "../../../../../../components/dropdown/switch-custom-time-interval-dropdown";

const KTHomeDesktopFilterBar = memo(() => {
  const dispatch = useDispatch();

  const [customTimeIntervalOpen, setCustomTimeIntervalOpen] = useState(false);

  const {
    sortMode,
    layoutMode,
    categories,
    intervalType,
    startdt,
    enddt,
    city,
  } = useSelector(
    (state) => ({
      sortMode: state.getIn(["home", "sortMode"]),
      layoutMode: state.getIn(["home", "layoutMode"]),
      categories: state.getIn(["home", "categories"]),
      intervalType: state.getIn(["home", "intervalType"]),
      startdt: state.getIn(["home", "startdt"]),
      enddt: state.getIn(["home", "enddt"]),
      city: state.getIn(["global", "city"]),
    }),
    shallowEqual
  );

  const onclickChangeLayoutMode = (mode) => {
    dispatch(getChangeHomeLayoutModeAction(mode));
  };

  let categoryTitle = "All Categories";
  if (categories.length === 1) {
    categoryTitle = categories[0].title;
  }

  function dropdownRender(menu) {
    return (
      <HomeDesktopFilterDropdownWrapper>
        {React.cloneElement(menu, {
          style: { boxShadow: "none" },
        })}
      </HomeDesktopFilterDropdownWrapper>
    );
  }

  // city
  let cityMessage = "Any Location";

  if (typeof city === "object" && Object.keys(city).length > 0) {
    const name = city.name ?? city.city ?? "";
    const state = city.state ?? "";
    if (name.length === 0 || state.length === 0) {
      cityMessage = `${name}${city.state}`;
    } else {
      cityMessage = `${name}, ${city.state}`;
    }
  }

  // sort
  const sortItems = homeAllEventsSortModes
    .map((ele, index) => {
      const resultArr = [
        {
          label: (
            <HomeDesktopFilterDropdownTitle>
              {ele.title}
            </HomeDesktopFilterDropdownTitle>
          ),
          key: index,
        },
      ];

      resultArr.push({
        type: "divider",
        style: { margin: "0 0" },
      });

      return resultArr;
    })
    .flat();

  const onSortModeClick = ({ key }) => {
    const sortMode = homeAllEventsSortModes[key];
    dispatch(getChangeHomeSortModeAction(sortMode));
  };

  // categories
  const categoryItems = allEventsFilterDesktopCategorys
    .map((ele, index) => {
      const resultArr = [
        {
          label: (
            <HomeDesktopFilterDropdownTitle>
              {ele.title}
            </HomeDesktopFilterDropdownTitle>
          ),
          key: index,
        },
      ];

      resultArr.push({
        type: "divider",
        style: { margin: "0 0" },
      });

      return resultArr;
    })
    .flat();

  const onCategoryClick = ({ key }) => {
    const category = allEventsFilterDesktopCategorys[key];
    dispatch(getChangeHomeCategoriesAction([category]));
  };

  // date range

  const dateMessage = getGlobalDateString(
    homeFilterDateIntervals,
    intervalType,
    startdt,
    enddt
  );

  const dateRangeItems = homeFilterDateIntervals
    .map((ele, index) => {
      const resultArr = [
        {
          label: (
            <HomeDesktopFilterDropdownTitle>
              {ele.title}
            </HomeDesktopFilterDropdownTitle>
          ),
          key: index,
        },
      ];

      resultArr.push({
        type: "divider",
        style: { margin: "0 0" },
      });

      return resultArr;
    })
    .flat();

  const onDateRangeClick = ({ key }) => {
    const dateRange = homeFilterDateIntervals[key];
    dispatch(getChangeHomeTemporalIntervalAction(dateRange.value));

    if (key === "6") {
      setCustomTimeIntervalOpen(true);
    }
  };

  return (
    <HomeDesktopFilterBarWrapper>
      <HomeDesktopFilterBarLeftWrapper>
        <Dropdown
          autoAdjustOverflow={false}
          menu={{ items: sortItems, onClick: onSortModeClick }}
          trigger={["click"]}
          dropdownRender={dropdownRender}
        >
          <KTHomeDesktopFilterButton
            title={sortMode.title}
            styled={1}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>

        <HomeDesktopFilterBarDivider />

        <KTSwitchCityDtpDropdown>
          <KTHomeDesktopFilterButton
            title={cityMessage}
            onClick={(e) => e.preventDefault()}
          />
        </KTSwitchCityDtpDropdown>

        <Dropdown
          autoAdjustOverflow={false}
          menu={{ items: categoryItems, onClick: onCategoryClick }}
          trigger={["click"]}
          dropdownRender={dropdownRender}
        >
          <KTHomeDesktopFilterButton
            title={categoryTitle}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>

        <KTSwitchCustomTimeIntervalDropdown
          open={customTimeIntervalOpen}
          onCancelClick={() => {
            setCustomTimeIntervalOpen(false);
          }}
          startdt={startdt}
          enddt={enddt}
          didIntervalValueChange={(ele) => {
            dispatch(
              getChangeHomeTemporalIntervalAction("6", ele.startdt, ele.enddt)
            );
          }}
        >
          <Dropdown
            autoAdjustOverflow={false}
            menu={{ items: dateRangeItems, onClick: onDateRangeClick }}
            trigger={["click"]}
            dropdownRender={dropdownRender}
          >
            <KTHomeDesktopFilterButton
              title={dateMessage}
              onClick={(e) => e.preventDefault()}
            />
          </Dropdown>
        </KTSwitchCustomTimeIntervalDropdown>
      </HomeDesktopFilterBarLeftWrapper>
      <HomeDesktopFilterBarRightWrapper>
        <CardLayoutIcon
          className={classNames("layoutModel", {
            activeLayoutModel: layoutMode === LAYOUT_MODE_CARD,
          })}
          onClick={() => onclickChangeLayoutMode(LAYOUT_MODE_CARD)}
        />
        <ListLayoutIcon
          className={classNames("layoutModel", {
            activeLayoutModel: layoutMode === LAYOUT_MODE_LIST,
          })}
          onClick={() => onclickChangeLayoutMode(LAYOUT_MODE_LIST)}
        />
      </HomeDesktopFilterBarRightWrapper>
    </HomeDesktopFilterBarWrapper>
  );
});

export default KTHomeDesktopFilterBar;
