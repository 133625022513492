import { styled } from "styled-components";

export const ShowsWrapper = styled.div`
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;

  .header {
    position: absolute;
    top: -80px;
    height: 800px;
    width: 100%;
    z-index: -1;
    background-color: black;
  }

  @media screen and (max-width: ${480}px) {
    .header {
      top: -180rem;
      height: 520rem;
    }
  }
`;
