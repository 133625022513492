import React, { memo } from "react";
import {
  ResultHeaderBackgroundImage,
  ResultHeaderTitle,
  ResultHeaderWrapper,
} from "./style";

const KTResultHeader = memo(({ title = "" }) => {
  return (
    <ResultHeaderWrapper>
      <ResultHeaderBackgroundImage
        src={require("@/assets/img/home/mobild-bg-header.jpg")}
      />
      <ResultHeaderTitle>{title}</ResultHeaderTitle>
    </ResultHeaderWrapper>
  );
});

export default KTResultHeader;
