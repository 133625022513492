import { styled } from "styled-components";

export const MobileAppMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MobileAppMenuSpace = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  min-height: 88rem;
`;
