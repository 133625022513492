import styled, { css } from "styled-components";
import { currValue3 } from "@/utils";

export const FilterContentDateRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30rem;

  @media screen and (min-width: 481px) {
    ${({ windowWidth }) => css`
      padding-top: ${currValue3(30, 40, 40, windowWidth)};
    `}
  }
`;

export const FilterContentDateRangeHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20rem;
  gap: 10rem;
  padding-bottom: 0;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    padding: 10rem 0;

    color: #000;
    font-family: Inter;
    font-size: 20rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .divider {
    height: 1rem;
    background: #f2f2f2;
  }

  @media screen and (min-width: 481px) {
    ${({ windowWidth }) => css`
      padding-left: ${currValue3(24, 40, 40, windowWidth)};
      padding-right: ${currValue3(24, 40, 40, windowWidth)};
    `}
    padding-top: 0;
    padding-bottom: 0;
    gap: 20px;

    .title {
      width: 200px;
      height: 36px;

      font-size: 30px;
    }
    .divider {
      height: 1px;
    }
  }
`;

export const FilterContentDateRangeListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20rem;
  gap: 14rem;

  .item {
    border-radius: 20rem;
    border: 1rem solid #c8c8c8;
    background: #fff;
    padding: 6rem 14rem;

    text-align: center;
    color: var(--iconb3, #b3b3b3);
    font-family: Inter;
    font-size: 13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 16.25px */

    cursor: pointer;
  }

  .itemSelected {
    color: #fff;
    background: var(--bg-piclist, #111120);
    border: 1rem solid var(--tag-stoke-8c, #111120);
  }

  @media screen and (min-width: 481px) {
    ${({ windowWidth }) => css`
      padding-left: ${currValue3(24, 40, 40, windowWidth)};
      padding-right: ${currValue3(24, 40, 40, windowWidth)};
    `}

    padding-top: 30px;
    padding-bottom: 0;
    gap: 20px;

    .item {
      border-radius: 20px;
      border: 1px solid var(--tag-stoke-8c, #8c8c8c);
      padding: 6px 14px;
      font-size: 13px;
    }

    .itemSelected {
      border: 1px solid var(--tag-stoke-8c, #111120);
    }
  }
`;

export const FilterContentDateRangeCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .headerWrapper {
    width: calc(100% - 54rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .headerDateNodeWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: 481px) {
    ${({ windowWidth }) => css`
      padding-left: ${currValue3(24, 40, 40, windowWidth)};
      padding-right: ${currValue3(24, 40, 40, windowWidth)};
    `}
    padding-top: 40px;

    .headerDateNodeWrapper {
      width: 360px;
      padding: 0 50px;
    }
  }
`;

export const FilterContentDateNodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 122rem;
  gap: 10rem;
  padding: 10rem 0;

  border-bottom: 1rem solid #8c8c8c;

  .title {
    min-width: 34rem;
    color: var(--iconb3, #b3b3b3);
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .content {
    color: #000;
    font-family: Inter;
    font-size: 15rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media screen and (min-width: 481px) {
    min-width: 122px;
    gap: 10px;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #8c8c8c;

    .title {
      min-width: 34px;
      font-size: 14px;
    }

    .content {
      font-size: 14px;
    }
  }
`;
