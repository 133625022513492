import { styled } from "styled-components";

export const HomeHeaderWrapper = styled.div`
  position: relative;
`;

export const HomeHeaderBGImage = styled.img`
  position: absolute;
  top: -184rem;
  height: calc(100% + 184rem);
  width: 100%;
  object-fit: cover;
  z-index: -1;
  

  @media screen and (min-width: ${481}px) {
    position: absolute;
    top: -80px;
    height: calc(100% + 80px);
    width: 100%;
    object-fit: cover;
    z-index: -1;
  
  }
`;

// padding: 8rem 20rem;
export const NoFees = styled.div`

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;



position: relative;

@media screen and (min-width: 1920px) {
  padding: 40px 900px;
  font-size: 50px;
}

@media screen and (min-width: 1024px) and (max-width: 1919px) {
  padding: 30px 710px;
  font-size: 40px;
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  padding: 20px 200px;
  font-size: 18px;
}

@media screen and (min-width: 480px)  {
  padding-top: 60px;
  padding-left: 100px;
  padding-right:100px;
  padding-bottom: 0px;

  font-size: 28px;
}

  color: #FFFFFF;
  font-family: Inter;
  font-size: 18rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 28px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
`;