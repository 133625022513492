import React, { memo } from "react";
import { DateViewWrapper } from "./style";

const KTDateView = memo(() => {
  return (
    <DateViewWrapper>
      <div className="date">Oct. 18</div>
      <div className="divider"> | </div>
      <div className="time">01:10 pm</div>
      <div className="divider"> | </div>
      <div className="week">MON</div>
    </DateViewWrapper>
  );
});

export default KTDateView;
