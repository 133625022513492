import { styled } from "styled-components";

export const AppHeaderMobileSearchWrapper = styled.div`
  margin-top: -18rem;
  margin-left: 20rem;
  margin-right: 20rem;

  border-radius: 10rem;
  background: #fff;

  display: flex;
  flex-direction: column;
  position: relative;
  

  .divider {
    background: #d9d9d9;
    height: 1rem;
    margin: 0 10rem;
  }
`;
