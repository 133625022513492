import React, { memo } from "react";
import { ListViewHorizontalWrapper, NavigationWrapper } from "./style";
import { currValue } from "@/utils";
import { Swiper, SwiperSlide } from "swiper/react";

import { ReactComponent as ArrowLeft } from "@/assets/img/icons/icon-arrow-circle-left.svg";
import { ReactComponent as ArrowRight } from "@/assets/img/icons/icon-arrow-circle-right.svg";

const KTListViewHorizontal = memo(
  ({
    items = [],
    windowWidth = window.screen.width,
    spaceBetween = "20px",
    swiperPadding = windowWidth > 480
      ? `0 ${currValue(50, 100, windowWidth)}`
      : "0",
    cellOfItem = (item, index) => {
      return <div />;
    },
    showSwiperControl = false,
    showBottomLine = false,
  }) => {

    const slides = items.map((event, index) => {
      return (
        <SwiperSlide className="ktSwiperSlide">
          {cellOfItem(event, index)}
        </SwiperSlide>
      );
    });

    let swiper;

    return (
      <ListViewHorizontalWrapper>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={spaceBetween}
          style={{ padding: swiperPadding }}
          onInit={(s) => (swiper = s)}
          onResize={(s) => (swiper = s)}
        >
          {slides}
        </Swiper>
        {showSwiperControl ? (
          <NavigationWrapper
            style={{
              padding:
                windowWidth > 480
                  ? "0 " + currValue(50, 100, windowWidth)
                  : "0 20rem",
              margin: `${currValue(40, 60, windowWidth)} 0`,
            }}
          >
            <ArrowLeft
              className="swiperButtonPrev"
              onClick={() => {
                if (swiper) {
                  swiper.slidePrev();
                }
              }}
            />
            <ArrowRight
              className="swiperButtonNext"
              onClick={() => {
                if (swiper) {
                  swiper.slideNext();
                }
              }}
            />
          </NavigationWrapper>
        ) : null}
        {showBottomLine ? <div className="line" /> : null}
      </ListViewHorizontalWrapper>
    );
  }
);

export default KTListViewHorizontal;
