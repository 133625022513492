import styled from "styled-components";

export const AboutRememberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 480px) {
    gap: 20rem;
  }
`;

export const AboutRememberTitle = styled.div`
  color: var(--black-19192a, #19192a);
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 56px */

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 30px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20rem;
  }
`;

export const AboutRememberSubTitle = styled.div`
  color: var(--non-selectable, #5f7595);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    font-size: 16px;
  }

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 14rem;
  }
`;

export const AboutRememberPoster = styled.video`
  width: 100%;
  height: auto;
`;
