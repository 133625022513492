import styled from "styled-components";

export const MAMenuSocialWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 20rem;

  padding: 24rem 44rem;
`;

export const MAMenuSocialItem = styled.img`
  width: 40rem;
  height: 40rem;
`;
