import styled from "styled-components";

export const AppFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 100px;

  @media screen and (max-width: 1919px) and (min-width: 1024px) {
    margin: 0 50px;
  }
  @media screen and (max-width: 1023px) and (min-width: 481px) {
    margin: 0 24px;
  }
`;

export const AppFooterDivider = styled.div`
  height: 1px;
  background: #d9d9d9;
`;
