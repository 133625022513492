import styled from "styled-components";

export const ClassifySubHeaderMultipleWrapper = styled.div`
  .ktSwiper {
    padding: 0 20rem;
  }

  .ktSwiperSlide {
    width: fit-content;
    height: fit-content;
  }

  .title {
    padding: 6rem 14rem;
    color: #000;
    font-family: Inter;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 128.523%; /* 17.993px */
    border-radius: 10rem;
    border: 1rem solid var(--icon-4d-black, #4d4d4d);
    background: #fff;
    width: fit-content;
    height: fit-content;
  }

  .avtive {
    color: #fff;
    border: 1rem solid var(--icon-line-black, #19192a);
    background: var(--bg-piclist, #111120);
  }
`;
