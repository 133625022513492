import styled from "styled-components";

export const EventCellExploreDesktopWrapper = styled.div`
  width: 458px;
  height: 430px;
  flex-shrink: 0;
`;

export const EventCellExploreDesktopTopWrapper = styled.div`
  width: 458px;
  height: 320px;
  flex-shrink: 0;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  .optionBar {
    display: flex;
    flex-direction: row;
    padding: 24px;
    gap: 24px;
    .icon {
      width: 28px;
      height: 28px;
    }
  }

  .dateBar {
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    margin: 20px;
    gap: 6px;

    .date {
      color: var(--icon-4d-black, #19192a);
      text-align: right;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    .time {
      color: var(--icon-4d-black, #4d4d4d);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .week {
      color: var(--icon-4d-black, #4d4d4d);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .divider {
      color: var(--icon-4d-black, #c8c8c8);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

export const EventCellExploreDesktopBottomWrapper = styled.div`
  display: flex;
  width: 418px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;

  .title {
    padding: 2px 0;
    color: #000;
    font-family: Merriweather;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .address {
    padding: 3px 0;
    color: var(--iconb3, #b3b3b3);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
