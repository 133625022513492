import React, { memo } from "react";
import {
  AnyLocationCellIcon,
  AnyLocationCellTitle,
  DropdownAnyLocationCellWrapper,
} from "./style";

const KTDropdownAnyLocationCell = memo(() => {
  return (
    <DropdownAnyLocationCellWrapper>
      <AnyLocationCellIcon
        src={require("@/assets/img/icons/icon-any.png")}
      />
      <AnyLocationCellTitle>Any Location</AnyLocationCellTitle>
    </DropdownAnyLocationCellWrapper>
  );
});

export default KTDropdownAnyLocationCell;
