import styled from "styled-components";

export const SearchKeywordsDtpDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px 50px;

  gap: 20px;

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
`;

export const SearchKeywordsDtpDropdownInputWrapper = styled.div`
  width: auto;
  flex: 1;

  display: flex;
  flex-direction: row;
  gap: 20px;

  .input {
    min-width: 269px;
    flex: 1;

    color: #4d4d4d;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  :where(.css-dev-only-do-not-override-byeoj0).ant-input {
    padding: 0;
  }

  .searchButton {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    cursor: pointer;
  }

  @media screen and (min-width: 481px) and (max-width: 600px) {
    .input {
      min-width: 140px;
    }
  }
`;

export const SearchKeywordsDtpDropdownDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #f2f2f2;
`;

export const SearchKeywordsDtpDropdownListContent = styled.div`
  display: flex;
  flex-direction: row;

  gap: 60px;
`;

export const SearchKeywordsDtpDropdownCellDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #f2f2f2;
`;
