import styled from "styled-components";
import { KT_THEME } from "../../../../common/constants";

export const AppHeaderRightContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  .menu-item {
    width: 80px;
    height: 79px;
    margin: 0px;
    color: ${(props) =>
      props.theme === KT_THEME.DEFAULT ? "#f2f2f2" : "#19192A"};
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;
  }

  .menu-item-active {
    width: 80px;
    height: 59px;
    margin: 0px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 10px solid white;
    border-top: 10px solid rgba(0, 0, 0, 0);

    text-decoration: none;
  }
`;

export const AppHeaderRightMenuButton = styled.img`
  width: 30px;
  height: 30px;
  flex-shrink: 0;

  cursor: pointer;
  user-select: none;
`;
