import { styled } from "styled-components";

export const AppHeaderMobileWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: start;
`


export const NoFees = styled.div`
display:flex;
justify-content: start;
font-size: 25px;

margin-top: -25rem;
margin-left:20rem;
position: relative;
font-family: Inter;
font-size: 25rem;
font-style: normal;
font-weight: 900;

.divider {
    background: #d9d9d9;
    height: 1rem;
    margin: 0 10rem;
  }

`;
