import { styled } from "styled-components";

export const EventsListViewWrapper = styled.div`
  display: grid;
  column-gap: 32px;
  justify-content: center;
  align-items: center;

  margin: 100px;

  @media screen and (min-width: 481px) and (max-width: 1023px) {
    margin: 40px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1919px) {
    margin: 50px;
  }

`;
