import React, { memo } from "react";
import {
  SearchVenueCellWrapper,
  SearchVenueContentWrapper,
  SearchVenueName,
  SearchVenuePortrait,
} from "./style";

const KTSearchVenueCell = memo(({ venue, onClick }) => {
  return (
    <SearchVenueCellWrapper
      onClick={() => {
        onClick(venue);
      }}
    >
      <SearchVenuePortrait
        src={require("@/assets/img/icons/icon-venue-default@3x.png")}
      />
      <SearchVenueContentWrapper>
        <SearchVenueName>{venue?.name}</SearchVenueName>
      </SearchVenueContentWrapper>
    </SearchVenueCellWrapper>
  );
});

export default KTSearchVenueCell;
