import React, { memo, useContext } from "react";
import { HomeFilterDropdownItem } from "../../../home-filter-bar/buttons/style";
import { Dropdown } from "antd";
import KTFilterBaritem from "../../../../../../../../components/filter/filter-baritem";
import { homeAllEventsSortModes } from "../../../../../../../../common/home/home-data";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChangeHomeSortModeAction } from "../../../../../../store/actionCreators";
import KTWindowSizeContext from "../../../../../../../../common/context/windowSizeContext";

const KTFilterSortButton = memo(() => {
  const windowWidth = useContext(KTWindowSizeContext)?.windowSize?.width;

  const dispatch = useDispatch();
  const { sortMode } = useSelector(
    (state) => ({
      sortMode: state.getIn(["home", "sortMode"]),
    }),
    shallowEqual
  );

  const onSortModeClick = ({ key }) => {
    const sortMode = homeAllEventsSortModes[key];
    dispatch(getChangeHomeSortModeAction(sortMode));
  };

  const items = homeAllEventsSortModes
    .map((ele, index, array) => {
      const resultArr = [
        {
          label: (
            <HomeFilterDropdownItem className="title">
              {ele.title}
            </HomeFilterDropdownItem>
          ),
          key: index,
        },
      ];

      if (index < array.length - 1) {
        resultArr.push({
          type: "divider",
          style: { margin: windowWidth > 480 ? "0 20px" : "0 20rem" },
          key: `divider${ele.sort}`,
        });
      }

      return resultArr;
    })
    .flat();

  return (
    <Dropdown
      autoAdjustOverflow={false}
      menu={{ items, onClick: onSortModeClick }}
      trigger={["click"]}
      overlayStyle={{
        overflow: "hidden",
        borderRadius: windowWidth > 480 ? "10px" : "10rem",
        border:
          windowWidth > 480
            ? "1px solid var(--dvdr-stroke-f2, #F2F2F2)"
            : "1rem solid var(--dvdr-stroke-f2, #F2F2F2)",
        background: " #FFF",
        boxShadow:
          windowWidth > 480
            ? "0px 4px 20px 0px rgba(0, 0, 0, 0.10)"
            : "0px 4rem 20rem 0px rgba(0, 0, 0, 0.10)",
      }}
    >
      <KTFilterBaritem title={sortMode.title} />
    </Dropdown>
  );
});

export default KTFilterSortButton;
