/**
 * 0- default, popularity, 1 - date , 2 - price low to hight, 3 - price high to low
 */
export const sportsAllEventsSortModes = [
  { title: "Sort by date", sort: 1 },
  { title: "Sort by Popularity", sort: 0 },
  { title: "Price low to high", sort: 2 },
  { title: "Price high to low", sort: 3 },
];

/**
 * 0: Any Dates
 * 1:This Weekend
 * 2:Next Weekend
 * 3:Next 7 Days
 * 4:Next 30 Days
 * 5:Next 60 Days
 * 6:Custom
 */
export const sportsFilterDateIntervals = [
  {
    title: "Any Dates",
    value: "0",
  },
  {
    title: "This Weekend",
    value: "1",
  },
  {
    title: "Next Weekend",
    value: "2",
  },
  {
    title: "Next 7 Days",
    value: "3",
  },
  {
    title: "Next 30 Days",
    value: "4",
  },
  {
    title: "Next 60 Days",
    value: "5",
  },
  {
    title: "Custom",
    value: "6",
  },
];

export const sportsCategories = [
  {
    title: "All Sports",
    normalImageName: require("@/assets/img/sports/categories/icon-all-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-all-selected@3x.png"),
    keyWord: "",
  },
  {
    title: "Football",
    normalImageName: require("@/assets/img/sports/categories/icon-football-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-football-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-football-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-football-selected.png"),
    keyWord: "FOOTBALL",
  },
  {
    title: "Basketball",
    normalImageName: require("@/assets/img/sports/categories/icon-basketball-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-basketball-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-basketball-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-basketball-selected.png"),
    keyWord: "Basketball",
  },
  {
    title: "Baseball",
    normalImageName: require("@/assets/img/sports/categories/icon-baseball-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-baseball-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-baseball-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-baseball-selected.png"),
    keyWord: "Baseball",
  },
  {
    title: "Soccer",
    normalImageName: require("@/assets/img/sports/categories/icon-soccer-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-soccer-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-soccer-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-soccer-selected.png"),
    keyWord: "Soccer",
  },
  {
    title: "Hockey",
    normalImageName: require("@/assets/img/sports/categories/icon-hockey-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-hockey-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-hockey-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-hockey-selected.png"),
    keyWord: "Hockey",
  },
  {
    title: "Golf",
    normalImageName: require("@/assets/img/sports/categories/icon-golf-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-golf-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-golf-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-golf-selected.png"),
    keyWord: "GOLF",
  },
  {
    title: "Tennis",
    normalImageName: require("@/assets/img/sports/categories/icon-tennis-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-tennis-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-tennis-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-tennis-selected.png"),
    keyWord: "TENNIS",
  },
  {
    title: "MMA",
    normalImageName: require("@/assets/img/sports/categories/icon-mma-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-mma-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-mma-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-mma-selected.png"),
    keyWord: "MIXED MARTIAL ARTS",
  },
  {
    title: "Auto Racing",
    normalImageName: require("@/assets/img/sports/categories/icon-auto-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-auto-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-auto-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-auto-selected.png"),
    keyWord: "Auto Racing",
  },
  {
    title: "Other Racing",
    normalImageName: require("@/assets/img/sports/categories/icon-racing-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-racing-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-otherracing-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-otherracing-selected.png"),
    keyWord: "Other Racing",
  },
  {
    title: "Volleyball",
    normalImageName: require("@/assets/img/sports/categories/icon-volleyball-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-volleyball-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-volleyball-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-volleyball-selected.png"),
    keyWord: "VOLLEYBALL",
  },
  {
    title: "Boxing",
    normalImageName: require("@/assets/img/sports/categories/icon-boxing-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-boxing-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-boxing-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-boxing-selected.png"),
    keyWord: "BOXING",
  },
  {
    title: "Horse Racing",
    normalImageName: require("@/assets/img/sports/categories/icon-horse-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-horse-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-horse-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-horse-selected.png"),
    keyWord: "Horse",
  },
  {
    title: "Motorcycle Racing",
    normalImageName: require("@/assets/img/sports/categories/icon-motorcycle-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-motorcycle-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-motor-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-motor-selected.png"),
    keyWord: "Motorcycle",
  },
  {
    title: "Rodeo",
    normalImageName: require("@/assets/img/sports/categories/icon-rodeo-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-rodeo-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-rodeo-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-rodeo-selected.png"),
    keyWord: "Rodeo",
  },
  {
    title: "Softball",
    normalImageName: require("@/assets/img/sports/categories/icon-softball-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-softball-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-softball-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-softball-selected.png"),
    keyWord: "SOFTBALL",
  },
  {
    title: "Lacrosse",
    normalImageName: require("@/assets/img/sports/categories/icon-lacrosse-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-lacrosse-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-lacrosse-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-lacrosse-selected.png"),
    keyWord: "LACROSSE",
  },
  {
    title: "Gymnastics",
    normalImageName: require("@/assets/img/sports/categories/icon-gymnastics-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-gymnastics-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-gymnastics-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-gymnastics-selected.png"),
    keyWord: "GYMNASTICS",
  },
  {
    title: "Wrestling",
    normalImageName: require("@/assets/img/sports/categories/icon-wrestling-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-wrestling-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-wrestling-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-wrestling-selected.png"),
    keyWord: "WRESTLING",
  },
  {
    title: "Skating",
    normalImageName: require("@/assets/img/sports/categories/icon-skating-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-skating-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-skating-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-skating-selected.png"),
    keyWord: "Skating",
  },
  {
    title: "Rugby",
    normalImageName: require("@/assets/img/sports/categories/icon-rugby-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-rugby-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-rugby-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-rugby-selected.png"),
    keyWord: "Rugby",
  },
  {
    title: "Other",
    normalImageName: require("@/assets/img/sports/categories/icon-other-unselected@3x.png"),
    selectedImageName: require("@/assets/img/sports/categories/icon-other-selected@3x.png"),
    d_normalImageName: require("@/assets/img/sports/d-categories/icon-other-unselected.png"),
    d_selectedImageName: require("@/assets/img/sports/d-categories/icon-other-selected.png"),
    keyWord: "Other",
  },
];
