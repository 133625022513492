import styled from "styled-components";

export const HomeTrendingMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HomeTrendingMobileListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30rem 0;

  gap: 40rem;
`;
