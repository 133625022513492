import React, { memo } from "react";
import { ResultWrapper } from "./style";
import { renderRoutes } from "react-router-config";
import KTResultHeader from "./c-components/result-header";
import KTResultSilderBar from "./c-components/result-slider-bar";
import { shallowEqual, useSelector } from "react-redux";
import withWindowSize from "@/components/higher-order/withWindowSize";
import KTWindowSizeContext from "../../common/context/windowSizeContext";
import KTResultDtpContent from "./c-pages/dtp-content";

const KTResult = memo((props) => {
  const { keywords = "", suggestions = {} } = useSelector(
    (state) => ({
      keywords: state.getIn(["search", "keywords"]),
      suggestions: state.getIn(["search", "suggestions"]),
    }),
    shallowEqual
  );

  const { route, windowSize } = props;

  const events = suggestions.events;
  const performers = suggestions.performers;
  const venues = suggestions.venues;

  const totalResultCount =
    (events?.totalResultCount ?? 0) +
    (performers?.totalResultCount ?? 0) +
    (venues?.totalResultCount ?? 0);

  return (
    <KTWindowSizeContext.Provider value={{ windowSize: windowSize }}>
      <ResultWrapper>
        <KTResultHeader
          title={`${totalResultCount} results for "${keywords}"`}
        />
        {windowSize.width > 1024 ? (
          <KTResultDtpContent suggestions={suggestions} />
        ) : (
          [
            <KTResultSilderBar suggestions={suggestions} />,
            renderRoutes(route.routes),
          ]
        )}
      </ResultWrapper>
    </KTWindowSizeContext.Provider>
  );
});

export default withWindowSize(KTResult);
