import React, { memo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getChangeSportsLayoutModeAction,
  getChangeSportsTemporalIntervalAction,
  getChangeSportsSortModeAction,
} from "../../store/actionCreators";
import {
  SportsDtpFilterBarDivider,
  SportsDtpFilterBarLeftWrapper,
  SportsDtpFilterBarRightWrapper,
  SportsDtpFilterBarWrapper,
  SportsDtpFilterDropdownTitle,
  SportsDtpFilterDropdownWrapper,
} from "./style";
import { Dropdown } from "antd";
import {
  LAYOUT_MODE_CARD,
  LAYOUT_MODE_LIST,
} from "../../../../common/constants";
import classNames from "classnames";
import { ReactComponent as CardLayoutIcon } from "@/assets/img/icons/icon-layout-card.svg";
import { ReactComponent as ListLayoutIcon } from "@/assets/img/icons/icon-layout-list.svg";
import KTHomeDesktopFilterButton from "../../../home/c-pages/all-events/c-components/home-desktop-filter-bar/c-components/home-desktop-filter-button";
import {
  sportsAllEventsSortModes,
  sportsFilterDateIntervals,
} from "../../local-data";
import KTSwitchCityDtpDropdown from "../../../../components/dropdown/switch-city-dtp-dropdown";
import KTSwitchCustomTimeIntervalDropdown from "../../../../components/dropdown/switch-custom-time-interval-dropdown";
import { getGlobalDateString } from "../../../../utils/tool-time-intervcal";

const KTSportsDtpFiterBar = memo(() => {
  const dispatch = useDispatch();

  const [customTimeIntervalOpen, setCustomTimeIntervalOpen] = useState(false);
  const { sortMode, layoutMode, intervalType, startdt, enddt, city } =
    useSelector(
      (state) => ({
        sortMode: state.getIn(["sports", "sortMode"]),
        layoutMode: state.getIn(["sports", "layoutMode"]),
        categories: state.getIn(["sports", "categories"]),
        intervalType: state.getIn(["sports", "intervalType"]),
        startdt: state.getIn(["sports", "startdt"]),
        enddt: state.getIn(["sports", "enddt"]),
        city: state.getIn(["global", "city"]),
      }),
      shallowEqual
    );

  let cityMessage = "Any Location";

  if (typeof city === "object" && Object.keys(city).length > 0) {
    const name = city.name ?? city.city ?? "";
    const state = city.state ?? "";
    if (name.length === 0 || state.length === 0) {
      cityMessage = `${name}${city.state}`;
    } else {
      cityMessage = `${name}, ${city.state}`;
    }
  }

  const onclickChangeLayoutMode = (mode) => {
    dispatch(getChangeSportsLayoutModeAction(mode));
  };

  function dropdownRender(menu) {
    return (
      <SportsDtpFilterDropdownWrapper>
        {React.cloneElement(menu, {
          style: { boxShadow: "none" },
        })}
      </SportsDtpFilterDropdownWrapper>
    );
  }

  // sort
  const sortItems = sportsAllEventsSortModes
    .map((ele, index) => {
      const resultArr = [
        {
          label: (
            <SportsDtpFilterDropdownTitle>
              {ele.title}
            </SportsDtpFilterDropdownTitle>
          ),
          key: index,
        },
      ];

      resultArr.push({
        type: "divider",
        style: { margin: "0 0" },
      });

      return resultArr;
    })
    .flat();

  const onSortModeClick = ({ key }) => {
    const sortMode = sportsAllEventsSortModes[key];
    dispatch(getChangeSportsSortModeAction(sortMode));
  };

  // date range

  const dateRangeItems = sportsFilterDateIntervals
    .map((ele, index) => {
      const resultArr = [
        {
          label: (
            <SportsDtpFilterDropdownTitle>
              {ele.title}
            </SportsDtpFilterDropdownTitle>
          ),
          key: index,
        },
      ];

      resultArr.push({
        type: "divider",
        style: { margin: "0 0" },
      });

      return resultArr;
    })
    .flat();

  const onDateRangeClick = ({ key }) => {
    const dateRange = sportsFilterDateIntervals[key];
    dispatch(getChangeSportsTemporalIntervalAction(dateRange.value));
    if (key === "6") {
      setCustomTimeIntervalOpen(true);
    }
  };

  const dateMessage = getGlobalDateString(
    sportsFilterDateIntervals,
    intervalType,
    startdt,
    enddt
  );

  return (
    <SportsDtpFilterBarWrapper>
      <SportsDtpFilterBarLeftWrapper>
        <Dropdown
          autoAdjustOverflow={false}
          menu={{ items: sortItems, onClick: onSortModeClick }}
          trigger={["click"]}
          dropdownRender={dropdownRender}
        >
          <KTHomeDesktopFilterButton
            title={sortMode.title}
            styled={1}
            onClick={(e) => e.preventDefault()}
          />
        </Dropdown>

        <SportsDtpFilterBarDivider />

        <KTSwitchCityDtpDropdown>
          <KTHomeDesktopFilterButton
            title={cityMessage}
            onClick={(e) => e.preventDefault()}
          />
        </KTSwitchCityDtpDropdown>

        <KTSwitchCustomTimeIntervalDropdown
          open={customTimeIntervalOpen}
          onCancelClick={() => {
            setCustomTimeIntervalOpen(false);
          }}
          startdt={startdt}
          enddt={enddt}
          didIntervalValueChange={(ele) => {
            dispatch(
              getChangeSportsTemporalIntervalAction("6", ele.startdt, ele.enddt)
            );
          }}
        >
          <Dropdown
            autoAdjustOverflow={false}
            menu={{ items: dateRangeItems, onClick: onDateRangeClick }}
            trigger={["click"]}
            dropdownRender={dropdownRender}
          >
            <KTHomeDesktopFilterButton
              title={dateMessage}
              onClick={(e) => e.preventDefault()}
            />
          </Dropdown>
        </KTSwitchCustomTimeIntervalDropdown>
      </SportsDtpFilterBarLeftWrapper>
      <SportsDtpFilterBarRightWrapper>
        <CardLayoutIcon
          className={classNames("layoutModel", {
            activeLayoutModel: layoutMode === LAYOUT_MODE_CARD,
          })}
          onClick={() => onclickChangeLayoutMode(LAYOUT_MODE_CARD)}
        />
        <ListLayoutIcon
          className={classNames("layoutModel", {
            activeLayoutModel: layoutMode === LAYOUT_MODE_LIST,
          })}
          onClick={() => onclickChangeLayoutMode(LAYOUT_MODE_LIST)}
        />
      </SportsDtpFilterBarRightWrapper>
    </SportsDtpFilterBarWrapper>
  );
});

export default KTSportsDtpFiterBar;
