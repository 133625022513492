import styled from "styled-components";
export const KTEventLoadMoreWrapper = styled.div`
  padding: 100px 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const KTEventLoadMoreTitle = styled.div`
  padding: 10rem 40rem;

  color: #19192a;
  text-align: center;
  font-family: Inter;
  font-size: 15rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 30rem;
  border: 1rem solid #b3b3b3;
  background: #fff;

  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 481px) {
    padding: 10px 40px;
    font-size: 15px;
    border-radius: 30px;
    border: 1px solid #b3b3b3;
  }
`;
