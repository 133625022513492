import React, { memo } from "react";
import { EventCellMobileCardAddressWrapper } from "./style";
import { ReactComponent as AddressIcon } from "@/assets/img/svg/icon-address.svg";

const KTEventCellMobileCardAddress = memo(({ address }) => {
  return (
    <EventCellMobileCardAddressWrapper>
      <AddressIcon className="icon" />
      <div className="title">{address}</div>
    </EventCellMobileCardAddressWrapper>
  );
});

export default KTEventCellMobileCardAddress;
