export const headerLinks = [
  {
    title: "MUSIC",
    link: "/music",
  },
  {
    title: "SPORTS",
    link: "/sports",
  },
  {
    title: "SHOWS",
    link: "/shows",
  },
];

export const mobileHeaderLinks = [
  {
    title: "Home",
    link: "/home/trending",
  },
  {
    title: "Music",
    link: "/music",
  },
  {
    title: "Sports",
    link: "/sports",
  },
  {
    title: "Shows",
    link: "/shows",
  },
];

export const mobileAppFooterHelpfulLinks = [
  {
    title: "FAQs/Support",
    link: "/FAQ",
  },
  {
    title: "Who we are",
    link: "/about",
  },
  {
    title: "Terms",
    link: "/terms/top",
  },
  {
    title: "Privacy Policy",
    link: "/terms/policy",
  },
];

export const appFooterConnect = [
  {
    title: "Who We Are",
    link: "/about",
  },
  {
    title: "Partners",
    link: "",
  },
  {
    title: "Investors",
    link: "",
  },
  {
    title: "Careers",
    link: "",
  },
];

export const appFooterFollowItems = [
  {
    iconName: "icon_follow_pinterest.png",
    link: "",
    alt: "Pinterest",
  },
  {
    iconName: "icon_follow_instagram.png",
    link: "",
    alt: "Instagram",
  },
  {
    iconName: "icon_follow_linkedin.png",
    link: "",
    alt: "LinkedIn",
  },
  {
    iconName: "icon_follow_facebook.png",
    link: "",
    alt: "Facebook",
  },
  {
    iconName: "icon_follow_twitter.png",
    link: "",
    alt: "Twitter",
  },
];

export const appSocials = [
  {
    image: require("@/assets/img/icons/icon-twitter-black.png"),
    link: "https://twitter.com/kustomTickets",
  },

  {
    image: require("@/assets/img/icons/icon-facebook-black.png"),
    link: "https://www.facebook.com/profile.php?id=61555520273176",
  },

  {
    image: require("@/assets/img/icons/icon-instagram-black.png"),
    link: "",
  },
];

export const SITE_MAP_ITEMS = [
  {
    title: "Concert Tickets",
    link: "/music",
  },
  {
    title: "Sport Tickets",
    link: "/sports",
  },
  {
    title: "Theatre Tickets",
    link: "/shows",
  },
];

export const HELPFUL_LINKS_ITEMS = [
  {
    title: "Who we are",
    link: "/about",
  },
  {
    title: "FAQs / Support",
    link: "/FAQ",
  },
  {
    title: "Privacy Policy",
    link: "/terms/policy",
  },
  {
    title: "Terms",
    link: "/terms/top",
  },
];
